import { randomBytes } from 'crypto';

export default {
  getRandomHexadecimalString: (length = 18) => {
    const randomBytesArray = randomBytes(Math.ceil(length / 2)); // Generate random bytes
    return randomBytesArray.toString('hex'); // Convert bytes to hexadecimal string
  },

  getRandomInteger: (min = 0, max = 100) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  },
};
