import React, { useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

import { gql, useMutation, useQuery } from '@apollo/client';

import Colors from './common/Colors';
import ConnectIntegrationModal from './ConnectIntegrationModal';
import ErrorMessage from './common/ErrorMessage';
import ShimmerBar from './common/ShimmerBar';
import Toggle from './common/Toggle';
import Tooltip from './common/Tooltip';
import { useSharedStyles } from '../utils/CssUtil';
import { DateFormatYearMonthDate12HourTime, DateFormatYearMonthDateAt12HourTime } from '../../src/utils/FormatUtil';
import {
  ExternalAccountModel,
  GetIntegrationCardExternalAccountsQuery,
  GetIntegrationCardExternalAccountsQueryVariables,
  GetIntegrationsListAndUserListQuery,
  GetIntegrationsListAndUserListQueryVariables,
  Integration,
  IntegrationLastImported,
  SetIntegrationCardExternalAccountDescMutation,
  SetIntegrationCardExternalAccountDescMutationVariables,
  SetIntegrationStateMutation,
  SetIntegrationStateMutationVariables,
  User,
} from '../generated/graphql';
import FormButton, { FormButtonStyle } from './common/FormButton';
import {
  TIntegrationExecutionFrequency,
  TIntegrationExecutionFrequencyToMomentUnit,
} from '../../src/types/IntegrationTypes';
import { TIntegrationStateType, TIntegrationTypes, TUserRole } from '../../src/types/BaseTypes';
import getAssetPath, { getVendorLogoAssetPath } from '../utils/AssetPathUtil';

type vendorIntegrationsType = Record<Partial<TIntegrationTypes> | string, Integration[]>;

const useUserRowStyles = createUseStyles({
  integrationHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  integrationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '130px',
  },
  integrationsList: {
    flexDirection: 'column',
    flex: 1,
  },
  integrationRow: {
    display: 'flex',
    flex: 1,
    height: '48px',
    marginBottom: '10px',
  },
  integrationHeaderRow: {
    height: 'auto',
  },
  integrationName: {
    width: '240px',
    fontSize: '12px',
    fontWeight: 600,
    padding: '4px 8px',
    '& > p': {
      margin: '2px 0',
      lineHeight: 1.3,
    },
  },
  integrationModifiedField: {
    color: Colors.MEDIUM_GRAY,
    fontSize: 10,
    fontWeight: 'normal',
    textTransform: 'capitalize',
  },
  integrationStatus: {
    textAlign: 'center',
    width: '100px',
    fontSize: '12px',
    fontWeight: 600,
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'center',
  },
  integrationLastImported: {
    textAlign: 'center',
    flex: 1,
    fontSize: '12px',
    fontWeight: 600,
    padding: '4px 8px',
  },
  integrationNextScheduledExecution: {
    textAlign: 'center',
    flex: 1,
    fontSize: '12px',
    fontWeight: 600,
    padding: '4px 8px',
  },
  integrationExecutionFrequency: {
    textAlign: 'center',
    flex: 1,
    fontSize: '12px',
    fontWeight: 600,
    padding: '4px 8px',
    textTransform: 'capitalize',
  },
  integrationActions: {
    textAlign: 'center',
    width: '64px',
    fontSize: '12px',
    fontWeight: 600,
    padding: '4px 8px',
  },
  cashAccountSection: {
    display: 'flex',
    flexFlow: 'column',
  },
  cashAccountBankContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: '10px',
  },
  cashAccountBankLogo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 130,
  },
  cashAccountList: {
    padding: '4px 8px',
    flex: 1,
  },
  cashAccountListTitle: {
    fontSize: '12px',
    fontWeight: 600,
    marginLeft: 2,
    marginBottom: 5,
  },
  cashAccountListItem: {
    margin: '2px 0',
  },
  cashAccountListItemLast4: {
    color: Colors.MEDIUM_GRAY,
    fontSize: '10px',
    marginLeft: 2,
    marginBottom: 4,
  },
  cashAccountListItemInputContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  cashAccountListItemInput: {
    width: 200,
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    borderRadius: '3px',
    fontSize: '10px',
    fontWeight: 500,
    marginLeft: '-3px',
    opacity: 0.25,
    padding: '3px 4px',
    '&:focus': {
      opacity: 1,
      outlineWidth: 0,
      outline: 'none',
    },
    '&:hover': {
      borderColor: Colors.DARK_GRAY,
      opacity: 1,
    },
  },
  cashAccountListItemButton: {
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    fontSize: '10px',
    marginLeft: '15px',
    opacity: 0.25,
    '&:hover': {
      opacity: 1,
      cursor: 'pointer',
    },
  },
});

const QUERY = gql`
  query GetIntegrationsListAndUserList {
    integrationList {
      id
      entity {
        name
        integrationType
        state
        lastUpdatedByUserId
        executionFrequency
        nextScheduledStartTime
        createdByUserId
        updatedAt
      }
    }

    userList {
      users {
        id
        firstName
        lastName
        email
        role
      }
    }

    integrationsLastImported {
      integrationId
      completionTimeMs
      numeralRecordsProcessed
      externalRecordsProcessed
    }

    currentUser {
      firstName
      lastName
      email
      role
      company {
        isRealCustomerCompany
        isTestCompany
        hasSelfServeIntegrationsEnabled
      }
    }
  }
`;

const MUTATION = gql`
  mutation SetIntegrationState($integration_id: String!, $new_state: String!) {
    setIntegrationState(integrationId: $integration_id, state: $new_state) {
      id
      entity {
        name
        integrationType
        state
        lastUpdatedByUserId
      }
    }
  }
`;

const EXTERNAL_ACCOUNTS_QUERY = gql`
  query GetIntegrationCardExternalAccounts {
    getIntegrationCardExternalAccounts {
      id
      institutionName
      descriptiveName
      accountNumberLastFourDigits
      accountType
      state
      baseCurrency
      companyId
      integrationId
    }
  }
`;

const EXTERNAL_ACCOUNT_DESC_MUTATION = gql`
  mutation SetIntegrationCardExternalAccountDesc($id: String!, $descriptiveName: String!) {
    setIntegrationCardExternalAccountDesc(id: $id, descriptiveName: $descriptiveName) {
      id
      descriptiveName
    }
  }
`;

const IntegrationsCard: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const classes = useUserRowStyles();

  const [extAccountDescValues, setExtAccountDescValues] = useState<{ [key: string]: string }>({});
  const [integrationModalIsOpen, setIntegrationModalIsOpen] = React.useState<boolean>(false);

  const {
    data,
    loading: isLoading,
    refetch: refetchIntegrations,
    error,
  } = useQuery<GetIntegrationsListAndUserListQuery, GetIntegrationsListAndUserListQueryVariables>(QUERY);
  const [changeStateMutation, { loading: isChangeStateLoading, error: changeStateErrorStr }] = useMutation<
    SetIntegrationStateMutation,
    SetIntegrationStateMutationVariables
  >(MUTATION);

  const { loading: isExtLoading, data: extData } = useQuery<
    GetIntegrationCardExternalAccountsQuery,
    GetIntegrationCardExternalAccountsQueryVariables
  >(EXTERNAL_ACCOUNTS_QUERY);

  const extDataGrouped =
    extData?.getIntegrationCardExternalAccounts?.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.institutionName]: [...(acc?.[cur.institutionName] || []), cur],
      };
    }, {} as { [key: string]: Partial<ExternalAccountModel>[] }) || {};

  const [changeExtAccountDescMutation, { loading: isChangeExtAccountDescLoading }] = useMutation<
    SetIntegrationCardExternalAccountDescMutation,
    SetIntegrationCardExternalAccountDescMutationVariables
  >(EXTERNAL_ACCOUNT_DESC_MUTATION);

  const vendorIntegrations = data?.integrationList.reduce(
    (allIntegrations: vendorIntegrationsType, currentIntegration: Integration) => {
      if (!allIntegrations[currentIntegration?.entity?.integrationType]) {
        allIntegrations[currentIntegration?.entity?.integrationType] = [currentIntegration];
      } else {
        allIntegrations[currentIntegration?.entity?.integrationType].push(currentIntegration);
      }
      allIntegrations[currentIntegration?.entity?.integrationType].sort((a, b) =>
        a?.entity.name.localeCompare(b?.entity.name)
      );
      return allIntegrations;
    },
    {}
  );

  const getUserFullNameFromId = (id: string) => {
    const user = data?.userList?.users?.filter((u: Partial<User>) => u.id === id)[0];
    return `${user?.firstName} ${user?.lastName}`;
  };

  const toggleHandler = (id: string, state: TIntegrationStateType) => () =>
    changeStateMutation({
      variables: {
        integration_id: id,
        new_state: state === TIntegrationStateType.ACTIVE ? TIntegrationStateType.PAUSED : TIntegrationStateType.ACTIVE,
      },
    });

  const getIngrationLastImportedData = (id: string) => {
    return data?.integrationsLastImported?.filter(
      (imports: IntegrationLastImported) => imports?.integrationId === id
    )[0];
  };

  const isUserAdmin = data?.currentUser?.role === TUserRole.ADMIN;
  const isRealCustomerCompany = !!data?.currentUser.company?.isRealCustomerCompany;
  const isTestCompany = !!data?.currentUser.company?.isTestCompany;
  const hasSelfServeIntegrationsEnabled = !!data?.currentUser.company?.hasSelfServeIntegrationsEnabled;

  const closeIntegrationModal = () => {
    refetchIntegrations();
    setIntegrationModalIsOpen(false);
  };

  const getNextScheduledStartTimeFormatted = (integration: Integration, isTestCompany = false) => {
    if (isTestCompany) {
      return moment(new Date()).set('hour', 23).startOf('hour').format(DateFormatYearMonthDateAt12HourTime);
    }
    if (integration?.entity?.nextScheduledStartTime && integration?.entity?.executionFrequency) {
      const nextScheduledStartTimeMs = new Date(integration?.entity?.nextScheduledStartTime).getTime();
      return moment(nextScheduledStartTimeMs)
        .add(
          nextScheduledStartTimeMs <= Date.now() ? 1 : 0,
          TIntegrationExecutionFrequencyToMomentUnit[
            integration.entity.executionFrequency as TIntegrationExecutionFrequency
          ]
        )
        .format(DateFormatYearMonthDateAt12HourTime);
    } else {
      return '';
    }
  };

  const extAccountDescHandler = (id?: string, newDesc?: string) => {
    if (id && newDesc) {
      changeExtAccountDescMutation({
        variables: {
          id,
          descriptiveName: newDesc,
        },
      });
    }
  };

  if (error || changeStateErrorStr) {
    return <ErrorMessage error={error || changeStateErrorStr} />;
  }

  return (
    <div
      className={sharedClasses.contentWrapper}
      style={{
        marginTop: '-235px',
      }}
    >
      <ConnectIntegrationModal isOpen={integrationModalIsOpen} onClose={closeIntegrationModal} />
      <div>
        <div className={classes.integrationHeader}>
          <h4>Financial Tech Stack</h4>
          <div style={{ marginLeft: 'auto' }}>
            {isUserAdmin && hasSelfServeIntegrationsEnabled ? (
              <FormButton height={36} onClick={() => setIntegrationModalIsOpen(true)} style={FormButtonStyle.ACCENTED}>
                Connect
              </FormButton>
            ) : null}
          </div>
        </div>
        <div className={classes.integrationWrapper}>
          <div className={classes.logo}></div>
          <div className={classes.integrationsList}>
            <div className={classnames(classes.integrationRow, classes.integrationHeaderRow)}>
              <div className={classes.integrationName}>Integration</div>
              <div className={classes.integrationStatus}>Status</div>
              <div className={classes.integrationLastImported}>Last Imported</div>
              <div className={classes.integrationNextScheduledExecution}>Next Scheduled Import</div>
              <div className={classes.integrationExecutionFrequency}>Import Schedule</div>
              <div className={classes.integrationActions}>Actions</div>
            </div>
          </div>
        </div>
        {isLoading
          ? null
          : Object.keys(TIntegrationTypes)
              .sort()
              .map((vendor) => {
                if (!vendorIntegrations || !vendorIntegrations[vendor]) {
                  return null;
                }

                return (
                  <div className={classes.integrationWrapper} key={vendor}>
                    <div className={classes.integrationsList}>
                      {vendorIntegrations[vendor]?.map((integration: Integration) => {
                        const lastImportedData = getIngrationLastImportedData(integration.id);

                        return (
                          <div key={integration.id} className={classes.integrationRow}>
                            <div className={classes.logo}>
                              <img src={getVendorLogoAssetPath(vendor as TIntegrationTypes)} width={74} />
                            </div>
                            <div className={classes.integrationName}>
                              <p>{integration?.entity?.name}</p>
                              <p className={classes.integrationModifiedField}>{`Last Modified by ${
                                isRealCustomerCompany
                                  ? 'Numeral'
                                  : getUserFullNameFromId(integration?.entity.lastUpdatedByUserId)
                              } at ${moment(integration?.entity?.updatedAt).format(
                                DateFormatYearMonthDate12HourTime
                              )}`}</p>
                            </div>
                            <div className={classes.integrationStatus}>
                              <Toggle
                                isOn={integration?.entity.state === TIntegrationStateType.ACTIVE}
                                onClick={toggleHandler(
                                  integration.id,
                                  integration?.entity.state as TIntegrationStateType
                                )}
                                isDisabled={isRealCustomerCompany || isChangeStateLoading}
                                data-integration-id={integration.id}
                              />
                            </div>
                            <div className={classes.integrationLastImported}>
                              {lastImportedData?.completionTimeMs ? (
                                <Tooltip
                                  content={
                                    <ul>
                                      <li>{lastImportedData?.externalRecordsProcessed} External records scanned</li>
                                      <li>{lastImportedData?.numeralRecordsProcessed} Numeral records generated</li>
                                    </ul>
                                  }
                                >
                                  {isTestCompany
                                    ? moment(new Date())
                                        .subtract(1, 'day')
                                        .set('hour', 23)
                                        .startOf('hour')
                                        .format(DateFormatYearMonthDateAt12HourTime)
                                    : moment(new Date(lastImportedData?.completionTimeMs)).format(
                                        DateFormatYearMonthDateAt12HourTime
                                      )}
                                </Tooltip>
                              ) : (
                                'N/A'
                              )}
                            </div>
                            <div className={classes.integrationNextScheduledExecution}>
                              {integration?.entity?.state === TIntegrationStateType.ACTIVE &&
                              integration?.entity.executionFrequency
                                ? getNextScheduledStartTimeFormatted(integration, isTestCompany)
                                : 'N/A'}
                            </div>
                            <div className={classes.integrationExecutionFrequency}>
                              {integration?.entity?.state === TIntegrationStateType.ACTIVE
                                ? integration?.entity?.executionFrequency
                                  ? integration?.entity?.executionFrequency.toLowerCase()
                                  : 'N/A'
                                : 'N/A'}
                            </div>
                            <div className={classes.integrationActions}>
                              <img src={`${getAssetPath()}/images/icon_action.svg`} height={18} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
      </div>
      {isExtLoading ? (
        <ShimmerBar delayMs={10000} height={100} width={400} isLightMode={true} />
      ) : (
        <div className={classes.cashAccountSection}>
          <h4>Bank Accounts</h4>
          {Object.keys(extDataGrouped).map((institutionName, institutionIdx) => (
            <div
              key={`cash-account-bank-${institutionIdx}-${institutionName}`}
              className={classes.cashAccountBankContainer}
            >
              <div className={classes.cashAccountBankLogo}>
                <img
                  src={`${getAssetPath()}/images/vendor/logo_${institutionName.toLowerCase().replace(/\s/g, '_')}.jpg`}
                  style={{ maxWidth: 110, maxHeight: 30 }}
                />
              </div>

              <div className={classes.cashAccountList}>
                <div className={classes.cashAccountListTitle}>Accounts</div>
                {extDataGrouped[institutionName].map((account) => {
                  const extAccountKey = `${account.id}`;
                  return (
                    <div
                      key={`cash-account-${account.id}-${account.institutionName}`}
                      className={classes.cashAccountListItem}
                    >
                      {account.accountNumberLastFourDigits ? (
                        <div className={classes.cashAccountListItemLast4}>
                          Ending {account.accountNumberLastFourDigits}
                        </div>
                      ) : null}
                      <div className={classes.cashAccountListItemInputContainer}>
                        <input
                          className={classes.cashAccountListItemInput}
                          key={extAccountKey}
                          type="text"
                          defaultValue={account.descriptiveName}
                          onChange={(e) => {
                            setExtAccountDescValues((prev) => {
                              return {
                                ...prev,
                                [extAccountKey]: e.target.value,
                              };
                            });
                          }}
                          disabled={!isUserAdmin}
                          maxLength={40}
                        />
                        {isUserAdmin ? (
                          <button
                            className={classes.cashAccountListItemButton}
                            onClick={() => extAccountDescHandler(account.id, extAccountDescValues[extAccountKey])}
                            disabled={
                              isChangeExtAccountDescLoading || extAccountDescValues[extAccountKey]?.endsWith(' ')
                            }
                            hidden={
                              !extAccountDescValues[extAccountKey] ||
                              account.descriptiveName === extAccountDescValues[extAccountKey]
                            }
                          >
                            {account.descriptiveName ? 'Update' : 'Add description'}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default IntegrationsCard;
