import React from 'react';

import { createUseStyles } from 'react-jss';
import getAssetPath from '../../utils/AssetPathUtil';

const useStyles = createUseStyles({
  loaderContainer: {
    opacity: 0.75,
    position: 'relative',
  },

  loaderImageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

type LoaderAnimationProps = {
  height: number;
};

const LoaderAnimation: React.FunctionComponent<LoaderAnimationProps> = (props: LoaderAnimationProps) => {
  const classes = useStyles();

  return (
    <div className={classes.loaderContainer} style={{ height: `${props.height}px` }}>
      <div className={classes.loaderImageContainer} style={{ height: `${props.height}px` }}>
        <img src={`${getAssetPath()}/images/logo_small_animated_looped.gif`} height={`${props.height}px`} />
      </div>
    </div>
  );
};

export default LoaderAnimation;
