export enum TNotificationType {
  TEXT = 'TEXT',
  CSV_DOWNLOAD = 'CSV_DOWNLOAD',
  RECONCILIATION_EXCEPTION = 'RECONCILIATION_EXCEPTION',
}

export enum TNotificationCreatorType {
  EXPORTS = 'EXPORTS',
  INVITES = 'INVITES',
  GENERAL = 'GENERAL',
  RECONCILIATION = 'RECONCILIATION',
}
