import React from 'react';
import classnames from 'classnames';

import Banner from './Banner';
import Colors from './common/Colors';
import { createUseStyles } from 'react-jss';
import getAssetPath from '../utils/AssetPathUtil';
import { useSharedStyles } from '../utils/CssUtil';

const useStyles = createUseStyles({
  auditTrailTimeRange: {
    fontSize: '16px',
    marginBottom: '20px',
    textAlign: 'center',
    '& span': {
      fontWeight: 'bold',
    },
    '& a': {
      display: 'inline-block',
      color: Colors.DARK_GRAY,
      border: `1px solid ${Colors.DARK_GRAY}`,
      borderRadius: '4px',
      padding: '5px 10px',
      marginLeft: '20px',
    },
  },
  auditTrailRow: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '20px',
  },
  auditTrailLineContainer: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    minWidth: '50px',
    position: 'relative',
  },
  auditTrailLine: {
    marginLeft: '50%',
    borderLeft: `1px solid ${Colors.MEDIUM_GRAY}`,
  },
  auditTrailDot: {
    position: 'absolute',
    top: '1px',
    left: '19px',
    '&:before': {
      backgroundColor: Colors.WHITE,
      color: Colors.MEDIUM_GRAY,
      content: '"◎"',
      display: 'block',
      fontSize: '14px',
    },
  },
  auditTrailItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 120px)',
  },
  auditTrailDate: {
    color: Colors.MEDIUM_GRAY,
    fontSize: '14px',
    marginBottom: '20px',
  },
  auditTrailItem: {
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: '5px',
    fontSize: '14px',
    padding: '20px',
    marginBottom: '20px',
    width: '100%',
  },
  auditTrailItemTopLine: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  auditTrailItemTopLineSummary: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& img': {
      width: '36px',
      height: '36px',
      borderRadius: '18px',
      marginRight: '10px',
    },
  },
  auditTrailItemTopLineSummaryDescription: {
    '& a': {
      color: Colors.DARK_GRAY,
      textDecoration: 'underline dotted',
      fontWeight: 'bold',
    },
  },
  auditTrailItemTopLineActions: {
    marginLeft: 'auto',
    '& a': {
      color: Colors.DARK_GRAY,
      border: `1px solid ${Colors.DARK_GRAY}`,
      borderRadius: '5px',
      fontSize: '14px',
      padding: '8px 15px',
    },
  },
  auditTrailItemContent: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
    paddingLeft: '41px',
  },
  auditTrailItemContentBefore: {
    border: `1px solid ${Colors.DARK_GRAY}`,
    borderRadius: 5,
    fontSize: '12px',
    position: 'relative',
    width: '50%',
    marginRight: 15,
    opacity: 0.4,
  },
  auditTrailItemContentAfter: {
    border: `1px solid ${Colors.DARK_GRAY}`,
    borderRadius: 5,
    fontSize: '12px',
    position: 'relative',
    width: '50%',
    marginLeft: 15,
    '&:before': {
      content: '"»"',
      position: 'absolute',
      top: '50%',
      left: 0,
      fontSize: '18px',
      marginTop: -10,
      marginLeft: -21,
    },
  },
  auditTrailItemEntityHeader: {
    backgroundColor: Colors.FAINT_GRAY,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: '5px 10px',
    textAlign: 'center',
    fontWeight: 'bold',
    borderBottom: `1px solid ${Colors.DARK_GRAY}`,
  },
  auditTrailItemEntityBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  auditTrailItemEntityBodyRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    '& a': {
      color: Colors.DARK_GRAY,
      fontWeight: 'bold',
    },
  },
  auditTrailItemEntityBodyRowField: {
    width: 45,
    marginRight: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  auditTrailItemEntityBodyRowContent: {},
  auditTrailItemEntityChangedValueBefore: {
    display: 'inline-block',
    padding: '0 2px',
    borderRadius: 2,
    backgroundColor: 'rgb(255, 182, 171)',
  },
  auditTrailItemEntityChangedValueAfter: {
    display: 'inline-block',
    padding: '0 2px',
    borderRadius: 2,
    backgroundColor: 'rgb(210, 255, 213)',
  },
  auditTrailItemContentJournalContainer: {
    border: `1px solid ${Colors.BLACK}`,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontSize: '12px',
  },
  auditTrailItemJournalHeader: {
    backgroundColor: Colors.FAINT_GRAY,
    borderBottom: `1px solid ${Colors.BLACK}`,
    textAlign: 'center',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    fontWeight: 'bold',
    padding: '5px 10px',
  },
  auditTrailItemJournalBody: {
    padding: '5px 0',
  },
  auditTrailItemJournalRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '3px 5px',
    '&:first-child': {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
  auditTrailItemJournalField: {
    width: '25%',
    '&:first-child': {
      width: '50%',
    },
  },
  auditTrailNavRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  auditTrailNavContainer: {
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: 7,
    '& a': {
      color: Colors.DARK_GRAY,
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  auditTrailNavNewer: {
    borderRight: `1px solid ${Colors.MEDIUM_GRAY}`,
    padding: '10px 20px',
  },
  auditTrailNavOlder: {
    padding: '10px 20px',
  },
  auditTrailNavDisabled: {
    color: Colors.MEDIUM_GRAY,
  },
});

const AuditTrail: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
        <div className={classes.auditTrailTimeRange}>
          Viewing audit trail from <span>all time</span> <a href="#">Update time range...</a>
        </div>
        <div className={classes.auditTrailRow}>
          <div className={classes.auditTrailLineContainer}>
            <div className={classes.auditTrailLine} />
          </div>
          <div className={classes.auditTrailItemContainer}></div>
        </div>
        <div className={classes.auditTrailRow}>
          <div className={classes.auditTrailLineContainer}>
            <div className={classes.auditTrailLine} />
            <div className={classes.auditTrailDot} />
          </div>
          <div className={classes.auditTrailItemContainer}>
            <div className={classes.auditTrailDate}>October 11, 2022</div>
            <div className={classes.auditTrailItem}>
              <div className={classes.auditTrailItemTopLine}>
                <div className={classes.auditTrailItemTopLineSummary}>
                  <img src={`${getAssetPath()}/images/profiles/yl.jpeg`} />
                  <div className={classes.auditTrailItemTopLineSummaryDescription}>
                    <a href="#">Yufei Liu</a> updated <a href="#">Rule ru-8ae94f2</a> at 12:15pm
                  </div>
                </div>
                <div className={classes.auditTrailItemTopLineActions}>
                  <a href="#">Details</a>
                </div>
              </div>
              <div className={classes.auditTrailItemContent}>
                <div className={classes.auditTrailItemContentBefore}>
                  <div className={classes.auditTrailItemEntityHeader}>Rule ru-8ae94f2</div>
                  <div className={classes.auditTrailItemEntityBody}>
                    <div className={classes.auditTrailItemEntityBodyRow}>
                      <div className={classes.auditTrailItemEntityBodyRowField}>If:</div>
                      <div className={classes.auditTrailItemEntityBodyRowContent}>
                        <strong>Payment</strong> is received for subscription SKU <a href="#">ip-391ae031a</a>
                      </div>
                    </div>
                    <div className={classes.auditTrailItemEntityBodyRow}>
                      <div className={classes.auditTrailItemEntityBodyRowField}>Then:</div>
                      <div className={classes.auditTrailItemEntityBodyRowContent}>
                        Recognize revenue over{' '}
                        <span className={classes.auditTrailItemEntityChangedValueBefore}>
                          <strong>24 months</strong>
                        </span>{' '}
                        from <strong>date of payment</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.auditTrailItemContentAfter}>
                  <div className={classes.auditTrailItemEntityHeader}>Rule ru-8ae94f2</div>
                  <div className={classes.auditTrailItemEntityBody}>
                    <div className={classes.auditTrailItemEntityBodyRow}>
                      <div className={classes.auditTrailItemEntityBodyRowField}>If:</div>
                      <div className={classes.auditTrailItemEntityBodyRowContent}>
                        <strong>Payment</strong> is received for subscription SKU <a href="#">ip-391ae031a</a>
                      </div>
                    </div>
                    <div className={classes.auditTrailItemEntityBodyRow}>
                      <div className={classes.auditTrailItemEntityBodyRowField}>Then:</div>
                      <div className={classes.auditTrailItemEntityBodyRowContent}>
                        Recognize revenue over{' '}
                        <span className={classes.auditTrailItemEntityChangedValueAfter}>
                          <strong>12 months</strong>
                        </span>{' '}
                        from <strong>date of payment</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.auditTrailItem}>
              <div className={classes.auditTrailItemTopLine}>
                <div className={classes.auditTrailItemTopLineSummary}>
                  <img src={`${getAssetPath()}/images/profiles/ck.jpeg`} />
                  <div className={classes.auditTrailItemTopLineSummaryDescription}>
                    <a href="#">Chris Kim</a> updated <a href="#">Rule ru-8ae94f2</a> at 10:07am
                  </div>
                </div>
                <div className={classes.auditTrailItemTopLineActions}>
                  <a href="#">Details</a>
                </div>
              </div>
              <div className={classes.auditTrailItemContent}>
                <div className={classes.auditTrailItemContentBefore}>
                  <div className={classes.auditTrailItemEntityHeader}>Rule ru-8ae94f2</div>
                  <div className={classes.auditTrailItemEntityBody}>
                    <div className={classes.auditTrailItemEntityBodyRow}>
                      <div className={classes.auditTrailItemEntityBodyRowField}>If:</div>
                      <div className={classes.auditTrailItemEntityBodyRowContent}>
                        <strong>Payment</strong> is received for subscription SKU <a href="#">ip-391ae031a</a>
                      </div>
                    </div>
                    <div className={classes.auditTrailItemEntityBodyRow}>
                      <div className={classes.auditTrailItemEntityBodyRowField}>Then:</div>
                      <div className={classes.auditTrailItemEntityBodyRowContent}>
                        Recognize revenue over{' '}
                        <span className={classes.auditTrailItemEntityChangedValueBefore}>
                          <strong>48 months</strong>
                        </span>{' '}
                        from <strong>date of payment</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.auditTrailItemContentAfter}>
                  <div className={classes.auditTrailItemEntityHeader}>Rule ru-8ae94f2</div>
                  <div className={classes.auditTrailItemEntityBody}>
                    <div className={classes.auditTrailItemEntityBodyRow}>
                      <div className={classes.auditTrailItemEntityBodyRowField}>If:</div>
                      <div className={classes.auditTrailItemEntityBodyRowContent}>
                        <strong>Payment</strong> is received for subscription SKU <a href="#">ip-391ae031a</a>
                      </div>
                    </div>
                    <div className={classes.auditTrailItemEntityBodyRow}>
                      <div className={classes.auditTrailItemEntityBodyRowField}>Then:</div>
                      <div className={classes.auditTrailItemEntityBodyRowContent}>
                        Recognize revenue over{' '}
                        <span className={classes.auditTrailItemEntityChangedValueAfter}>
                          <strong>24 months</strong>
                        </span>{' '}
                        from <strong>date of payment</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.auditTrailRow}>
          <div className={classes.auditTrailLineContainer}>
            <div className={classes.auditTrailLine} />
            <div className={classes.auditTrailDot} />
          </div>
          <div className={classes.auditTrailItemContainer}>
            <div className={classes.auditTrailDate}>Oct 10, 2022</div>
            <div className={classes.auditTrailItem}>
              <div className={classes.auditTrailItemTopLine}>
                <div className={classes.auditTrailItemTopLineSummary}>
                  <img src={`${getAssetPath()}/images/profiles/yl.jpeg`} />
                  <div className={classes.auditTrailItemTopLineSummaryDescription}>
                    <a href="#">Yufei Liu</a> added a journal entry to <a href="#">Deferred Revenue Recognized</a> at
                    3:42pm
                  </div>
                </div>
                <div className={classes.auditTrailItemTopLineActions}>
                  <a href="#">Details</a>
                </div>
              </div>
              <div className={classes.auditTrailItemContent}>
                <div className={classes.auditTrailItemContentJournalContainer}>
                  <div className={classes.auditTrailItemJournalHeader}>Journal</div>
                  <div className={classes.auditTrailItemJournalBody}>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Account</div>
                      <div className={classes.auditTrailItemJournalField}>Debit</div>
                      <div className={classes.auditTrailItemJournalField}>Credit</div>
                    </div>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Deferred Revenue Recognized</div>
                      <div className={classes.auditTrailItemJournalField}></div>
                      <div className={classes.auditTrailItemJournalField}>$450.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.auditTrailItem}>
              <div className={classes.auditTrailItemTopLine}>
                <div className={classes.auditTrailItemTopLineSummary}>
                  <img src={`${getAssetPath()}/images/profiles/yl.jpeg`} />
                  <div className={classes.auditTrailItemTopLineSummaryDescription}>
                    <a href="#">Yufei Liu</a> added a journal entry to <a href="#">Deferred Revenue</a> at 3:42pm
                  </div>
                </div>
                <div className={classes.auditTrailItemTopLineActions}>
                  <a href="#">Details</a>
                </div>
              </div>
              <div className={classes.auditTrailItemContent}>
                <div className={classes.auditTrailItemContentJournalContainer}>
                  <div className={classes.auditTrailItemJournalHeader}>Journal</div>
                  <div className={classes.auditTrailItemJournalBody}>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Account</div>
                      <div className={classes.auditTrailItemJournalField}>Debit</div>
                      <div className={classes.auditTrailItemJournalField}>Credit</div>
                    </div>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Deferred Revenue</div>
                      <div className={classes.auditTrailItemJournalField}>$450.00</div>
                      <div className={classes.auditTrailItemJournalField}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.auditTrailRow}>
          <div className={classes.auditTrailLineContainer}>
            <div className={classes.auditTrailLine} />
            <div className={classes.auditTrailDot} />
          </div>
          <div className={classes.auditTrailItemContainer}>
            <div className={classes.auditTrailDate}>September 30, 2022</div>
            <div className={classes.auditTrailItem}>
              <div className={classes.auditTrailItemTopLine}>
                <div className={classes.auditTrailItemTopLineSummary}>
                  <img src={`${getAssetPath()}/images/profiles/ck.jpeg`} />
                  <div className={classes.auditTrailItemTopLineSummaryDescription}>
                    <a href="#">Chris Kim</a> added a journal entry to <a href="#">Deferred Revenue Recognized</a> at
                    1:25pm
                  </div>
                </div>
                <div className={classes.auditTrailItemTopLineActions}>
                  <a href="#">Details</a>
                </div>
              </div>
              <div className={classes.auditTrailItemContent}>
                <div className={classes.auditTrailItemContentJournalContainer}>
                  <div className={classes.auditTrailItemJournalHeader}>Journal</div>
                  <div className={classes.auditTrailItemJournalBody}>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Account</div>
                      <div className={classes.auditTrailItemJournalField}>Debit</div>
                      <div className={classes.auditTrailItemJournalField}>Credit</div>
                    </div>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Deferred Revenue Recognized</div>
                      <div className={classes.auditTrailItemJournalField}></div>
                      <div className={classes.auditTrailItemJournalField}>$100.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.auditTrailItem}>
              <div className={classes.auditTrailItemTopLine}>
                <div className={classes.auditTrailItemTopLineSummary}>
                  <img src={`${getAssetPath()}/images/profiles/ck.jpeg`} />
                  <div className={classes.auditTrailItemTopLineSummaryDescription}>
                    <a href="#">Chris Kim</a> added a journal entry to <a href="#">Deferred Revenue</a> at 10:35am
                  </div>
                </div>
                <div className={classes.auditTrailItemTopLineActions}>
                  <a href="#">Details</a>
                </div>
              </div>
              <div className={classes.auditTrailItemContent}>
                <div className={classes.auditTrailItemContentJournalContainer}>
                  <div className={classes.auditTrailItemJournalHeader}>Journal</div>
                  <div className={classes.auditTrailItemJournalBody}>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Account</div>
                      <div className={classes.auditTrailItemJournalField}>Debit</div>
                      <div className={classes.auditTrailItemJournalField}>Credit</div>
                    </div>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Deferred Revenue</div>
                      <div className={classes.auditTrailItemJournalField}>$100.00</div>
                      <div className={classes.auditTrailItemJournalField}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.auditTrailItem}>
              <div className={classes.auditTrailItemTopLine}>
                <div className={classes.auditTrailItemTopLineSummary}>
                  <img src={`${getAssetPath()}/images/profiles/yl.jpeg`} />
                  <div className={classes.auditTrailItemTopLineSummaryDescription}>
                    <a href="#">Yufei Liu</a> added a journal entry to <a href="#">Cash</a> at 8:55am
                  </div>
                </div>
                <div className={classes.auditTrailItemTopLineActions}>
                  <a href="#">Details</a>
                </div>
              </div>
              <div className={classes.auditTrailItemContent}>
                <div className={classes.auditTrailItemContentJournalContainer}>
                  <div className={classes.auditTrailItemJournalHeader}>Journal</div>
                  <div className={classes.auditTrailItemJournalBody}>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Account</div>
                      <div className={classes.auditTrailItemJournalField}>Debit</div>
                      <div className={classes.auditTrailItemJournalField}>Credit</div>
                    </div>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Cash</div>
                      <div className={classes.auditTrailItemJournalField}></div>
                      <div className={classes.auditTrailItemJournalField}>$25.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.auditTrailItem}>
              <div className={classes.auditTrailItemTopLine}>
                <div className={classes.auditTrailItemTopLineSummary}>
                  <img src={`${getAssetPath()}/images/profiles/yl.jpeg`} />
                  <div className={classes.auditTrailItemTopLineSummaryDescription}>
                    <a href="#">Yufei Liu</a> added a journal entry to <a href="#">Non-Deferred Revenue</a> at 8:54am
                  </div>
                </div>
                <div className={classes.auditTrailItemTopLineActions}>
                  <a href="#">Details</a>
                </div>
              </div>
              <div className={classes.auditTrailItemContent}>
                <div className={classes.auditTrailItemContentJournalContainer}>
                  <div className={classes.auditTrailItemJournalHeader}>Journal</div>
                  <div className={classes.auditTrailItemJournalBody}>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Account</div>
                      <div className={classes.auditTrailItemJournalField}>Debit</div>
                      <div className={classes.auditTrailItemJournalField}>Credit</div>
                    </div>
                    <div className={classes.auditTrailItemJournalRow}>
                      <div className={classes.auditTrailItemJournalField}>Deferred Revenue</div>
                      <div className={classes.auditTrailItemJournalField}>$25.00</div>
                      <div className={classes.auditTrailItemJournalField}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.auditTrailNavRow}>
          <div className={classes.auditTrailNavContainer}>
            <a className={classnames(classes.auditTrailNavNewer, classes.auditTrailNavDisabled)} href="#">
              Newer
            </a>
            <a className={classes.auditTrailNavOlder} href="#">
              Older
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditTrail;
