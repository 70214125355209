import React from 'react';

import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import Colors from './common/Colors';
import { NavItemProps } from './NavItem';
import getAssetPath from '../utils/AssetPathUtil';

export const navItemClasses = {
  navItem: {
    alignItems: 'center',
    borderRadius: '5px',
    color: Colors.DARK_GRAY,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'left',
    margin: '5px 10px',
    padding: '8px 16px',
    transition: 'background-color 0.1s',

    '& $navIconSelected': {
      display: 'none',
    },

    '& $navIconUnselected': {
      display: 'inline-block',
    },
    position: 'relative',
  },

  navItemHoverable: {
    '&:hover': {
      backgroundColor: Colors.MAIN_BLUE,
      color: Colors.WHITE,
      '& span': {
        color: Colors.WHITE,
      },
      '& img[data-selected="true"]': {
        display: 'inline-block',
      },

      '& img[data-selected="false"]': {
        display: 'none',
      },
    },
  },

  navItemSecondary: {
    display: 'flex',
    flexFlow: 'row wrap',
    color: Colors.DARK_GRAY,

    '& $navIconSelected': {
      display: 'none',
    },

    '& $navIconUnselected': {
      display: 'inline-block',
    },
  },

  navItemNested: {
    marginLeft: -10,
    marginBottom: -8,
  },

  selected: {
    backgroundColor: Colors.MAIN_BLUE,
    color: Colors.WHITE,

    '& $navIconSelected': {
      display: 'inline-block',
    },

    '& $navIconUnselected': {
      display: 'none',
    },
  },

  navIcon: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    marginRight: '5px',
    width: '36px',
  },

  navIconImage: {
    height: '18px',
  },

  navIconSelected: {},
  navIconUnselected: {},
};
const useStyles = createUseStyles(navItemClasses);

const NavItemLink: React.FunctionComponent<NavItemProps> = (props: NavItemProps) => {
  const classes = useStyles();
  const imgStyle: { [key: string]: string } = {};
  if (props.iconHeight) {
    imgStyle.height = `${props.iconHeight}px`;
  }
  if (props.iconLeftMargin) {
    imgStyle.marginLeft = `${props.iconLeftMargin}px`;
  }

  const hoverClass = props.isDisabledByDefault ? '' : classes.navItemHoverable;

  return (
    <Link
      key={`navitemlink-${props.type}`}
      className={`
        ${props.isSecondary ? classes.navItemSecondary : `${classes.navItem} ${hoverClass}`}
        ${props.type !== props.type ? classes.navItemNested : ''}
        ${props.isSelected ? classes.selected : ''}
      `}
      to={props.isDisabledByDefault ? '#' : props.to || '#'}
      onClick={() => props.onClick && props.onClick()}
    >
      <span className={classes.navIcon}>
        <img
          data-selected="false"
          className={[classes.navIconImage, classes.navIconUnselected].join(' ')}
          style={imgStyle}
          src={`${getAssetPath()}/images/icon_${props.iconType}.svg`}
        />
        <img
          data-selected="true"
          className={[classes.navIconImage, classes.navIconSelected].join(' ')}
          style={imgStyle}
          src={`${getAssetPath()}/images/icon_${props.iconType}_selected.svg`}
        />
      </span>
      <span>{props.label}</span>
    </Link>
  );
};

export default NavItemLink;
