import React from 'react';
import moment from 'moment';

import { createUseStyles } from 'react-jss';

import Colors from './Colors.tsx';
import { JournalEntriesForInvoice } from '../../generated/graphql.tsx';
import { TLedgerDirection, TLedgerDirectionAbbreviated } from '../../../src/types/LedgerTypes.ts';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& $row *': {
      fontSize: '12px',
    },
  },
  row: {
    display: 'flex',
    marginBottom: '10px',
    paddingBottom: '10px',
    borderBottom: `1px solid ${Colors.LIGHT_GRAY}`,
    alignItems: 'center',
  },
  labelColumn: {
    width: '375px',
  },
  labelRow: {
    display: 'flex',
    fontSize: '20px',
    width: '90px',
    fontWeight: 'bold',
    marginLeft: '20px',
    textAlign: 'center !important',
    justifyContent: 'center',
  },
  accountNameRow: {
    marginLeft: '20px',
    width: '250px',
  },
  amountColumn: {
    fontWeight: 'bold',
    width: '135px',
    textAlign: 'center',
  },
  amountRow: {
    fontWeight: 'bold',
    width: '135px',
    textAlign: 'center',
  },
  tableTitle: {
    fontSize: '12px',
    color: Colors.MEDIUM_GRAY,
    textAlign: 'center',
  },
});

type JournalEntriesTimelineProps = {
  entries: JournalEntriesForInvoice[];
  entryLabelWidthPx?: number;
  invoceIssuedAt?: string;
};

export const JournalEntriesTimeline: React.FunctionComponent<JournalEntriesTimelineProps> = ({
  entries,
  invoceIssuedAt,
}: JournalEntriesTimelineProps) => {
  const classes = useStyles();
  const firstDate = moment(invoceIssuedAt || '2021-11-01').format('YYYY-MM-DD');

  // TODO: use this mock data for demo purposes
  entries = [
    {
      label: firstDate,
      entries: [
        {
          label: 'Accounts Receivable - Silver Plan',
          direction: TLedgerDirection.DEBIT,
          amountStr: '$735.32',
          dateStr: firstDate,
        },
        {
          label: 'Deferred Revenue - Silver Plan',
          direction: TLedgerDirection.CREDIT,
          amountStr: '$713.9',
          dateStr: firstDate,
        },
        {
          label: 'Tax Payable - Silver Plan',
          direction: TLedgerDirection.CREDIT,
          amountStr: '$21.42',
          dateStr: firstDate,
        },
      ],
    },
    {
      label: `${firstDate} to ${moment(firstDate).add(1, 'month').format('YYYY-MM-DD')}`,
      entries: [
        {
          label: 'Deferred Revenue - Silver Plan',
          direction: TLedgerDirection.DEBIT,
          amountStr: '$59.49',
          dateStr: firstDate,
        },
        {
          label: 'Revenue',
          direction: TLedgerDirection.CREDIT,
          amountStr: '$59.49',
          dateStr: firstDate,
        },
      ],
    },
    {
      label: firstDate,
      entries: [
        {
          label: 'Deposit in Transit',
          direction: TLedgerDirection.DEBIT,
          amountStr: '$735.32',
          dateStr: firstDate,
        },
        {
          label: 'Accounts Receivable - Silver Plan',
          direction: TLedgerDirection.CREDIT,
          amountStr: '$735.32',
          dateStr: firstDate,
        },
      ],
    },
    {
      label: `${moment(firstDate).add(3, 'days').format('YYYY-MM-DD')}`,
      entries: [
        {
          label: 'Cash',
          direction: TLedgerDirection.DEBIT,
          amountStr: '$735.32',
          dateStr: firstDate,
        },
        {
          label: 'Deposit in Transit',
          direction: TLedgerDirection.CREDIT,
          amountStr: '$735.32',
          dateStr: firstDate,
        },
      ],
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <h4 className={`${classes.tableTitle} ${classes.labelColumn}`}>Journal Entry</h4>
        <h4 className={`${classes.tableTitle} ${classes.amountColumn}`}>{TLedgerDirectionAbbreviated.DEBIT}</h4>
        <h4 className={`${classes.tableTitle} ${classes.amountColumn}`}>{TLedgerDirectionAbbreviated.CREDIT}</h4>
      </div>
      {entries.map((je) => {
        return (
          <div key={je.label}>
            {je.entries.map((e, idx) => {
              return (
                <div key={idx} className={classes.row}>
                  <span className={classes.labelRow}>{idx === 0 ? je.label.replace(' ', '\n') : ''}</span>
                  <span className={classes.accountNameRow}>{e.label.toUpperCase()}</span>
                  <span className={classes.amountRow}>{e.direction === TLedgerDirection.DEBIT ? e.amountStr : ''}</span>
                  <span className={classes.amountRow}>
                    {e.direction === TLedgerDirection.CREDIT ? e.amountStr : ''}
                  </span>
                </div>
              );
            })}
            <div className={classes.row} style={{ height: '10px' }} />
          </div>
        );
      })}
    </div>
  );
};
