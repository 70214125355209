import React, { useRef, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import Colors from './common/Colors';
import FormButton from './common/FormButton';
import { TPasswordConfig } from '../../src/validators/ValidatePassword';
import ValidatedPasswordInput from './common/ValidatedPasswordInput';
import getAssetPath from '../utils/AssetPathUtil';
import { useSharedStyles } from '../utils/CssUtil';
import { SetNewUserMutation, SetNewUserMutationVariables } from '../generated/graphql';

const useStyles = createUseStyles({
  registerContainer: {
    display: 'block',
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    width: '100%',
  },

  contentWrapper: {
    height: 'auto',
    minHeight: 'auto',
    margin: '-50px auto 0',
    padding: '30px',
    width: '270px',
  },

  loginLogoContainer: {
    textAlign: 'center',

    '& p': {
      fontSize: '14px',
      margin: '10px 0 20px',
    },
  },

  loginRow: {
    fontSize: '14px',
    margin: '15px 0',

    '& > span': {
      display: 'block',
      marginBottom: '-10px',
      width: 'auto',
    },

    '& input': {
      background: 'transparent',
      border: `1px solid ${Colors.MEDIUM_GRAY}`,
      borderRadius: '3px',
      color: Colors.DARK_GRAY,
      fontSize: '14px',
      padding: '8px',
      transition: 'border 0.1s',
      width: '100%',

      '&:focus': {
        border: `1px solid ${Colors.DARK_GRAY}`,
        boxShadow: '1px 1px 1px rgba(0,0,0,0.035)',
        outline: 'none',
      },

      '&:disabled': {
        backgroundColor: Colors.LIGHT_GRAY,
      },
    },

    '& button': {
      width: '100%',
    },
  },

  error: {
    color: Colors.WARNING_RED,
    fontSize: '12px',
    marginTop: '-8px',
  },
});

const NEWUSER_MUTATION = gql`
  mutation SetNewUser($firstName: String!, $lastName: String!, $password: String!, $email: String!, $token: String!) {
    setNewUser(firstName: $firstName, lastName: $lastName, password: $password, email: $email, token: $token) {
      ok
      message
    }
  }
`;

enum JoinState {
  Initial,
  Submitting,
  Succeeded,
  Failed,
}

const Join: React.FunctionComponent = () => {
  const [stateValue, setStateValue] = useState<JoinState>(JoinState.Initial);
  const [errorValue, setErrorStateValue] = useState<string | null>(null);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const passwordRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const [newUserMutation, { error: newUserErrorStr }] = useMutation<SetNewUserMutation, SetNewUserMutationVariables>(
    NEWUSER_MUTATION
  );

  const navigate = useNavigate();
  const continueFn = async () => {
    setStateValue(JoinState.Submitting);
    let errorMessage = 'An error occurred';
    let isValidSoFar = true;
    const firstName = firstNameRef.current?.value;
    const lastName = lastNameRef.current?.value;
    const password = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;

    if (!firstName) {
      errorMessage = 'Please enter first name.';
      isValidSoFar = false;
    }
    if (!lastName && isValidSoFar) {
      errorMessage = 'Please enter last name.';
      isValidSoFar = false;
    }
    if (!password && isValidSoFar) {
      errorMessage = 'Please enter password.';
      isValidSoFar = false;
    }
    if (!confirmPassword && isValidSoFar) {
      errorMessage = 'Please enter confirm password.';
      isValidSoFar = false;
    }
    if (password && confirmPassword && password !== confirmPassword && isValidSoFar) {
      errorMessage = 'Password and confirm password do not match.';
      isValidSoFar = false;
    }
    if (!isValidPassword && isValidSoFar) {
      errorMessage = 'Please confirm password adheres to requirements.';
      isValidSoFar = false;
    }
    if (!isValidSoFar) {
      setStateValue(JoinState.Failed);
      setErrorStateValue(newUserErrorStr?.message || errorMessage);
      return;
    }

    if (firstName && lastName && password && email && token) {
      const newUser = await newUserMutation({
        variables: {
          firstName,
          lastName,
          password,
          email,
          token,
        },
      });
      if (newUser?.data?.setNewUser?.ok) {
        setStateValue(JoinState.Succeeded);
        navigate('/');
      } else {
        setStateValue(JoinState.Failed);
        setErrorStateValue(newUser?.data?.setNewUser?.message || errorMessage);
      }
    }
  };

  return (
    <div className={classes.registerContainer}>
      <div className={classnames(sharedClasses.banner, sharedClasses.loginBanner)}></div>
      <div className={classnames(sharedClasses.contentWrapper, classes.contentWrapper)}>
        <div className={classes.loginLogoContainer}>
          <img src={`${getAssetPath()}/images/logo_small.png`} height={48} />
          <p>Create an account to get started.</p>
        </div>
        <div className={classes.loginRow}>
          <span>First Name:</span>
        </div>
        <div className={classes.loginRow}>
          <input
            type="text"
            name="firstName"
            disabled={stateValue === JoinState.Submitting}
            placeholder="John"
            ref={firstNameRef}
          />
        </div>
        <div className={classes.loginRow}>
          <span>Last Name:</span>
        </div>
        <div className={classes.loginRow}>
          <input
            type="text"
            name="lastName"
            disabled={stateValue === JoinState.Submitting}
            placeholder="Doe"
            ref={lastNameRef}
          />
        </div>
        <div className={classes.loginRow}>
          <span>Password:</span>
        </div>
        <div className={classes.loginRow}>
          <ValidatedPasswordInput
            validationRequirements={TPasswordConfig}
            setIsValid={setIsValidPassword}
            disabled={stateValue === JoinState.Submitting}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                continueFn();
              }
            }}
            innerRef={passwordRef}
          />
        </div>
        <div className={classes.loginRow}>
          <span>Confirm Password:</span>
        </div>
        <div className={classes.loginRow}>
          <input
            type="password"
            name="password"
            disabled={stateValue === JoinState.Submitting}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                continueFn();
              }
            }}
            ref={confirmPasswordRef}
          />
        </div>
        <div className={classes.loginRow}>
          <FormButton isDisabled={stateValue === JoinState.Submitting} onClick={continueFn}>
            Create Account
          </FormButton>
        </div>
        {errorValue ? <div className={classes.error}>{errorValue}</div> : null}
      </div>
    </div>
  );
};

export default Join;
