import React, { useMemo } from 'react';

import { removeTypename } from '../../../revenue/utils/transformedDraggedDimension.ts';
import { useRevenueTableContext } from '../../../../context/RevenueTableContext.tsx';
import MenuDropdown, {
  MenuDropdownHorizontalAlignment,
  MenuItem,
} from '../../../../components/common/MenuDropdown.tsx';
import { PivotSettingModel, useGetAllPivotsQuery } from '../../../../generated/graphql.tsx';

export const PivotDropDown = () => {
  const { fetchRevenueTable, onSelectedPivot, selectedPivot } = useRevenueTableContext();

  const { data: pivots } = useGetAllPivotsQuery({
    fetchPolicy: 'cache-only',
  });

  const dropdownPivots = useMemo(
    () =>
      (pivots?.getAllPivots?.map((pivot) => ({
        label: pivot.name,
        onClick: () => {
          fetchRevenueTable({
            dimensions: removeTypename(pivot.dimensionsDepth),
          });
          onSelectedPivot(pivot as PivotSettingModel);
        },
      })) || []) as MenuItem[],
    [fetchRevenueTable, onSelectedPivot, pivots?.getAllPivots]
  );
  return (
    <MenuDropdown
      menuItemStyle={{
        height: '42px',
        boxSizing: 'border-box',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
      dropdownStyle={{
        maxHeight: '300px',
        width: '200px',
        overflow: 'auto',
      }}
      highlightColor="MISTY_BLUE"
      items={dropdownPivots}
      horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
    >
      {selectedPivot?.name || 'Overview'}
    </MenuDropdown>
  );
};
