import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    animation: '$rotate 2s linear infinite',
    zIndex: 2,
    position: 'absolute',
    width: ({ size }) => size,
    height: ({ size }) => size,
    '& .path': {
      stroke: '#93bfec',
      strokeLinecap: 'round',
      animation: '$dash 1.5s ease-in-out infinite',
    },
  },
  // Define the keyframes within the same useStyles call
  '@keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes dash': {
    '0%': {
      strokeDasharray: '1, 150',
      strokeDashoffset: '0',
    },
    '50%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: '-35',
    },
    '100%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: '-124',
    },
  },
});

export const Spinner = (props: { size?: number }) => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <svg className={classes.spinner} viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      </svg>
    </div>
  );
};
