import { ApolloLink, FetchResult, NextLink, Observable, Operation } from '@apollo/client/core';

import { CancelableRequest } from './cancelableRequests.ts';

export class CancelLink extends ApolloLink {
  requests: Set<CancelableRequest>;

  constructor() {
    super();
    this.requests = new Set();
  }

  request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    const cancelableRequest = new CancelableRequest(operation, forward);
    this.requests.add(cancelableRequest);

    return new Observable<FetchResult>((observer) => {
      const subscription = cancelableRequest.execute().subscribe({
        next: (result) => observer.next(result),
        error: (error) => observer.error(error),
        complete: () => observer.complete(),
      });

      return () => {
        subscription.unsubscribe();
        this.requests.delete(cancelableRequest);
      };
    });
  }

  cancelRequests(): void {
    this.requests.forEach((request) => {
      request.controller.abort();
    });
    this.requests.clear();
  }
}
