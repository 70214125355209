export enum TCsvExportType {
  DATA_HUB = 'DATA_HUB',
  REVENUE_DASHBOARD = 'REVENUE_DASHBOARD',
  RECON_REPORT = 'RECON_REPORT',
  FAKE = 'FAKE',
  MISSING_DATA_REPORT = 'MISSING_DATA_REPORT',
  DEPOSITS_REPORT = 'DEPOSITS_REPORT',
  REVENUE_TRANSACTION_REPORT = 'REVENUE_TRANSACTION_REPORT',
  REVENUE_TRANSACTION_REPORT_V2 = 'REVENUE_TRANSACTION_REPORT_V2',
  BANKING_TRANSACTION_REPORT = 'BANKING_TRANSACTION_REPORT',
  CASH_TRANSACTION_REPORT = 'CASH_TRANSACTION_REPORT',
  RECOGNITION_SCHEDULE = 'RECOGNITION_SCHEDULE',
}

export enum TCsvExportSyncType {
  REVENUE_TRANSACTION_REPORT_V2 = 'REVENUE_TRANSACTION_REPORT_V2',
}
