import React, { useEffect } from 'react';

import Banner from './Banner';
import ErrorMessage from './common/ErrorMessage';
import RevenueCard from './RevenueCard';
import { TSearchBarLazyQueryFn } from './SearchBar';
import { useAppContext } from '../context/AppContext.tsx';
import { useRevenueTableContext } from '../context/RevenueTableContext';
import { useSharedStyles } from '../utils/CssUtil';
import { useSideBarContext } from '../features/sideBar/providers/SideBarProvider';

export const getDefaultDashboardYear = (isRealCompany: boolean | undefined, companyName?: string) => {
  if (isRealCompany === undefined) {
    return undefined;
  }
  if (companyName === 'PayZen 2023') {
    return 2023;
  }
  return isRealCompany ? 2024 : 2022;
};

const RevenueCardWithBanner: React.FunctionComponent = () => {
  const { sideBarWidth, onResetSideBar } = useSideBarContext();
  const sharedClasses = useSharedStyles();
  const { companyData, isRealCustomerCompany, hasYearToggle } = useAppContext();

  const { loading, error, revenueTable, setSelectedYear } = useRevenueTableContext();

  useEffect(() => {
    if (!companyData?.currentCompany.id) {
      return;
    }
    // After setting companyData, determine and set the default year.
    const defaultYear = getDefaultDashboardYear(isRealCustomerCompany, companyData?.currentCompany?.name);

    if (defaultYear !== undefined) {
      setSelectedYear(defaultYear.toString());
    }
  }, [companyData, isRealCustomerCompany, setSelectedYear]);

  useEffect(() => {
    return () => {
      if (window.location.pathname !== '/app/revenue') {
        onResetSideBar();
      }
    };
  }, [onResetSideBar]);

  const enableLockFeature = !isRealCustomerCompany || !!companyData?.currentCompany?.settings?.isMonthLockEnabled;

  const useGlMethod = !!enableLockFeature && !!companyData?.currentCompany?.settings?.isPushToGlEnabled;
  const overviewVersion = companyData?.currentCompany?.settings?.overviewVersion;

  let highlights: Array<{
    label?: string;
    type?: string;
    value?: string;
  }> = [
    { label: '', type: '', value: '' },
    { label: '', type: '', value: '' },
    { label: '', type: '', value: '' },
  ];
  if (revenueTable) {
    highlights = revenueTable.highlights || [];
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.main} style={{ width: `calc(100vw - ${sideBarWidth}px)` }}>
      <Banner
        showSearchBar={!!(overviewVersion && overviewVersion >= 2)}
        searchBarQueryFn={TSearchBarLazyQueryFn.SEARCH_RECOGNITION_SCHEDULE}
        highlights={highlights.map((highlight) => ({
          header: highlight.label || '',
          isLoading: loading,
          value: highlight.value || '',
        }))}
      />
      <RevenueCard
        isLoading={loading}
        isUndergoingMaintenance={companyData?.currentCompany?.settings?.isUndergoingMaintenance || false}
        data={revenueTable ? revenueTable : undefined}
        hasTimeToggle={hasYearToggle()}
        enableLockFeature={enableLockFeature}
        enableLockIcon={enableLockFeature}
        useGlMethod={useGlMethod}
      />
    </div>
  );
};

export default RevenueCardWithBanner;
