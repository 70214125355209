import React from 'react';

import { createUseStyles } from 'react-jss';

import Colors from './Colors';

const useStyles = createUseStyles({
  notificationBanner: {
    color: Colors.MEDIUM_GRAY,
    fontSize: 20,
    paddingBottom: 20,
    textAlign: 'center',

    '& img': {
      height: 30,
      marginRight: 10,
      verticalAlign: 'middle',
      width: 30,
    },
  },
});

type TNotificationBannerProps = {
  text: string;
  imgPath?: string;
  styles?: React.CSSProperties;
  className?: string;
};

const NotificationBanner: React.FunctionComponent<TNotificationBannerProps> = ({
  text,
  imgPath,
  styles,
  className,
}: TNotificationBannerProps) => {
  const classes = useStyles();
  return (
    <div style={styles} className={className || classes.notificationBanner}>
      {imgPath ? <img src={imgPath} /> : <></>}
      {text}
    </div>
  );
};

export default NotificationBanner;
