import React, { useEffect, useMemo } from 'react';

import classnames from 'classnames';
import { noop } from 'lodash';
import { Tabulator, Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';

import { CurrencyToggle } from '../features/revenue/components/currencyToggle/CurrencyToggle.tsx';
import DataGrid from './data_grid/DataGrid';
import ExportButton from './CSVExportButton';
import FormatUtil from '../../src/utils/FormatUtil';
import LoaderAnimation from './common/LoaderAnimation';
import NotificationBanner from './common/NotificationBanner';
import { PivotDropDown } from '../features/pivotSettings/components/pivotDropDown/PivotDropDown.tsx';
import SectionNavHeader from './SectionNavHeader';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import { TCurrency } from '../../src/types/BaseTypes.ts';
import { TransactionDropDown } from '../features/pivotSettings/components/transactionDropDown/TransactionDropDown.tsx';
import { YearDropDown } from '../features/revenue/components/yearDropdown/YearDropDown.tsx';
import getAssetPath from '../utils/AssetPathUtil';
import { makeHtml5Droppable } from '../features/pivotSettings/renderProps/dragDrop/utils/makeHtml5Droppable.ts';
import { processDraggedItemsInHierarchy } from '../features/pivotSettings/utils/rowGroupGenerator.ts';
import { useAppContext } from '../context/AppContext.tsx';
import { useQueryRevenueTable } from '../features/revenue/hooks/useQueryRevenueTable.ts';
import { useRevenueTableContext } from '../context/RevenueTableContext.tsx';
import { useSharedStyles } from '../utils/CssUtil';
import { useSideBarContext } from '../features/sideBar/providers/SideBarProvider';
import { useStyles } from '../features/revenue/styles.ts';
import { ColLabels, TableCardProps } from '../features/revenue/types.ts';
import { DataGridFormatter, DataGridRow } from './data_grid/DataGridTypes.ts';
import { DimensionItem, ExtendedEntryRow } from '../features/pivotSettings/types.ts';
import { EntryRow, RowGroup, useLockedMonthsQuery } from '../generated/graphql';
import { calcColWidth, getFormatOptions, processNestedRows } from './data_grid/utils/ColumnUtils';
import { findNestedItemsByPath, insertItemsIntoHierarchy } from '../features/revenue/utils/updateNestedData.ts';
import {
  generateParentChildRelation,
  generateParentChildRelationFromRow,
} from '../features/revenue/utils/generateParentChildRelation.ts';

const RevenueCard: React.FunctionComponent<TableCardProps> = (props: TableCardProps) => {
  const { isRealCustomerCompany } = useAppContext();

  const { isDragging } = useSideBarContext();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const {
    nestedData,
    selectedYear,
    setNestedData,
    onSelectedCellPath,
    isSupportCustomPivot,
    onSetRowGroup,
    dgRef,
    setDgRef,
    isTransformedRevenuePage,
  } = useRevenueTableContext();

  const { data: lockedMonthsData } = useLockedMonthsQuery();

  const startTime = props.data?.startTimestampMs ? new Date(props.data?.startTimestampMs) : null;
  const endTime = props.data?.endTimestampMs ? new Date(props.data?.endTimestampMs) : null;
  const revenueYearRangeStr = props.data?.selectedYear?.toString();

  const lockedMonthStrings = props?.enableLockFeature
    ? lockedMonthsData?.lockedMonths?.map((m) => m.yearMonth)
    : props?.data?.lockedMonthStrings?.map((m) => m.replace('-', ''));

  const revenueTableResponse = useQueryRevenueTable({
    fetchPolicy: 'cache-only',
  });

  const rowGroups = useMemo(() => props.data?.rowGroups || [], [props.data?.rowGroups]);

  const colLabels = useMemo(() => {
    let labels: ColLabels[] = [];
    rowGroups.forEach((rowGroup) => {
      (rowGroup.rows || []).forEach((row) => {
        if (!row.cells) {
          return;
        }
        if (row.cells.length > labels.length) {
          labels = row.cells.map((cell) => ({
            label: cell.label as string,
            timespanStart: cell.timespanStart,
          }));
        }
      });
    });

    return labels;
  }, [rowGroups]);

  const getParentChildRelation = (cell: Tabulator.CellComponent) => generateParentChildRelation(cell);

  const formatOptions = getFormatOptions(revenueTableResponse?.currency as TCurrency);

  const columns = useMemo(() => {
    const _columns: TabulatorTypes.ColumnDefinition[] = colLabels?.[0]
      ? [
          {
            title: '',
            field: 'name',
            headerSort: false,
            minWidth: 260,
            frozen: true,
            formatter:
              !isRealCustomerCompany || isSupportCustomPivot
                ? (cell: Tabulator.CellComponent, _, onRendered: Tabulator.EmptyCallback) => {
                    onRendered(() => {
                      const cellData = cell.getData() as DataGridRow[];
                      const cellElement = cell.getElement() as HTMLElement;
                      if (cellData['isDraggable']) {
                        cellElement.setAttribute('draggable', 'true');
                        cellElement.ondragend = () => {
                          const droppedParentHierarchy = getParentChildRelation(cell);
                          // Temp flag to support test company
                          if (droppedParentHierarchy.length === 1) {
                            return;
                          }
                          setNestedData((cacheNestedData) =>
                            insertItemsIntoHierarchy(
                              [...(cacheNestedData || [])],
                              [...droppedParentHierarchy],
                              formatOptions
                            )
                          );
                        };
                      }
                      makeHtml5Droppable(cellElement, (_, draggedItem: DimensionItem) => {
                        const droppedParentHierarchy = getParentChildRelation(cell);
                        if (isSupportCustomPivot) {
                          onSetRowGroup([
                            ...(droppedParentHierarchy as any),
                            {
                              label: draggedItem.label,
                              rowLabel: draggedItem.rowLabel,
                              value: draggedItem?.values?.[0],
                              dimensionKeys: draggedItem.dimensionKeys || null,
                            },
                          ]);
                          return;
                        }
                        setNestedData((cacheNestedData) => {
                          const processItems = processDraggedItemsInHierarchy<ExtendedEntryRow>(
                            [...(cacheNestedData || [])],
                            [...droppedParentHierarchy],
                            draggedItem.values || []
                          );
                          if (processItems.length) {
                            const itemsToBeInserted = insertItemsIntoHierarchy(
                              [...(cacheNestedData || [])],
                              [...droppedParentHierarchy],
                              formatOptions,
                              processItems
                            );
                            return itemsToBeInserted;
                          }
                          return cacheNestedData;
                        });
                      });
                    });
                    return cell.getValue();
                  }
                : undefined,
          },
        ]
      : [];

    colLabels?.forEach((cell) => {
      const keyStr = `${cell.label}_${String(cell.timespanStart).substring(0, 4)}`;
      const isLocked =
        cell.timespanStart &&
        lockedMonthStrings &&
        lockedMonthStrings?.indexOf(FormatUtil.dateToYearMonthStr(new Date(cell.timespanStart))) >= 0;
      const cssContentDisabled = isLocked ? 'content-cell-disabled' : '';
      const cssContentLockIcon = props?.enableLockIcon ? 'content-lock-icon' : '';
      _columns.push({
        title: String(cell.label),
        field: keyStr,
        headerSort: false,
        headerTooltip: false,
        cssClass: classnames(sharedClasses.contentCellRightAlign, cssContentDisabled, cssContentLockIcon),
        cellClick: (_, cell) => {
          onSelectedCellPath(cell);
        },
        formatterParams: {
          customFormatter: DataGridFormatter.LINK_FORMATTER,
          timezone: undefined,
          cssClass: sharedClasses.linkUnderlined,
          hideTooltip: true,
        },
        width: cell.label ? calcColWidth(cell.label.length / 2, 100) : undefined,
        ...(props?.enableLockFeature
          ? {
              accessorParams: {
                clickType: 'lockMonthHeaderClick',
                timespanStart: cell.timespanStart,
                isLocked,
                isRealCustomerCompany: isRealCustomerCompany,
                useNewMethod: props?.useGlMethod,
              },
            }
          : {}),
      });
    });

    _columns.push({
      title: 'Total',
      field: 'total',
      headerSort: false,
      cssClass: classnames(sharedClasses.contentCellRightAlign),
      formatterParams: {
        customFormatter: DataGridFormatter.LINK_FORMATTER,
        timezone: undefined,
        cssClass: classnames(sharedClasses.linkUnderlined),
      },
    });

    return _columns;
  }, [
    colLabels,
    isRealCustomerCompany,
    isSupportCustomPivot,
    sharedClasses.contentCellRightAlign,
    sharedClasses.linkUnderlined,
    setNestedData,
    formatOptions,
    onSetRowGroup,
    lockedMonthStrings,
    props?.enableLockIcon,
    props?.enableLockFeature,
    props?.useGlMethod,
    onSelectedCellPath,
  ]);

  const options: TabulatorTypes.Options = {
    layout: 'fitData',
    sortMode: 'remote',
    persistence: undefined,
    groupBy: 'reportSection',
    groupHeader: (value) => value,
    dataTree: true,
    dataTreeStartExpanded: !isRealCustomerCompany,
    dataTreeBranchElement: false, // hide branch element
    dataTreeChildIndent: 20,
    dataTreeCollapseElement: '<div class="tabulator-tree-icon tabulator-tree-collapse">▼</div>',
    dataTreeExpandElement: '<div class="tabulator-tree-icon tabulator-tree-expand">▲</div>',
    rowFormatter:
      isRealCustomerCompany && !isSupportCustomPivot
        ? noop
        : (row: TabulatorTypes.RowComponent) => {
            const element = row.getElement();
            const data = row.getData();
            makeHtml5Droppable(
              element,
              () => element.classList.remove('highlighted-row'),
              () => element.classList.add('highlighted-row'),
              () => element.classList.remove('highlighted-row')
            );

            if (data._rowCssClass) {
              element.classList.add(data._rowCssClass);
            }
          },
  };

  const onToggleRow = (row: Tabulator.RowComponent) => {
    if (!isRealCustomerCompany) return;
    const rowData = dgRef?.current.getRow(row);
    // Rowgroup childrens are already processed.
    if (rowData?.getData()._children.length) return;
    const hierarchy = generateParentChildRelationFromRow(row);
    if (hierarchy.length === 1) return;
    const nestedItem = findNestedItemsByPath<RowGroup>(revenueTableResponse?.rowGroups as RowGroup[], hierarchy);

    nestedItem.rows.forEach((item) => {
      rowData?.addTreeChild((processNestedRows([item] as EntryRow[], formatOptions, 'name') || [])[0], false);
    });
  };

  useEffect(() => {
    // By default all first level rows are collapsed, so manually expand them.
    // This approach is more performant than expanding each child row individually.
    if (!dgRef?.current || !isRealCustomerCompany) return;
    dgRef.current.on('renderStarted', () => {
      dgRef?.current.setData(nestedData);
    });
    dgRef?.current.on('renderComplete', () => {
      dgRef?.current.getRows().forEach((row) => {
        row.treeExpand();
      });
    });
  }, [dgRef, nestedData, isRealCustomerCompany]);

  const events = {
    dataTreeRowExpanded: (row: Tabulator.RowComponent) => onToggleRow(row),
    dataTreeRowCollapsed: (row: Tabulator.RowComponent) => onToggleRow(row),
  };

  const showCustomizeOptions = !isRealCustomerCompany || isSupportCustomPivot;

  return (
    <div className={sharedClasses.contentWrapper}>
      {props.isLoading ? (
        <div className={sharedClasses.contentLoaderContainer}>
          <LoaderAnimation height={80} />
        </div>
      ) : (
        <>
          <div className={sharedClasses.contentHeaderWrapper}>
            <SectionNavHeader
              sections={[
                {
                  label: 'Overview' + (revenueYearRangeStr ? ', ' + revenueYearRangeStr : ''),
                },
              ]}
            />
            <div className={sharedClasses.contentHeaderUtils}>
              {showCustomizeOptions && isTransformedRevenuePage && <TransactionDropDown />}
              {showCustomizeOptions && !isTransformedRevenuePage && <PivotDropDown />}
              {!isRealCustomerCompany && <CurrencyToggle />}
              {props.hasTimeToggle && <YearDropDown />}
              <ExportButton
                type={TCsvExportType.REVENUE_DASHBOARD}
                url={`/download/csv-revenue?st=${startTime?.getTime()}&et=${endTime?.getTime()}&sy=${selectedYear}`}
              />
            </div>
          </div>
          {props.isUndergoingMaintenance && (
            <NotificationBanner
              text="Your data is currently undergoing maintenance. Some functionalities may be temporarily unavailable."
              imgPath={`${getAssetPath()}/images/icon_maintenance.svg`}
            />
          )}
          <div
            className={[sharedClasses.content, classes.defaultStyles, isDragging ? classes.pivotDragging : ''].join(
              ' '
            )}
          >
            <DataGrid
              setDgRef={setDgRef}
              data={nestedData || []}
              className={classes.revenueTable}
              columns={columns}
              options={options}
              shouldEnablePager={false}
              events={events}
            />
            <div className={sharedClasses.afterTable}>
              <div className={sharedClasses.afterTableLeft}></div>
              <div className={sharedClasses.afterTableRight}>Generated at {new Date().toString()}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RevenueCard;
