import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';

export const generateParentChildRelation = (cell: Tabulator.CellComponent): string[] => {
  const parentHierarchy: string[] = [cell.getRow().getData().name];

  const fetchParent = (childRow: Tabulator.RowComponent) => {
    const parentRow = childRow.getTreeParent() as Tabulator.RowComponent;
    if (parentRow) {
      parentHierarchy.unshift(parentRow.getData().name);
      fetchParent(parentRow);
    }
  };

  fetchParent(cell.getRow());
  return parentHierarchy;
};

export const generateParentChildRelationFromRow = (row: Tabulator.RowComponent): string[] => {
  const parentHierarchy: string[] = [row.getData().name];

  const fetchParent = (childRow: Tabulator.RowComponent) => {
    const parentRow = childRow.getTreeParent() as Tabulator.RowComponent;
    if (parentRow) {
      parentHierarchy.unshift(parentRow.getData().name);
      fetchParent(parentRow);
    }
  };

  fetchParent(row);
  return parentHierarchy;
};
