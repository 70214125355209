import React, { useRef, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import Colors from './common/Colors';
import FormButton from './common/FormButton';
import { TPasswordConfig } from '../../src/validators/ValidatePassword';
import ValidatedPasswordInput from './common/ValidatedPasswordInput';
import getAssetPath from '../utils/AssetPathUtil';
import { useSharedStyles } from '../utils/CssUtil';
import { SetNewPasswordMutation, SetNewPasswordMutationVariables } from '../generated/graphql';

const useStyles = createUseStyles({
  loginContainer: {
    display: 'block',
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    width: '100%',
  },

  contentWrapper: {
    height: 'auto',
    minHeight: 'auto',
    margin: '-50px auto 0',
    padding: '30px',
    width: '270px',
  },

  loginLogoContainer: {
    textAlign: 'center',

    '& p': {
      fontSize: '14px',
      margin: '10px 0 20px',
    },
  },

  loginRow: {
    fontSize: '14px',
    margin: '15px 0',

    '& > span': {
      display: 'block',
      marginBottom: '-10px',
      width: '100%',
    },

    '& input': {
      background: 'transparent',
      border: `1px solid ${Colors.MEDIUM_GRAY}`,
      borderRadius: '3px',
      color: Colors.DARK_GRAY,
      fontSize: '14px',
      padding: '8px',
      transition: 'border 0.1s',
      width: '100%',

      '&:focus': {
        border: `1px solid ${Colors.DARK_GRAY}`,
        boxShadow: '1px 1px 1px rgba(0,0,0,0.035)',
        outline: 'none',
      },

      '&:disabled': {
        backgroundColor: Colors.LIGHT_GRAY,
      },
    },

    '& button': {
      width: '100%',
    },
  },

  error: {
    color: Colors.WARNING_RED,
    fontSize: '12px',
    marginTop: '-8px',
  },
});

const NEW_PASSWORD_MUTATION = gql`
  mutation SetNewPassword($newPassword: String!, $confirmNewPassword: String!, $token: String!, $email: String!) {
    setNewPassword(newPassword: $newPassword, confirmNewPassword: $confirmNewPassword, token: $token, email: $email) {
      ok
      message
    }
  }
`;

enum ResetPasswordState {
  Initial,
  Submitting,
  Succeeded,
  Failed,
}

const ResetPassword: React.FunctionComponent = () => {
  const [stateValue, setStateValue] = useState<ResetPasswordState>(ResetPasswordState.Initial);
  const [errorValue, setErrorStateValue] = useState<string | null>(null);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmNewPasswordRef = useRef<HTMLInputElement>(null);

  const [newPasswordMutation, { error: newPasswordErrorStr }] = useMutation<
    SetNewPasswordMutation,
    SetNewPasswordMutationVariables
  >(NEW_PASSWORD_MUTATION);

  const continueFn = async () => {
    setStateValue(ResetPasswordState.Submitting);
    let errorMessage = 'An error occurred';
    let isValidSoFar = true;
    const newPassword = newPasswordRef.current?.value;
    const confirmNewPassword = confirmNewPasswordRef.current?.value;

    if (!newPassword) {
      errorMessage = 'Please enter password.';
      isValidSoFar = false;
    }
    if (!confirmNewPassword && isValidSoFar) {
      errorMessage = 'Please enter confirm password.';
      isValidSoFar = false;
    }
    if (newPassword && confirmNewPassword && newPassword !== confirmNewPassword && isValidSoFar) {
      errorMessage = 'New password does not match confirm password.';
      isValidSoFar = false;
    }
    if (!isValidPassword && isValidSoFar) {
      errorMessage = 'Please confirm password adheres to requirements.';
      isValidSoFar = false;
    }
    if (!isValidSoFar) {
      setStateValue(ResetPasswordState.Failed);
      setErrorStateValue(newPasswordErrorStr?.message || errorMessage);
      return;
    }

    if (newPassword && confirmNewPassword && email && token) {
      const newUserPassword = await newPasswordMutation({
        variables: {
          newPassword,
          confirmNewPassword,
          token,
          email,
        },
      });
      if (newUserPassword?.data?.setNewPassword?.ok) {
        setStateValue(ResetPasswordState.Succeeded);
        navigate('/reset-success');
      } else {
        setStateValue(ResetPasswordState.Failed);
        setErrorStateValue(
          newPasswordErrorStr?.message || newUserPassword?.data?.setNewPassword?.message || errorMessage
        );
      }
    }
  };

  return (
    <div className={classes.loginContainer}>
      <div className={classnames(sharedClasses.banner, sharedClasses.loginBanner)}></div>
      <div className={classnames(sharedClasses.contentWrapper, classes.contentWrapper)}>
        <div className={classes.loginLogoContainer}>
          <img src={`${getAssetPath()}/images/logo_small.png`} height={48} />
          <p>Enter a new password</p>
        </div>
        <div className={classes.loginRow}>
          <span>New Password:</span>
        </div>
        <div className={classes.loginRow}>
          <ValidatedPasswordInput
            validationRequirements={TPasswordConfig}
            setIsValid={setIsValidPassword}
            disabled={stateValue === ResetPasswordState.Submitting}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                continueFn();
              }
            }}
            innerRef={newPasswordRef}
          />
        </div>
        <div className={classes.loginRow}>
          <span>Confirm New Password:</span>
        </div>
        <div className={classes.loginRow}>
          <input
            type="password"
            name="confirmNewPassword"
            disabled={stateValue === ResetPasswordState.Submitting}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                continueFn();
              }
            }}
            ref={confirmNewPasswordRef}
          />
        </div>
        <div className={classes.loginRow}>
          <FormButton isDisabled={stateValue === ResetPasswordState.Submitting} onClick={continueFn}>
            Reset Password
          </FormButton>
          <div className={classes.loginRow}>
            <a href="/">Already have an account? Login</a>
          </div>
        </div>
        {errorValue ? <div className={classes.error}>{errorValue}</div> : null}
      </div>
    </div>
  );
};

export default ResetPassword;
