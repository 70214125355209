import { TExtraIntegrationTypes, TIntegrationTypes } from '../../src/types/BaseTypes';

let assetPath: string | null = null;

const getVendorLogoFileName = (vendorName: TIntegrationTypes | TExtraIntegrationTypes): string => {
  switch (vendorName) {
    case TIntegrationTypes.ADYEN:
      return 'logo_adyen.svg';
    case TIntegrationTypes.AMAZON_SELLER_CENTRAL:
      return 'logo_amazon_seller_central.svg';
    case TIntegrationTypes.APPLE_FINANCE_REPORT:
    case TExtraIntegrationTypes.APPLE:
      return 'logo_apple.jpg';
    case TIntegrationTypes.AUTHORIZE_NET:
      return 'logo_authorize_net.jpg';
    case TExtraIntegrationTypes.AWS:
      return 'logo_aws.png';
    case TExtraIntegrationTypes.BLUESNAP:
      return 'logo_bluesnap.png';
    case TIntegrationTypes.BRAINTREE:
      return 'logo_braintree.png';
    case TIntegrationTypes.CHARGEBEE:
      return 'logo_chargebee.svg';
    case TExtraIntegrationTypes.CHARGIFY:
      return 'logo_chargify.png';
    case TExtraIntegrationTypes.CHECKOUT:
      return 'logo_checkout.png';
    case TExtraIntegrationTypes.GOCARDLESS:
      return 'logo_gocardless.png';
    case TExtraIntegrationTypes.FLYWIRE:
      return 'logo_flywire.png';
    case TIntegrationTypes.GOOGLE_PLAY_EARNINGS_REPORT:
    case TIntegrationTypes.GOOGLE_BIG_QUERY:
    case TIntegrationTypes.GOOGLE_PLAY_SALES_REPORT:
      return 'logo_google_cloud_platform.png';
    case TIntegrationTypes.GOOGLE_PLAY_API:
      return 'logo_google.png';
    case TIntegrationTypes.HOMEGROWN:
      return 'logo_homegrown.svg';
    case TExtraIntegrationTypes.HUBSPOT:
      return 'logo_hubspot.png';
    case TExtraIntegrationTypes.INTERNAL_BILLING_SYSTEMS:
      return 'logo_internal_billing_systems.png';
    case TExtraIntegrationTypes.MICROSOFT_AZURE:
      return 'logo_azure.jpg';
    case TIntegrationTypes.MODERN_TREASURY:
      return 'logo_modern_treasury.svg';
    case TExtraIntegrationTypes.NETSUITE:
      return 'logo_netsuite.png';
    case TExtraIntegrationTypes.NMI:
      return 'logo_nmi.png';
    case TExtraIntegrationTypes.ORACLE:
      return 'logo_oracle.png';
    case TIntegrationTypes.PAYMENTECH:
      return 'logo_chase.svg';
    case TIntegrationTypes.PAYPAL:
      return 'logo_paypal.svg';
    case TIntegrationTypes.PLAID:
      return 'logo_plaid.svg';
    case TExtraIntegrationTypes.QUICKBOOKS:
      return 'logo_quickbooks.png';
    case TIntegrationTypes.RECURLY:
      return 'logo_recurly.png';
    case TExtraIntegrationTypes.SAGE_INTACCT:
      return 'logo_sage_intacct.png';
    case TExtraIntegrationTypes.SAP:
      return 'logo_sap.png';
    case TIntegrationTypes.SALESFORCE:
      return 'logo_salesforce.svg';
    case TIntegrationTypes.SIMPLE_SHOPIFY:
    case TIntegrationTypes.SHOPIFY:
      return 'logo_shopify.png';
    case TExtraIntegrationTypes.SNOWFLAKE:
      return 'logo_snowflake.png';
    case TIntegrationTypes.STRIPE:
    case TExtraIntegrationTypes.STRIPE_BILLING:
      return 'logo_stripe.svg';
    case TExtraIntegrationTypes.WORKDAY:
      return 'logo_workday.png';
    case TExtraIntegrationTypes.ZOHO:
      return 'logo_zoho.png';
    case TExtraIntegrationTypes.ZUORA:
      return 'logo_zuora.png';
    case TExtraIntegrationTypes.GOOGLE:
      return 'logo_google.png';
    case TExtraIntegrationTypes.GOOGLE_CLOUD_PLATFORM:
      return 'logo_google_cloud_platform.png';
    case TIntegrationTypes.EBANX:
      return 'logo_ebanx.svg';
    default:
      return `logo_${vendorName.toLowerCase()}.png`;
  }
};

export const getVendorLogoAssetPath = (vendorName: TIntegrationTypes): string =>
  `${getAssetPath()}/images/vendor/${getVendorLogoFileName(vendorName)}`;

const getAssetPath: () => string = () => {
  if (assetPath !== null) {
    return assetPath;
  }

  const elem = document.getElementById('container');
  if (!elem) {
    assetPath = '';
    return assetPath;
  }
  const dataset = elem.dataset;
  if (!dataset) {
    assetPath = '';
    return assetPath;
  }
  assetPath = dataset.assetHostName || '';
  return assetPath;
};

export const assetPathGenerator = (path: string): string => `${getAssetPath()}${path}`;

export default getAssetPath;
