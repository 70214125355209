import { createUseStyles } from 'react-jss';
import Colors from '../../../../components/common/Colors.tsx';

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    padding: '10px 0',
    flexDirection: 'column',
    height: '100%',
  },

  heading: {
    color: Colors.DARK_GRAY,
    fontSize: '19px',
    fontWeight: 'bold',
  },

  itemContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: 'max-height 0.5s ease-in-out',
    maxHeight: '0',
    marginLeft: '50px',
  },

  itemContainerExpanded: {
    maxHeight: '2000px',
  },

  itemLabel: {
    color: Colors.DARK_GRAY,
    fontSize: '19px',
    padding: '4px 0',
    cursor: 'pointer',
    fontWeight: 100,
  },

  headingContainer: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },

  dropOverlay: {
    border: `1px dashed ${Colors.MISTY_BLUE}`,
    background: Colors.SOFT_SKY_BLUE,
    padding: '2px 8px',
    borderRadius: '4px',
    textAlign: 'center',
  },
});
