import React from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import DataGrid from '../data_grid/DataGrid';
import { GetRelationshipHighlightsAndDrilldownTableQuery } from '../../generated/graphql';
import LoaderAnimation from '../common/LoaderAnimation';
import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import { useSharedStyles } from '../../utils/CssUtil';
import { flattenData, getColumns } from '../data_grid/utils/ColumnUtils';

const useStyles = createUseStyles({
  loaderContainer: {
    textAlign: 'center',
    marginTop: '150px',
    marginRight: '85px',
    display: 'flex',
    justifyContent: 'center',
  },
  tableFixedWidth: {
    '& > table': {
      tableLayout: 'fixed',
    },

    '& > table td': {
      tableLayout: 'fixed',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
    },
  },
  rulesTable: {
    '&.tabulator': {
      fontSize: '13px',
    },
  },
});

type TReconciliationRulesProps = {
  data?: GetRelationshipHighlightsAndDrilldownTableQuery;
  isLoading: boolean;
};

const ReconciliationRulesTable: React.FunctionComponent<TReconciliationRulesProps> = ({ data, isLoading }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const columns = data?.getReconciliationRulesForIntegrationRelationship?.rows?.[0]
    ? [
        {
          title: 'Numeral Rule ID',
          field: 'id',
          minWidth: 200,
        } as TabulatorTypes.ColumnDefinition,
        ...getColumns(data.getReconciliationRulesForIntegrationRelationship.rows[0], false, sharedClasses),
      ]
    : [];
  columns.forEach((col) => {
    col.headerSort = false;
  });
  const flattenedData = data?.getReconciliationRulesForIntegrationRelationship?.rows
    ? flattenData(data?.getReconciliationRulesForIntegrationRelationship.rows)
    : [];

  const options: TabulatorTypes.Options = {
    data: flattenedData,
    sortMode: 'remote',
    persistence: undefined,
  };

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <LoaderAnimation height={80} />
        </div>
      ) : (
        <div className={classnames(sharedClasses.content, classes.tableFixedWidth)}>
          <DataGrid className={classes.rulesTable} columns={columns} options={options} />
        </div>
      )}
    </>
  );
};

export default ReconciliationRulesTable;
