import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  saveContainer: {
    width: '65%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});
