import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import Colors from '../Colors.tsx';
import { ToastProps } from './types.ts';
import { useStyles } from './styles.ts';

export const Toast: React.FC<ToastProps> = ({ message, type }) => {
  const classNames = useStyles();
  let icon;
  let backgroundColor: string = Colors.WHITE;

  switch (type) {
    case 'success':
      icon = faCheckCircle;
      backgroundColor = Colors.DARK_GREEN;
      break;
    case 'error':
      icon = faExclamationCircle;
      break;
    case 'warning':
      icon = faExclamationTriangle;
      backgroundColor = Colors.PILL_CHARGEBEE;
      break;
    default:
      icon = null;
      break;
  }

  return message ? (
    <>
      <div className={classNames.iconContainer}>
        <FontAwesomeIcon icon={icon} width={24} height={24} color={backgroundColor} />
      </div>
      <span>{message}</span>
    </>
  ) : null;
};
