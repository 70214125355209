import React from 'react';

import { ApplySettingControlProps } from './types.ts';
import { FormButtonV2 } from '../../../../components/common/FormButtonV2/FormButtonV2.tsx';
import { Spacer } from '../../../../components/Spacer.tsx';
import { useStyles } from './styles.ts';

export const ApplySettingControl = ({ onClose, onSave, onSaveAs }: ApplySettingControlProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.saveContainer}>
        <FormButtonV2 variant="disabled" onClick={onSave}>
          Save view
        </FormButtonV2>
        <Spacer width={8} />
        <FormButtonV2 variant="dark" onClick={onSaveAs}>
          Save view as
        </FormButtonV2>
      </div>
      <FormButtonV2 variant="stale" onClick={onClose}>
        Close
      </FormButtonV2>
    </div>
  );
};
