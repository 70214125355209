import { useCallback } from 'react';

import { useGetCurrentSessionLazyQuery, useGetCurrentUserLazyQuery } from '../generated/graphql.tsx';

export const useAuth = () => {
  const saveToLocalStorage = <T>(key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const [fetchCurrentSession, { loading: currentSessionLoading, data: currentSessionData }] =
    useGetCurrentSessionLazyQuery({
      onCompleted: (data) => {
        saveToLocalStorage('currentSession', data);
      },
    });

  const [fetchCurrentUser, { loading: currentUserLoading, data: currentUserData }] = useGetCurrentUserLazyQuery({
    onCompleted: (data) => {
      saveToLocalStorage('currentUser', data.currentUser);
    },
  });

  const fetchAuthDetails = useCallback(() => {
    return Promise.all([fetchCurrentSession(), fetchCurrentUser()]);
  }, [fetchCurrentSession, fetchCurrentUser]);

  return {
    fetchAuthDetails,
    currentUserData,
    currentSessionData,
    isLoading: currentUserLoading || currentSessionLoading,
  };
};
