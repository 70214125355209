import { CellEntry } from '../../../generated/graphql.tsx';
import { DimensionValue } from '../types.ts';
import { CellData, DataGridRow } from '../../../components/data_grid/DataGridTypes.ts';

/** TODO: these are dummy calculation logic, need to be replaced by BE */
const percentageToValueMapping = (total: number, percentage: number): number => (total * percentage) / 100;

export const generateCellEntry = (exisitngCellEntry: CellData[], amount: number): CellEntry[] => {
  return exisitngCellEntry.map((cellEntry) => ({
    ...cellEntry,
    amount: percentageToValueMapping(cellEntry?.amount, amount),
  }));
};

export const generateEntryRow = (label: string, amount: number, exisitngCellEntry: CellData[]): DataGridRow => ({
  cells: generateCellEntry(exisitngCellEntry, amount),
  dimensionValue: null,
  glMappingIds: [],
  label,
  shouldShowSumOfTimespans: true, // @TODO: need to figure out how to set this
  sumUrl: '',
});

const filterCellData = (cell: CellData): CellData[] => {
  const cellData: CellData[] = [];
  const cellDataObj = cell || {};
  for (const key in cellDataObj) {
    if (cellDataObj[key] && key.toLowerCase() !== 'total') {
      cellData.push(cellDataObj[key] as CellData);
    }
  }

  return cellData;
};

export const processDraggedItemsInHierarchy = <T = DataGridRow>(
  hierarchyData: DataGridRow[],
  hierarchyPath: string[],
  itemsToProcess: DimensionValue[]
): T[] => {
  const targetIndex = hierarchyData.findIndex(
    (node) => hierarchyPath[0] === node.name // renamed row to node for generality
  );
  if (targetIndex !== -1) {
    const targetGroup = hierarchyData[targetIndex];
    if (hierarchyPath.length > 1) {
      hierarchyPath.shift();
      return processDraggedItemsInHierarchy(targetGroup._children as DataGridRow[], hierarchyPath, itemsToProcess);
    } else {
      return itemsToProcess.map(
        ({ label, value }) =>
          (targetGroup._cellData ? generateEntryRow(label, value, filterCellData(targetGroup._cellData)) : []) as T
      );
    }
  }
  return [];
};
