import {
  TRuleIfConditionElementaryCondition,
  TRuleIfConditionNumberOperator,
  TRuleIfConditionStringOperator,
  TRuleSupportedEntityTypes,
  TRuleSupportedFields,
  TRuleSupportedStringFields,
} from '../../../../src/types/RuleTypes';

export const stringOperatorToDisplay: {
  [key in TRuleIfConditionStringOperator]: string;
} = {
  [TRuleIfConditionStringOperator.IS]: 'is',
  [TRuleIfConditionStringOperator.IS_NOT]: 'is not',
  [TRuleIfConditionStringOperator.IS_CASE_INSENSITIVE]: 'is (case insensitive)',
  [TRuleIfConditionStringOperator.IS_NOT_CASE_INSENSITIVE]: 'is not (case insensitive)',
  [TRuleIfConditionStringOperator.CONTAINS]: 'contains',
  [TRuleIfConditionStringOperator.DOES_NOT_CONTAIN]: 'does not contain',
};

export const numberOperatorToDisplay: {
  [key in TRuleIfConditionNumberOperator]: string;
} = {
  [TRuleIfConditionNumberOperator.IS]: '=',
  [TRuleIfConditionNumberOperator.IS_NOT]: '≠',
  [TRuleIfConditionNumberOperator.GT]: '>',
  [TRuleIfConditionNumberOperator.LT]: '<',
  [TRuleIfConditionNumberOperator.GTE]: '≥',
  [TRuleIfConditionNumberOperator.LTE]: '≤',
};

export const entityTypeToDisplay: {
  [key in TRuleSupportedEntityTypes]: string;
} = {
  [TRuleSupportedEntityTypes.INVOICE]: 'Invoice',
  [TRuleSupportedEntityTypes.PAYMENT]: 'Payment',
  [TRuleSupportedEntityTypes.REFUND]: 'Refund',
  [TRuleSupportedEntityTypes.SUBSCRIPTION]: 'Subscription',
  [TRuleSupportedEntityTypes.ITEM_PRICE]: 'SKU',
};

export const getDisplayNameFromEntityAndField = (t: TRuleSupportedEntityTypes, f: TRuleSupportedFields) => {
  return (entityTypeToDisplay[t] + '.' + f).toLowerCase();
};

export const getDisplayNameFromCondition = (
  rootEntityType: TRuleSupportedEntityTypes,
  c: TRuleIfConditionElementaryCondition
) => {
  if (c.entity.length === 0) {
    return '';
  }
  const entityType = c.entity[0].linkType === 'self' ? rootEntityType : c.entity[0].entityType;
  const entityAndFieldDisplay = getDisplayNameFromEntityAndField(entityType, c.fieldName);
  const isStringField = Object.values(TRuleSupportedStringFields).some((f) => f === c.fieldName);
  let operatorDisplay = '';
  if (isStringField) {
    const op = Object.values(TRuleIfConditionStringOperator).find((o) => o === c.operator);
    if (op) {
      operatorDisplay = stringOperatorToDisplay[op];
    }
  }
  {
    const op = Object.values(TRuleIfConditionNumberOperator).find((o) => o === c.operator);
    if (op) {
      operatorDisplay = numberOperatorToDisplay[op];
    }
  }
  return [entityAndFieldDisplay, operatorDisplay, c.operand].join(' ');
};
