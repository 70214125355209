import React from 'react';
import pluralize from 'pluralize';

import FormButton from '../common/FormButton';
import { TRuleSupportedEntityTypes } from '../../../src/types/RuleTypes';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSharedStyles } from '../../utils/CssUtil';

const useStyles = createUseStyles({
  ruleTriggerDelay: {},
  ruleTriggerDelayFormInput: {
    margin: '15px 15px 0 0',
    width: 100,
  },
  ruleCreationSubheader: {
    marginBottom: 15,
  },
});

type RulesTriggerFlowProps = {
  entityType: TRuleSupportedEntityTypes;
  delayDays?: number;
  onChange: (d: number | undefined) => void;
  onNext: () => void;
};

const RulesTriggerFlow: React.FunctionComponent<RulesTriggerFlowProps> = ({
  entityType,
  delayDays,
  onChange,
  onNext,
}: RulesTriggerFlowProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const entityPlural = pluralize(entityType).toLowerCase();
  const hasDelay = delayDays !== undefined;
  return (
    <div className={sharedClasses.ruleCreationTriggerFlow}>
      <div className={classnames(sharedClasses.ruleCreationSubheader, classes.ruleCreationSubheader)}>
        Once we find matching {entityPlural}, when should this rule run?
      </div>
      <div className="rule-trigger-options">
        <div
          className={classnames(
            sharedClasses.formBlock,
            sharedClasses.formBlockClickable,
            hasDelay ? sharedClasses.formBlockInactive : sharedClasses.formBlockActive
          )}
          onClick={() => onChange(undefined)}
        >
          <strong>Immediately upon import.</strong> Whenever matching {entityPlural} are imported, run this rule on
          them.
        </div>
        <div
          className={classnames(
            sharedClasses.formBlock,
            sharedClasses.formBlockClickable,
            hasDelay ? sharedClasses.formBlockActive : sharedClasses.formBlockInactive
          )}
        >
          <strong>Wait a number of days before running.</strong> This only affects newly imported {entityPlural} going
          forward; existing {entityPlural} will still be triggered right away.
          <div className={classes.ruleTriggerDelay}>
            <input
              className={classnames(sharedClasses.formInput, classes.ruleTriggerDelayFormInput)}
              disabled={false}
              placeholder={'0'}
              onChange={(elem) => {
                const val = elem.currentTarget.value;
                const valNum: number | undefined = isNaN(Number(val)) || val.trim() === '' ? undefined : Number(val);
                onChange(valNum);
              }}
              value={delayDays === undefined ? '' : String(delayDays)}
            />
            <span>days</span>
          </div>
        </div>
      </div>
      <div className={sharedClasses.ruleCreationFooter}>
        <FormButton onClick={onNext}>Next</FormButton>
      </div>
    </div>
  );
};

export default RulesTriggerFlow;
