import { Link } from 'react-router-dom';
import React from 'react';
import { SideBarHeaderProps } from './types';
import { assetPathGenerator } from '../../../../utils/AssetPathUtil';
import { useStyles } from './styles';
import MenuDropdown, { MenuDropdownHorizontalAlignment } from '../../../../components/common/MenuDropdown';

export const Header = ({ showCustomerCompany, name, company }: SideBarHeaderProps) => {
  const classes = useStyles();
  const brandLogo = assetPathGenerator('/images/logo_small.png');

  // TODO: need to refactor, the window.location.reload() is not a good practice
  const onOptionSelect = (entity: string) => {
    localStorage.setItem('multiEntity', entity);
    window.location.reload();
  };

  return (
    <>
      <div className={classes.logoContainer}>
        <Link to="/app/home">
          <img className={classes.logoImage} src={brandLogo} />
        </Link>
      </div>
      <div className={classes.idContainer}>
        <div className={classes.nameContainer}>{name}</div>
        <div className={classes.companyContainer}>
          {showCustomerCompany ? (
            <div style={{ textAlign: 'center' }}>{company}</div>
          ) : (
            <MenuDropdown
              horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
              items={[
                {
                  label: `${company} US`,
                  onClick: () => onOptionSelect('US'),
                },
                {
                  label: `${company} EU`,
                  onClick: () => onOptionSelect('EU'),
                },
                {
                  label: `${company} APAC`,
                  onClick: () => onOptionSelect('APAC'),
                },
              ]}
            >
              {`${company} ${localStorage.getItem('multiEntity') || 'US'}`}
            </MenuDropdown>
          )}
        </div>
      </div>
    </>
  );
};
