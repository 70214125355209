import lodash from 'lodash';

const sum: (arr: number[]) => number = (arr) => {
  return arr.reduce((s, cur) => s + cur, 0);
};

const avg: (arr: number[]) => number = (arr) => {
  return sum(arr) / arr.length;
};

function chunk<T>(arr: T[], chunkSize: number): T[][] {
  const out = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    out.push(arr.slice(i, i + chunkSize));
  }
  return out;
}

function flatten<T>(arr: T[][]): T[] {
  return arr.reduce((flattened, cur) => flattened.concat(cur), []);
}

function getOnlyOrThrow<T>(arr: T[], err?: string): T {
  if (arr.length !== 1) {
    throw new Error(err || `expected only one element in array, found ${arr.length}`);
  }
  return arr[0];
}

function partition<T>(arr: T[], fn: (t: T) => string): { [key in string]: T[] } {
  const obj: { [key in string]: T[] } = {};
  arr.forEach((cur) => {
    const key = fn(cur);
    if (!obj[key]) {
      obj[key] = [];
    }
    obj[key].push(cur);
  });
  return obj;
}

function randomlyPick<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

const range: (to: number) => number[] = (to) => {
  const out: number[] = [];
  for (let i = 0; i < to; i++) {
    out.push(i);
  }
  return out;
};

function unique<T>(arr: T[]): T[] {
  return uniqueBy(arr, (elem) => String(elem));
}

function uniqueBy<T>(arr: T[], fn: (t: T) => string): T[] {
  const uniqueArr: T[] = [];
  const map: { [key: string]: boolean } = {};
  arr.forEach((cur) => {
    const key = fn(cur);
    if (map[key]) {
      return;
    }
    map[key] = true;
    uniqueArr.push(cur);
  });
  return uniqueArr;
}

function filterNull<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((t) => !!t).map((t) => t as T);
}

function intersection<T>(arr1: T[], arr2: T[]): T[] {
  return lodash.intersection(arr1, arr2);
}

function doArraysHaveIntersection<T>(arr1: T[], arr2: T[]): boolean {
  return intersection(arr1, arr2).length > 0;
}

export default {
  avg,
  sum,
  chunk,
  flatten,
  getOnlyOrThrow,
  partition,
  randomlyPick,
  range,
  unique,
  uniqueBy,
  filterNull,
  intersection,
  doArraysHaveIntersection,
};
