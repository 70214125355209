import { createUseStyles } from 'react-jss';

import Colors from '../Colors';

export const useStyles = createUseStyles({
  toasterContainer: {
    position: 'fixed',
    top: '10px',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    minWidth: '300px',
    boxSizing: 'border-box',
    background: Colors.WHITE,
    maxHeight: '60px',
    borderRadius: '6px',
  },
  success: {
    backgroundColor: '#d4edda',
    color: '#fff',
  },
  error: {
    backgroundColor: '#f8d7da',
    color: '#721c24',
  },
  warning: {
    backgroundColor: '#fff3cd',
    color: '#856404',
  },
  toast: {
    fontSize: '16px',
    padding: '8px 16px',
    boxShadow: `0px 0px 3px ${Colors.SHADOW}`,
    transition: 'opacity 0.6s',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    opacity: 0,
    '&.visible': {
      opacity: 1,
    },
  },
  iconContainer: {
    paddingRight: '10px',
  },
});
