export type BundleInput = {
  name: string;
  sku: string;
  productCode: string;
  description: string;
  amount: number;
  priceListItemIds: string;
};

export default function ValidateBundle({ name, sku, productCode, description, amount, priceListItemIds }: BundleInput) {
  if (!name || !name.trim()) {
    return 'Name cannot be empty';
  }
  if (name.length > 100) {
    return 'Name cannot be more than 100 characters';
  }

  if (!sku || !sku.trim()) {
    return 'SKU cannot be empty';
  }
  if (sku.length > 100) {
    return 'SKU cannot be more than 100 characters';
  }

  if (!productCode || !productCode.trim()) {
    return 'Product code cannot be empty';
  }
  if (productCode.length > 100) {
    return 'Product code cannot be more than 100 characters';
  }

  if (!description || !description.trim()) {
    return 'Description cannot be empty';
  }
  if (description.length > 200) {
    return 'Description cannot be more than 200 characters';
  }

  if (!amount) {
    return 'Price must be set';
  }
  if (amount <= 0) {
    return 'Price cannot be negative or zero';
  }

  if (!priceListItemIds || !priceListItemIds.trim()) {
    return 'Must include at least one item in bundle';
  }
  return;
}
