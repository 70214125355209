import React from 'react';

import pluralize from 'pluralize';
import { useParams, useSearchParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import ArrayUtil from '../../src/utils/ArrayUtil';
import ErrorMessage from './common/ErrorMessage';
import PrettyJSON from './common/PrettyJSON';
import RawJsonDisplayCardWithBanner from './common/JsonDisplayPageWithBanner';
import { GetExceptionJsonDataQuery, GetExceptionJsonDataQueryVariables } from '../generated/graphql';

const QUERY = gql`
  query GetExceptionJsonData($cash_transaction_id: String!) {
    rawExceptionObjects(cash_transaction_id: $cash_transaction_id) {
      payoutRvo {
        vendorExternalId
        objectJsonStr
      }
      paymentRvo {
        vendorExternalId
        objectJsonStr
        vendor
      }
      refundRvo {
        vendorExternalId
        objectJsonStr
        vendor
      }
      payoutRecipientVendor
    }
  }
`;

const ReconciliationExceptionsDrilldownJsonWithBanner: React.FunctionComponent = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const { error, data } = useQuery<GetExceptionJsonDataQuery, GetExceptionJsonDataQueryVariables>(QUERY, {
    variables: { cash_transaction_id: params.cashTxnId || '' },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const primaryObjectId = searchParams.get('pid');
  const primaryObjectTable = searchParams.get('pt');
  const integrationRelationshipId = searchParams.get('ir');
  const reconciliationExceptionId = searchParams.get('eid');

  return data ? (
    <>
      <RawJsonDisplayCardWithBanner
        breadcrumSections={[
          { label: 'Reconciliations', url: '/app/reconciliation' },
          {
            label: `Exception ID: ${reconciliationExceptionId}`,
            url: `/app/reconciliations/exceptions?pid=${primaryObjectId}&pt=${primaryObjectTable}&ir=${integrationRelationshipId}&eid=${integrationRelationshipId}`,
          },
        ]}
        sectionData={[
          {
            header: pluralize('Cash Transaction', data?.rawExceptionObjects.refundRvo ? 2 : 1),
            headerPillLabel: data?.rawExceptionObjects.paymentRvo?.vendor || data.rawExceptionObjects.refundRvo?.vendor,
            tabs: ArrayUtil.filterNull([
              data?.rawExceptionObjects.paymentRvo
                ? {
                    header: `Payment: ${data?.rawExceptionObjects?.paymentRvo?.vendorExternalId}`,
                    content: (
                      <PrettyJSON
                        object={
                          data?.rawExceptionObjects.paymentRvo?.objectJsonStr
                            ? JSON.parse(data?.rawExceptionObjects.paymentRvo?.objectJsonStr)
                            : {}
                        }
                      />
                    ),
                  }
                : null,
              data?.rawExceptionObjects.refundRvo
                ? {
                    header: `Refund: ${data?.rawExceptionObjects.refundRvo.vendorExternalId}`,
                    content: (
                      <PrettyJSON
                        object={
                          data?.rawExceptionObjects.refundRvo?.objectJsonStr
                            ? JSON.parse(data?.rawExceptionObjects.refundRvo?.objectJsonStr)
                            : {}
                        }
                      />
                    ),
                  }
                : null,
            ]),
          },
          {
            header: 'Payout',
            headerPillLabel: data?.rawExceptionObjects.payoutRecipientVendor,
            tabs: [
              {
                header: `Payout: ${data?.rawExceptionObjects.payoutRvo?.vendorExternalId}`,
                content: (
                  <PrettyJSON
                    object={
                      data?.rawExceptionObjects.payoutRvo?.objectJsonStr
                        ? JSON.parse(data?.rawExceptionObjects.payoutRvo?.objectJsonStr)
                        : {}
                    }
                  />
                ),
              },
            ],
          },
        ]}
      />
    </>
  ) : (
    <></>
  );
};

export default ReconciliationExceptionsDrilldownJsonWithBanner;
