import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { createUseStyles } from 'react-jss';

import Colors from './Colors';

export const borderStyle = `1px solid ${Colors.DARK_GRAY}`;

const useStyles = createUseStyles({
  container: {
    marginBottom: 20,
    fontSize: 14,
  },

  header: {
    backgroundColor: Colors.FAINT_GRAY,
    border: borderStyle,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    display: 'flex',
    padding: 15,

    '& > div:first-child': {
      fontWeight: 'bold',
    },

    '& > div:last-child': {
      marginLeft: 'auto',
    },
  },

  collapsibleHeader: {
    cursor: 'pointer',
    display: 'flex',

    '& > span:last-child': {
      fontSize: 12,
      marginLeft: 5,
      marginTop: 2,
    },
  },

  body: {
    borderLeft: borderStyle,
    borderRight: borderStyle,
    borderBottom: borderStyle,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    overflowY: 'hidden',
    transition: 'height 0.25s',
  },
});

type SectionWithHeaderProps = {
  children?: ReactElement | ReactElement[] | string | number;
  header?: ReactElement | string | number;
  headerUtility?: ReactElement | string | number;

  isCollapsible?: boolean;
  isCollapsedInitially?: boolean;

  bodyPaddingPx?: number;
};

const SectionWithHeader: React.FunctionComponent<SectionWithHeaderProps> = ({
  children,
  header,
  headerUtility,
  isCollapsible,
  isCollapsedInitially,
  bodyPaddingPx = 15,
}) => {
  const classes = useStyles();

  const [isCollapsed, updateIsCollapsed] = useState(!!isCollapsedInitially);
  useEffect(() => {
    updateIsCollapsed(!!isCollapsedInitially);
  }, [isCollapsedInitially]);
  const [innerBodyHeight, updateInnerBodyHeight] = useState<number | undefined>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let isMounted = true;
    if (!innerBodyHeight) {
      window.setTimeout(() => {
        if (isMounted && ref.current) {
          updateInnerBodyHeight(ref.current.clientHeight);
        }
      }, 100);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div
          className={isCollapsible ? classes.collapsibleHeader : undefined}
          onClick={isCollapsible ? () => updateIsCollapsed(!isCollapsed) : undefined}
        >
          {header}
          {isCollapsible ? <span>{isCollapsed ? '▽' : '△'}</span> : null}
        </div>
        <div>{headerUtility}</div>
      </div>
      <div
        className={classes.body}
        style={
          isCollapsible
            ? {
                height: isCollapsed ? '0' : innerBodyHeight ? `${innerBodyHeight + 2}px` : '0',
                ...(isCollapsed ? { borderBottom: 'none' } : {}),
              }
            : undefined
        }
      >
        <div ref={ref} style={{ padding: `${bodyPaddingPx}px` }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SectionWithHeader;
