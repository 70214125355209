import { DataGridChildren, DataGridRow } from '../../../components/data_grid/DataGridTypes.ts';

import { FormatOptions, processNestedRows } from '../../../components/data_grid/utils/ColumnUtils.ts';
import { EntryRow } from '../../../generated/graphql.tsx';
import { ExtendedEntryRow } from '../../pivotSettings/types.ts';

export const insertItemsIntoHierarchy = <T>(
  hierarchyData: T[],
  path: string[],
  formatOptions: FormatOptions,
  items?: ExtendedEntryRow[] | EntryRow[]
) => {
  const targetIndex: number = hierarchyData.findIndex((node) => path[0] === node['name']);
  if (targetIndex !== -1) {
    const targetNode = hierarchyData[targetIndex] as DataGridRow;
    if (path.length > 1 && targetNode._children) {
      const newPath = path.slice(1);
      insertItemsIntoHierarchy(targetNode._children, newPath, formatOptions, items);
    } else {
      targetNode.isDraggable = true;
      targetNode._children = items ? targetNode._children : undefined;
      if (items) {
        targetNode._children = items.length
          ? processNestedRows(items, formatOptions, 'name') || []
          : ([] as DataGridChildren[]);
      }
    }
  }
  // Return the updated hierarchy
  return hierarchyData; // Return the root hierarchyData
};

export const findNestedItemsByPath = <T>(hierarchyData: T[] = [], path: string[]): T => {
  const findNestedItem = (data: T[], currentPath: string[]): T | undefined => {
    const targetIndex: number = data.findIndex((node) => currentPath[0] === node['label']);
    if (targetIndex !== -1) {
      const targetNode = data[targetIndex];

      if (currentPath.length === 1) {
        return targetNode; // Return the direct parent of the last nested item
      }

      if (targetNode['rows']?.length) {
        const newPath = currentPath.slice(1);
        return findNestedItem(targetNode['rows'], newPath);
      }
    }
    return undefined; // Return undefined if not found
  };

  return findNestedItem(hierarchyData, path) as T;
};
