import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import { gql, useQuery } from '@apollo/client';

import Colors from '../common/Colors';
import { GetAssociatedPaymentsQuery } from '../../generated/graphql';
import LoaderAnimation from '../common/LoaderAnimation';
import PagingControls from '../PagingControls';
import Table from '../common/Table';
import { TransactionCellRowFragment } from '../../apollo/GraphQLFragments';
import { useSharedStyles } from '../../utils/CssUtil';

const useStyles = createUseStyles({
  loaderContainer: {
    textAlign: 'center',
    marginTop: '150px',
    marginRight: '85px',
    display: 'flex',
    justifyContent: 'center',
  },
  emptyState: {
    color: Colors.MEDIUM_GRAY,
  },
  tableFixedWidth: {
    '& > table': {
      tableLayout: 'fixed',
    },

    '& > table td': {
      tableLayout: 'fixed',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
    },
  },
});

const QUERY = gql`
  ${TransactionCellRowFragment}
  query GetAssociatedPayments(
    $primary_object_id: String!
    $primary_object_table: String!
    $integration_relationship_id: String!
    $reconciliation_exception_id: String
    $page: Float
  ) {
    associatedPayments(
      primary_object_id: $primary_object_id
      primary_object_table: $primary_object_table
      integration_relationship_id: $integration_relationship_id
      reconciliation_exception_id: $reconciliation_exception_id
      page: $page
    ) {
      rows {
        ...TransactionCellRowFragment
      }
      currentPage
      pageCount
    }
  }
`;

type TAssociatedTransactionsProps = {
  primaryObjectId: string;
  primaryObjectTable: string;
  integrationRelationshipId: string;
  reconciliationExceptionId?: string;
};

const ReconciliationExceptionAssociatedTransactionsTable: React.FunctionComponent<TAssociatedTransactionsProps> = ({
  primaryObjectId,
  primaryObjectTable,
  integrationRelationshipId,
  reconciliationExceptionId,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const [pageNum, setPageNum] = useState(1);
  const queryParams = {
    primary_object_id: primaryObjectId,
    primary_object_table: primaryObjectTable,
    integration_relationship_id: integrationRelationshipId,
    reconciliation_exception_id: reconciliationExceptionId,
    page: pageNum,
  };

  const {
    loading: isLoading,
    error,
    data,
    refetch,
  } = useQuery<GetAssociatedPaymentsQuery>(QUERY, { variables: queryParams });

  useEffect(() => {
    refetch({
      primary_object_id: primaryObjectId,
      primary_object_table: primaryObjectTable,
      integration_relationship_id: integrationRelationshipId,
      reconciliation_exception_id: reconciliationExceptionId,
      page: pageNum,
    });
  }, [primaryObjectId, primaryObjectTable, pageNum]);

  if (error) {
    return <div className={sharedClasses.main}>Error: {error.message}</div>;
  }

  if (error) {
    return <div className={sharedClasses.main}>Error: {(error as Error).message}</div>;
  }

  let canPageForward = false;
  let canPageBackward = false;
  let currentPage = 1;
  let pageCount = 1;
  if (data?.associatedPayments) {
    currentPage = data.associatedPayments.currentPage || 1;
    pageCount = data.associatedPayments.pageCount || 1;
    canPageForward = currentPage < pageCount;
    canPageBackward = currentPage > 1;
  }

  return (
    <div className={classnames(sharedClasses.content, classes.tableFixedWidth)}>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <LoaderAnimation height={80} />
        </div>
      ) : data?.associatedPayments.rows?.length ? (
        <>
          <Table
            columnMinWidth={75}
            firstColumnLabel="Numeral ID"
            rows={data?.associatedPayments.rows || []}
            shouldAttemptToFitColumnContent
          />

          <div className={sharedClasses.afterTable}>
            <div className={sharedClasses.afterTableLeft}>
              <PagingControls
                canPageForward={canPageForward}
                canPageBackward={canPageBackward}
                currentPage={currentPage}
                pageCount={pageCount}
                pageSize={50}
                refetchPage={(pageNum) => setPageNum(pageNum)}
                shouldDisableChangingPageSize
              />
            </div>
            <div className={sharedClasses.afterTableRight} />
          </div>
        </>
      ) : (
        <div className={classes.emptyState}>No associated transactions found</div>
      )}
    </div>
  );
};

export default ReconciliationExceptionAssociatedTransactionsTable;
