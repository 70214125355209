import { createUseStyles } from 'react-jss';
import Colors from '../../../../components/common/Colors.tsx';

export const useStyles = createUseStyles({
  checkboxContainer: {
    width: 14,
    height: 14,
    borderRadius: 5,
    border: `1px solid ${Colors.SILVER_GRAY}`,
    overflow: 'hidden',
    position: 'relative',
  },
  defaultPivotContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 16,
  },
  textBox: {
    height: 48,
    width: '100%',
    padding: 12,
    fontSize: 18,

    '&::placeholder': {
      color: '#B4B4B4',
    },
  },
  checkbox: {
    height: 16,
    width: 16,
    display: 'inline-flex',
    margin: 0,
    position: 'absolute',
    top: -1,
    left: -1,
  },
  defaultPivotLabel: {
    marginLeft: 8,
    fontSize: 16,
    color: `${Colors.DEEP_GRAY}`,
  },
  formControls: {
    width: '50%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
});
