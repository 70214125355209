import React from 'react';
import { createUseStyles } from 'react-jss';

export interface SpacerProps {
  width?: number;
  height?: number;
}

const useStyles = createUseStyles<SpacerProps>({
  spacer: {
    width: ({ width }) => `${width || 0}px`,
    height: ({ height }) => `${height || 0}px`,
  },
});

export const Spacer = ({ width, height }: SpacerProps) => {
  const classes = useStyles({ width, height });
  return <div className={classes.spacer} />;
};
