import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { createUseStyles } from 'react-jss';
import { useMatch, useNavigate } from 'react-router-dom';

import LoaderAnimation from '../components/common/LoaderAnimation.tsx';
import { useAuth } from '../hooks/useAuth.ts';
import { CompanyMetaDataProps, useCompanyMetaData } from '../hooks/useCompanyMetaData.ts';
import { GetCurrentSessionQuery, LoginResponse, Session, UserModel } from '../generated/graphql';
import { TLoginStep, TNavItem } from '../../src/types/BaseTypes.ts';

type AppContextData = CompanyMetaDataProps &
  GetCurrentSessionQuery & {
    appName: string;
    onResetAuth: () => void;
    isAuthenticated: boolean;
    currentUser: UserModel | null;
    onLoginSuccess: (user: LoginResponse) => Promise<void>;
  };

interface AppProviderProps {
  children: ReactNode;
}

const useStyles = createUseStyles({
  loaderContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const AppContext = React.createContext<AppContextData>({} as AppContextData);

export const AppProvider = ({ children }: AppProviderProps) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { fetchAuthDetails, isLoading, currentSessionData, currentUserData } = useAuth();
  const matchLogin = useMatch('/login');
  const matchDefault = useMatch('/');
  const isNonAuthRoute = !!matchLogin || !!matchDefault;
  const [isAuthenticated, setAuthenticated] = useState(false);

  const companyMetadata = useCompanyMetaData(TNavItem.REVENUE, !isAuthenticated);

  const getFromLocalStorage = <T,>(key: string): T | null => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  };

  const bootstrapApp = useCallback(async () => {
    const sessionData = getFromLocalStorage<Session>('currentSession');
    const userData = getFromLocalStorage<UserModel>('currentUser');
    if (sessionData && userData) {
      setAuthenticated(true);
    } else {
      await fetchAuthDetails();
      setAuthenticated(true);
    }
  }, [fetchAuthDetails]);

  const onLoginSuccess = useCallback(
    async (user: LoginResponse) => {
      await fetchAuthDetails();
      setAuthenticated(true);
      navigate(user?.loginStep === TLoginStep.COMPLETED ? '/app/revenue' : '/login-otp');
    },
    [fetchAuthDetails, navigate]
  );

  useEffect(() => {
    if (!isNonAuthRoute) {
      bootstrapApp();
    }
  }, [bootstrapApp, isNonAuthRoute]);

  const onResetAuth = () => {
    setAuthenticated(false);
    localStorage.removeItem('currentSession');
    localStorage.removeItem('currentUser');
  };

  const cachedCurrentUser = (currentUserData?.currentUser ||
    getFromLocalStorage<UserModel>('currentUser') ||
    {}) as UserModel;

  const cachedCurrentSession = (currentSessionData ||
    getFromLocalStorage<GetCurrentSessionQuery>('currentSession') ||
    {}) as GetCurrentSessionQuery;

  return (
    <AppContext.Provider
      value={{
        appName: 'Numeral',
        isAuthenticated,
        onResetAuth,
        onLoginSuccess,
        currentUser: cachedCurrentUser,
        currentSession: cachedCurrentSession.currentSession,
        currentAvailableNavSidebars: cachedCurrentSession?.currentAvailableNavSidebars ?? [],
        currentNavSidebarsCollapsed: cachedCurrentSession?.currentNavSidebarsCollapsed ?? [],
        ...companyMetadata,
      }}
    >
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <LoaderAnimation height={64} />
        </div>
      ) : (
        children
      )}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextData => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
};

export default AppContext;
