import React, { useCallback, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { PlaidLinkOnSuccess, PlaidLinkOnSuccessMetadata, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { useNavigate, useParams } from 'react-router-dom';

import { gql, useMutation, useQuery } from '@apollo/client';

import Colors from './common/Colors';
import FormButton from './common/FormButton';
import getAssetPath from '../utils/AssetPathUtil';
import { useSharedStyles } from '../utils/CssUtil';
import {
  GetValidPlaidLinkTokenQuery,
  GetValidPlaidLinkTokenQueryVariables,
  SetPlaidAccessTokenMutation,
  SetPlaidAccessTokenMutationVariables,
} from '../generated/graphql.js';

const useStyles = createUseStyles({
  loginContainer: {
    display: 'block',
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    width: '100%',
  },

  contentWrapper: {
    height: 'auto',
    minHeight: 'auto',
    margin: '-50px auto 0',
    padding: '30px',
    width: '270px',
  },

  loginLogoContainer: {
    textAlign: 'center',

    '& p': {
      fontSize: '14px',
      margin: '10px 0 20px',
    },
  },

  loginRow: {
    fontSize: '14px',
    margin: '15px 0',

    '& > span': {
      display: 'block',
      marginBottom: '-10px',
      width: '80px',
    },

    '& input': {
      background: 'transparent',
      border: `1px solid ${Colors.MEDIUM_GRAY}`,
      borderRadius: '3px',
      color: Colors.DARK_GRAY,
      fontSize: '14px',
      padding: '8px',
      transition: 'border 0.1s',
      width: '100%',

      '&:focus': {
        border: `1px solid ${Colors.DARK_GRAY}`,
        boxShadow: '1px 1px 1px rgba(0,0,0,0.035)',
        outline: 'none',
      },

      '&:disabled': {
        backgroundColor: Colors.LIGHT_GRAY,
      },
    },

    '& button': {
      width: '100%',
    },
  },

  error: {
    color: Colors.WARNING_RED,
    fontSize: '12px',
    marginTop: '-8px',
  },
});

const QUERY = gql`
  query GetValidPlaidLinkToken($encryptedCompanyId: String, $isUpdateMode: Boolean, $institutionName: String) {
    getValidPlaidLinkToken(
      encryptedCompanyId: $encryptedCompanyId
      isUpdateMode: $isUpdateMode
      institutionName: $institutionName
    ) {
      isSuccess
      linkToken
    }
  }
`;

const MUTATION = gql`
  mutation SetPlaidAccessToken(
    $publicToken: String!
    $institutionName: String!
    $encryptedCompanyId: String
    $isUpdateMode: Boolean
  ) {
    setPlaidAccessToken(
      publicToken: $publicToken
      institutionName: $institutionName
      encryptedCompanyId: $encryptedCompanyId
      isUpdateMode: $isUpdateMode
    ) {
      isSuccess
      error
    }
  }
`;

enum TLinkState {
  Initial,
  Submitting,
  Succeeded,
  Failed,
}

type TPlaidLinkProps = {
  isBehindLogin?: boolean;
  isUpdateMode?: boolean;
};

const PlaidLink: React.FunctionComponent<TPlaidLinkProps> = (props: TPlaidLinkProps) => {
  const [stateValue, setStateValue] = useState<TLinkState>(TLinkState.Initial);
  const [errorValue, setErrorStateValue] = useState<string | null>(null);

  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { encryptedCompanyId, institutionName } = params;

  const [setAccessKey, { error: setAccessKeyError }] = useMutation<
    SetPlaidAccessTokenMutation,
    SetPlaidAccessTokenMutationVariables
  >(MUTATION);

  const { data: plaidLinkTokenData, loading: isPlaidLinkTokenLoading } = useQuery<
    GetValidPlaidLinkTokenQuery,
    GetValidPlaidLinkTokenQueryVariables
  >(
    QUERY,
    encryptedCompanyId
      ? { variables: { encryptedCompanyId, ...(props.isUpdateMode ? { isUpdateMode: true, institutionName } : {}) } }
      : undefined
  );

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const response = await setAccessKey({
        variables: {
          publicToken: public_token,
          institutionName: metadata.institution?.name || institutionName || '',
          ...(encryptedCompanyId ? { encryptedCompanyId } : {}),
          ...(props.isUpdateMode ? { isUpdateMode: true } : {}),
        },
      });

      if (response.data?.setPlaidAccessToken.isSuccess) {
        setStateValue(TLinkState.Succeeded);
        setErrorStateValue(null);
        if (props.isBehindLogin) {
          navigate('/app/home');
        } else {
          navigate('/');
        }
      } else {
        setStateValue(TLinkState.Failed);
        setErrorStateValue(JSON.stringify(setAccessKeyError));
      }
    },
    []
  );

  const config: PlaidLinkOptions = {
    onSuccess,
    token: plaidLinkTokenData?.getValidPlaidLinkToken.linkToken || '',
  };
  const { open, ready } = usePlaidLink(config);

  return (
    <div className={classes.loginContainer}>
      <div className={classnames(sharedClasses.contentWrapper, classes.contentWrapper)}>
        <div className={classes.loginLogoContainer}>
          <img src={`${getAssetPath()}/images/logo_small.png`} height={48} />
          <p>
            {props.isUpdateMode
              ? 'Re-authenticate your bank account connection with Plaid'
              : 'Link your bank account with Plaid'}
          </p>
        </div>
        <div className={classes.loginRow}>
          <FormButton
            isDisabled={
              !ready ||
              stateValue !== TLinkState.Initial ||
              isPlaidLinkTokenLoading ||
              !plaidLinkTokenData?.getValidPlaidLinkToken.isSuccess
            }
            onClick={() => {
              setStateValue(TLinkState.Submitting);
              open();
            }}
          >
            {props.isUpdateMode ? 'Launch Update' : 'Launch Link'}
          </FormButton>
        </div>
        {errorValue ? <div className={classes.error}>{errorValue}</div> : null}
      </div>
    </div>
  );
};

export default PlaidLink;
