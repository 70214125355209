import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import Colors from './Colors';
import { DATE_RANGE_SELECT_DROPDOWN_WIDTH_PX } from '../../utils/TableConstants';
import DataTableCell from './TableCell';
import { Link } from 'react-router-dom';
import getAssetPath from '../../utils/AssetPathUtil';

import TableColumnFilterDateModal from './TableColumnFilterDateModal';
import TableColumnFilterStrModal from './TableColumnFilterStrModal';
import { TransactionCellRowFragmentFragment } from '../../generated/graphql';
import { useSharedStyles } from '../../utils/CssUtil';
import {
  DateFilter,
  Sort,
  SortDirections,
  StringToStringMap,
  SupportedSortKeys,
} from '../../../src/types/DataHubTypes';

const PIXELS_PER_CHARACTER = 8;
const FROZEN_CELL_CLASS_NAME = 'frozen-column';

const useTableCellHeaderStyles = createUseStyles({
  filterable: {
    cursor: 'pointer',
    '&:hover $filterIcon': {
      display: 'inline-block',
    },
  },
  filterIcon: {
    height: 16,
    float: 'right',
    display: 'none',
    opacity: 0.85,
    width: 'auto',
  },
  contentCellSortable: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      '&:after': {
        content: '"▲"',
        display: 'inline-block',
        fontSize: '10px',
        marginLeft: 3,
      },
    },
  },
  contentCellSortableAsc: {
    '&:hover': {
      '&:after': {
        content: '"▼"',
      },
    },
    '&:after': {
      content: '"▲"',
      display: 'inline-block',
      fontSize: '10px',
      marginLeft: 3,
    },
  },
  contentCellSortableDes: {
    '&:hover': {
      '&:after': {
        content: '"▲"',
      },
    },
    '&:after': {
      content: '"▼"',
      display: 'inline-block',
      fontSize: '10px',
      marginLeft: 3,
    },
  },
});

const useTableCellBodyStyles = createUseStyles({
  highlightedActiveRow: {
    '.content table & td': {
      backgroundColor: Colors.LIGHT_GRAY,
    },
  },
});

const filterableColumns = ['Source ID'];

type TableHeaderCellProps = {
  currentDateFilter?: DateFilter;
  currentSort?: Sort;
  dateFilterStartField?: string;
  dateFilterEndField?: string;
  isLeftAligned: boolean;
  isSticky: boolean;
  label: string;
  minWidth?: number;
  onSort?: (s?: Sort) => void;
  sortKey?: SupportedSortKeys;
  onColFilter?: (obj: StringToStringMap) => void;
  searchParams?: URLSearchParams;
};

const TableHeaderCell: React.FunctionComponent<TableHeaderCellProps> = ({
  currentDateFilter,
  currentSort,
  dateFilterStartField,
  dateFilterEndField,
  isLeftAligned,
  isSticky,
  label,
  minWidth,
  onSort,
  sortKey,
  onColFilter,
  searchParams,
}: TableHeaderCellProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useTableCellHeaderStyles();
  const [isDateSelectorVisible, updateIsDateSelectorVisible] = useState(false);
  const [isStringColFilterVisible, updateIsStringColFilterVisible] = useState(false);

  const tdRef = useRef<HTMLTableCellElement>(null);

  const showColumnFilter = (dateFilterStartField && dateFilterEndField) || filterableColumns.includes(label);

  const isColumnSortable = !!sortKey && !filterableColumns.includes(label);

  const getDropdownPosition = () => {
    const td = tdRef.current;
    if (!td) {
      return;
    }
    const viewportWidthPx = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const tdBoundingClientRect = td.getBoundingClientRect();
    let leftOffsetPx = td.offsetLeft;
    if (viewportWidthPx - tdBoundingClientRect.right <= DATE_RANGE_SELECT_DROPDOWN_WIDTH_PX) {
      leftOffsetPx = td.offsetLeft + td.offsetWidth - DATE_RANGE_SELECT_DROPDOWN_WIDTH_PX;
    }
    return {
      left: leftOffsetPx,
      top: td.offsetTop + tdBoundingClientRect.height - 25,
    };
  };

  return (
    <td
      className={classnames({
        [sharedClasses.contentCellRightAlign]: !isLeftAligned,
        [classes.contentCellSortable]: isColumnSortable,
        [classes.contentCellSortableAsc]:
          isColumnSortable && !!(currentSort?.key === sortKey && currentSort?.direction === SortDirections.ASC),
        [classes.contentCellSortableDes]:
          isColumnSortable && !!(currentSort?.key === sortKey && currentSort?.direction === SortDirections.DESC),
        [FROZEN_CELL_CLASS_NAME]: isSticky,
        [classes.filterable]: showColumnFilter,
      })}
      style={minWidth ? { minWidth } : {}}
      ref={tdRef}
      onClick={() => {
        if (dateFilterStartField && dateFilterEndField) {
          return updateIsDateSelectorVisible(!isDateSelectorVisible);
        }
        if (filterableColumns.includes(label)) {
          return updateIsStringColFilterVisible((prev) => !prev);
        }
        if (sortKey && onSort) {
          return onSort({
            key: sortKey,
            direction:
              currentSort?.key === sortKey
                ? currentSort?.direction === SortDirections.ASC
                  ? SortDirections.DESC
                  : SortDirections.ASC
                : SortDirections.ASC,
          });
        }
      }}
    >
      {label}
      {showColumnFilter ? (
        <img className={classes.filterIcon} src={`${getAssetPath()}/images/icon_filter.svg`} />
      ) : null}
      {isDateSelectorVisible ? (
        <TableColumnFilterDateModal
          currentDateFilter={currentDateFilter}
          dateFilterStartField={dateFilterStartField}
          dateFilterEndField={dateFilterEndField}
          onColFilter={onColFilter}
          getDropdownPosition={getDropdownPosition}
          updateIsDateSelectorVisible={updateIsDateSelectorVisible}
        />
      ) : null}
      {isStringColFilterVisible ? (
        <TableColumnFilterStrModal
          label={label}
          colKey={sortKey}
          onColFilter={onColFilter}
          getDropdownPosition={getDropdownPosition}
          updateIsStringColFilterVisible={updateIsStringColFilterVisible}
          searchParams={searchParams}
        />
      ) : null}
    </td>
  );
};

export type CombinedRowType = TransactionCellRowFragmentFragment & {
  labelOnClick?: () => void;
};

type TableProps = {
  className?: string;
  columnMinWidth?: number;
  dateFilter?: DateFilter;
  firstColumnLabel: string;
  onSort?: (s?: Sort) => void;
  onColFilter?: (obj: StringToStringMap) => void;
  rows: CombinedRowType[];
  shouldAttemptToFitColumnContent?: boolean;
  sort?: Sort;
  isActiveRowFn?: (row: CombinedRowType) => boolean;
  searchParams?: URLSearchParams;
};

const Table: React.FunctionComponent<TableProps> = ({
  className,
  columnMinWidth,
  dateFilter,
  firstColumnLabel,
  onSort,
  onColFilter,
  rows,
  shouldAttemptToFitColumnContent = false,
  sort,
  isActiveRowFn,
  searchParams,
}: TableProps) => {
  const colLabels: {
    isLeftAligned?: boolean;
    isSticky?: boolean;
    label: string;
    minWidth?: number;
    sortKey?: string;
    dateFilterStartField?: string;
    dateFilterEndField?: string;
  }[] = [
    {
      isLeftAligned: true,
      label: firstColumnLabel,
      isSticky: !!rows[0].isSticky,
    },
  ];
  const ref = useRef<HTMLTableElement>(null);
  const scrollRef = useRef<HTMLTableRowElement>(null);
  const executeScroll = () => scrollRef.current?.scrollIntoView();
  const classes = useTableCellBodyStyles();

  useEffect(() => {
    let isMounted = true;
    window.setTimeout(() => {
      if (isMounted && ref.current) {
        const frozenCells = ref.current.querySelectorAll(`.${FROZEN_CELL_CLASS_NAME}`);
        const cumulativeWidth: { [key: string]: number } = {};
        frozenCells.forEach((c) => {
          const cell = c as HTMLTableCellElement;
          const parent = cell.parentNode;
          if (!parent) {
            return;
          }
          const indexInParent: number = Array.prototype.indexOf.call(parent.children, cell);
          if (!cumulativeWidth[String(indexInParent)]) {
            cumulativeWidth[String(indexInParent)] = cell.clientWidth;
          }
          if (indexInParent > 0) {
            let offset = 0;
            for (let i = 0; i < indexInParent; i++) {
              offset += (cumulativeWidth[String(i)] || 0) + (i === 0 ? 2 : 1);
            }
            cell.style.left = `${Math.round(offset)}px`;
          }
        });
      }
    }, 100);
    executeScroll();
    return () => {
      isMounted = false;
    };
  }, []);

  (rows[0].cells || []).forEach((c) => {
    let minWidth = columnMinWidth;
    if (shouldAttemptToFitColumnContent && c.content) {
      minWidth = Math.max(c.content.length * PIXELS_PER_CHARACTER, minWidth || 0);
    }
    colLabels.push({
      isLeftAligned: c.isLeftAligned,
      isSticky: !!c.isSticky,
      label: c.label || '',
      minWidth,
      sortKey: c.sortKey || undefined,
      dateFilterStartField: c.dateFilterStartField || undefined,
      dateFilterEndField: c.dateFilterEndField || undefined,
    });
  });

  if (!rows.length) {
    return <div />;
  }

  return (
    <table className={className} style={{ position: 'relative' }} ref={ref}>
      <thead>
        <tr>
          {colLabels.map((l, idx) => (
            <TableHeaderCell
              currentDateFilter={dateFilter}
              currentSort={sort}
              dateFilterStartField={l.dateFilterStartField}
              dateFilterEndField={l.dateFilterEndField}
              isLeftAligned={!!l.isLeftAligned}
              isSticky={!!l.isSticky}
              label={l.label}
              minWidth={l.minWidth}
              onSort={onSort}
              sortKey={l.sortKey as SupportedSortKeys}
              onColFilter={onColFilter}
              key={`table-header-cell-${idx}`}
              searchParams={searchParams}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, idxR) => (
          <tr
            ref={isActiveRowFn && isActiveRowFn(row) ? scrollRef : null}
            className={classnames(row.customClass, {
              [classes.highlightedActiveRow]: isActiveRowFn && isActiveRowFn(row),
            })}
            key={`tr-${idxR}`}
          >
            <td className={classnames({ [FROZEN_CELL_CLASS_NAME]: !!row.isSticky })}>
              {row.labelUrl ? (
                <Link to={row.labelUrl}>{row.label}</Link>
              ) : (
                row.label && <span onClick={row.labelOnClick}>{row.label}</span>
              )}
            </td>
            {(row.cells || []).map((cell, idxC) => (
              <DataTableCell
                customClass={classnames(cell.customClass, { [FROZEN_CELL_CLASS_NAME]: !!cell.isSticky })}
                formattedValue={cell.content}
                href={cell.url || undefined}
                isLeftAligned={cell.isLeftAligned}
                key={`cell-${idxR}-${idxC}`}
                pills={cell.pills || undefined}
                tooltip={cell.tooltip || undefined}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
