import React from 'react';

import Modal from 'react-modal';

const ModalWrapper: React.FunctionComponent<Modal.Props> = (props: Modal.Props) => {
  const style = {
    content: {
      width: '664px',
      height: '240px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'visible',
    },
    overlay: {
      zIndex: 1000,
    },
    ...props.style,
  } as Modal.Styles;

  return <Modal {...props} style={style} />;
};

export default ModalWrapper;
