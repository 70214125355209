import React, { useState } from 'react';

import RuleIfConditionFlow from './RuleIfConditionFlow';
import RulesThenActionFlow from './RuleThenActionFlow';
import RulesTriggerFlow from './RuleTriggerFlow';
import { createUseStyles } from 'react-jss';
import RulePreview, { RuleCreationSteps, RuleInFlow } from './RulePreview';

const useStyles = createUseStyles({
  ruleNewFlowHeader: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  ruleNewFlowContainer: {
    display: 'flex',
  },
  ruleNewFlowPreview: {
    marginLeft: 'auto',
    marginTop: 5,
  },
});

const NewRuleFlow: React.FunctionComponent = () => {
  const classes = useStyles();
  const [rule, updateRule] = useState<RuleInFlow>({});
  const [step, updateStep] = useState<RuleCreationSteps>(RuleCreationSteps.IF);

  return (
    <div className={classes.ruleNewFlowContainer}>
      <div style={{ flex: '1' }}>
        <div className={classes.ruleNewFlowHeader}>New Rule</div>
        <div>
          {step === RuleCreationSteps.IF ? (
            <RuleIfConditionFlow
              entity={rule.if ? rule.if.entity : undefined}
              conditions={rule.if ? rule.if.conditions || [] : []}
              onChange={(entity, conditions) => {
                updateRule({
                  ...rule,
                  if: {
                    ...(rule.if || {}),
                    entity,
                    conditions,
                  },
                });
              }}
              onNext={() => {
                updateStep(RuleCreationSteps.TRIGGER);
                updateRule({
                  ...rule,
                  trigger: {
                    ...(rule.trigger || {}),
                  },
                });
              }}
            />
          ) : step === RuleCreationSteps.TRIGGER && rule.if?.entity ? (
            <RulesTriggerFlow
              delayDays={rule.trigger?.delayDays}
              entityType={rule.if?.entity}
              onChange={(delayDays) => {
                updateRule({
                  ...rule,
                  trigger: {
                    ...(rule.trigger || {}),
                    delayDays,
                  },
                });
              }}
              onNext={() => updateStep(RuleCreationSteps.THEN)}
            />
          ) : rule.if?.entity ? (
            <RulesThenActionFlow
              entityType={rule.if?.entity}
              entrySets={rule.then?.journalEntrySets || []}
              onChange={(es) => {
                updateRule({
                  ...rule,
                  then: {
                    ...(rule.then || {}),
                    journalEntrySets: es,
                  },
                });
              }}
            />
          ) : null}
        </div>
      </div>
      <div className={classes.ruleNewFlowPreview}>
        <RulePreview step={step} rule={rule} />
      </div>
    </div>
  );
};

export default NewRuleFlow;
