import React, { useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import Colors from './common/Colors';
import FormButton from './common/FormButton';
import getAssetPath from '../utils/AssetPathUtil';
import { useSharedStyles } from '../utils/CssUtil';
import {
  ProvideNetsuiteAccessCredentialsMutation,
  ProvideNetsuiteAccessCredentialsMutationVariables,
} from '../generated/graphql.js';

const useStyles = createUseStyles({
  container: {
    display: 'block',
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    width: '100%',
  },
  contentWrapper: {
    height: 'auto',
    minHeight: 'auto',
    margin: '-50px auto 0',
    padding: '30px',
    width: '270px',
  },
  logoContainer: {
    textAlign: 'center',

    '& p': {
      fontSize: '14px',
      margin: '10px 0 20px',
    },
  },
  row: {
    fontSize: '14px',
    margin: '15px 0',

    '& > span': {
      display: 'block',
      marginBottom: '-10px',
      width: '80px',
    },

    '& input': {
      background: 'transparent',
      border: `1px solid ${Colors.MEDIUM_GRAY}`,
      borderRadius: '3px',
      color: Colors.DARK_GRAY,
      fontSize: '14px',
      padding: '8px',
      transition: 'border 0.1s',
      width: '100%',

      '&:focus': {
        border: `1px solid ${Colors.DARK_GRAY}`,
        boxShadow: '1px 1px 1px rgba(0,0,0,0.035)',
        outline: 'none',
      },

      '&:disabled': {
        backgroundColor: Colors.LIGHT_GRAY,
      },
    },

    '& button': {
      width: '100%',
    },
  },
  error: {
    color: Colors.WARNING_RED,
    fontSize: '12px',
    marginTop: '-8px',
  },
});

const PROVIDE_TOKEN_MUTATION = gql`
  mutation ProvideNetsuiteAccessCredentials($token_id: String!, $token_secret: String!, $encrypted_company_id: String) {
    provideNetsuiteAccessCredentials(
      token_id: $token_id
      token_secret: $token_secret
      encrypted_company_id: $encrypted_company_id
    ) {
      isSuccessful
      error
    }
  }
`;

PROVIDE_TOKEN_MUTATION;

enum TConnectState {
  Initial,
  Submitting,
  Succeeded,
  Failed,
}

const NetsuiteConnect: React.FunctionComponent = () => {
  const [stateValue, setStateValue] = useState<TConnectState>(TConnectState.Initial);
  const [errorValue, setErrorValue] = useState<string | null>(null);
  const [tokenId, setTokenId] = useState<string>('');
  const [tokenSecret, setTokenSecret] = useState<string>('');

  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const encryptedCompanyId = queryParams.get('ecid');

  const [provideAccessCreds, { error: provideAccessCredsError }] = useMutation<
    ProvideNetsuiteAccessCredentialsMutation,
    ProvideNetsuiteAccessCredentialsMutationVariables
  >(PROVIDE_TOKEN_MUTATION);

  if (provideAccessCredsError) {
    setErrorValue(provideAccessCredsError.message);
  }

  const continueFn = async () => {
    setStateValue(TConnectState.Submitting);
    await provideAccessCreds({
      variables: {
        token_id: tokenId,
        token_secret: tokenSecret,
        encrypted_company_id: encryptedCompanyId,
      },
    }).then((res) => {
      if (res.data?.provideNetsuiteAccessCredentials.isSuccessful) {
        setStateValue(TConnectState.Succeeded);
        encryptedCompanyId ? navigate('/') : navigate('/app/home');
      } else {
        setStateValue(TConnectState.Failed);
        setErrorValue(res.data?.provideNetsuiteAccessCredentials?.error || 'Something went wrong');
      }
    });
  };

  return (
    <div className={classes.container}>
      <div className={classnames(sharedClasses.contentWrapper, classes.contentWrapper)}>
        <div className={classes.logoContainer}>
          <img src={`${getAssetPath()}/images/logo_small.png`} height={48} />
          <p>Connect your Netsuite account with Numeral</p>
        </div>
        <div className={classes.row}>
          Token ID:
          <input type="text" className={classes.row} onChange={(e) => setTokenId(e.target.value)}></input>
          Token Secret:
          <input type="text" className={classes.row} onChange={(e) => setTokenSecret(e.target.value)}></input>
          <div className={classes.row}>
            <FormButton
              isDisabled={!tokenId || !tokenSecret || stateValue !== TConnectState.Initial}
              onClick={() => {
                setStateValue(TConnectState.Submitting);
                continueFn();
              }}
            >
              Submit
            </FormButton>
          </div>
        </div>
        {errorValue ? <div className={classes.error}>{errorValue}</div> : null}
      </div>
    </div>
  );
};

export default NetsuiteConnect;
