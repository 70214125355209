import React from 'react';
import pluralize from 'pluralize';

import Colors from '../common/Colors';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { getDisplayNameFromCondition } from './utils/RuleDisplayUtil';
import { TRuleIfConditionElementaryCondition, TRuleSupportedEntityTypes } from '../../../src/types/RuleTypes';

const useStyles = createUseStyles({
  rulePreview: {
    color: 'rgb(37 72 150)',
    width: 350,
    minHeight: 400,
    backgroundColor: 'rgb(240 245 255)',
    padding: 20,
    paddingLeft: 30,
    boxSizing: 'border-box',
    fontSize: '14px',
    borderRadius: 5,
    border: '1px solid rgb(168 195 255)',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.05)',
    '& h3': {
      fontSize: '14px',
      lineHeight: '20px',
      margin: '0 0 15px',
      padding: 0,
    },
    '& h4': {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '16px',
      margin: '0 0 5px',
      padding: 0,
      fontStyle: 'italic',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  rulePreviewSection: {
    borderLeft: '3px solid transparent',
    marginBottom: 15,
    marginLeft: -13,
    opacity: 0.5,
    padding: '3px 0 3px 10px',
    '& ul': {
      margin: '5px 0',
      paddingLeft: 20,
    },
  },
  rulePreviewSectionActive: {
    borderLeft: '3px solid rgb(37 72 150)',
    opacity: 1,
  },
  rulePreviewSectionBody: {},
  rulePreviewIfSummary: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  rulePreviewThenActionsAmount: {
    display: 'inline-block',
    padding: '2px 4px',
    backgroundColor: Colors.LIGHT_GRAY,
    minWidth: 20,
    textAlign: 'center',
    margin: 3,
    borderRadius: 2,
  },
});

export enum RuleCreationSteps {
  IF,
  TRIGGER,
  THEN,
}

// TODO quick and dirty for demo
export type RuleInFlowJournalEntrySet = {
  id: string;
  account?: string;
  entryCount?: string; // TODO: this should be number
  initialEntryTiming?: string;
  subsequentEntryTiming?: string;
  entryAmount?: string;
  entryAmountFormula?: string;
};

export type RuleInFlow = {
  if?: {
    entity: TRuleSupportedEntityTypes;
    conditions: TRuleIfConditionElementaryCondition[];
  };
  trigger?: {
    delayDays?: number;
  };
  then?: {
    journalEntrySets: RuleInFlowJournalEntrySet[];
  };
};

type RulePreviewProps = {
  step: RuleCreationSteps;
  shouldSkipNullStateForCurrentStep?: boolean;
  rule: RuleInFlow;
};

const RulePreview: React.FunctionComponent<RulePreviewProps> = ({ step, rule }: RulePreviewProps) => {
  const classes = useStyles();
  return (
    <div className={classes.rulePreview}>
      <h3>Rule Summary</h3>
      <div
        className={classnames(
          classes.rulePreviewSection,
          step === RuleCreationSteps.IF ? classes.rulePreviewSectionActive : ''
        )}
      >
        <h4>Which records to apply to?</h4>
        {rule.if && rule.if.entity ? (
          <div className={classes.rulePreviewSectionBody}>
            {rule.if.conditions.length ? (
              <div className={classes.rulePreviewIfSummary}>
                {pluralize(rule.if.entity.toLowerCase())} that match all of following:
                <ul>
                  {rule.if.conditions.map((c, idx) => (
                    <li key={`rule-summary-condition-${idx}`}>
                      {rule.if?.entity ? getDisplayNameFromCondition(rule.if?.entity, c) : ''}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div>All {pluralize(rule.if.entity.toLowerCase())}</div>
            )}
          </div>
        ) : null}
      </div>
      <div
        className={classnames(
          classes.rulePreviewSection,
          step === RuleCreationSteps.TRIGGER ? classes.rulePreviewSectionActive : ''
        )}
      >
        <h4>When is it triggered?</h4>
        {rule.trigger ? (
          rule.trigger.delayDays ? (
            <div className={classes.rulePreviewSectionBody}>After {rule.trigger.delayDays} days</div>
          ) : (
            <div className={classes.rulePreviewSectionBody}>Immediately</div>
          )
        ) : null}
      </div>
      <div
        className={classnames(
          classes.rulePreviewSection,
          step === RuleCreationSteps.THEN ? classes.rulePreviewSectionActive : ''
        )}
      >
        <h4>What journal entries does this rule make?</h4>
        {rule.then
          ? rule.then.journalEntrySets.map((es, idx) => (
              <div className={classes.rulePreviewSectionBody} key={`rule-preview-section-body-${idx}`}>
                <strong>{es.account}: </strong>
                {es.entryCount === '1' ? '1 entry ' : es.entryCount + ' monthly entries '}
                with amount
                <span className={classes.rulePreviewThenActionsAmount}>{es.entryAmountFormula || es.entryAmount}</span>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default RulePreview;
