import React from 'react';

import Modal from 'react-modal';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import ApolloClient from '../apollo/ApolloClient';
import AuditTrail from './AuditTrail';
import Banner from './Banner';
import CashTransactionsCardWithBanner from './CashTransactionsCardWithBanner';
import CompanySettings from './CompanySettings';
import DataExceptionsWithBanner from './DataExceptionsWithBanner';
import DataHubCard from './DataHubCard';
import DataHubLineItemCardWithBanner from './DataHubLineItemCardWithBanner';
import DepositsCardWithBanner from './DepositsCardWithBanner';
import EmailSent from './EmailSent';
import Exceptions from './Exceptions';
import ExceptionsDrilldownCardWithBanner from './ReconciliationExceptionsDrilldownCardWithBanner';
import ForgotPassword from './ForgotPassword';
import IntegrationsCard from './IntegrationsCard';
import Join from './Join';
import Login from './Login';
import LoginOtp from './LoginOTP';
import Maintenance from './Maintenance';
import NetsuiteConnect from './NetsuiteConnectContainer';
import PasswordResetSuccess from './PasswordResetSuccess';
import PaymentRecognitionScheduleCardWithBanner from './PaymentRecognitionScheduleCardWithBanner';
import PlaidLink from './PlaidLinkContainer';
import PriceListCard from './price_list/PriceListCard';
import PriceListNewBundle from './price_list/PriceListNewBundle';
import ReconciliationCardWithBanner from './ReconciliationCardWithBanner';
import ReconciliationExceptionsDrilldownJsonWithBanner from './ReconciliationExceptionsDrilldownJsonWithBanner';
import ReportsCard from './reports/ReportsCard';
import ResetPassword from './ResetPassword';
import RevenueCardWithBanner from './RevenueCardWithBanner';
import { RevenueTableProvider } from '../context/RevenueTableContext.tsx';
import RulesCard from './rules/RulesCard';
import SecuritySettings from './SecuritySettings';
import { SideBar } from '../layout/SideBar/SideBar';
import { SideBarProvider } from '../features/sideBar/providers/SideBarProvider';
import TeamWithBanner from './TeamWithBanner';
import TransactionsCardWithBanner from './TransactionsCardWithBanner';
import TransformedRevenue from './TransformedRevenue.tsx';
import { useSharedStyles } from '../utils/CssUtil';
import { AppProvider, useAppContext } from '../context/AppContext';

Modal.setAppElement('#container');

const App: React.FunctionComponent<unknown> = () => {
  return (
    <>
      <SideBar />
      <Outlet />
    </>
  );
};

const AuthenticatedRoutes = React.memo(() => {
  const sharedClasses = useSharedStyles();

  return (
    <RevenueTableProvider>
      <Routes>
        <Route
          path="/connect/plaid/:encryptedCompanyId"
          element={
            <div className={sharedClasses.main}>
              <Banner hideUserNav />
              <PlaidLink />
            </div>
          }
        />
        <Route
          path="/connect/netsuite"
          element={
            <div className={sharedClasses.main}>
              <Banner hideUserNav />
              <NetsuiteConnect />
            </div>
          }
        />
        <Route
          path="/connect/plaid/:encryptedCompanyId/update/:institutionName"
          element={
            <div className={sharedClasses.main}>
              <Banner hideUserNav />
              <PlaidLink isUpdateMode />
            </div>
          }
        />
        <Route path="/app" element={<App />}>
          <Route path="home" element={<Navigate to="/app/revenue" />} />
          <Route path="data-hub" element={<DataHubCard />} />
          <Route path="data-hub/line-item/:lineItemId" element={<DataHubLineItemCardWithBanner />} />
          <Route path="reports" element={<ReportsCard />} />
          <Route path="revenue" element={<RevenueCardWithBanner />} />
          <Route path="reconciliation" element={<ReconciliationCardWithBanner />} />
          <Route path="missing-data" element={<DataExceptionsWithBanner />} />
          <Route path="transactions" element={<TransformedRevenue />} />
          <Route path="revenue/transactions" element={<TransactionsCardWithBanner />} />
          <Route path="revenue/transaction" element={<PaymentRecognitionScheduleCardWithBanner />} />
          <Route path="revenue/cash-transactions" element={<CashTransactionsCardWithBanner />} />
          <Route path="deposits" element={<DepositsCardWithBanner />} />
          <Route path="expenses" element={<div className={sharedClasses.main}>Expenses go here</div>} />
          <Route path="audit-trail" element={<AuditTrail />} />
          <Route path="exceptions" element={<Exceptions />} />
          <Route path="reconciliations/exceptions" element={<ExceptionsDrilldownCardWithBanner />} />
          <Route
            path="reconciliations/exceptions/:cashTxnId"
            element={<ReconciliationExceptionsDrilldownJsonWithBanner />}
          />
          <Route
            path="integrations"
            element={
              <div className={sharedClasses.main}>
                <Banner />
                <IntegrationsCard />
              </div>
            }
          />
          <Route
            path="plaid-link"
            element={
              <div className={sharedClasses.main}>
                <Banner />
                <PlaidLink isBehindLogin />
              </div>
            }
          />
          <Route
            path="rules"
            element={
              <div className={sharedClasses.main}>
                <Banner />
                <RulesCard />
              </div>
            }
          />
          <Route path="price-list" element={<PriceListCard />} />
          <Route path="price-list/new-bundle" element={<PriceListNewBundle />} />
          <Route path="team" element={<TeamWithBanner />} />
          <Route path="company-settings" element={<CompanySettings />} />
          <Route
            path="settings/security"
            element={
              <div className={sharedClasses.main}>
                <Banner />
                <SecuritySettings />
              </div>
            }
          />
        </Route>
      </Routes>
    </RevenueTableProvider>
  );
});

const AppContainer = React.memo(() => {
  const { isAuthenticated } = useAppContext();

  return (
    <SideBarProvider>
      <Routes>
        {isAuthenticated ? (
          <Route path="*" element={<AuthenticatedRoutes />} />
        ) : (
          <>
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/login-otp" element={<LoginOtp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-success" element={<PasswordResetSuccess />} />
            <Route path="/join" element={<Join />} />
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </SideBarProvider>
  );
});

const AppContainerWrapper = React.memo(() => (
  <ApolloProvider client={ApolloClient}>
    <BrowserRouter>
      <AppProvider>
        <AppContainer />
      </AppProvider>
    </BrowserRouter>
  </ApolloProvider>
));

export default AppContainerWrapper;
