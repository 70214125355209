import { createUseStyles } from 'react-jss';

import Colors from '../../components/common/Colors.tsx';

export const useStyles = createUseStyles({
  wrapper: {
    padding: '0 32px',
    height: '100%',
  },
  menuItems: {
    height: '100%',
    display: 'flex',
    backgroundColor: Colors.FAINT_GRAY,
    padding: '32px',
    borderRadius: '10px',
    flexDirection: 'column',
  },
});
