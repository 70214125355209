import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import pluralize from 'pluralize';
import { useParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import ArrayUtil from '../../src/utils/ArrayUtil';
import Banner from './Banner';
import Colors from './common/Colors';
import { DateFormatYearMonthDateTime } from '../../src/utils/FormatUtil';
import ErrorMessage from './common/ErrorMessage';
import KeyValueList from './common/KeyValueList';
import LoaderAnimation from './common/LoaderAnimation';
import PrettyJSON from './common/PrettyJSON';
import { TVendor } from '../../src/types/BaseTypes';
import TabbedContainers from './common/TabbedContainers';
import { useSharedStyles } from '../utils/CssUtil';
import { GetSingleInvoiceItemQuery, GetSingleInvoiceItemQueryVariables } from '../generated/graphql';
import SectionWithHeader, { borderStyle } from './common/SectionWithHeader';

const useStyles = createUseStyles({
  columnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  column: {
    borderRight: borderStyle,
    flex: '1',
    padding: '15px',

    '&:last-child': {
      borderRight: '0',
    },

    '& ul': {
      margin: '0',
      padding: '0 0 0 15px',
      listStyleType: 'circle',

      '& li': {
        margin: '0',
        padding: '0 0 5px 0',
      },
    },

    '&::before': {
      color: Colors.MEDIUM_GRAY,
      content: 'attr(data-header)',
      display: 'block',
      fontSize: '12px',
      fontWeight: 'bold',
      marginBottom: '15px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },

  columnGreyedOut: {
    backgroundColor: Colors.FAINT_GRAY,
    minWidth: '50%',
    maxWidth: '50%',
  },

  tabHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',

    '& span': {
      display: 'block',
    },
  },
  cellPill: {
    letterSpacing: '0',
    marginLeft: '5px',
    padding: '4px',
  },

  rawJSONContainer: {
    overflowY: 'scroll',
    padding: '10px 5px',
    minHeight: '200px',
  },
});

const QUERY = gql`
  query GetSingleInvoiceItem($id: String!) {
    invoiceItemDetails(id: $id) {
      id
      rawJSON
      vendor
      netPaidAmountFormatted
      oandaRawJSON
      entity {
        formattedAmount
        description
      }
      invoice {
        id
        netPaidAmountFormatted
        rawJSON
        entity {
          currency
          dueAt
          formattedAmount
        }
        invoiceItems {
          entity {
            description
          }
        }
        paymentApplications {
          paymentId
          rawJSON
          entity {
            currency
            formattedAmount
            amount
          }
          paymentEntity {
            completedAt
            formattedAmount
            vendor
          }
          refunds {
            id
            rawJSON
            entity {
              currency
              amount
              completedAt
              formattedAmount
            }
          }
        }
        contract {
          id
          rawJSON
          entity {
            vendor
            state
            description
            isSubscription
            intervalUnit
            intervalLength
            startsAt
            endsAt
          }
          customer {
            id
            rawJSON
            vendor
            vendorExternalId
            contracts {
              id
            }
          }
          invoices {
            id
          }
        }
      }
      priceListItem {
        id
        rawJSON
        entity {
          name
          description
          sku
          productCode
          productFamily
          saleType
          termIntervalUnit
          termIntervalLength
          formattedAmount

          vendor
          vendorExternalId
        }
      }
    }
  }
`;

const LineHeightPerJSONRowPx = 15;

const getJSONHeight = (fieldCount?: number) => Math.min(((fieldCount || 0) + 2) * LineHeightPerJSONRowPx, 220);

enum Section {
  LineItem,
  Transaction,
  Contract,
  Customer,
}

type DataHubLineItemCardWithBannerProps = {
  initialOpenSection?: Section;
};

const DataHubLineItemCardWithBanner: React.FunctionComponent<DataHubLineItemCardWithBannerProps> = ({
  initialOpenSection = Section.LineItem,
}: DataHubLineItemCardWithBannerProps) => {
  const sharedClasses = useSharedStyles();
  const params = useParams();
  const invoiceItemId = params.lineItemId || '';

  const classes = useStyles();

  const [isInitialSectionOpen, updateIsInitialSectionOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;
    window.setTimeout(() => {
      if (isMounted && !isInitialSectionOpen) {
        updateIsInitialSectionOpen(true);
      }
    }, 500);
    return () => {
      isMounted = false;
    };
  }, []);

  const {
    loading: isLoading,
    error,
    data,
  } = useQuery<GetSingleInvoiceItemQuery, GetSingleInvoiceItemQueryVariables>(QUERY, {
    variables: { id: invoiceItemId },
  });

  // Because the array is frozen in strict mode, need to copy the array before sorting it
  const paymentApplications = data
    ? (data.invoiceItemDetails.invoice?.paymentApplications || [])
        .slice()
        .sort(
          (a, b) => new Date(a.paymentEntity.completedAt).getTime() - new Date(b.paymentEntity.completedAt).getTime()
        )
    : [];
  const refunds = ArrayUtil.flatten(paymentApplications.map((ea) => ea.refunds || []));

  const invoiceJSONObject = data
    ? data.invoiceItemDetails.invoice?.rawJSON
      ? JSON.parse(data.invoiceItemDetails.invoice?.rawJSON)
      : undefined
    : undefined;

  const oandaJSONObject = data
    ? data.invoiceItemDetails.oandaRawJSON
      ? JSON.parse(data.invoiceItemDetails.oandaRawJSON)
      : undefined
    : undefined;

  const paymentApplicationJSONObjects = paymentApplications.map((p) => (p.rawJSON ? JSON.parse(p.rawJSON) : undefined));
  const transactionJSONMaxLines = Math.max(
    ...[invoiceJSONObject]
      .concat(paymentApplicationJSONObjects)
      .filter((obj) => !!obj)
      .map((obj) => Object.keys(obj).length)
  );
  const transactionJSONHeightPx = getJSONHeight(transactionJSONMaxLines);

  const invoiceItemJSONObject = data
    ? data.invoiceItemDetails.rawJSON
      ? JSON.parse(data.invoiceItemDetails.rawJSON)
      : undefined
    : undefined;
  const invoiceItemJSONHeightPx = getJSONHeight(invoiceItemJSONObject ? Object.keys(invoiceItemJSONObject).length : 0);

  const contract = data?.invoiceItemDetails?.invoice?.contract;
  const contractJSONObject = data
    ? data.invoiceItemDetails.invoice?.contract?.rawJSON
      ? JSON.parse(data.invoiceItemDetails.invoice?.contract?.rawJSON)
      : undefined
    : undefined;
  const contractJSONHeightPx = getJSONHeight(contractJSONObject ? Object.keys(contractJSONObject).length : 0);

  const customer = contract?.customer;
  const customerJSONObject = data
    ? data.invoiceItemDetails.invoice?.contract?.customer?.rawJSON
      ? JSON.parse(data.invoiceItemDetails.invoice?.contract?.customer?.rawJSON)
      : undefined
    : undefined;
  const customerJSONHeightPx = getJSONHeight(customerJSONObject ? Object.keys(customerJSONObject).length : 0);

  const invoiceVendor = data ? data.invoiceItemDetails.vendor : '';
  const transactionTabs = [
    {
      header: (
        <div className={classes.tabHeader}>
          <span>Invoice</span>
          <span className={classnames(sharedClasses.cellPill, classes.cellPill)} data-pill-value={invoiceVendor}>
            {invoiceVendor}
          </span>
        </div>
      ),
      content: <PrettyJSON object={invoiceJSONObject} />,
    },
    ...paymentApplications.map((pa, idx) => ({
      header: (
        <div className={classes.tabHeader}>
          <span>Payment {idx + 1}</span>
          <span
            className={classnames(sharedClasses.cellPill, classes.cellPill)}
            data-pill-value={pa.paymentEntity.vendor}
          >
            {pa.paymentEntity.vendor}
          </span>
        </div>
      ),
      content: <PrettyJSON object={pa.rawJSON || ''} />,
    })),
  ];

  if (oandaJSONObject) {
    transactionTabs.push({
      header: (
        <div className={classes.tabHeader}>
          <span>Currency </span>
          <span className={classnames(sharedClasses.cellPill, classes.cellPill)} data-pill-value={TVendor.OANDA}>
            {TVendor.OANDA}
          </span>
        </div>
      ),
      content: <PrettyJSON object={oandaJSONObject} />,
    });
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.main}>
      <Banner showSearchBar />
      <div
        className={sharedClasses.contentWrapper}
        style={{
          marginTop: '-250px',
        }}
      >
        {isLoading || !data ? (
          <div className={sharedClasses.contentLoaderContainer}>
            <LoaderAnimation height={80} />
          </div>
        ) : (
          <>
            <div className={sharedClasses.contentHeaderWrapper}>
              <div className={sharedClasses.contentHeader}>
                <div className={sharedClasses.cardTabs}>
                  <div className={classnames(sharedClasses.cardTab, sharedClasses.cardTabSelected)}>
                    <span>Line Item: {data.invoiceItemDetails.id}</span>
                  </div>
                </div>
              </div>
              <div className={sharedClasses.contentHeaderUtils}></div>
            </div>
            <div>
              <SectionWithHeader
                header="Line Item"
                isCollapsible
                isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === Section.LineItem)}
                bodyPaddingPx={0}
              >
                <div className={classes.columnContainer}>
                  <div className={classes.column} data-header="Numeral Data">
                    <KeyValueList
                      keyValuePairs={[
                        {
                          key: 'Numeral ID:',
                          value: data.invoiceItemDetails.id,
                        },
                        {
                          key: 'Description:',
                          value: data.invoiceItemDetails.entity.description,
                        },
                        {
                          key: 'Amount invoiced:',
                          value: data.invoiceItemDetails.entity.formattedAmount,
                        },
                        {
                          key: 'Net amount paid:',
                          value: data.invoiceItemDetails.netPaidAmountFormatted,
                        },
                        {
                          key: 'Product SKU:',
                          value: data.invoiceItemDetails.priceListItem?.entity?.sku || '',
                        },
                        {
                          key: 'Product list price:',
                          value: data.invoiceItemDetails.priceListItem?.entity?.formattedAmount || '',
                        },
                      ]}
                    />
                  </div>
                  <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                    <TabbedContainers
                      tabContentClass={classes.rawJSONContainer}
                      tabContentStyle={{ height: invoiceItemJSONHeightPx }}
                      tabs={[
                        {
                          header: (
                            <div className={classes.tabHeader}>
                              <span>Invoice Item</span>
                              <span
                                className={classnames(sharedClasses.cellPill, classes.cellPill)}
                                data-pill-value={data.invoiceItemDetails.vendor}
                              >
                                {data.invoiceItemDetails.vendor}
                              </span>
                            </div>
                          ),
                          content: <PrettyJSON object={invoiceItemJSONObject} />,
                        },
                      ]}
                    />
                  </div>
                </div>
              </SectionWithHeader>

              <SectionWithHeader
                header="Transaction"
                isCollapsible
                isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === Section.Transaction)}
                bodyPaddingPx={0}
              >
                <div className={classes.columnContainer}>
                  <div className={classes.column} data-header="Numeral Data">
                    <KeyValueList
                      keyValuePairs={[
                        {
                          key: 'Numeral ID:',
                          value: data.invoiceItemDetails.invoice?.id || '',
                        },
                        {
                          key: 'Amount due:',
                          value: data.invoiceItemDetails.invoice?.entity?.formattedAmount || '',
                        },
                        {
                          key: 'Due at:',
                          value: data.invoiceItemDetails.invoice?.entity?.dueAt
                            ? moment(new Date(data.invoiceItemDetails.invoice.entity.dueAt)).format(
                                DateFormatYearMonthDateTime
                              )
                            : '',
                        },
                        {
                          key: 'Line items:',
                          value: (
                            <ul>
                              {(data.invoiceItemDetails.invoice?.invoiceItems || []).map((ea, idx) => (
                                <li key={`invoice-item-${idx}`}>{ea.entity.description}</li>
                              ))}
                            </ul>
                          ),
                        },
                        {
                          key: 'Payments:',
                          value: paymentApplications.length ? (
                            <ul>
                              {paymentApplications.map((ea, idx) => (
                                <li key={`payment-${idx}`}>
                                  {ea.paymentEntity.formattedAmount} ({ea.entity.formattedAmount} applied) at{' '}
                                  {moment(ea.paymentEntity.completedAt).format(DateFormatYearMonthDateTime)}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            'N/A'
                          ),
                        },
                        {
                          key: 'Refunds:',
                          value: refunds.length ? (
                            <ul>
                              {refunds.map((ea, idx) => (
                                <li key={`refund-${idx}`}>
                                  {ea.entity.formattedAmount} at{' '}
                                  {moment(ea.entity.completedAt).format(DateFormatYearMonthDateTime)}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            'N/A'
                          ),
                        },
                        {
                          key: 'Net amount paid:',
                          value: data.invoiceItemDetails.invoice?.netPaidAmountFormatted || '',
                        },
                      ]}
                    />
                  </div>
                  <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                    <TabbedContainers
                      tabContentClass={classes.rawJSONContainer}
                      tabContentStyle={{
                        height: transactionJSONHeightPx,
                      }}
                      tabs={transactionTabs}
                    />
                  </div>
                </div>
              </SectionWithHeader>

              <SectionWithHeader
                header="Contract"
                isCollapsible
                isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === Section.Contract)}
                bodyPaddingPx={0}
              >
                <div className={classes.columnContainer}>
                  <div className={classes.column} data-header="Numeral Data">
                    <KeyValueList
                      keyValuePairs={[
                        {
                          key: 'Numeral ID:',
                          value: contract?.id || '',
                        },
                        {
                          key: 'Description:',
                          value: contract?.entity.description || '',
                        },
                        {
                          key: 'State:',
                          value: contract?.entity.state || '',
                        },
                        {
                          key: 'Invoice count:',
                          value: (contract?.invoices || []).length,
                        },
                        {
                          key: 'Is Subscription:',
                          value: String(!!contract?.entity.isSubscription),
                        },
                        ...(contract?.entity.isSubscription &&
                        contract?.entity.intervalUnit &&
                        contract?.entity.intervalLength
                          ? [
                              {
                                key: 'Subscription length:',
                                value: `${contract?.entity.intervalLength} ${pluralize(
                                  contract?.entity.intervalUnit.toLowerCase(),
                                  contract?.entity.intervalLength
                                )}`,
                              },
                            ]
                          : []),
                        {
                          key: contract?.entity.isSubscription ? 'Starts at:' : 'Contract entered at:',
                          value: contract?.entity.startsAt
                            ? moment(new Date(contract?.entity.startsAt)).format(DateFormatYearMonthDateTime)
                            : '',
                        },
                        ...(contract?.entity.isSubscription && contract?.entity.endsAt
                          ? [
                              {
                                key: 'Ends at:',
                                value: moment(new Date(contract?.entity.endsAt)).format(DateFormatYearMonthDateTime),
                              },
                            ]
                          : []),
                      ]}
                    />
                  </div>
                  <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                    <TabbedContainers
                      tabContentClass={classes.rawJSONContainer}
                      tabContentStyle={{ height: contractJSONHeightPx }}
                      tabs={[
                        {
                          header: (
                            <div className={classes.tabHeader}>
                              <span>Contract</span>
                              <span
                                className={classnames(sharedClasses.cellPill, classes.cellPill)}
                                data-pill-value={contract?.entity.vendor}
                              >
                                {contract?.entity.vendor}
                              </span>
                            </div>
                          ),
                          content: <PrettyJSON object={contractJSONObject || {}} />,
                        },
                      ]}
                    />
                  </div>
                </div>
              </SectionWithHeader>

              <SectionWithHeader
                header="Customer"
                isCollapsible
                isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === Section.Customer)}
                bodyPaddingPx={0}
              >
                <div className={classes.columnContainer}>
                  <div className={classes.column} data-header="Numeral Data">
                    <KeyValueList
                      keyValuePairs={[
                        {
                          key: 'Numeral ID:',
                          value: customer?.id || '',
                        },
                        {
                          key: 'Contract count:',
                          value: (customer?.contracts || []).length,
                        },
                        {
                          key: 'External ID:',
                          value: customer?.vendorExternalId || '',
                        },
                      ]}
                    />
                  </div>
                  <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                    <TabbedContainers
                      tabContentClass={classes.rawJSONContainer}
                      tabContentStyle={{ height: customerJSONHeightPx }}
                      tabs={[
                        {
                          header: (
                            <div className={classes.tabHeader}>
                              <span>Customer</span>
                              <span
                                className={classnames(sharedClasses.cellPill, classes.cellPill)}
                                data-pill-value={customer?.vendor}
                              >
                                {customer?.vendor}
                              </span>
                            </div>
                          ),
                          content: <PrettyJSON object={customerJSONObject || {}} />,
                        },
                      ]}
                    />
                  </div>
                </div>
              </SectionWithHeader>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DataHubLineItemCardWithBanner;
