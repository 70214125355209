import camelCase from 'camelcase';
import moment from 'moment';

// Date stuff
export const DateFormatMonthShortHand = 'MMM';
export const DateFormatMonthYear = 'MMM YYYY';
export const DateFormatLongMonthYear = 'MMMM YYYY';
export const DateFormatYearMonth = 'YYYY-MM';
export const DateFormatYearMonthNoDash = 'YYYYMM';
export const DateFormatYearMonthDate = 'YYYY-MM-DD';
export const DateFormatDateMonthYear = 'DD-MM-YYYY';
export const DateFormatYearMonthDateNoDelimiters = 'YYYYMMDD';
export const DateFormatYearMonthDateTime = 'YYYY-MM-DD hh:mm:ss';
export const DateFormatYearMonthDate12HourTime = 'YYYY-MM-DD h:mm A';
export const DateFormatYearMonthDateAt12HourTime = 'YYYY-MM-DD [at] h:mm A';
export const DateFormatMonthDateShortYearTime24Hours = 'M/D/YY H:mm';
export const DateFormatMonthDateYearTime24HoursWithSeconds = 'YYYY-MM-DD HH:mm:ss';
export const DateFormatYearMonthDateTimeNoSpaces = 'YYYYMMDDhhmmss';
export const DateFormatYearMonthDateShort = 'MM/DD/YY';
export const DateFormatIso8601 = 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ';
export const DateFormatIso8601Pretty = 'YYYY-MM-DD HH:mm:ss.SSS';
export const DateFormatIso8601WithoutMillisOrTimezone = 'YYYY-MM-DDTHH:mm:ss';
export const DateFormatIso8601WithoutMillis = 'YYYY-MM-DDTHH:mm:ssZ';

export const LuxonDateFormats = {
  DateFormatMonthDateYearTime24HoursWithSeconds: 'yyyy-MM-dd HH:mm:ss',
  DateFormatYearMonthDate: 'yyyy-MM-dd',
  DateFormatYearMonth: 'yyyy-MM',
  DateFormatYearMonthDate12HourTimeWithTimezone: 'dd-MMM-yyyy hh:mm a z', // 21-Oct-2023 11:00 AM PST
  DateFormatYearMonthDateT24HourTimeWithTimezone: "yyyy-MM-dd'T'HH:mm:ss ZZZ", // 2023-10-09T11:10:04 -0700
  DateFormatShortMonthDayYear12HourTimeWithTimezone: 'LLL d, yyyy h:mm:ss a ZZZ', // 2023-10-09T11:10:04 -0700
  DateFormatShortMonthYear: 'LLL yyyy', // Oct 2023
  DateFormatShortMonthDayYearWithSlashes: 'M/d/yy',
  DateFormatShortMonthDayYearWithHyphens: 'M-d-yy',
  DateFormatYearMonthDateShort: 'MM/dd/yy',
};

export const DateRegexMonthDateShortYearTime24Hours = /^\d{1,2}\/\d{1,2}\/\d{2} \d{1,2}:\d{2}$/g;

const dateToYearMonthStr = (d: Date) => {
  return moment(d).format(DateFormatYearMonthNoDash);
};

const dateToMonthStr = (d: Date) => {
  return moment(d).format(DateFormatYearMonth);
};

const monthStrToDate = (str: string) => {
  const [year, month] = str.split('-').map((s) => Number(s));
  return new Date(year, month - 1);
};

export type TFormatCurrencyOptions = {
  forceDecimalPlaces?: number;
  rawToDisplayPowerDifference?: number;
  currencySymbol?: string;
  shouldUseShortForm?: boolean;
  showWithParenthesis?: boolean;
  showWithoutCommas?: boolean;
};

const formatNumberWithCommas = (amountStr: string) => {
  const parts = amountStr.split('.');
  const wholeNumberPart = parts[0];
  let formattedWholeNumberPart = '';
  let charsProcessed = 0;
  for (let i = wholeNumberPart.length - 1; i >= 0; i--) {
    const curChar: string = wholeNumberPart.substring(i, i + 1);
    formattedWholeNumberPart = curChar + formattedWholeNumberPart;
    charsProcessed++;
    if (charsProcessed % 3 === 0) {
      const charsRemaining = wholeNumberPart.length - charsProcessed;
      if (charsRemaining > 0) {
        formattedWholeNumberPart = ',' + formattedWholeNumberPart;
      }
    }
  }
  return { formattedNumber: formattedWholeNumberPart, parts: parts };
};

const formatNumberWithoutCommas = (amountStr: string) => {
  const parts = amountStr.split('.');
  const wholeNumberPart = parts[0];
  return { formattedNumber: wholeNumberPart, parts: parts };
};

const formatCurrencyAmount: (amount: number, options?: TFormatCurrencyOptions) => string = (amount, options = {}) => {
  let adjustedAmount = Math.abs(amount);
  const isNegative = amount < 0 && Math.round(adjustedAmount) !== 0;

  const rawToDisplayPowerDifference =
    options.rawToDisplayPowerDifference === undefined ? 2 : options.rawToDisplayPowerDifference;
  if (rawToDisplayPowerDifference > 0) {
    const factor = Math.pow(10, rawToDisplayPowerDifference);
    adjustedAmount = Math.round(adjustedAmount) / factor;
  }

  if (options.shouldUseShortForm) {
    return (options.currencySymbol || '') + formatNumber(adjustedAmount * (isNegative ? -1 : 1));
  }

  const formattedWholeNumber = options.showWithoutCommas
    ? formatNumberWithoutCommas('' + adjustedAmount)
    : formatNumberWithCommas('' + adjustedAmount);

  if (options.currencySymbol) {
    formattedWholeNumber.formattedNumber = options.currencySymbol + formattedWholeNumber.formattedNumber;
  }

  if (isNegative && !options.showWithParenthesis) {
    formattedWholeNumber.formattedNumber = '-' + formattedWholeNumber.formattedNumber;
  }

  const forceDecimalPlaces = options.forceDecimalPlaces || 0;

  if (formattedWholeNumber.parts.length > 1) {
    let decimal = formattedWholeNumber.parts[1];
    while (decimal.length < forceDecimalPlaces) {
      decimal += '0';
    }
    formattedWholeNumber.formattedNumber += '.' + decimal;
  } else {
    if (forceDecimalPlaces === 0) {
      return formattedWholeNumber.formattedNumber;
    }
    let zeros = '';
    for (let i = 0; i < forceDecimalPlaces; i++) {
      zeros += '0';
    }
    formattedWholeNumber.formattedNumber = formattedWholeNumber.formattedNumber + '.' + zeros;
  }

  if (isNegative && options.showWithParenthesis) {
    formattedWholeNumber.formattedNumber = '(' + formattedWholeNumber.formattedNumber + ')';
  }
  return formattedWholeNumber.formattedNumber;
};

/*
 * Converts given number to a formatted string with precision
 * https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900/9462382#9462382
 */
const formatNumber = (num?: number, precision = 1) => {
  if (!num) {
    return 0;
  }

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  return item ? (num / item.value).toFixed(precision).replace(rx, '$1') + item.symbol : '0';
};

// TODO (Juan) this should really be in the DateUtils file.  Due to client vs server node
// packaging that doesn't seem to be easy.  Look into how to fix that
// https://app.asana.com/0/1202515250551700/1203658538913030/f
export function getYearRangeString(startMs: number, endMs: number): string {
  const startYear = new Date(startMs).getFullYear();
  const endYear = new Date(endMs).getFullYear();
  let yearWording = startYear.toString();
  if (startYear !== endYear) {
    yearWording += ` - ${endYear.toString()}`;
  }
  return yearWording;
}

/**
 * Deeply converts a given object's keys to camelCase though they are in whatever forms
 * @returns object with all keys formatted in camel case
 */
const formatObjectKeysToCamelCase = (obj: Record<string, any>): Record<string, any> => {
  if (Array.isArray(obj)) {
    return obj.map((item) => formatObjectKeysToCamelCase(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: Record<string, any> = {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const camelKey = camelCase(key);
        newObj[camelKey] = formatObjectKeysToCamelCase(obj[key]);
      }
    }

    return newObj;
  }

  return obj;
};

export default {
  formatNumberWithCommas,
  dateToYearMonthStr,
  dateToMonthStr,
  monthStrToDate,
  formatCurrencyAmount,
  formatNumber,
  getYearRangeString,
  formatObjectKeysToCamelCase,
};
