import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Link, To } from 'react-router-dom';
import React, { ReactElement } from 'react';

import Colors from './Colors';

const useStyles = createUseStyles({
  link: {
    color: Colors.MEDIUM_GRAY,
    '&:visited': {
      color: Colors.MEDIUM_GRAY,
    },
  },
});

type SecondaryLinkProps = {
  children: ReactElement | string | number;
  className?: string;
  onClick?: () => void;
  to: To;
};

const SecondaryLink: React.FunctionComponent<SecondaryLinkProps> = ({ children, className, onClick, to }) => {
  const classes = useStyles();

  return (
    <Link className={classnames(classes.link, className)} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};

export default SecondaryLink;
