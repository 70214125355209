import React, { ReactElement } from 'react';

import { Timeline, TimelineEvent } from 'react-event-timeline';
import { createUseStyles } from 'react-jss';

import { TLedgerDirection, TLedgerDirectionAbbreviated } from '../../../src/types/LedgerTypes';
import { JournalEntriesForInvoice } from '../../generated/graphql';
import getAssetPath from '../../utils/AssetPathUtil';
import Colors from './Colors';

const DEFAULT_ENTRY_LABEL_WIDTH_PX = 300;

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& $row *': {
      fontSize: 12,
    },
  },
  row: {
    display: 'flex',
    marginBottom: 15,

    '&:last-child': {
      marginBottom: '0',
    },
  },
  dateLabelColumn: {
    width: 80,
    textAlign: 'center',
  },
  entriesLabelColumn: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  filterableLink: {
    textDecoration: 'none',
  },
  journalEntrySingleLine: {
    paddingBottom: 5,
  },
  perDayColumn: {
    width: 65,
    paddingLeft: 15,
    color: Colors.MEDIUM_GRAY,
  },
  descriptionColumn: {
    width: 150,
    paddingLeft: 15,
    '& $journalEntrySingleLine': {
      whiteSpace: 'nowrap',
    },
  },
  glAccountColumn: {
    width: 350,
    color: Colors.MEDIUM_GRAY,
    '& $journalEntrySingleLine': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: 14,
    },
  },
  filterContainer: {
    paddingBottom: 10,
    display: 'flex',
    textTransform: 'capitalize',
  },
  filterTag: {
    padding: 3,
    backgroundColor: Colors.FAINTEST_BLUE,
    borderRadius: '3px',
  },
  debitOrCreditColumn: {
    width: 30,
    color: Colors.MEDIUM_GRAY,
  },
  amountColumn: {
    fontWeight: 'bold',
    width: 75,
    textAlign: 'right',
  },
  timelineAndIconColumn: {
    width: 46,

    '& div': {
      flex: 1,

      '& section': {
        height: 'calc(100% - 30px)',

        '& > div': {
          padding: '10 0',

          '& > div': {
            background: Colors.WHITE,
          },
        },
      },
    },
  },
});

type TJournalEntryTimelineProps = {
  entries: JournalEntriesForInvoice[];
  entryLabelWidthPx?: number;
  hideDirection?: boolean;
};

export const JournalEntryTimeline: React.FunctionComponent<TJournalEntryTimelineProps> = ({
  entries,
  entryLabelWidthPx = DEFAULT_ENTRY_LABEL_WIDTH_PX,
  hideDirection,
}: TJournalEntryTimelineProps) => {
  const classes = useStyles();
  const isDescriptionColumnEnabled = entries.some((e) => e.entries.some((e) => e.description));
  const isGlAccountColumnEnabled = entries.some((e) => e.entries.some((e) => e.glAccount));

  const [filters, setFilters] = React.useState<{ [key in keyof JournalEntriesForInvoice]?: string }>({});
  const [entriesToDisplay, setEntriesToDisplay] = React.useState<JournalEntriesForInvoice[]>(entries);

  const applyFilterToEntries = (
    entries: JournalEntriesForInvoice[],
    filters: { [key in keyof JournalEntriesForInvoice]?: string }
  ) => {
    return entries.reduce((acc, e) => {
      if (!e.entries.some((entry) => Object.keys(filters).every((k) => entry[k] === filters[k]))) {
        return acc;
      }
      acc.push({
        ...e,
        entries: e.entries.filter((entry) => {
          return Object.keys(filters).every((k) => entry[k] === filters[k]);
        }),
      });
      return acc;
    }, [] as JournalEntriesForInvoice[]);
  };

  return (
    <div className={classes.container}>
      <div className={classes.filterContainer}>
        {Object.entries(filters).map(([key, value]) => (
          <div key={key} className={classes.filterTag}>
            {value}{' '}
            <img
              onClick={() => {
                const updatedFilters = { ...filters };
                delete updatedFilters[key as keyof JournalEntriesForInvoice];
                const updatedEntries = applyFilterToEntries(entries, updatedFilters);
                setFilters(updatedFilters);
                setEntriesToDisplay(updatedEntries);
              }}
              src={`${getAssetPath()}/images/icon_remove.svg`}
              height={10}
              style={{ paddingTop: 2 }}
              alt={'An image of the letter X inside of a circle that allows you to remove a filter'}
            />
          </div>
        ))}
      </div>
      {entriesToDisplay.map((e, idx) => {
        return (
          <div className={classes.row} key={`${e.label}-${idx}`}>
            <div className={classes.dateLabelColumn}>
              {e.label.split(' ').map((val, valIdx) => (
                <div key={`dateLabelColumn-${valIdx}`}>{val}</div>
              ))}
            </div>
            <div className={classes.timelineAndIconColumn}>
              <Timeline
                key={`tl-${idx}`}
                style={{ height: `${e.entries.length * 17}px` }}
                lineColor={Colors.LIGHT_GRAY}
              >
                {[
                  <TimelineEvent
                    title={''}
                    icon={
                      <img
                        src={`${getAssetPath()}/images/timeline_pencil_icon.svg`}
                        height="20px"
                        alt={'An image of a pencil representing a journal entry'}
                      />
                    }
                    bubbleStyle={{ background: Colors.WHITE, top: '-28px' }}
                    iconStyle={{ cursor: 'default' }}
                    iconColor={Colors.LIGHT_GRAY}
                    key={`tle-${idx}`}
                  />,
                ]}
              </Timeline>
            </div>

            <div
              className={classes.entriesLabelColumn}
              style={{ textTransform: 'capitalize', whiteSpace: 'nowrap', width: entryLabelWidthPx }}
            >
              {e.entries.map((e, entryIdx) => {
                return (
                  <div className={classes.journalEntrySingleLine} key={`entriesLabelColumn-${entryIdx}`}>
                    <a
                      className={classes.filterableLink}
                      onClick={() => {
                        const updatedFilters = { ...filters, label: e.label };
                        const updatedEntries = applyFilterToEntries(entries, updatedFilters);
                        setFilters(updatedFilters);
                        setEntriesToDisplay(updatedEntries);
                      }}
                    >
                      {e.label}
                    </a>
                  </div>
                );
              })}
            </div>
            {!hideDirection && (
              <div className={classes.debitOrCreditColumn}>
                {e.entries.map((e, entryIdx) => {
                  return (
                    <div className={classes.journalEntrySingleLine} key={`debitOrCreditColumn-${entryIdx}`}>
                      {e.direction === TLedgerDirection.DEBIT
                        ? TLedgerDirectionAbbreviated.DEBIT
                        : TLedgerDirectionAbbreviated.CREDIT}
                      <br />
                    </div>
                  );
                })}
              </div>
            )}
            <div className={classes.amountColumn}>
              {e.entries.map((e, entryIdx) => {
                return (
                  <div className={classes.journalEntrySingleLine} key={`amountColumn-${entryIdx}`}>
                    {e.amountStr}
                  </div>
                );
              })}
            </div>
            <div className={classes.perDayColumn}>
              {e.entries.map((e, entryIdx) => {
                return (
                  <div className={classes.journalEntrySingleLine} key={`perDayColumn-${entryIdx}`}>
                    {e.isSpread ? '(per day)' : ''}
                  </div>
                );
              })}
            </div>
            {isGlAccountColumnEnabled && (
              <div className={classes.glAccountColumn}>
                {e.entries.map((e, entryIdx) => {
                  return (
                    <div className={classes.journalEntrySingleLine} key={`glAccountColumn-${entryIdx}`}>
                      {e.glAccount}
                    </div>
                  );
                })}
              </div>
            )}
            {isDescriptionColumnEnabled ? (
              <div className={classes.descriptionColumn}>
                {e.entries.map((e, entryIdx) => {
                  return (
                    <div className={classes.journalEntrySingleLine} key={`descriptionColumn-${entryIdx}`}>
                      {e.description || '-'}
                    </div>
                  );
                })}
              </div>
            ) : undefined}
          </div>
        );
      })}
    </div>
  );
};

type TGenericTimelineProps = {
  entries: {
    label: string;
    value: ReactElement | ReactElement[] | string | number;
    iconPath: string;
    positionYOffsetPx?: number;
  }[];
  entryLabelWidthPx?: number;
};
export const GenericTimeline: React.FunctionComponent<TGenericTimelineProps> = ({
  entries,
  entryLabelWidthPx = DEFAULT_ENTRY_LABEL_WIDTH_PX,
}: TGenericTimelineProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {entries.map(({ label, value, iconPath, positionYOffsetPx }, idx) => {
        return (
          <div className={classes.row} key={`${label}-${idx}`}>
            <div className={classes.dateLabelColumn}>
              <div>{label}</div>
            </div>
            <div className={classes.timelineAndIconColumn}>
              <Timeline key={`tl-${idx}`} style={{ height: 17 }} lineColor={Colors.LIGHT_GRAY}>
                {[
                  <TimelineEvent
                    title={''}
                    icon={<img src={iconPath} height={16} alt={'Event icon'} />}
                    bubbleStyle={{ background: Colors.WHITE, top: '-28px' }}
                    iconStyle={{ cursor: 'default' }}
                    iconColor={Colors.LIGHT_GRAY}
                    key={`tle-${idx}`}
                  />,
                ]}
              </Timeline>
            </div>

            <div
              className={classes.entriesLabelColumn}
              style={{ width: entryLabelWidthPx, ...(positionYOffsetPx ? { marginTop: positionYOffsetPx } : {}) }}
            >
              {value}
            </div>
          </div>
        );
      })}
    </div>
  );
};
