import React, { useRef, useState } from 'react';

import Colors from '../common/Colors';
import FormButton from '../common/FormButton';
import Random from '../../../src/utils/Random';
import { RuleInFlowJournalEntrySet } from './RulePreview';
import SearchableDropdown from '../common/SearchableDropdown';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useSharedStyles } from '../../utils/CssUtil';
import {
  EntityTypeToSupportedVariableFields,
  TRuleSupportedEntityTypes,
  TRuleSupportedNumberFields,
} from '../../../src/types/RuleTypes';
import { entityTypeToDisplay, getDisplayNameFromEntityAndField } from './utils/RuleDisplayUtil';

const useStyles = createUseStyles({
  ruleCreationThenFormRow: {
    display: 'flex',
    marginBottom: 10,
    lineHeight: '38px',
    '& input': {
      boxSizing: 'border-box',
      width: 250,
      '&[type="radio"]': {
        marginRight: 5,
        width: 20,
      },
    },
    '& > div': {
      '&:first-child': {
        width: 200,
      },
      '&:last-child': {
        flex: 1,
      },
    },
  },
  ruleCreationThenFormAction: {
    paddingLeft: 150,
    paddingTop: 10,
  },
  ruleFormulaContainer: {
    position: 'relative',
    width: 250,
  },
  ruleFormulaInputContainer: {
    position: 'relative',
    '& > div': {
      height: 38,
      letterSpacing: '1px',
      paddingLeft: 10,
    },
    '& input': {
      background: 'transparent',
      color: 'transparent',
      caretColor: Colors.DARK_GRAY,
      letterSpacing: '1.26px',
      position: 'absolute',
      top: 0,
      width: 250,
    },
  },
  ruleFormulaVariableContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: -120,
    left: -240,
    width: 220,
    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: 5,
    padding: 10,
    boxSizing: 'border-box',
    height: 300,
    fontSize: '12px',
    lineHeight: '16px',
    zIndex: 2,
    '& > div': {
      marginBottom: 10,
    },
    '& .select-search': {
      '& .select-search__select': {
        top: 26,
        width: 'calc(100% - 3px)',
        '& > .select-search__options': {
          maxHeight: 220,
        },
      },
      '& .select-search__input': {
        fontSize: '12px',
        padding: 5,
        width: 'calc(100% - 13px)',
        '&.select-search': {
          '& .select-search__group-header': {
            padding: 5,
          },
        },
      },
      '& .select-search__group': {
        '& .select-search__option': {
          paddingLeft: 20,
        },
      },
      '&:has-focus': {
        '& .select-search__input': {
          padding: 4,
        },
      },
    },
  },
  ruleFormulaVariableHeader: {
    fontWeight: 'bold',
  },
  ruleFormulaVariableContainerTriangleOuter: {
    position: 'absolute',
    right: 0,
    top: 128,
    width: 0,
    height: 0,
    marginRight: -9,
    clear: 'both',
    borderTop: '9px solid transparent',
    borderBottom: '9px solid transparent',
    borderLeft: `9px solid ${Colors.MEDIUM_GRAY}`,
  },
  ruleFormulaVariableContainerTriangleInner: {
    position: 'absolute',
    right: 0,
    top: 128,
    width: 0,
    height: 0,
    clear: 'both',
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    borderLeft: `8px solid ${Colors.WHITE}`,
    marginRight: -8,
    marginTop: 1,
  },
  ruleFormulaVariableList: {
    flex: 1,
    marginTop: -10,
    overflowY: 'scroll',
    '& > div': {
      marginBottom: 5,
    },
  },
  ruleCreationThenInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  ruleCreationThenInputSwitch: {
    fontSize: '12px',
    color: Colors.MEDIUM_GRAY,
    cursor: 'pointer',
    marginLeft: 15,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ruleThenActionsAmount: {
    display: 'inline-block',
    padding: '2px 8px',
    backgroundColor: Colors.LIGHT_GRAY,
    minWidth: 20,
    textAlign: 'center',
    margin: 5,
    borderRadius: 3,
  },
});

type TMappedVar = {
  name: string;
  entity: TRuleSupportedEntityTypes;
  field: TRuleSupportedNumberFields;
  colorIdx: number;
};

type FormulaInputContainerProps = {
  entityType: TRuleSupportedEntityTypes;
  inputFormula?: string;
  canonicalFormula?: string;
  onChange: (i: string | undefined, c: string | undefined) => void;
};

const colorPalletes = [
  '#1e9d3c', // green
  '#c26f1d', // orange
  '#9c1dc2', // purple
  '#c73818', // red
  '#1c19c2', // blue
  '#afba1a', // yellow
];

const FormulaInputContainer: React.FunctionComponent<FormulaInputContainerProps> = ({
  entityType,
  inputFormula,
  onChange,
}: FormulaInputContainerProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [mappedVars, updateMappedVars] = useState<TMappedVar[]>([]);
  const [shouldShowModal, updateShouldShowModal] = useState<boolean>(false);

  const ref = useRef(null);
  useOutsideClick(ref, (t) => {
    if (t) {
      let node: HTMLElement | null = t as HTMLElement;
      let matched = false;
      while (node) {
        if (node.id === 'ruleFormulaVariableModal' || node.className.indexOf('select-search__options') >= 0) {
          matched = true;
          break;
        }
        node = node.parentElement;
      }
      if (matched) {
        return;
      }
    }
    updateShouldShowModal(false);
  });

  const fieldOptions = [
    {
      type: 'group',
      name: entityTypeToDisplay[entityType],
      value: entityType,
      disabled: true,
      items: EntityTypeToSupportedVariableFields[entityType].map((f) => ({
        name: getDisplayNameFromEntityAndField(entityType, f),
        value: getDisplayNameFromEntityAndField(entityType, f),
      })),
    },
  ].concat(
    Object.values(TRuleSupportedEntityTypes)
      .filter((t) => t !== entityType)
      .map((t) => ({
        type: 'group',
        name: entityTypeToDisplay[t],
        value: t,
        disabled: true,
        items: EntityTypeToSupportedVariableFields[t].map((f) => ({
          name: getDisplayNameFromEntityAndField(t, f),
          value: getDisplayNameFromEntityAndField(t, f),
        })),
      }))
  );

  const formula = inputFormula || '';
  const getCanonicalFormula = (formula: string) => {
    const elements: JSX.Element[] = [];
    let currentCanonicalFormula = '';

    for (let i = 0; i < formula.length; i++) {
      const curChar = formula.charAt(i);
      const prevChar = i > 0 ? formula.charAt(i - 1) : undefined;
      const nextChar = i < formula.length - 1 ? formula.charAt(i + 1) : undefined;
      const isUpperCaseLetter =
        curChar.charCodeAt(0) >= 'A'.charCodeAt(0) && curChar.charCodeAt(0) <= 'Z'.charCodeAt(0);
      const isPrevCharLetterOrDigit =
        !!prevChar &&
        ((prevChar.charCodeAt(0) >= 'A'.charCodeAt(0) && prevChar.charCodeAt(0) <= 'Z'.charCodeAt(0)) ||
          (prevChar.charCodeAt(0) >= 'a'.charCodeAt(0) && prevChar.charCodeAt(0) <= 'z'.charCodeAt(0)) ||
          (prevChar.charCodeAt(0) >= '0'.charCodeAt(0) && prevChar.charCodeAt(0) <= '9'.charCodeAt(0)));
      const isNextCharLetterOrDigit =
        !!nextChar &&
        ((nextChar.charCodeAt(0) >= 'A'.charCodeAt(0) && nextChar.charCodeAt(0) <= 'Z'.charCodeAt(0)) ||
          (nextChar.charCodeAt(0) >= 'a'.charCodeAt(0) && nextChar.charCodeAt(0) <= 'z'.charCodeAt(0)) ||
          (nextChar.charCodeAt(0) >= '0'.charCodeAt(0) && nextChar.charCodeAt(0) <= '9'.charCodeAt(0)));
      const style: { color?: string } = {};
      let v: TMappedVar | undefined = undefined;
      if (isUpperCaseLetter && !isPrevCharLetterOrDigit && !isNextCharLetterOrDigit) {
        v = mappedVars.find((mv) => mv.name === curChar);
      }
      if (v) {
        style.color = colorPalletes[v.colorIdx];
        currentCanonicalFormula += getDisplayNameFromEntityAndField(v.entity, v.field);
      } else {
        currentCanonicalFormula += curChar;
      }
      elements.push(<span style={style}>{curChar}</span>);
    }

    return {
      elements,
      currentCanonicalFormula,
    };
  };

  const { elements } = getCanonicalFormula(formula);

  return (
    <div className={classes.ruleFormulaContainer}>
      <div className={classes.ruleFormulaInputContainer}>
        <div>{elements}</div>
        <input
          className={sharedClasses.formInput}
          onChange={(e) => {
            const formula = (e.target.value || '').slice(0, 25);
            const { currentCanonicalFormula } = getCanonicalFormula(formula);
            onChange(formula, currentCanonicalFormula);
          }}
          placeholder="A / 12"
          ref={ref}
          onFocus={() => updateShouldShowModal(true)}
          value={formula}
        />
      </div>
      <div
        id="ruleFormulaVariableModal"
        className={classes.ruleFormulaVariableContainer}
        style={{ display: shouldShowModal ? 'block' : 'none' }}
      >
        <div className={classes.ruleFormulaVariableHeader}>Reference variables</div>
        <div>
          Map a <strong>field</strong> from associated records to a <strong>variable</strong> you can reference in your
          formula
        </div>
        <div>
          <SearchableDropdown
            options={fieldOptions}
            onChange={(val) => {
              const parts = val.split('.');
              const entity = Object.values(TRuleSupportedEntityTypes).find((e) => {
                let enteredVal = parts[0].toLowerCase();
                // TODO
                if (enteredVal === 'sku') {
                  enteredVal = 'item_price';
                }
                return e.toLowerCase() === enteredVal;
              });
              const field = parts[1] as TRuleSupportedNumberFields;
              if (!entity) {
                return;
              }
              const alreadyMapped = mappedVars.some((mv) => mv.entity === entity && mv.field === field);
              if (alreadyMapped) {
                return;
              }

              const lastVar = mappedVars.length ? mappedVars[mappedVars.length - 1] : undefined;

              // TODO will overflow after 26
              const nextVarName = lastVar ? String.fromCharCode(lastVar.name.charCodeAt(0) + 1) : 'A';
              updateMappedVars(
                mappedVars.concat([
                  {
                    name: nextVarName,
                    field,
                    entity,
                    colorIdx: lastVar ? (lastVar.colorIdx + 1) % colorPalletes.length : 0,
                  },
                ])
              );
            }}
            width={200}
            placeholder="Field name to map"
          />
        </div>
        <div className={classes.ruleFormulaVariableList}>
          {mappedVars.map((mv, idx) => (
            <div key={`var-list-${idx}`}>
              <strong style={{ color: colorPalletes[mv.colorIdx] }}>{mv.name}</strong>:{' '}
              {getDisplayNameFromEntityAndField(mv.entity, mv.field)}
            </div>
          ))}
        </div>
        <div className={classes.ruleFormulaVariableContainerTriangleOuter} />
        <div className={classes.ruleFormulaVariableContainerTriangleInner} />
      </div>
    </div>
  );
};

type JournalEntrySetProps = {
  entityType: TRuleSupportedEntityTypes;
  entrySet: RuleInFlowJournalEntrySet;
  onChange: (es: RuleInFlowJournalEntrySet) => void;
  onAdd: () => void;
};

const JournalEntrySet: React.FunctionComponent<JournalEntrySetProps> = ({
  entityType,
  entrySet,
  onChange,
  onAdd,
}: JournalEntrySetProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [isUsingFormula, updateIsUsingFormula] = useState<boolean>(false);

  // TODO this is fake, hook up with server
  const accountOptions = [
    {
      name: 'Deferred Revenue Recognized',
      value: 'Deferred Revenue Recognized',
    },
    {
      name: 'Deferred Revenue Accrued',
      value: 'Deferred Revenue Accrued',
    },
    {
      name: 'Non-deferred Revenue Recognized',
      value: 'Non-deferred Revenue Recognized',
    },
  ];

  return (
    <div>
      <div className={classes.ruleCreationThenFormRow}>
        <div>Account:</div>
        <div>
          <SearchableDropdown
            options={accountOptions}
            selectedValue={entrySet.account}
            onChange={(account) => {
              onChange({
                ...entrySet,
                account,
              });
            }}
            width={200}
          />
        </div>
      </div>
      <div className={classes.ruleCreationThenFormRow}>
        <div>Number of entries:</div>
        <div>
          <input
            className={sharedClasses.formInput}
            value={entrySet.entryCount}
            onChange={(e) => {
              onChange({
                ...entrySet,
                entryCount: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={classes.ruleCreationThenFormRow}>
        <div>
          Timing of {entrySet.entryCount === '1' ? '' : 'initial '}
          entry:
        </div>
        <div>
          <div>
            <input type="radio" id="initial-timing-same-day" value="same-day" name="initial-timing" checked />
            <label htmlFor="initial-timing-same-day">Same day as transaction</label>
          </div>
          <div>
            <input type="radio" id="initial-timing-start-of-month" value="start-of-month" name="initial-timing" />
            <label htmlFor="initial-timing-start-of-month">Start of month of transaction</label>
          </div>
          <div>
            <input type="radio" id="initial-timing-end-of-month" value="end-of-month" name="initial-timing" />
            <label htmlFor="initial-timing-end-of-month">End of month of transaction</label>
          </div>
        </div>
      </div>
      {entrySet.entryCount === '1' ? null : (
        <div className={classes.ruleCreationThenFormRow}>
          <div>Timing of subsequent entries:</div>
          <div>
            <div>
              <input
                type="radio"
                id="subsequent-timing-every-month"
                value="every-month"
                name="subsequent-timing"
                checked
              />
              <label htmlFor="subsequent-timing-every-month">Every month</label>
            </div>
            <div>
              <input type="radio" id="subsequent-timing-every-quarter" value="every-quarter" name="subsequent-timing" />
              <label htmlFor="subsequent-timing-every-quarter">Every quarter</label>
            </div>
          </div>
        </div>
      )}
      <div className={classes.ruleCreationThenFormRow}>
        <div>Entry amount:</div>
        <div className={classes.ruleCreationThenInputContainer}>
          {isUsingFormula ? (
            <>
              <FormulaInputContainer
                entityType={entityType}
                inputFormula={entrySet.entryAmount}
                canonicalFormula={entrySet.entryAmountFormula}
                onChange={(i, c) => {
                  onChange({
                    ...entrySet,
                    entryAmount: i,
                    entryAmountFormula: c,
                  });
                }}
              />
              <span className={classes.ruleCreationThenInputSwitch} onClick={() => updateIsUsingFormula(false)}>
                Switch to simple amount
              </span>
            </>
          ) : (
            <>
              <input
                className={sharedClasses.formInput}
                placeholder="0.00"
                value={entrySet.entryAmount}
                onChange={(e) => {
                  onChange({
                    ...entrySet,
                    entryAmount: e.target.value,
                  });
                }}
              />
              <span className={classes.ruleCreationThenInputSwitch} onClick={() => updateIsUsingFormula(true)}>
                Switch to formula
              </span>
            </>
          )}
        </div>
      </div>
      <div className={classes.ruleCreationThenFormRow}>
        <div></div>
        <div className={classes.ruleCreationThenFormAction}>
          <FormButton onClick={onAdd}>Add</FormButton>
        </div>
      </div>
    </div>
  );
};

type RulesThenActionFlowProps = {
  entityType: TRuleSupportedEntityTypes;
  entrySets: RuleInFlowJournalEntrySet[];
  onChange: (entrySets: RuleInFlowJournalEntrySet[]) => void;
};

const RulesThenActionFlow: React.FunctionComponent<RulesThenActionFlowProps> = ({
  entityType,
  entrySets,
  onChange,
}: RulesThenActionFlowProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [entrySetID, updateEntrySetID] = useState<string | undefined>();

  const currentEntrySet = entrySetID ? entrySets.find((es) => es.id === entrySetID) : undefined;

  return (
    <div className={sharedClasses.ruleCreationThenFlow}>
      <div className={sharedClasses.ruleCreationSubheader}>What journal entries should this rule create?</div>
      {currentEntrySet === undefined ? (
        <>
          <div className="rule-then-actions">
            {entrySets.map((es, idx) => (
              <div
                className={classnames(sharedClasses.formBlock, sharedClasses.formBlockActive)}
                key={`form-block-${idx}`}
              >
                <strong>{es.account}: </strong>
                {es.entryCount === '1' ? '1 entry ' : es.entryCount + ' monthly entries '}
                with amount
                <span className={classes.ruleThenActionsAmount}>{es.entryAmountFormula || es.entryAmount}</span>
              </div>
            ))}
            <div
              className={classnames(
                sharedClasses.formBlock,
                sharedClasses.formBlockClickable,
                sharedClasses.formBlockInactive
              )}
              onClick={() => {
                const id = Random.getRandomHexadecimalString();
                onChange(
                  entrySets.concat([
                    {
                      id,
                      account: 'Deferred Revenue Recognized',
                      entryCount: '1',
                    },
                  ])
                );
                updateEntrySetID(id);
              }}
            >
              Add new journal entries
            </div>
          </div>
          <div className={sharedClasses.ruleCreationFooter}>
            <FormButton isDisabled={entrySets.length === 0} onClick={() => null}>
              Save
            </FormButton>
          </div>
        </>
      ) : (
        <JournalEntrySet
          entityType={entityType}
          entrySet={currentEntrySet}
          onChange={(entrySet) => {
            const idx = entrySets.findIndex((es) => es.id === entrySet.id);
            if (idx < 0) {
              return;
            }
            onChange(
              entrySets
                .slice(0, idx)
                .concat([entrySet])
                .concat(entrySets.slice(idx + 1))
            );
          }}
          onAdd={() => updateEntrySetID(undefined)}
        />
      )}
    </div>
  );
};

export default RulesThenActionFlow;
