/**
 * `values` are in %, these should be calculated based on the revene model its dropped into
 */
export const defaultDimensions = [
  {
    id: '3693a728-3b2c-4054-9f47-9987e22a5b6c',
    heading: 'Numeralized Dimensions',
    items: [
      {
        id: '1d712f61-c0d2-4aa3-9f4c-1ad31c61f8d5',
        label: 'Contract ID',
        values: [],
      },
      {
        id: '2fcda115-5e9c-40d6-ba19-c2ee83de7bca',
        label: 'Customer ID',
        values: [],
      },
      {
        id: '45fbb75a-6512-4dd8-a514-d7a170c913fc',
        label: 'Invoice ID',
        values: [],
      },
      {
        id: 'a57e8e8f-b2ce-4750-a30f-002ed627a402',
        label: 'Price List Item Name',
        values: [],
      },
      {
        id: 'bad0c660-ca13-4721-a5e7-b9599789b983',
        label: 'Price List Item ID',
        values: [],
      },
      {
        id: 'dc4530e8-dded-48ac-bed8-b28f54366ae4',
        label: 'SKU',
        values: [],
      },
    ],
  },
  {
    id: 'b586c7eb-37ab-4bf6-8171-76b2ae84e1d1',
    heading: 'Raw Data Dimensions',
    items: [
      {
        id: '1613d9ce-fddc-4068-ba78-b06aae9b5b28',
        label: 'Original Currency',
        values: [],
      },
      {
        id: '94f2f856-726b-455d-a54d-0c0fbff98567',
        label: 'Source ID',
        values: [],
      },
      {
        id: '0bd37f21-6ab3-42e4-88d0-f6e4977edaa0',
        label: 'Source System',
        values: [],
      },
    ],
  },
  {
    id: 'cb54c0c4-5baa-4379-8ef1-276d1476e3a6',
    heading: 'Custom Dimensions',
    items: [
      {
        id: '9ce50307-5857-4c91-913f-5915e5fd9b64',
        label: 'Rental Type',
      },
      {
        id: '0c2dba3d-52fc-435f-93c4-31c481aadb2f',
        label: 'Sales Rep',
      },
      {
        id: '50b2c033-1a3e-422d-aaaa-3f9ca6501c90',
        label: 'State',
        values: [
          {
            id: 'f26c8b2b-66d7-448f-aaf2-a276158822e0',
            label: 'Alabama',
            value: 1.71,
          },
          {
            id: 'b8c908e1-21bf-4aaa-b7e8-9c97feb3fc8d',
            label: 'Alaska',
            value: 3.31,
          },
          {
            id: '41d3e615-b8df-44d3-8e62-03a4a95497b6',
            label: 'Arizona',
            value: 3.6,
          },
          {
            id: '4a7b5d7d-276d-4bd0-a4d4-44acff88a687',
            label: 'Arkansas',
            value: 0.05,
          },
          {
            id: '0209c51f-0e35-4716-a3ce-c7c427c9d864',
            label: 'California',
            value: 1.76,
          },
          {
            id: '71f1198d-abae-4bf7-8a93-51dbdd7c3061',
            label: 'Colorado',
            value: 2.8,
          },
          {
            id: 'c184afc7-a586-4a1c-92f3-e34a72e467cd',
            label: 'Connecticut',
            value: 1.63,
          },
          {
            id: 'c2772153-6068-469f-84f4-1b2a367413a7',
            label: 'Delaware',
            value: 3.54,
          },
          {
            id: '6d303ff9-49c0-4784-858b-d702e3acccb8',
            label: 'Florida',
            value: 0.97,
          },
          {
            id: 'c1a41e8b-e421-4e8c-9161-1a283d7a34a8',
            label: 'Georgia',
            value: 0.23,
          },
          {
            id: '0c2960d0-2b3f-4725-b433-116d70614df8',
            label: 'Hawaii',
            value: 2.84,
          },
          {
            id: '8edb28d5-0f09-4431-823d-7955aa45535a',
            label: 'Idaho',
            value: 3.2,
          },
          {
            id: 'a9c9a39a-f687-4cfb-b0f9-92cd267a131a',
            label: 'Illinois',
            value: 1.16,
          },
          {
            id: 'bcf5398a-9ab9-4192-b527-4d39b9eb5f86',
            label: 'Indiana',
            value: 3.11,
          },
          {
            id: '1402fbf8-5fcb-41d5-9efd-96cbce86c547',
            label: 'Iowa',
            value: 1.74,
          },
          {
            id: 'd2f2448b-2adb-4b06-b7bb-07ee35f3fa53',
            label: 'Kansas',
            value: 3.27,
          },
          {
            id: '1f90f0bf-ec49-4783-9c13-fe77b360b081',
            label: 'Kentucky',
            value: 2.6,
          },
          {
            id: '3d9a5903-1534-48c3-bb41-b3edfd734f3b',
            label: 'Louisiana',
            value: 3.18,
          },
          {
            id: 'e51caab2-ddcc-4861-a4a8-b3000556c81b',
            label: 'Maine',
            value: 1.14,
          },
          {
            id: '2be9253d-71dd-4065-8b35-eb5403f7cd69',
            label: 'Maryland',
            value: 3.17,
          },
          {
            id: '7e1be9a0-ca58-4011-a52a-033eae421e5a',
            label: 'Massachusetts',
            value: 0.77,
          },
          {
            id: '5e992a3a-56db-46cb-bc7a-e118f690aefa',
            label: 'Michigan',
            value: 0.95,
          },
          {
            id: '718307cd-42c8-4a1f-844b-69611091a9cb',
            label: 'Minnesota',
            value: 2.27,
          },
          {
            id: '36ae5b1f-151a-4e36-b398-d469f611117b',
            label: 'Mississippi',
            value: 3.32,
          },
          {
            id: '294303f0-21bf-4a28-810a-4cf432a9e03c',
            label: 'Missouri',
            value: 2.57,
          },
          {
            id: 'c1ef6139-03d6-435f-a6a2-b4ca103cb33f',
            label: 'Montana',
            value: 1.34,
          },
          {
            id: '41d34310-e8dc-4782-b991-47b42c1aa709',
            label: 'Nebraska',
            value: 3.26,
          },
          {
            id: '5503c5c9-e416-48bd-8cbc-98ec9315e292',
            label: 'Nevada',
            value: 0.34,
          },
          {
            id: 'd57b45cd-59be-4ca9-b0c9-0e70537a0670',
            label: 'New Hampshire',
            value: 2.2,
          },
          {
            id: 'aadcfb84-5ad7-41f8-8aaa-3885be37417b',
            label: 'New Jersey',
            value: 1.08,
          },
          {
            id: '9877f975-b60f-4699-9f40-04aa40576548',
            label: 'New Mexico',
            value: 1.31,
          },
          {
            id: 'e6a5af80-bbd2-488d-8102-5e0529a095b0',
            label: 'New York',
            value: 3.51,
          },
          {
            id: '99a344b3-47b5-4747-ad1f-d55b467b3c08',
            label: 'North Carolina',
            value: 2.46,
          },
          {
            id: '66da6dfd-1956-41f4-8859-be705155458c',
            label: 'North Dakota',
            value: 0.4,
          },
          {
            id: 'dd450e7d-8403-44dc-a2ee-2a8337a546d4',
            label: 'Ohio',
            value: 2.0,
          },
          {
            id: 'b40a4a5b-0441-4aeb-9e2c-30dfbe7e8d70',
            label: 'Oklahoma',
            value: 2.25,
          },
          {
            id: '55c2f600-2bc3-41ec-85e5-49419442f293',
            label: 'Oregon',
            value: 0.3,
          },
          {
            id: '6b598d5c-c758-470a-8b77-de7794d4b284',
            label: 'Pennsylvania',
            value: 3.01,
          },
          {
            id: '6b099aae-c145-4225-9cb6-dfe76417fae6',
            label: 'Rhode Island',
            value: 1.57,
          },
          {
            id: '8d4a450f-d841-40da-af11-7161bf23fe8c',
            label: 'South Carolina',
            value: 2.69,
          },
          {
            id: '73ecbecc-6ee2-48cf-a097-6de4d115b5f2',
            label: 'South Dakota',
            value: 0.59,
          },
          {
            id: '0702522e-4624-45fe-99c6-34741ad37c79',
            label: 'Tennessee',
            value: 2.44,
          },
          {
            id: '0eb88244-9801-41c8-a668-b1cc9ca510e0',
            label: 'Texas',
            value: 2.88,
          },
          {
            id: '76179819-1170-4c6b-b871-1d62778d04d0',
            label: 'Utah',
            value: 1.37,
          },
          {
            id: '546e1785-ff30-4bac-9139-5bd38715ca1b',
            label: 'Vermont',
            value: 0.79,
          },
          {
            id: '3d44b990-a80a-4730-9c2d-baf72d985204',
            label: 'Virginia',
            value: 2.08,
          },
          {
            id: '1fc1f4a8-5fc2-4785-adee-c5135bcf93d2',
            label: 'Washington',
            value: 1.67,
          },
          {
            id: '0cb8719d-d6cf-4c1b-be83-2785d7ccd229',
            label: 'West Virginia',
            value: 1.29,
          },
          {
            id: '59e7e5f3-a789-46b5-9205-11389c3659dc',
            label: 'Wisconsin',
            value: 3.29,
          },
          {
            id: 'ceb8d30d-1952-4eb5-9014-a95a4b616c76',
            label: 'Wyoming',
            value: 1.0,
          },
        ],
      },
      {
        id: '71063c91-846b-4b2e-8495-98f10a4222db',
        label: 'Tax Status',
      },
      {
        label: 'Product Line',
        id: '14df87ac-8127-4b12-95e1-f7632da06042',
        values: [
          {
            label: 'Bronze',
            id: '271f496b-ad0a-4ca6-90a7-e66728fe8dce',
            value: 25,
          },
          {
            label: 'Silver',
            id: '406b2150-f84f-409e-b2fe-9d151afef723',
            value: 35,
          },
          {
            label: 'Gold',
            id: 'ef430eaf-f17b-454a-88bb-fe1862f04d20',
            value: 40,
          },
        ],
      },
    ],
  },
];

export const healthie = [
  {
    id: '3693a728-3b2c-4054-9f47-9987e22a5b6c',
    heading: 'Numeralized Dimensions',
    items: [
      {
        id: '844730a7-5b39-402d-bfee-8201f40627d8',
        rowLabel: '*',
        label: 'State', // BE will populate this
        values: [''],
        dimensionKeys: {
          state: ['*'],
        },
      },
    ],
  },
];
