import React, { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { AnimatedWrapper } from '../../features/sideBar/components/animatedWrapper/index';
import { Header } from '../../features/sideBar/components/header/index';
import NavItem from '../NavItem';
import { SidebarWidth } from '../../features/sideBar/constants/sideBarWidth';
import { TNavItem } from '../../../src/types/BaseTypes';
import { TOGGLE_WIDTH_PX } from './constants.ts';
import { defaultNavItems } from '../../features/sideBar/constants/defaultNavItems';
import { filterNavItemsByReportSetting } from '../../features/sideBar/utils/filterNavItemsByReportSettings';
import getAssetPath from '../../utils/AssetPathUtil';
import { useAppContext } from '../../context/AppContext.tsx';
import { useSetSettingsMutation } from '../../generated/graphql';
import { useSideBarContext } from '../../features/sideBar/providers/SideBarProvider';
import { useStyles } from './styles.ts';
import { getMatchedPath, shouldShowNavItem } from './utils.ts';

export const NavigationSidebar: React.FunctionComponent = React.memo(() => {
  const { currentUser, currentAvailableNavSidebars, currentNavSidebarsCollapsed, currentSession } = useAppContext();
  const { showSideBarType, onShowSideBarType } = useSideBarContext();
  const defaultNavItemOrder = Object.values(TNavItem);
  const matchedPath = getMatchedPath();
  const classes = useStyles();
  const [selectedNav, updateSelectedNav] = useState(matchedPath || 'ledger');

  const location = useLocation();

  const [navItems, setNavItems] = useState(defaultNavItems);

  useEffect(() => {
    // Update selectedNav based on the current location
    if (location.pathname !== selectedNav) {
      updateSelectedNav(location.pathname);
    }
  }, [location.pathname, selectedNav]);

  const isRealCustomerCompany = !!currentUser?.company?.isRealCustomerCompany;
  const companyName = currentUser?.company?.name || '';

  const [isCollapsed, updateIsCollapsed] = useState(false);

  const [hasAnimation, updateHasAnimation] = useState(false);

  const [items, setItems] = useState<TNavItem[]>(defaultNavItemOrder);
  const [itemsCollapsed, setItemsCollapsed] = useState<TNavItem[]>([]);

  const [isReorderable, setIsReorderable] = useState(false);
  const [reorderLabel, setReorderLabel] = useState<'Reorder' | 'Confirm'>('Reorder');

  const hide = showSideBarType === 'DEFAULT' ? isCollapsed : true;

  useEffect(() => {
    if (currentUser) {
      const companyReportSettings = currentUser?.company?.settings?.reportSettings;
      if (companyReportSettings) {
        const filterFn = filterNavItemsByReportSetting(companyReportSettings);
        setNavItems(defaultNavItems.filter(filterFn));
        setItems(currentAvailableNavSidebars as TNavItem[]);
        setItemsCollapsed(currentNavSidebarsCollapsed as TNavItem[]);
      }
    }
  }, [currentAvailableNavSidebars, currentNavSidebarsCollapsed, currentUser]);

  const [changeSettingsMutation] = useSetSettingsMutation();

  if (!hasAnimation) {
    window.setTimeout(() => {
      updateHasAnimation(true);
    }, 500);
  }

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    const activeId = active?.id as TNavItem;
    const overId = over?.id as TNavItem;

    if (activeId !== overId) {
      setItems((items) => {
        const oldIndex = items.indexOf(activeId);
        const newIndex = items.indexOf(overId);

        const newArray = arrayMove(items, oldIndex, newIndex);
        return newArray;
      });
    }
  }, []);

  const handleReorderButtonClick = useCallback(() => {
    const newLabel = reorderLabel === 'Reorder' ? 'Confirm' : 'Reorder';
    setReorderLabel(newLabel);
    setIsReorderable(newLabel === 'Confirm');
    if (newLabel === 'Reorder' && currentUser?.id) {
      changeSettingsMutation({
        variables: {
          new_settings: JSON.stringify({
            sidebarNavItemOrder: items,
            sidebarNavItemCollapsed: itemsCollapsed,
          }),
        },
      });
    }
  }, [reorderLabel, currentUser?.id, items, itemsCollapsed, changeSettingsMutation]);

  const handleToggle = useCallback(() => {
    updateIsCollapsed((prev) => !prev);
    onShowSideBarType(isCollapsed ? 'DEFAULT' : 'NONE');
  }, [isCollapsed, onShowSideBarType]);

  const handleSetCollapsed = useCallback(
    (type: TNavItem) => {
      const newItemCollapsed = itemsCollapsed.includes(type)
        ? itemsCollapsed.filter((item) => item !== type)
        : [...itemsCollapsed, type];

      setItemsCollapsed(newItemCollapsed);
      changeSettingsMutation({
        variables: {
          new_settings: JSON.stringify({
            sidebarNavItemOrder: items,
            sidebarNavItemCollapsed: newItemCollapsed,
          }),
        },
      });
    },
    [itemsCollapsed, items, changeSettingsMutation]
  );

  return (
    <AnimatedWrapper
      isCollapsed={hide}
      hasAnimation={hasAnimation}
      width={SidebarWidth.DEFAULT}
      toggleWidth={TOGGLE_WIDTH_PX}
      relative
    >
      <Header
        showCustomerCompany={isRealCustomerCompany}
        name={currentSession.firstName ?? ''}
        company={currentSession.companyName ?? ''}
      />
      <div className={classes.navBar}>
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((type) => {
              const navItem = navItems.find((item) => item.type === type);
              if (!navItem) {
                return null;
              }
              const { iconType, label, appPath, secondaryItems, iconHeight, iconLeftMargin } = navItem;
              return (
                <NavItem
                  key={type}
                  type={type}
                  iconType={iconType}
                  label={label}
                  isReorderable={isReorderable}
                  secondaryItems={(secondaryItems || []).filter((item) =>
                    shouldShowNavItem(isRealCustomerCompany, companyName, item.type)
                  )}
                  selectedNav={selectedNav}
                  isSelected={appPath ? selectedNav === appPath : undefined}
                  onClick={appPath ? () => updateSelectedNav(appPath) : undefined}
                  to={appPath ? `/app/${appPath}` : undefined}
                  isCollapsed={itemsCollapsed?.includes(type)}
                  setCollapsed={() => handleSetCollapsed(type)}
                  iconHeight={iconHeight}
                  iconLeftMargin={iconLeftMargin}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
      <button className={classes.reorderButton} onClick={handleReorderButtonClick}>
        <img
          className={`${classes.reorderButtonIcon} ${classes.reorderButtonIconUnselected}`}
          src={`${getAssetPath()}/images/icon_nav_reorder.svg`}
        />
        <img
          className={`${classes.reorderButtonIcon} ${classes.reorderButtonIconSelected}`}
          src={`${getAssetPath()}/images/icon_nav_reorder_selected.svg`}
        />
        <span className={classes.reorderButtonLabel}>{reorderLabel}</span>
      </button>
      <div className={classes.subtext}>© {new Date().getFullYear()}, Numeral Technologies, Inc.</div>
      <div className={classes.toggle} onClick={handleToggle}>
        <span>{hide ? '〉' : '〈'}</span>
      </div>
    </AnimatedWrapper>
  );
});
