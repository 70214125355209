import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';

import Toaster from './Toaster.tsx';
import { ToastItem, ToastType } from './types.ts';

export const useToaster = (autoHideDuration = 3000) => {
  const [toast, setToast] = useState<ToastItem | null>(null);

  const notify = (message: string, type: ToastType = 'success') => {
    const newToast: ToastItem = {
      message,
      type,
    };

    setToast(newToast);
  };

  useEffect(() => {
    const toasterElement = document.createElement('div');
    document.body.appendChild(toasterElement);

    ReactDOM.render(<Toaster toast={toast} autoHideDuration={autoHideDuration} />, toasterElement);

    return () => {
      ReactDOM.unmountComponentAtNode(toasterElement);
      document.body.removeChild(toasterElement);
    };
  }, [toast, autoHideDuration]);

  return { notify };
};
