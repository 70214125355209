import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import Colors from './common/Colors';
import FormButton from './common/FormButton';
import getAssetPath from '../utils/AssetPathUtil';
import { useSharedStyles } from '../utils/CssUtil';
import {
  ConfirmTwoFactorCodeMutation,
  ConfirmTwoFactorCodeMutationVariables,
  SendTwoFactorEmailMutation,
  SendTwoFactorEmailMutationVariables,
} from '../generated/graphql';

const useStyles = createUseStyles({
  loginContainer: {
    display: 'block',
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    width: '100%',
  },

  contentWrapper: {
    height: 'auto',
    minHeight: 'auto',
    margin: '-50px auto 0',
    padding: '30px',
    width: '270px',
  },

  loginLogoContainer: {
    textAlign: 'center',

    '& p': {
      fontSize: '14px',
      margin: '10px 0 20px',
    },
  },

  loginRow: {
    fontSize: '14px',
    margin: '15px 0',

    '& > span': {
      display: 'block',
      marginBottom: '-10px',
      width: '80px',
    },

    '& input': {
      background: 'transparent',
      border: `1px solid ${Colors.MEDIUM_GRAY}`,
      borderRadius: '3px',
      color: Colors.DARK_GRAY,
      fontSize: '14px',
      padding: '8px',
      transition: 'border 0.1s',
      width: '100%',

      '&:focus': {
        border: `1px solid ${Colors.DARK_GRAY}`,
        boxShadow: '1px 1px 1px rgba(0,0,0,0.035)',
        outline: 'none',
      },

      '&:disabled': {
        backgroundColor: Colors.LIGHT_GRAY,
      },
    },

    '& button': {
      width: '100%',
    },
  },

  error: {
    color: Colors.WARNING_RED,
    fontSize: '12px',
    marginTop: '-8px',
  },
});

const SEND_TWO_FACTOR_EMAIL_MUTATION = gql`
  mutation SendTwoFactorEmail {
    sendTwoFactorEmail {
      isSuccessful
    }
  }
`;

const CONFIRM_TWO_FACTOR_CODE_MUTATION = gql`
  mutation confirmTwoFactorCode($code: String!) {
    confirmTwoFactorCode(code: $code) {
      isSuccessful
    }
  }
`;

const LoginOtp: React.FunctionComponent = () => {
  const codeRef = useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sharedClasses = useSharedStyles();
  const [errorValue, setErrorStateValue] = useState<string | null>(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const [sentTwoFactor] = useMutation<SendTwoFactorEmailMutation, SendTwoFactorEmailMutationVariables>(
    SEND_TWO_FACTOR_EMAIL_MUTATION
  );
  const [confirmTwoFactorCode] = useMutation<ConfirmTwoFactorCodeMutation, ConfirmTwoFactorCodeMutationVariables>(
    CONFIRM_TWO_FACTOR_CODE_MUTATION
  );
  const [sentEmail, setHasSentEmail] = useState(false);
  useEffect(() => {
    if (sentEmail) return;
    sentTwoFactor().then((result) => {
      if (result.data?.sendTwoFactorEmail.isSuccessful) {
        setHasSentEmail(true);
      } else {
        setErrorStateValue(
          `Error when sending the email.  Please refresh the page and if the error persists email support@gonumeral.com`
        );
      }
    });
  }, [sentTwoFactor, sentEmail]);

  const continueFn = async () => {
    setIsSubmitting(true);
    const confirmed = await confirmTwoFactorCode({ variables: { code: codeRef.current?.value || '' } });
    if (confirmed.data?.confirmTwoFactorCode.isSuccessful) {
      navigate('/app/home');
    } else {
      navigate('/');
    }
  };
  return (
    <div className={classes.loginContainer}>
      <div className={classnames(sharedClasses.banner, sharedClasses.loginBanner)}></div>
      <div className={classnames(sharedClasses.contentWrapper, classes.contentWrapper)}>
        <div className={classes.loginLogoContainer}>
          <img src={`${getAssetPath()}/images/logo_small.png`} height={48} />
          <p>Please check your email for a one time code</p>
        </div>
        {errorValue ? <div className={classes.error}>{errorValue}</div> : null}
        <div className={classes.loginRow}>
          <span>One Time code:</span>
        </div>
        <div className={classes.loginRow}>
          <input
            type="textbox"
            name="textbox"
            disabled={isSubmitting}
            placeholder="ex: 123456"
            required
            ref={codeRef}
          />
        </div>
        <div className={classes.loginRow}>
          <FormButton isDisabled={isSubmitting} onClick={continueFn}>
            Check and Confirm Code
          </FormButton>
        </div>
      </div>
    </div>
  );
};

export default LoginOtp;
