import { WithId } from '../types.ts';

export const makeHtml5Droppable = <T extends WithId>(
  element: HTMLElement | null,
  onDragEnd: (event: DragEvent, data: T) => void,
  onDragOver?: (event: DragEvent) => void,
  onDragLeave?: (event: DragEvent) => void
): void => {
  if (!element) return;

  element.ondragover = (event) => {
    event.preventDefault();
    onDragOver?.(event);
  };

  element.ondragleave = (event) => {
    event.preventDefault();
    onDragLeave?.(event);
  };

  element.ondrop = (event) => {
    event.preventDefault();
    const data = JSON.parse(event.dataTransfer?.getData('application/reactflow') || '{}');
    onDragEnd(event, data);
  };
};
