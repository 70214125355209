import React from 'react';
import { useStyles } from './styles';
import { FormButtonProps } from './types';

export const FormButtonV2: React.FunctionComponent<FormButtonProps> = ({
  children,
  isDisabled,
  dynamicWidth,
  onClick,
  width,
  variant = 'accented',
  height,
  expand,
  size = 'small',
  extraStyle = {},
}: FormButtonProps) => {
  const classes = useStyles();
  const buttonStyles = {
    [classes.buttonAccented]: variant === 'accented',
    [classes.buttonNeutral]: variant === 'neutral',
    [classes.buttonPlain]: variant === 'plain',
    [classes.buttonStale]: variant === 'stale',
    [classes.buttonDisabled]: variant === 'disabled',
    [classes.buttonDark]: variant === 'dark',
    [classes.large]: size === 'large',
    [classes.small]: size === 'small',
    [classes.medium]: size === 'medium',
    [classes.expand]: expand,
  };

  const classNames = Object.keys(buttonStyles)
    .filter((key) => buttonStyles[key])
    .join(' ');

  return (
    <button
      className={`${classes.buttonBase} ${classNames}`}
      style={{
        ...(dynamicWidth ? { width: 'auto' } : width ? { width: `${width}px` } : {}),
        ...(height ? { height: `${height}px` } : {}),
        ...extraStyle,
      }}
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
    >
      {children}
    </button>
  );
};
