import pluralize from 'pluralize';

function getCountWordString(count: number, word: string): string {
  if (count === 1) {
    return `${count} ${word}`;
  }
  return `${count} ${pluralize(word)}`;
}

function hexEncode(str: string): string {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    const hex = str.charCodeAt(i).toString(16);
    result += ('000' + hex).slice(-4);
  }
  return result;
}

function hexDecode(str: string): string {
  const hexes = str.match(/.{1,4}/g) || [];
  let result = '';
  for (let i = 0; i < hexes.length; i++) {
    result += String.fromCharCode(parseInt(hexes[i], 16));
  }

  return result;
}

function replaceParamInUrl(url: string, key: string, val: string): string {
  const urlParts = url.split('?');
  const before = urlParts[0];
  const after = urlParts.length < 2 ? '' : urlParts.slice(1).join('?');

  const safeVal = encodeURIComponent(val);

  const paramPairs = after.split('&');
  if (paramPairs.length === 1 && paramPairs[0] === '') {
    paramPairs.pop();
  }
  const processed: string[] = [];
  let found = false;
  paramPairs.forEach((pair) => {
    const parts = pair.split('=');
    if (parts.length !== 2) {
      processed.push(pair);
      return;
    }

    if (parts[0] === key) {
      processed.push([key, safeVal].join('='));
      found = true;
    } else {
      processed.push(pair);
    }
  });
  if (!found) {
    processed.push([key, safeVal].join('='));
  }

  return before + '?' + processed.join('&');
}

const getPageUrl = <TUrlParam extends string>(
  rootUrlIncludingQuestionMark: string,
  params: { [key in TUrlParam]?: string | number | undefined }
) =>
  rootUrlIncludingQuestionMark +
  Object.keys(params)
    .filter((key) => !!params[key as TUrlParam])
    .map((key) => `${key}=${params[key as TUrlParam]}`)
    .join('&');

const toTitleCase = (str: string) =>
  str.replace(/_/g, ' ').replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

const snakeToCamel = (str: string) =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

const toKebabCase = (str: string) => str.toLowerCase().replace(/\s+/g, '-');

export default {
  getCountWordString,
  hexEncode,
  hexDecode,
  replaceParamInUrl,
  getPageUrl,
  toTitleCase,
  snakeToCamel,
  toKebabCase,
};
