import React from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import Colors from './common/Colors';
import getAssetPath from '../utils/AssetPathUtil';
import { useSharedStyles } from '../utils/CssUtil';

const useStyles = createUseStyles({
  contentWrapper: {
    height: 'auto',
    minHeight: 'auto',
    margin: '-50px auto 0',
    padding: '30px',
    width: '270px',
  },
  maintenanceLogoContainer: {
    textAlign: 'center',

    '& p': {
      fontSize: '14px',
      margin: '10px 0 20px',
    },
  },

  urlLink: {
    color: Colors.MAIN_BLUE,
    textDecoration: 'underline',
    visited: Colors.MAIN_BLUE,
  },
});
const Maintenance: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  return (
    <>
      <div className={sharedClasses.main}>
        <div className={classnames(sharedClasses.banner, sharedClasses.loginBanner)}></div>
        <div className={classnames(sharedClasses.contentWrapper, classes.contentWrapper)}>
          <div className={classes.maintenanceLogoContainer}>
            <img src={`${getAssetPath()}/images/logo_small.png`} height={48} />
            <p>This Numeral page is temporarily unavailable while undergoing maintenance.</p>
            <p>
              Please check our{' '}
              <a className={classes.urlLink} href="https://status.gonumeral.com/">
                status page
              </a>{' '}
              for latest updates.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Maintenance;
