import { ApolloError } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSharedStyles } from '../../utils/CssUtil.ts';

type ErrorMessageProps = {
  error?: ApolloError;
};

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({ error }: ErrorMessageProps) => {
  const sharedClasses = useSharedStyles({});
  const navigate = useNavigate();

  if (!error) return null;

  if (error.message.includes('Not signed in')) {
    setTimeout(() => {
      navigate('/');
    }, 100);
  }
  // only show first error message from graphQLErrors list
  return <div className={sharedClasses.main}>Error: {error?.graphQLErrors?.[0]?.message}</div>;
};

export default ErrorMessage;
