import React, { CSSProperties, ReactElement } from 'react';

import { createUseStyles } from 'react-jss';

import Colors from './Colors';

const useStyles = createUseStyles({
  button: {
    borderRadius: '3px',
    color: Colors.WHITE,
    cursor: 'pointer',
    fontSize: '14px',
    height: '38px',
    letterSpacing: '1px',
    transition: 'background-color 0.1s',
    width: '100px',

    '&[disabled]': {
      background: Colors.LIGHT_GRAY,
      border: `1px solid ${Colors.LIGHT_GRAY}`,
      cursor: 'default',

      '&:hover': {
        background: Colors.LIGHT_GRAY,
      },
    },
  },

  buttonAccented: {
    background: Colors.LIGHT_BLUE,
    border: `1px solid ${Colors.MAIN_BLUE}`,

    '&:hover': {
      background: Colors.MAIN_BLUE,
    },
  },

  buttonNeutral: {
    background: Colors.MEDIUM_GRAY,
    border: `1px solid ${Colors.DARK_GRAY}`,

    '&:hover': {
      background: Colors.DARK_GRAY,
    },
  },

  buttonStale: {
    background: Colors.MEDIUM_GRAY,
    border: `1px solid ${Colors.STALE_DARK_GRAY}`,

    '&:hover': {
      background: Colors.DARK_GRAY,
    },
  },

  buttonDisabled: {
    background: Colors.DISABLED,
    border: `1px solid ${Colors.DISABLED_BORDER}`,
    color: Colors.DISABLED_TEXT,
  },

  buttonPlain: {
    color: Colors.DARK_GRAY,
    background: Colors.WHITE,
    border: `1px solid ${Colors.DARK_GRAY}`,

    '&:hover': {
      background: Colors.LIGHT_GRAY,
    },
  },
});

export enum FormButtonStyle {
  ACCENTED,
  NEUTRAL,
  PLAIN,
  STALE,
  DISABLED,
}

export enum SIZE {
  SMALL,
}

type FormButtonProps = {
  children: ReactElement | string | number;
  isDisabled?: boolean;
  onClick: () => void;
  style?: FormButtonStyle;
  width?: number;
  height?: number;
  extraStyle?: CSSProperties;
};

const FormButton: React.FunctionComponent<FormButtonProps> = ({
  children,
  isDisabled,
  onClick,
  style = FormButtonStyle.ACCENTED,
  height,
  width,
  extraStyle = {},
}: FormButtonProps) => {
  const classes = useStyles();

  return (
    <button
      className={[
        classes.button,
        style === FormButtonStyle.ACCENTED ? classes.buttonAccented : '',
        style === FormButtonStyle.NEUTRAL ? classes.buttonNeutral : '',
        style === FormButtonStyle.PLAIN ? classes.buttonPlain : '',
        style === FormButtonStyle.STALE ? classes.buttonStale : '',
        style === FormButtonStyle.DISABLED ? classes.buttonDisabled : '',
      ].join(' ')}
      style={{
        ...(width ? { width: `${width}px` } : {}),
        ...(height ? { height: `${height}px` } : {}),
        ...extraStyle,
      }}
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
    >
      {children}
    </button>
  );
};

export default FormButton;
