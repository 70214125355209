import Colors from '../../../../components/common/Colors';
import { SideBarDimensionsProps } from './types.ts';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles<SideBarDimensionsProps>({
  sidebar: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: Colors.WHITE,
    boxShadow: '1px 0 1px rgba(0,0,0,0.02)',
    fontFamily: "'Akshar', sans-serif",
    minHeight: '100vh',
    minWidth: ({ sidebarWidth }) => `${sidebarWidth}px`,
    position: ({ relative }) => (relative ? 'relative' : 'absolute'),
    width: ({ sidebarWidth }) => `${sidebarWidth}px`,
  },
  sidebarWithAnimation: {
    transition: 'margin-left 0.4s',
  },
  sidebarCollapsed: {
    marginLeft: ({ sidebarWidth, toggleWidth = 0 }) => `-${sidebarWidth - toggleWidth}px`,

    '& $toggle': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,

      '& span': {
        marginLeft: '8px',
      },
    },
  },
});
