import pluralize from 'pluralize';
import React, { useState } from 'react';

import Colors from '../common/Colors';
import SearchableDropdown from '../common/SearchableDropdown';
import { SelectSearchOption } from 'react-select-search';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSharedStyles } from '../../utils/CssUtil';
import {
  EntityTypeToSupportedFields,
  RuleEntityConfigs,
  TRuleIfConditionElementaryCondition,
  TRuleIfConditionNumberOperator,
  TRuleIfConditionOperator,
  TRuleIfConditionStringOperator,
  TRuleLinkedEntity,
  TRuleSupportedEntityTypes,
  TRuleSupportedFields,
  TRuleSupportedStringFields,
} from '../../../src/types/RuleTypes';
import FormButton, { FormButtonStyle } from '../common/FormButton';
import {
  entityTypeToDisplay,
  getDisplayNameFromEntityAndField,
  numberOperatorToDisplay,
  stringOperatorToDisplay,
} from './utils/RuleDisplayUtil';

const useStyles = createUseStyles({
  ruleSelectEntityContainer: {
    display: 'flex',
    marginTop: 20,
    '& button': {
      marginLeft: 15,
    },
  },
  ruleConditionContainer: {
    display: 'flex',
    '& > *': {
      marginRight: 15,
    },
  },
  ruleIfConditionsOptions: {
    marginTop: 20,
  },
  ruleIfConditionsContainer: { maxWidth: 700 },
  ruleIfConditionsHeader: {
    marginBottom: 15,
  },
  ruleIfConditionRowAnd: {
    color: Colors.MEDIUM_GRAY,
    fontSize: '12px',
    margin: '15px 0',
    textAlign: 'center',
  },
});

const allowedInitialEntityTypes = Object.values(TRuleSupportedEntityTypes).filter(
  (t) => RuleEntityConfigs[t].allowAsInitialEntity
);

type EntitySelectorProps = {
  entityType?: TRuleSupportedEntityTypes;
  onChange: (type: TRuleSupportedEntityTypes) => void;
};

const EntitySelector: React.FunctionComponent<EntitySelectorProps> = ({
  entityType,
  onChange,
}: EntitySelectorProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const options = allowedInitialEntityTypes.map((t) => ({
    name: pluralize(entityTypeToDisplay[t]),
    value: t,
  }));
  const [selectedEntityType, updateSelectedEntityType] = useState<TRuleSupportedEntityTypes | undefined>(
    entityType || options[0].value
  );

  return (
    <div>
      <div className={sharedClasses.ruleCreationSubheader}>
        Select the <strong>type of records</strong> you&apos;d like this rule to run on:
      </div>
      <div className={classes.ruleSelectEntityContainer}>
        <SearchableDropdown
          options={options}
          onChange={(val) => {
            updateSelectedEntityType(val as TRuleSupportedEntityTypes);
          }}
          selectedValue={selectedEntityType}
          width={400}
        />
        <FormButton
          onClick={() => {
            if (selectedEntityType) {
              onChange(selectedEntityType);
            }
          }}
        >
          Select
        </FormButton>
      </div>
    </div>
  );
};

type ConditionProps = {
  isNew?: boolean;
  rootEntity: TRuleSupportedEntityTypes;
  entity?: TRuleLinkedEntity;
  fieldName?: TRuleSupportedFields;
  operator?: TRuleIfConditionOperator;
  operand?: string | number;
  onSave: (c: TRuleIfConditionElementaryCondition) => void;
  onRemove?: () => void;
};

const Condition: React.FunctionComponent<ConditionProps> = ({
  isNew,
  rootEntity,
  entity,
  fieldName,
  operator,
  operand,
  onSave,
  onRemove,
}: ConditionProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const fieldOptions = [
    {
      type: 'group',
      name: entityTypeToDisplay[rootEntity],
      value: rootEntity,
      disabled: true,
      items: EntityTypeToSupportedFields[rootEntity].map((f) => ({
        name: getDisplayNameFromEntityAndField(rootEntity, f),
        value: getDisplayNameFromEntityAndField(rootEntity, f),
      })),
    },
  ].concat(
    Object.values(TRuleSupportedEntityTypes)
      .filter((t) => t !== rootEntity)
      .map((t) => ({
        type: 'group',
        name: entityTypeToDisplay[t],
        value: t,
        disabled: true,
        items: EntityTypeToSupportedFields[t].map((f) => ({
          name: getDisplayNameFromEntityAndField(t, f),
          value: getDisplayNameFromEntityAndField(t, f),
        })),
      }))
  );

  const [selectedField, updateSelectedField] = useState<TRuleSupportedFields | undefined>(fieldName);
  const [selectedEntity, updateSelectedEntity] = useState<TRuleLinkedEntity | undefined>(entity);
  let selectedFieldValue: string | undefined = undefined;
  if (selectedEntity && selectedField) {
    const entity = selectedEntity.linkType === 'self' ? rootEntity : selectedEntity.entityType;
    selectedFieldValue = (entityTypeToDisplay[entity] + '.' + selectedField).toLowerCase();
  }

  let operatorOptions: SelectSearchOption[] = [];
  let isStringField = false;
  if (selectedField) {
    if (Object.values(TRuleSupportedStringFields).find((f) => f === selectedField)) {
      isStringField = true;
      operatorOptions = Object.values(TRuleIfConditionStringOperator).map((o) => ({
        name: stringOperatorToDisplay[o],
        value: o,
      }));
    } else {
      operatorOptions = Object.values(TRuleIfConditionNumberOperator).map((o) => ({
        name: numberOperatorToDisplay[o],
        value: o,
      }));
    }
  }

  const [selectedOperator, updateSelectedOperator] = useState<TRuleIfConditionOperator | undefined>(operator);
  const [selectedOperand, updateSelectedOperand] = useState<string | number | undefined>(operand);

  const canAdd =
    selectedEntity !== undefined &&
    selectedField !== undefined &&
    selectedOperator !== undefined &&
    selectedOperand !== undefined;

  const saveFn = (
    entity?: TRuleLinkedEntity,
    fieldName?: TRuleSupportedFields,
    operator?: TRuleIfConditionOperator,
    operand?: string | number
  ) => {
    if (entity !== undefined && fieldName !== undefined && operator !== undefined && operand !== undefined) {
      onSave({
        type: 'elementary',
        entity: [entity],
        fieldName,
        operator,
        operand,
      });
      if (isNew) {
        updateSelectedOperand('');
        updateSelectedOperator(undefined);
        updateSelectedField(undefined);
        updateSelectedEntity(undefined);
      }
    }
  };

  return (
    <div className={classes.ruleConditionContainer}>
      <SearchableDropdown
        options={fieldOptions}
        onChange={(val) => {
          const parts = val.split('.');
          if (parts.length === 1) {
            const field = parts[0] as TRuleSupportedFields;
            const entity = {
              linkType: 'self',
            } as TRuleLinkedEntity;
            updateSelectedField(field);
            updateSelectedEntity(entity);
            if (!isNew) {
              saveFn(entity, field, selectedOperator, selectedOperand);
            }
          } else {
            const entityType = Object.values(TRuleSupportedEntityTypes).find((e) => {
              let enteredVal = parts[0].toLowerCase();
              // TODO
              if (enteredVal === 'sku') {
                enteredVal = 'item_price';
              }
              return e.toLowerCase() === enteredVal;
            });
            const field = parts[1] as TRuleSupportedFields;
            if (entityType) {
              updateSelectedField(field);
              const entity = (
                entityType === rootEntity
                  ? {
                      linkType: 'self',
                    }
                  : {
                      linkType: 'by_field',
                      entityType: entityType,
                      fieldNameOrigin: {
                        fieldType: 'built_in',
                        fieldName: entityType.toLowerCase() + '_id', // TODO: replace with template / multi layer
                      },
                      fieldNameDestination: {
                        fieldType: 'built_in',
                        fieldName: TRuleSupportedStringFields.ID,
                      },
                    }
              ) as TRuleLinkedEntity;
              updateSelectedEntity(entity);
              if (!isNew) {
                saveFn(entity, field, selectedOperator, selectedOperand);
              }
            }
          }
        }}
        selectedValue={selectedFieldValue}
        width={300}
        placeholder="Field name to compare"
      />
      <SearchableDropdown
        options={operatorOptions}
        disabled={!selectedField}
        disableSearch={true}
        onChange={(valStr) => {
          const val = valStr as TRuleIfConditionOperator;
          updateSelectedOperator(val);
          if (!isNew) {
            saveFn(selectedEntity, selectedField, val, selectedOperand);
          }
        }}
        placeholder="comparator"
        selectedValue={selectedOperator}
        width={200}
      />
      <input
        className={sharedClasses.formInput}
        disabled={!selectedOperator}
        placeholder={isStringField ? 'value' : '0.00'}
        onChange={(elem) => {
          const val = elem.currentTarget.value;
          updateSelectedOperand(val);
          if (!isNew) {
            saveFn(selectedEntity, selectedField, selectedOperator, val);
          }
        }}
        value={selectedOperand}
      />
      {isNew ? (
        <FormButton
          isDisabled={!canAdd}
          onClick={() => {
            saveFn(selectedEntity, selectedField, selectedOperator, selectedOperand);
          }}
        >
          Add
        </FormButton>
      ) : null}
      {isNew || !onRemove ? null : (
        <FormButton onClick={onRemove} style={FormButtonStyle.NEUTRAL}>
          Remove
        </FormButton>
      )}
    </div>
  );
};

type ConditionsProps = {
  entityType: TRuleSupportedEntityTypes;
  conditions: TRuleIfConditionElementaryCondition[];
  onChange: (c: TRuleIfConditionElementaryCondition[]) => void;
  onNext: () => void;
};

const Conditions: React.FunctionComponent<ConditionsProps> = ({
  entityType,
  conditions,
  onChange,
  onNext,
}: ConditionsProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const entityName = entityTypeToDisplay[entityType].toLowerCase();

  return (
    <div className={classes.ruleIfConditionsContainer}>
      <div className={sharedClasses.ruleCreationSubheader}>
        Which <strong>{pluralize(entityName)}</strong> should this rule run on?
      </div>
      <div className={classes.ruleIfConditionsOptions}>
        <div
          className={classnames(
            sharedClasses.formBlock,
            sharedClasses.formBlockClickable,
            conditions.length > 0 ? sharedClasses.formBlockInactive : '',
            conditions.length === 0 ? sharedClasses.formBlockActive : ''
          )}
          onClick={() => onChange([])}
        >
          <strong>All of them:</strong> this rule will apply to every {entityName}
        </div>
        <div
          className={classnames(
            sharedClasses.formBlock,
            conditions.length > 0 ? sharedClasses.formBlockActive : '',
            conditions.length === 0 ? sharedClasses.formBlockInactive : ''
          )}
        >
          <div className={classes.ruleIfConditionsHeader}>
            <strong>Those that match these conditions:</strong>
          </div>
          {conditions.map((ec, idx) => (
            // TODO: entity[0] needs to be replaced to support multi-layer
            <div className="rule-if-condition-row" key={[ec.entity[0].linkType, ec.fieldName, ec.operator].join('_')}>
              <Condition
                key={[ec.entity[0].linkType, ec.fieldName, ec.operator].join('_')}
                rootEntity={entityType}
                entity={ec.entity[0]}
                fieldName={ec.fieldName}
                operator={ec.operator}
                operand={ec.operand}
                onSave={(ec) =>
                  onChange(
                    conditions
                      .slice(0, idx)
                      .concat([ec])
                      .concat(conditions.slice(idx + 1))
                  )
                }
                onRemove={() => onChange(conditions.slice(0, idx).concat(conditions.slice(idx + 1)))}
              />
              <div className={classes.ruleIfConditionRowAnd}>and</div>
            </div>
          ))}
          <div className="rule-if-condition-new-container">
            <Condition isNew={true} rootEntity={entityType} onSave={(ec) => onChange(conditions.concat([ec]))} />
          </div>
        </div>
      </div>
      <div className={sharedClasses.ruleCreationFooter}>
        <FormButton onClick={onNext}>Next</FormButton>
      </div>
    </div>
  );
};

type RuleIfConditionFlowProps = {
  entity?: TRuleSupportedEntityTypes;
  conditions: TRuleIfConditionElementaryCondition[];
  onChange: (e: TRuleSupportedEntityTypes, c: TRuleIfConditionElementaryCondition[]) => void;
  onNext: () => void;
};

const RuleIfConditionFlow: React.FunctionComponent<RuleIfConditionFlowProps> = ({
  entity,
  conditions,
  onChange,
  onNext,
}: RuleIfConditionFlowProps) => {
  const sharedClasses = useSharedStyles();
  return (
    <div className={sharedClasses.ruleCreationIfFlow}>
      {entity ? null : (
        <div className={sharedClasses.ruleCreationSubheader}>Which records does this rule apply to?</div>
      )}
      {entity === undefined ? (
        <EntitySelector
          entityType={entity}
          onChange={(type) => {
            onChange(type, conditions);
          }}
        />
      ) : (
        <Conditions
          entityType={entity}
          conditions={conditions}
          onChange={(c) => {
            onChange(entity, c);
          }}
          onNext={onNext}
        />
      )}
    </div>
  );
};

export default RuleIfConditionFlow;
