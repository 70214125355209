import React from 'react';

import { MutationFunctionOptions, gql, useMutation, useQuery } from '@apollo/client';

import Banner from './Banner';
import ErrorMessage from './common/ErrorMessage';
import TeamCard from './TeamCard';
import { useSharedStyles } from '../utils/CssUtil';
import { useToaster } from './common/Toaster/useToasterHook.tsx';
import {
  Exact,
  GetUserListAndCurrentUserQuery,
  GetUserListAndCurrentUserQueryVariables,
  InviteUserMutation,
  InviteUserMutationVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  SetRoleMutation,
  SetRoleMutationVariables,
} from '../generated/graphql';

const QUERY = gql`
  query GetUserListAndCurrentUser {
    userList {
      users {
        id
        firstName
        lastName
        email
        role
      }
    }
    currentUser {
      id
      firstName
      lastName
      email
      role
      company {
        name
      }
    }
  }
`;

const CHANGE_ROLE_MUTATION = gql`
  mutation SetRole($user_id: String!, $role: String!) {
    setRole(userId: $user_id, role: $role) {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

const PASSWORD_RESET_MUTATION = gql`
  mutation ResetPassword($user_id: String!) {
    resetPassword(userId: $user_id) {
      ok
      message
    }
  }
`;

const INVITE_USER_MUTATION = gql`
  mutation InviteUser($user_email: String!, $user_role: String!) {
    inviteUser(email: $user_email, role: $user_role) {
      ok
      message
    }
  }
`;

const TableCardWithBanner: React.FunctionComponent = () => {
  const { notify } = useToaster();
  const sharedClasses = useSharedStyles();
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery<GetUserListAndCurrentUserQuery, GetUserListAndCurrentUserQueryVariables>(QUERY);

  const [changeRoleMutation, { loading: isChangeRoleLoading, error: changeRoleErrorStr }] = useMutation<
    SetRoleMutation,
    SetRoleMutationVariables
  >(CHANGE_ROLE_MUTATION);
  const [resetPasswordMutation, { loading: isPasswordResetLoading, error: passwordResetErrorStr }] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(PASSWORD_RESET_MUTATION);
  const [inviteUserMutation, { data: userInviteData, loading: isUserInviteLoading, error: inviteUserErrorStr }] =
    useMutation<InviteUserMutation, InviteUserMutationVariables>(INVITE_USER_MUTATION);

  if (error || passwordResetErrorStr || inviteUserErrorStr || changeRoleErrorStr) {
    return <ErrorMessage error={error || passwordResetErrorStr || inviteUserErrorStr || changeRoleErrorStr} />;
  }

  const onInviteUser = async (
    data: MutationFunctionOptions<InviteUserMutation, Exact<{ user_email: string; user_role: string }>> | undefined
  ) => {
    try {
      await inviteUserMutation(data);
      notify('Notification sent! Please check your inbox or spam folder.');
    } catch (err) {
      notify('Notification stuck! Retry in a sec.');
    }
  };

  return (
    <div className={sharedClasses.main}>
      <Banner
        highlights={[
          {
            header: 'Company',
            value: data?.currentUser?.company?.name || '',
            isLoading,
          },
          {
            header: 'Team Members',
            value: `${data?.userList?.users?.length}`,
            isLoading,
          },
        ]}
      />
      <TeamCard
        data={data}
        handleChangeRole={changeRoleMutation}
        handlePasswordReset={resetPasswordMutation}
        handleUserInvite={onInviteUser}
        userInviteData={userInviteData}
        isUserInviteLoading={isUserInviteLoading}
        isPasswordResetLoading={isPasswordResetLoading}
        isChangeRoleLoading={isChangeRoleLoading}
      />
    </div>
  );
};

export default TableCardWithBanner;
