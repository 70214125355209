import Colors from './Colors';
import React from 'react';
import { createUseStyles } from 'react-jss';
import SelectSearch, { SelectSearchOption } from 'react-select-search';

const useStyles = createUseStyles({
  selectSearchWrapper: {
    '& .select-search': {
      height: 40,
      position: 'relative',
      '& .select-search__input': {
        boxSizing: 'border-box',
        display: 'block',
        borderRadius: 3,
        border: `1px solid ${Colors.MEDIUM_GRAY}`,
        outline: 'none',
        padding: 10,
        position: 'absolute',
        width: 'calc(100% - 20px)',
        zIndex: 1,
        '&:disabled': {
          backgroundColor: Colors.LIGHT_GRAY,
        },
      },
      '& .select-search__select': {
        display: 'block',
        position: 'absolute',
        top: 38,
        width: '100%',
        zIndex: 2,
        '& > .select-search__options': {
          border: `1px solid ${Colors.MEDIUM_GRAY}`,
          borderTop: 'none',
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          maxHeight: 300,
          overflowY: 'auto',
        },
        '& .select-search__options': {
          background: Colors.WHITE,
          display: 'block',
          listStyle: 'none',
          margin: 0,
          padding: 0,
          width: 'calc(100% - 22px)',
          '& .select-search__option': {
            marginLeft: 0,
          },
        },
      },
      '& .select-search__group': {
        border: 0,
        '& .select-search__option': {
          paddingLeft: 20,
        },
      },
      '& .select-search__group-header': {
        color: Colors.MEDIUM_GRAY,
        fontSize: '12px',
        padding: 10,
      },
      '& .select-search__option': {
        background: 'none',
        border: 'none',
        display: 'block',
        fontSize: '12px',
        padding: 10,
        textAlign: 'left',
        width: '100%',
        '&:hover': {
          backgroundColor: Colors.MAIN_BLUE,
          color: Colors.WHITE,
          display: 'block',
          width: '100%',
        },
      },
      '&.has-focus': {
        '& .select-search__input': {
          border: `2px solid ${Colors.MAIN_BLUE}`,
          padding: 9,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
  },
});

type SearchableDropdownProps = {
  options: SelectSearchOption[];
  disabled?: boolean;
  disableSearch?: boolean;
  onChange?: (val: string) => void;
  placeholder?: string;
  selectedValue?: string;
  width: number;
};

const allMatch = (haystack: string, needle: string) => {
  const splitAndNormalize = (input: string) => {
    const lowerCaseParts = input
      .split(/[^a-zA-Z0-9]/)
      .filter((ea) => !!ea)
      .map((ea) => ea.toLowerCase());
    const obj = lowerCaseParts.reduce(
      (prev, cur) => ({
        ...prev,
        [cur]: true,
      }),
      {}
    );
    return Object.keys(obj);
  };
  const needleParts = splitAndNormalize(needle);
  const haystackParts = splitAndNormalize(haystack);
  const used: { [key in string]: boolean } = {};
  return needleParts.every((n) =>
    haystackParts.some((h) => {
      if (used[h]) {
        return false;
      }
      if (h.indexOf(n) >= 0) {
        used[h] = true;
        return true;
      }
      return false;
    })
  );
};

const SearchableDropdown: React.FunctionComponent<SearchableDropdownProps> = ({
  options,
  disabled,
  disableSearch,
  onChange,
  placeholder,
  selectedValue,
  width,
}: SearchableDropdownProps) => {
  const classes = useStyles();
  return (
    <div className={classes.selectSearchWrapper} style={{ width: `${width}px` }}>
      <SelectSearch
        disabled={!!disabled}
        options={options}
        filterOptions={(items) => {
          return (searchValue) => {
            if (searchValue.length === 0) {
              return options;
            }

            const out: SelectSearchOption[] = [];
            items.forEach((it) => {
              if (it.type === 'group') {
                const children = (it.items || []).filter((c) => allMatch(it.name + ' ' + c.name, searchValue));
                if (children.length) {
                  out.push({
                    ...it,
                    items: children,
                  });
                }
              } else {
                if (allMatch(it.name, searchValue)) {
                  out.push(it);
                }
              }
            });
            return out;
          };
        }}
        placeholder={placeholder}
        value={selectedValue}
        search={!disableSearch}
        onChange={(val: unknown) => {
          onChange && onChange(val as string);
        }}
      />
    </div>
  );
};

export default SearchableDropdown;
