import { useMemo } from 'react';

import { TableHighlight, TableHighlightFragmentFragment } from '../../../generated/graphql.tsx';

export const useTempHighlightCalc = (highlights: TableHighlight[] = [], isLoading: boolean, amount?: number) => {
  const getAmount = (value: string): number => parseFloat(value.replace(/[^.0-9]/g, ''));

  return useMemo(() => {
    let tableHighlights: TableHighlightFragmentFragment[] = highlights.length
      ? highlights
      : [
          { label: '', type: '', value: '' },
          { label: '', type: '', value: '' },
        ];
    if (amount) {
      const selectedAmount = getAmount(amount.toString());
      const totalAmount = ['TOTAL_REVENUE_RECOGNIZED', 'TOTAL_AMOUNT'];
      const totalCount = ['TRANSACTIONS_PROCESSED', 'PAYMENT_COUNT'];
      const totalRevenueRecognizedValue = getAmount(
        tableHighlights.find((highlight) => totalAmount.includes(highlight.type))?.value ?? '0'
      );
      const transactionsProcessedValue = getAmount(
        tableHighlights.find((highlight) => totalCount.includes(highlight.type))?.value ?? '0'
      );

      const percetage = Math.ceil((selectedAmount / totalRevenueRecognizedValue) * 100);
      tableHighlights = tableHighlights.map((highlight) => {
        if (totalAmount.includes(highlight.type)) {
          return { ...highlight, value: `$${amount}`, label: highlight.label };
        } else if (totalCount.includes(highlight.type)) {
          return {
            ...highlight,
            value: Math.ceil((transactionsProcessedValue * percetage) / 100),
            label: highlight.label,
          };
        }
        return highlight;
      }) as TableHighlightFragmentFragment[];
    }

    return tableHighlights.map((highlight) => ({
      header: highlight.label || '',
      isLoading,
      value: highlight.value || '',
    }));
  }, [highlights, isLoading, amount]);
};
