import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';

import { CancelLink } from '../utils/router/cancelLinks';

const hostName = document !== null && document !== undefined ? document.location.host : 'localhost:3434';
const isSecure = document !== null && document !== undefined && document.location.protocol === 'https:';

export const cancelLink = new CancelLink();

const contextLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
  },
}));

const httpLink = new HttpLink({
  uri: `${isSecure ? 'https' : 'http'}://${hostName}/graphql`,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([cancelLink, contextLink, httpLink]),
});

export default client;
