import React from 'react';

import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import Colors from './common/Colors';
import { useSharedStyles } from '../utils/CssUtil';

const useStyles = createUseStyles({
  contentHeaderPill: {
    display: 'inline-block',
    lineHeight: '1.2',
    position: 'relative',
    '&:after': {
      content: '"»"',
      display: 'block',
      float: 'left',
      margin: '0 10px',
    },
    '&:last-child:after': {
      display: 'none',
    },
  },
  contentHeaderPillContent: {
    display: 'block',
    float: 'left',
    maxWidth: '800px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  contentHeaderPillLink: {
    display: 'block',
    float: 'left',
    maxWidth: '350px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    borderBottom: `1px dotted ${Colors.MEDIUM_GRAY}`,
    color: Colors.MEDIUM_GRAY,
  },
});

export type TSectionNavHeaderSection = { label?: string; url?: string | null };

type SectionNavHeaderProps = {
  isLastItemClickable?: boolean;
  sections: TSectionNavHeaderSection[];
};

const SectionNavHeader: React.FunctionComponent<SectionNavHeaderProps> = ({
  sections,
  isLastItemClickable,
}: SectionNavHeaderProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  return (
    <div className={sharedClasses.contentHeader}>
      {sections
        .filter((ea) => !!ea.label)
        .map((s, idx) => {
          return (
            <span className={classes.contentHeaderPill} key={`content-header-pill-${idx}`}>
              {idx === sections.length - 1 && !isLastItemClickable ? (
                <span className={classes.contentHeaderPillContent} title={s.label}>
                  {s.label}
                </span>
              ) : (
                <Link className={classes.contentHeaderPillLink} to={s.url || '#'} title={s.label}>
                  {s.label}
                </Link>
              )}
            </span>
          );
        })}
    </div>
  );
};

export default SectionNavHeader;
