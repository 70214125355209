import { TNavItem } from '../../../src/types/BaseTypes.ts';
import {
  ALWAYS_HIDDEN,
  COMPANY_WITH_TRANSACTION_SUPPORT,
  HIDDEN_FOR_NON_BANK_RECON_COMPANIES,
  SHOW_TRANSACTION,
} from './constants.ts';

export const getMatchedPath = () => {
  let path = document.location.pathname;
  let matchedPath = '';
  const prefix = '/app';
  if (path.indexOf(prefix) === 0) {
    path = path.substring(prefix.length);
    if (path.length) {
      if (path.startsWith('/')) {
        path = path.substring(1);
        matchedPath = path.split('/')[0];
      }
    }
  }

  return matchedPath;
};

const shouldHideForScribd = (companyName: string, navItemType: TNavItem): boolean => {
  return companyName.toLowerCase().includes('scribd') && navItemType === TNavItem.REPORTS;
};

const shouldShowForTransactionSupport = (companyName: string, navItemType: TNavItem): boolean => {
  return COMPANY_WITH_TRANSACTION_SUPPORT.includes(companyName.toLowerCase()) && SHOW_TRANSACTION.includes(navItemType);
};

const shouldAlwaysHide = (isRealCustomerCompany: boolean, navItemType: TNavItem): boolean => {
  return isRealCustomerCompany && ALWAYS_HIDDEN.includes(navItemType);
};

const shouldHideForNonBankReconCompanies = (
  isRealCustomerCompany: boolean,
  companyName: string,
  navItemType: TNavItem
): boolean => {
  return (
    isRealCustomerCompany &&
    !companyName.toLowerCase().includes('payzen') &&
    !companyName.toLowerCase().includes('gabb') &&
    !companyName.toLowerCase().includes('captionsai') &&
    !companyName.toLowerCase().includes('scribd') &&
    HIDDEN_FOR_NON_BANK_RECON_COMPANIES.includes(navItemType)
  );
};

const shouldHideReconciliationForNonPayzen = (companyName: string, navItemType: TNavItem): boolean => {
  return !companyName.toLowerCase().includes('payzen') && navItemType === TNavItem.RECONCILIATION;
};

export const shouldShowNavItem = (
  isRealCustomerCompany: boolean,
  companyName: string,
  navItemType: TNavItem
): boolean => {
  if (shouldHideForScribd(companyName, navItemType)) return false;
  if (shouldShowForTransactionSupport(companyName, navItemType)) return true;
  if (shouldAlwaysHide(isRealCustomerCompany, navItemType)) return false;
  if (shouldHideForNonBankReconCompanies(isRealCustomerCompany, companyName, navItemType)) return false;
  if (shouldHideReconciliationForNonPayzen(companyName, navItemType)) return false;

  return true;
};
