import Colors from './Colors';
import React from 'react';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tooltip: {
    position: 'relative',
    display: 'inline-block',
    borderBottom: '1px dotted black',
    cursor: 'pointer',

    '& > span': {
      visibility: 'hidden',
      width: 'max-content',
      display: 'flex',
      flexWrap: 'nowrap',
      textAlign: 'left',
      padding: '16px 16px',
      borderRadius: '6px',
      backgroundColor: Colors.WHITE,
      position: 'absolute',
      zIndex: '1',
      opacity: '0',
      transition: 'opacity .6s',
      boxShadow: `0px 0px 3px ${Colors.SHADOW}`,
      fontWeight: 'normal',
      '& > ul': {
        margin: '0',
        paddingInlineStart: '20px',
      },
    },

    '& > span:after': {
      content: ' ',
      position: 'absolute',
      bottom: '100%' /* At the top of the tooltip */,
      left: '50%',
      marginLeft: '-5px',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent black transparent',
      visibility: 'visible',
    },

    '&:hover > span': {
      visibility: 'visible',
      opacity: '1',
    },
  },
  tooltipBottom: {
    top: '135%',
    left: '50%',
    marginLeft: '-60px',

    '&:after': {
      content: '',
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      marginLeft: '-5px',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent #555 transparent',
    },
  },
});

interface TooltipProps {
  children: JSX.Element | string;
  content?: JSX.Element | string;
}
const Tooltip: React.FunctionComponent<TooltipProps> = ({ children, content }) => {
  const classes = useStyles();

  return (
    <div className={classes.tooltip}>
      {children}
      <span className={classnames('tooltip-content', classes.tooltipBottom)}>{content}</span>
    </div>
  );
};

export default Tooltip;
