import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';

export const stringFilterHeaderClick = (
  column: Tabulator.ColumnComponent,
  updateIsStringColFilterVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setStringFilterParams: React.Dispatch<
    React.SetStateAction<
      | {
          column?: Tabulator.ColumnComponent | undefined;
          label?: string | undefined;
          field?: string | undefined;
        }
      | undefined
    >
  >
) => {
  const colDef = column.getDefinition();
  setStringFilterParams({
    column,
    label: colDef.title,
    field: column.getField(),
  });
  updateIsStringColFilterVisible(true);
};
