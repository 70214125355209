import React, { useEffect } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import Banner from './Banner';
import Colors from './common/Colors';
import DataGrid from './data_grid/DataGrid';
import ErrorMessage from './common/ErrorMessage';
import ExportButton from './CSVExportButton';
import LoaderAnimation from './common/LoaderAnimation';
import { ReconciliationURLParam } from '../../src/types/ReconciliationTypes';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import { useSharedStyles } from '../utils/CssUtil';
import { MissingDataReportQuery, MissingDataReportQueryVariables } from '../generated/graphql';
import { TableHighlightFragment, TransactionCellRowFragment } from '../apollo/GraphQLFragments';
import { flattenData, getColumns } from './data_grid/utils/ColumnUtils';

const QUERY = gql`
  ${TransactionCellRowFragment}
  ${TableHighlightFragment}
  query MissingDataReport(
    $primary_integration_id: String
    $secondary_integration_id: String
    $page: Int
    $page_size: Int
  ) {
    missingDataReport(
      primary_integration_id: $primary_integration_id
      secondary_integration_id: $secondary_integration_id
      page: $page
      page_size: $page_size
    ) {
      rows {
        ...TransactionCellRowFragment
      }
      highlights {
        ...TableHighlightFragment
      }
      pageCount
      currentPage
      pageSize
      timezoneLocation
    }
  }
`;

const useDataExceptionStyles = createUseStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 0',
    minHeight: '96px',
  },
  headerLeft: {
    width: '400px',

    '& h2': {
      fontSize: '22px',
      fontWeight: 'bold',
      margin: '5px 0 15px',
    },
  },
  tableHeader: {
    backgroundColor: Colors.FAINT_BLUE,
    border: '3px 3px 0 0',
    fontSize: '13px',
    fontWeight: 'bold',
    padding: '5px 7px',
    textAlign: 'center',
  },
  horizontallyScrollableContent: {
    minHeight: '480px',
    overflowX: 'scroll',
    paddingBottom: '20px', // to show the scroll bar
  },
  exceptionsTable: {
    '&.tabulator': {
      fontSize: '13px',
    },
  },
  loaderContainer: {
    textAlign: 'center',
    marginTop: '150px',
    marginRight: '85px',
    display: 'flex',
    justifyContent: 'center',
  },
});
const DataExceptionsWithBanner: React.FunctionComponent = () => {
  const classes = useDataExceptionStyles();
  const sharedClasses = useSharedStyles({});
  const [params, setParams] = useSearchParams();
  const primaryIntegrationId = params.get('primary_integration_id');
  const secondaryIntegrationId = params.get('secondary_integration_id');
  const pageNumber = params.get('p');
  const pageSize = params.get('ps');
  const queryParams = {
    primaryIntegrationId: primaryIntegrationId || null,
    secondaryIntegrationId: secondaryIntegrationId || null,
    page: (pageNumber ? Number(pageNumber) : 1) || 1,
    pageSize: (pageSize ? Number(pageSize) : 50) || 50,
  };
  const {
    loading: isLoading,
    error,
    data,
    refetch,
  } = useQuery<MissingDataReportQuery, MissingDataReportQueryVariables>(QUERY, {
    variables: queryParams,
  });
  useEffect(() => {
    refetch({
      page: (pageNumber ? Number(pageNumber) : 1) || 1,
      page_size: (pageSize ? Number(pageSize) : 50) || 50,
    });
  }, [pageNumber, pageSize]);

  const timezoneLocation = data?.missingDataReport?.timezoneLocation;
  const pagerVariables = {
    currentPage: data?.missingDataReport?.currentPage || 1,
    pageCount: data?.missingDataReport?.pageCount || 1,
    pageSize: (pageSize ? Number(pageSize) : 50) || 50,
    refetchPage: (page: number, pageSize: number) => {
      setParams({
        [ReconciliationURLParam.PAGE]: String(page),
        [ReconciliationURLParam.PAGE_SIZE]: String(pageSize),
      });
    },
  };

  const columns = data?.missingDataReport?.rows?.[0]
    ? [
        {
          title: 'Source System Creation Date',
          field: 'date',
          minWidth: 90,
        } as TabulatorTypes.ColumnDefinition,
        ...getColumns(data.missingDataReport.rows[0], false, sharedClasses, timezoneLocation),
      ]
    : [];
  columns.forEach((col) => {
    col.headerSort = false;
  });
  const flattenedData = data?.missingDataReport?.rows ? flattenData(data?.missingDataReport.rows, 'date') : [];

  const options: TabulatorTypes.Options = {
    data: flattenedData,
    sortMode: 'remote',
    persistence: undefined,
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <div className={sharedClasses.cardTabs}>
              <div className={sharedClasses.cardTab}>
                <a href="/app/reconciliation">Reconciliations</a>
              </div>
              <div className={sharedClasses.cardTab}>Missing Data</div>
            </div>
          </div>
        </div>
        <div className={sharedClasses.content}>
          {isLoading ? (
            <div className={classes.loaderContainer}>
              <LoaderAnimation height={80} />
            </div>
          ) : (
            <>
              <div className={sharedClasses.contentHeaderWrapper}>
                <div className={sharedClasses.contentHeaderUtils}>
                  <ExportButton type={TCsvExportType.MISSING_DATA_REPORT} searchParams={params} enabled />
                </div>
              </div>
              <div className={classes.tableHeader}>Exceptions</div>
              <div className={classes.horizontallyScrollableContent}>
                <DataGrid
                  className={classes.exceptionsTable}
                  columns={columns}
                  options={options}
                  shouldEnablePager={true}
                  pagerVariables={pagerVariables}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default DataExceptionsWithBanner;
