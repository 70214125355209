export enum TCustodialStatus {
  SETTLED_IN_BANK = 'Settled in bank',
  DEPOSIT_PENDING = 'Deposit pending',
  AVAILABLE_FOR_WITHDRAWAL = 'Available for withdrawal',
  PAYMENT_IN_TRANSIT = 'Payment in transit',
}

export enum TReconciliationExceptionFilterOption {
  NONE = 'None',
  BANKING_ONLY = 'Banking only',
  DATA_ONLY = 'Data only',
  BOTH = 'Both',
}

export enum TBankReconciliationStatusDisplayLabel {
  RECONCILED = 'Reconciled',
  EXCEPTION = 'Exception',
  PENDING = 'Pending',
}

export enum ReconciliationURLParam {
  VENDOR = 'v',
  CUSTODIAN = 'c',
  CUSTODIAL_STATUS = 'cs',
  EXTERNAL_BANK_ACCOUNT_ID = 'eaid',
  PAYOUT_ID = 'po',
  VENDOR_EXTERNAL_ID = 'vei',
  EXCEPTION_FILTER_OPTION = 'efo',
  PAGE = 'p',
  PAGE_SIZE = 'ps',
  START_DATE_STRING = 'sd',
  END_DATE_STRING = 'ed',
}
