import React, { ReactElement } from 'react';

import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    marginBottom: '15px',

    '& > div:first-child': {
      fontWeight: 'bold',
      minWidth: '200px',
    },

    '&:last-child': {
      marginBottom: '0',
    },
  },
});

type KeyValue = {
  key: ReactElement | string | number;
  value: ReactElement | string | number;
};

type KeyValueListProps = {
  keyValuePairs: KeyValue[];
};

const KeyValueList: React.FunctionComponent<KeyValueListProps> = ({ keyValuePairs }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {keyValuePairs.map(({ key, value }, idx) => (
        <div className={classes.row} key={`key-value-${idx}`}>
          <div>{key}</div>
          <div>{value}</div>
        </div>
      ))}
    </div>
  );
};

export default KeyValueList;
