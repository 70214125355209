import React from 'react';

import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { useSearchParams } from 'react-router-dom';

import { ApolloQueryResult, gql, useQuery } from '@apollo/client';

import Banner from '../Banner';
import DataGrid from '../data_grid/DataGrid';
import ErrorMessage from '../common/ErrorMessage';
import { GetPriceListQuery } from '../../generated/graphql';
import LoaderAnimation from '../common/LoaderAnimation';
import MenuDropdown from '../common/MenuDropdown';
import { PriceListItemFragment } from '../../apollo/GraphQLFragments';
import StringUtil from '../../../src/utils/StringUtil';
import { useSharedStyles } from '../../utils/CssUtil';
import {
  CurrencyToRawToDisplayPowerDifference,
  CurrencyToSymbol,
  IntervalUnitToUnitDisplay,
  TCurrency,
  TIntervalUnit,
} from '../../../src/types/BaseTypes';
import { DataGridAjaxParams, DataGridRow } from '../../../src/types/DataGridTypes';
import FormatUtil, { DateFormatYearMonthDate, DateFormatYearMonthDateTime } from '../../../src/utils/FormatUtil';
import {
  PriceListItemSaleType,
  PriceListItemState,
  PriceListItemTypeToDisplay,
} from '../../../src/types/PriceListTypes';

const useStyles = createUseStyles({
  priceListTable: {
    '&.tabulator': {
      fontSize: '13px',
      '& .tabulator-tableholder': {
        overflow: 'hidden',
      },
    },
  },
});

const QUERY = gql`
  ${PriceListItemFragment}
  query GetPriceList($sortField: String, $sortDir: String) {
    priceList(sortField: $sortField, sortDir: $sortDir) {
      items {
        ...PriceListItemFragment
      }
    }

    currentUser {
      company {
        isRealCustomerCompany
      }
    }
  }
`;

const PriceListCard: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const getColumns = () => {
    return [
      {
        title: 'Numeral ID',
        field: 'id',
        minWidth: 160,
        widthGrow: 1.5,
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
      },
      {
        title: 'SKU',
        field: 'sku',
        widthGrow: 0.7,
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
      },
      {
        title: 'Product code',
        field: 'productCode',
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
      },
      {
        title: 'Product name',
        field: 'name',
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
      },
      {
        title: 'Description',
        field: 'description',
        minWidth: 100,
        widthGrow: 3,
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
      },
      {
        title: 'Product family',
        field: 'productFamily',
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
      },
      {
        title: 'Sale type',
        field: 'saleType',
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
        formatter: (cell) => {
          return PriceListItemTypeToDisplay[cell.getValue() as PriceListItemSaleType] || 'N/A';
        },
      },
      {
        title: 'currency',
        field: 'currency',
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
        visible: false,
      },
      {
        title: 'List price',
        field: 'amount',
        widthGrow: 1.3,
        cssClass: classnames(sharedClasses.contentCellTextWrapped, sharedClasses.contentCellRightAlign),
        formatter: (cell) => {
          const row = cell.getRow();
          const currencyValue = row.getCell('currency').getValue() || TCurrency.USD;

          const currencyFormat = {
            currencySymbol: CurrencyToSymbol[currencyValue as TCurrency],
            forceDecimalPlaces: CurrencyToRawToDisplayPowerDifference[currencyValue as TCurrency],
            rawToDisplayPowerDifference: CurrencyToRawToDisplayPowerDifference[currencyValue as TCurrency],
          };
          return cell.getValue() ? FormatUtil.formatCurrencyAmount(cell.getValue(), currencyFormat) : 'Variable';
        },
      },
      {
        title: 'termIntervalUnit',
        field: 'termIntervalUnit',
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
        visible: false,
      },
      {
        title: 'Term',
        field: 'termIntervalLength',
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
        formatter: (cell) => {
          const row = cell.getRow();
          const saleTypeValue = row.getCell('saleType').getValue();
          const termIntervalUnitValue = row.getCell('termIntervalUnit').getValue();

          let term = 'N/A';
          if (saleTypeValue === PriceListItemSaleType.DEFERRED && cell.getValue() && termIntervalUnitValue) {
            const unit = IntervalUnitToUnitDisplay[termIntervalUnitValue as TIntervalUnit];
            term = StringUtil.getCountWordString(cell.getValue(), unit);
          }
          return term;
        },
      },
      {
        title: 'Active?',
        field: 'state',
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
        formatter: (cell) => {
          return cell.getValue() === PriceListItemState.ACTIVE ? 'Y' : 'N';
        },
      },
      {
        title: 'Effective Date',
        field: 'effectiveDate',
        minWidth: 85,
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
        formatter: (cell) => {
          return cell.getValue() ? moment(cell.getValue()).format(DateFormatYearMonthDate) : '';
        },
      },
      {
        title: 'Imported from',
        field: 'vendor',
        minWidth: 95,
        widthGrow: 1.1,
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
        formatter: (cell) => {
          return renderToString(
            <span>
              <span
                className={sharedClasses.cellPill}
                data-pill-category="payment-salesforce"
                data-pill-value={cell.getValue()}
              >
                {cell.getValue()}
              </span>
            </span>
          );
        },
      },
      {
        title: 'Last updated at',
        field: 'updatedAt',
        minWidth: 100,
        cssClass: classnames(sharedClasses.contentCellTextWrapped),
        formatter: (cell) => {
          return moment(cell.getValue()).format(DateFormatYearMonthDateTime);
        },
      },
    ] as TabulatorTypes.ColumnDefinition[];
  };
  const classes = useStyles();
  const { loading: isLoading, error, data, refetch: priceListRefetch } = useQuery<GetPriceListQuery>(QUERY);
  const [params] = useSearchParams();
  const parsedActiveParam = params ? params.get('id') : undefined;

  const columns = getColumns();

  const options: TabulatorTypes.Options = {
    selectable: true,
    sortMode: 'remote',
    ajaxURL: '/graphql',
    ajaxConfig: {
      method: 'POST',
    },
    ajaxParams: {},
    // ajaxRequestFunc requires ajaxURL, ajaxConfig, and ajaxParams to work
    ajaxRequestFunc: (_url: string, _config: TabulatorTypes.AjaxConfig, params: DataGridAjaxParams) => {
      return priceListRefetch({ sortField: params?.sort?.[0]?.field, sortDir: params?.sort?.[0]?.dir });
    },
    ajaxResponse: function (_url: string, _params: unknown, response: ApolloQueryResult<GetPriceListQuery>) {
      return (
        response?.data?.priceList.items?.map((row) => {
          // __typename must be removed in order to render the datagrid correctly
          const { __typename, ...rest } = row;
          return rest as DataGridRow;
        }) || []
      );
    },
    rowFormatter: (row: TabulatorTypes.RowComponent) => {
      const data = row.getData();
      if (data.id === parsedActiveParam) {
        row.select();
      }
    },
    persistence: undefined,
  };

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
        <div className={sharedClasses.content}>
          <div className={sharedClasses.contentHeaderWrapper}>
            <div className={sharedClasses.contentHeader}>
              <div className={sharedClasses.cardTabs}>
                <div className={classnames(sharedClasses.cardTab, sharedClasses.cardTabSelected)}>
                  <span>Price List</span>
                </div>
              </div>
            </div>
            <div className={sharedClasses.contentHeaderUtils}>
              {isLoading || data?.currentUser.company?.isRealCustomerCompany ? undefined : (
                <MenuDropdown
                  items={[
                    {
                      label: 'Product',
                      href: '/app/price-list/new-product',
                    },
                    {
                      label: 'Bundle',
                      href: '/app/price-list/new-bundle',
                    },
                  ]}
                >
                  New
                </MenuDropdown>
              )}
            </div>
          </div>
          {isLoading ? (
            <div className={sharedClasses.contentLoaderContainer}>
              <LoaderAnimation height={80} />
            </div>
          ) : (
            <div style={{ overflowX: 'scroll' }}>
              <DataGrid className={classes.priceListTable} columns={columns} options={options} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceListCard;
