import React, { createContext, useCallback, useMemo, useState } from 'react';

import { SidebarWidth } from '../constants/sideBarWidth';
import { SideBarContextProps, SideBarProviderProps } from './types';

export type SideBarTypes = 'DEFAULT' | 'PIVOT' | 'NONE';

export const SideBarContext = createContext<SideBarContextProps>({
  isDragging: false,
  onSetIsDragging: () => {},
  sideBarWidth: SidebarWidth.DEFAULT,
  showSideBarType: 'DEFAULT',
  onShowSideBarType: () => {},
  onResetSideBar: () => {},
});

export const SideBarProvider = ({ children }: SideBarProviderProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [showSideBarType, setShowSideBarType] = useState<SideBarTypes>('DEFAULT');

  const onResetSideBar = useCallback(() => setShowSideBarType('DEFAULT'), []);

  const value = useMemo(
    () => ({
      isDragging,
      showSideBarType,
      onResetSideBar,
      onSetIsDragging: setIsDragging,
      onShowSideBarType: setShowSideBarType,
      sideBarWidth: SidebarWidth[showSideBarType],
    }),
    [isDragging, onResetSideBar, showSideBarType]
  );

  return <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>;
};

export const useSideBarContext = () => React.useContext(SideBarContext);
