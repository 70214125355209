// this is needed so that webpack will add the CSP nonce to all generated script / style tags
// without this nonce, our script would block
const cspTag = document.querySelector('meta[property="csp-nonce"]');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
__webpack_nonce__ = cspTag ? cspTag.content : undefined;

// Must use `require` here instead of `import`; `import` is auto hoisted to the top, which
// is undesirable since we want nonce to be set first before any subsequent files are
// imported / executed. This is the only place on Numeral's front-end that will use require
require('./start');
