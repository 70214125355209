import { createUseStyles } from 'react-jss';

import Colors from '../common/Colors.tsx';
import { SidebarWidth } from '../../features/sideBar/constants/sideBarWidth.ts';
import { TOGGLE_WIDTH_PX } from './constants.ts';

export const useStyles = createUseStyles({
  sidebar: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    backgroundColor: Colors.WHITE,
    boxShadow: '1px 0 1px rgba(0,0,0,0.02)',
    fontFamily: "'Akshar', sans-serif",
    minHeight: '100vh',
    minWidth: `${SidebarWidth.DEFAULT}px`,
    position: 'relative',
    width: `${SidebarWidth.DEFAULT}px`,
  },
  sidebarWithAnimation: {
    transition: 'margin-left 0.4s',
  },
  sidebarCollapsed: {
    marginLeft: `-${SidebarWidth.DEFAULT - TOGGLE_WIDTH_PX}px`,

    '& $toggle': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,

      '& span': {
        marginLeft: '8px',
      },
    },
  },
  navBar: {
    fontSize: '18px',
    letterSpacing: '0.5px',
    margin: '0 12px 20px',
    padding: '0',
  },
  subtext: {
    bottom: 0,
    color: Colors.MEDIUM_GRAY,
    fontSize: '12px',
    fontWeight: 300,
    margin: 'auto 0 20px',
    padding: '5px',
    textAlign: 'center',
  },
  toggle: {
    backgroundColor: Colors.FAINT_BLUE,
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.01)',
    color: Colors.WHITE,
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '36px',
    right: 0,
    top: '32px',
    position: 'absolute',
    transition: 'background-color 0.1s',

    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',

    height: '36px',
    width: `${TOGGLE_WIDTH_PX}px`,

    '& span': {
      display: 'inline-block',
      marginLeft: '-2px',
    },

    '&:hover': {
      backgroundColor: Colors.MAIN_BLUE,
    },
  },
  reorderButton: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    width: 73,
    margin: '0 auto',
    boxSizing: 'border-box',
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: 4,
    backgroundColor: 'transparent',
    color: Colors.MEDIUM_GRAY,
    cursor: 'pointer',

    '&:hover': {
      borderColor: Colors.BLACK,
      color: Colors.BLACK,
    },
    '& $reorderButtonIconSelected': {
      display: 'inline-block',
    },

    '& $reorderButtonIconUnselected': {
      display: 'none',
    },
  },
  reorderButtonSelected: {
    borderColor: Colors.BLACK,
    color: Colors.BLACK,
  },
  reorderButtonIcon: {
    height: 14,
    width: 14,
  },
  reorderButtonIconSelected: {
    display: 'none',
  },
  reorderButtonIconUnselected: {},
  reorderButtonLabel: {
    fontFamily: 'Akshar',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '12px',
    paddingTop: 3,
  },
});
