import { CompanyReportSettings } from '../../../generated/graphql';
import { NavItemProps } from '../../../components/NavItem';

export const filterNavItemsByReportSetting = (companyReportSettings: CompanyReportSettings[]) => {
  const filterFn = (item: NavItemProps) => {
    const reportSettings = companyReportSettings?.find((report) => report.name === item.type);
    if ((item.secondaryItems || []).length > 0) {
      // Recursively filter secondary items
      item.secondaryItems = (item.secondaryItems || []).filter(filterFn);
    }
    if (!reportSettings) {
      return true;
    }
    return reportSettings.isEnabled;
  };

  // Use filterFn here to filter the passed item
  return filterFn;
};
