import { Classes } from 'jss';
import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';
import { DataGridRow } from '../DataGridTypes.ts';
import { CellPillFragmentFragment } from '../../../generated/graphql.tsx';
import { TOP_ROWS_TOGGLE_COUNT } from '../constants.ts/grid.ts';

export const cellTooltip = (cell: Tabulator.CellComponent, classes: Classes) => {
  const data = cell.getData() as DataGridRow;
  const rowPosition = cell.getRow().getPosition();
  const value = data?._cellData?.[cell.getField()]?.tooltip;
  const pills = (data?._cellData?.[cell.getField()]?.pills || []) as CellPillFragmentFragment[];

  if (value) {
    const cellElem = cell.getElement();
    cellElem.classList.add(classes.tooltipCell);
    if (pills.length > 0) {
      cellElem.classList.add(classes.pillsCell);
    }
    const tooltipOuter = document.createElement('div');
    tooltipOuter.classList.add('tooltip-outer');
    const tooltipInner = document.createElement('div');
    tooltipInner.classList.add(classes.tooltip);
    tooltipInner.innerText = value?.toString();
    if (rowPosition <= TOP_ROWS_TOGGLE_COUNT) {
      tooltipInner.classList.add('topRows');
    }
    tooltipOuter.appendChild(tooltipInner);
    cellElem.appendChild(tooltipOuter);
    return '';
  }

  const colDef = cell?.getColumn()?.getDefinition();
  const colFormatter = colDef?.formatter;
  if (typeof colFormatter === 'function') {
    if (pills?.length > 0) {
      return pills.map((pill) => pill.label).join(', ');
    }

    const hideTooltip = (colDef?.formatterParams as Tabulator.JSONRecord)?.hideTooltip;
    if (hideTooltip) {
      return '';
    }

    return colFormatter?.(cell, colDef?.formatterParams || {}, () => null);
  }

  return cell.getValue();
};
