import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

import Banner from './Banner';
import Colors from './common/Colors';
import { DateFormatYearMonthDateTime } from '../../src/utils/FormatUtil';
import PrettyJSON from './common/PrettyJSON';
import SectionNavHeader from './SectionNavHeader';
import SectionWithHeader from './common/SectionWithHeader';
import TabbedContainers from './common/TabbedContainers';
import { TransactionCellFragmentFragment } from '../generated/graphql.js';
import { useSharedStyles } from '../utils/CssUtil';
import { ActivitiesModal, ActivitiesTimeline } from './reconciliations/ReconciliationExceptionActivitiesTimeline';
import FormButton, { FormButtonStyle } from './common/FormButton';

const getFakeCharge = (id: string, amount: number, date: Date) => ({
  id,
  object: 'charge',
  amount,
  amount_captured: amount,
  amount_refunded: 0,
  application: null,
  application_fee: null,
  application_fee_amount: null,
  balance_transaction: `txn_${id.substring(3).split('').reverse().join('')}`,
  billing_details: 'REMOVED',
  calculated_statement_descriptor: null,
  captured: false,
  created: date.getTime() / 1000,
  currency: 'usd',
  customer: null,
  disputed: false,
  failure_balance_transaction: null,
  failure_code: null,
  failure_message: null,
  fraud_details: {},
  invoice: null,
  livemode: false,
  metadata: {},
  on_behalf_of: null,
  outcome: null,
  paid: true,
  payment_intent: null,
  payment_method: 'card_19yUNL2eZvKYlo2CNGsN6EWH',
  payment_method_details: 'REMOVED',
  receipt_email: null,
  receipt_number: null,
  redaction: null,
  refunded: false,
  refunds: null,
  review: null,
  shipping: null,
  source_transfer: null,
  statement_descriptor: null,
  statement_descriptor_suffix: null,
  status: 'succeeded',
  transfer_data: null,
  transfer_group: null,
});

const mockExceptions = [
  {
    id: 'pm-de92dfb85b7500e3',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-20 23:01:12',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-000213f14f8795f1&tt=MONTH`,
    activities: [
      {
        firstName: 'Yufei',
        lastName: 'Liu',
        comment: 'Resolved; backfill ran which added invoice',
      },
    ],
  },
  {
    id: 'pm-c16b12cc9c5052a4',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-20 23:01:10',
    amount: '$29.99',
    amountRaw: 2999,
    exception: 'Mismatched invoice amount ($19.99)',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-0025d4c78802e18c&tt=MONTH`,
    activities: [
      {
        firstName: 'Patrick',
        lastName: 'McCauley',
        comment:
          'Stripe is sending an orphaned Charge with incorrect invoice. One-off issue. Running a backfill job to fix.',
      },
      {
        firstName: 'Chris',
        lastName: 'Kim',
        comment: 'Investigating with engineering team, seems to be a data import issue',
      },
    ],
  },
  {
    id: 'pm-c206b32c9bc2522e',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-20 23:01:07',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-00a9e074edb15a2b&tt=MONTH`,
    activities: [
      {
        firstName: 'Patrick',
        lastName: 'McCauley',
        comment:
          'Stripe is sending an orphaned Charge with incorrect invoice. One-off issue. Running a backfill job to fix.',
      },
      {
        firstName: 'Juan',
        lastName: 'Gonzalez',
        comment: 'Looking into this now',
      },
    ],
  },
  {
    id: 'pm-98f54ad087af44b6',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-20 23:01:07',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'Mismatched invoice amount ($29.99)',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-00d0580d100042c1&tt=MONTH`,
    activities: [
      {
        firstName: 'Yufei',
        lastName: 'Liu',
        comment: 'Our pipeline is using the Payment amount which is correct. Can ignore.',
      },
    ],
  },
  {
    id: 'pm-c0ef3030c7317762',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-20 23:01:07',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'Mismatched invoice amount ($29.99)',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-00d6cc011d23a752&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-163f0b00bd90030e',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-20 23:01:07',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-0102e60114956ca9&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-dce4434c937fcc8b',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-19 23:02:13',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-017d8d4cd0c3e603&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-05c9f4f02b57eaca',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-19 23:02:13',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-01fbfc5cba6b5824&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-cc0c023e80497902',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-19 23:02:11',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-02215b956cf0883e&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-1054396608ccf4c5',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-19 23:02:11',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-0222f2605b04d731&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-c0f631140de08b75',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-19 23:02:10',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-02caea640a98ecf2&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-0a7633cdbaa9cb13',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-19 23:02:10',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-04280024673d0455&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-a38159fb00794ff0',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-19 23:02:05',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-042af88ef5a9e3ef&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-d420e6d06ba8d88b',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-19 23:02:05',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-052b89f89594c6f6&tt=MONTH`,
    activities: [],
  },
  {
    id: 'pm-cd68e2ffd5cf5664',
    objectType: 'Payment',
    source: 'stripe',
    importedAt: '2023-05-19 23:02:05',
    amount: '$99.99',
    amountRaw: 9999,
    exception: 'No matching invoice found',
    status: 'REVIEW REQUIRED',
    url: `/app/revenue/transaction?id=iv-059d0d691c5aad2f&tt=MONTH`,
    activities: [],
  },
];

const getMockExceptionRows = () => {
  return mockExceptions.map((item) => {
    const cells: (TransactionCellFragmentFragment & {
      isToggle?: boolean;
    })[] = [];

    cells.push({
      label: 'Object Type',
      isLeftAligned: true,
      content: item.objectType,
    });

    cells.push({
      label: 'Source',
      isLeftAligned: true,
      content: '',
      pills: [
        {
          label: item.source,
          category: `payment-${item.source}`,
          value: item.source,
        },
      ],
    });

    cells.push({
      label: 'Imported At',
      isLeftAligned: true,
      content: item.importedAt,
    });

    cells.push({
      label: 'Amount',
      isLeftAligned: false,
      content: item.amount,
    });

    cells.push({
      label: 'Exception',
      isLeftAligned: true,
      content: item.exception,
    });

    cells.push({
      label: 'Status',
      isLeftAligned: true,
      content: item.status,
      isToggle: true,
    });

    return {
      label: item.id,
      amount: item.amountRaw,
      date: moment(item.importedAt, DateFormatYearMonthDateTime).toDate(),
      activities: item.activities,
      cells,
      url: item.url,
    };
  });
};

const useStyles = createUseStyles({
  exceptionsCardContainter: {
    '& h4': {
      fontSize: '16px',
      marginTop: '20px',
      marginBottom: '20px',
    },
    '& h4:first-child': {
      marginTop: '5px',
    },
    '& a': {
      color: Colors.MAIN_BLUE,
    },
  },
  exceptionsCardHeader: {
    '& a': {
      color: Colors.MEDIUM_GRAY,
      textDecoration: 'underline',
    },
    '& a:visited': {
      color: Colors.MEDIUM_GRAY,
    },
  },
  jsonContainer: {
    backgroundColor: '#dee8ff',
    padding: '10px',
    borderRadius: '4px',
    margin: '20px',
    maxHeight: '150px',
    overflowY: 'scroll',
  },
  remediationOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
  },
  remediationOptionButton: {
    marginRight: '20px',
  },
  remediationOptionDescription: {
    color: Colors.MEDIUM_GRAY,
  },
  exceptionRowLink: {
    color: Colors.DARK_GRAY,
    '&:visited': {
      color: Colors.DARK_GRAY,
    },
  },
  exceptionRowExpansion: {
    height: 0,
    overflowY: 'hidden',
    transition: 'all 0.2s ease',
    '& > h4': {
      fontSize: '13px',
      margin: '10px 0',
    },
  },
  exceptionRowExpansionJsonHeader: {
    fontWeight: 500,
    marginLeft: 6,
  },
});

const Exceptions: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const exceptionRows = getMockExceptionRows();
  const firstExceptionRow = exceptionRows[0];

  const [curExpandedRow, setCurExpandedRow] = useState<number | undefined>(undefined);
  const [curModalItemId, setCurModalItemId] = useState<string | undefined>(undefined);
  const [incomingComment, setIncomingComment] = useState<string | undefined>();
  const [incomingExceptionStatus, setIncomingExceptionStatus] = useState<string | undefined>();

  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div
        className={classnames(
          sharedClasses.contentWrapper,
          sharedClasses.contentWrapperWithoutHighlights,
          classes.exceptionsCardContainter
        )}
      >
        <div className={classnames(sharedClasses.contentHeaderWrapper, classes.exceptionsCardHeader)}>
          <SectionNavHeader
            sections={[
              {
                label: '15 Exceptions (5 Unseen)',
              },
            ]}
          />
          <div className={sharedClasses.contentHeaderUtils}>
            Notification: Enabled (Email) <a href="#">Change</a>
          </div>
        </div>
        <div className={sharedClasses.content}>
          <table style={{ position: 'relative' }}>
            <thead>
              <tr>
                <td>{firstExceptionRow.label}</td>
                {firstExceptionRow.cells.map((cell, idx) => (
                  <td
                    key={`header-td-${idx}`}
                    className={classnames({ [sharedClasses.contentCellRightAlign]: !cell.isLeftAligned })}
                  >
                    {cell.label}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {exceptionRows.map((row, idx) => (
                <>
                  <tr key={`row-${idx}`}>
                    <td>
                      <Link className={classes.exceptionRowLink} to={row.url}>
                        {row.label}
                      </Link>
                    </td>
                    <>
                      {row.cells.map((cell, cellIdx) => (
                        <td
                          key={`cell-${idx}-${cellIdx}`}
                          className={classnames({
                            [sharedClasses.contentCellRightAlign]: !cell.isLeftAligned,
                            'content-cell-warning': cell.label === 'Status',
                          })}
                        >
                          {cell.isToggle ? (
                            <span
                              style={{ textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => setCurExpandedRow(curExpandedRow === idx ? undefined : idx)}
                            >
                              {cell.content}
                            </span>
                          ) : (
                            <>
                              {cell.content}
                              {(cell.pills || []).map((pill, pillIdx) => (
                                <span
                                  className={sharedClasses.cellPill}
                                  key={`pill-${idx}-${cellIdx}-${pillIdx}`}
                                  data-pill-category={pill.category}
                                  data-pill-value={pill.value}
                                >
                                  {pill.label}
                                </span>
                              ))}
                            </>
                          )}
                        </td>
                      ))}
                    </>
                  </tr>
                  <tr>
                    <td colSpan={7} style={{ height: 0 }}>
                      <div
                        className={classes.exceptionRowExpansion}
                        style={curExpandedRow === idx ? { height: 720, overflowY: 'scroll' } : {}}
                      >
                        <h4>Exception Events:</h4>
                        <ActivitiesTimeline
                          activeTabIndex={0}
                          data={{
                            reconciliationExceptionActivity: [
                              {
                                id: 'Activity Timeline',
                                reconciliationRuleId: '',
                                status: 'REVIEW_REQUIRED',
                                exceptionActivities: [
                                  ...row.activities.map((activity, idx) => ({
                                    activityType: 'COMMENT',
                                    incomingStatus: 'REVIEW_REQUIRED',
                                    timestampMs: moment(row.date)
                                      .add(8, 'hours')
                                      .add(37 * (row.activities.length - idx), 'minutes')
                                      .toDate()
                                      .getTime(),
                                    comment: activity.comment,
                                    user: {
                                      entity: {
                                        firstName: activity.firstName,
                                        lastName: activity.lastName,
                                        email: '',
                                        role: '',
                                      },
                                    },
                                  })),
                                  {
                                    activityType: 'STATUS_CHANGE',
                                    incomingStatus: 'REVIEW_REQUIRED',
                                    timestampMs: row.date.getTime(),
                                    comment: `Exception detected during import of ${row.label}: ${
                                      row.cells.find((cell) => cell.label === 'Exception')?.content
                                    }`,
                                  },
                                ],
                              },
                            ],
                          }}
                          onTabChange={() => null}
                          openModal={() => {
                            setIncomingComment('');
                            setIncomingExceptionStatus('REVIEW_REQUIRED');
                            setCurModalItemId(row.label);
                          }}
                        />
                        <h4>Raw Data:</h4>
                        <TabbedContainers
                          lightMode
                          initialTabIndex={0}
                          tabs={[
                            {
                              header: (
                                <>
                                  {row.label}
                                  <span
                                    className={classnames(
                                      sharedClasses.cellPill,
                                      classes.exceptionRowExpansionJsonHeader
                                    )}
                                    data-pill-category="stripe"
                                    data-pill-value="stripe"
                                  >
                                    stripe
                                  </span>
                                </>
                              ),
                              content: (
                                <div key={`json-${idx}`} className={classes.jsonContainer}>
                                  <PrettyJSON
                                    object={getFakeCharge(`ch_${row.label.split('-')[1]}`, row.amount, row.date)}
                                  />
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
        {/* Previous mock UI */}
        <div style={{ display: 'none' }}>
          <SectionWithHeader
            header={'Unable to Match Stripe Charge ch_ae923u7e901b813 to Known Product'}
            headerUtility={'Feb 13 2023, 3:25pm'}
          >
            <h4>What Happened?</h4>
            <div>
              A charge (<strong>ID: ch_ae923u7e901b813</strong>) imported from your Stripe integration{' '}
              <a href="/app/integrations">Stripe USA</a> cannot be matched to any known products in the{' '}
              <a href="/app/price-list">Price List</a>.
            </div>
            <h4>Raw Object</h4>
            <div>This charge object was imported on February 13, 2023, at 3:25 PM.</div>
            <div className={classes.jsonContainer}>
              <PrettyJSON object={getFakeCharge('ch_ae923u7e901b813', 7453, new Date(2023, 1, 13, 5, 25))} />
            </div>
            <h4>Accounting Impact</h4>
            <div>
              This charge and its amount (<strong>$74.53</strong>) has not been included in any Numeral automations, and
              is excluded from Revenue Recognition and Cash Reconciliation reports.
            </div>
            <h4>Remediations</h4>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} width={220} height={40}>
                  Select Product…
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Manually select a product from Price List for this charge
              </div>
            </div>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} width={220} height={40}>
                  Recognize One-off
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Mark this charge as ad-hoc and recognize it as Non-Deferred Revenue
              </div>
            </div>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} style={FormButtonStyle.NEUTRAL} width={220} height={40}>
                  Dismiss
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Dismiss this exception and continue to exclude this charge from Numeral
              </div>
            </div>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} style={FormButtonStyle.NEUTRAL} width={220} height={40}>
                  Contact Support
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Contact the Numeral Support team for additional options
              </div>
            </div>
          </SectionWithHeader>
          <SectionWithHeader
            header={'Stripe Charge ch_8571bf74da37518 Over-Applied to Invoice iv-8129da4805'}
            headerUtility={'Feb 10 2023, 6:05pm'}
          >
            <h4>What Happened?</h4>
            <div>
              A charge (<strong>ID: ch_8571bf74da37518</strong>) imported from your Stripe integration{' '}
              <a href="/app/integrations">Stripe USA</a> , with an amount of $9.99, is matched against an Invoice (
              <strong>
                <a href="#">ID: iv-8129da4805</a>
              </strong>
              ). But that invoice has only $0 due after credits and discounts are applied.
            </div>
            <h4>Raw Object</h4>
            <div>This charge object was imported on February 10, 2023, at 6:05 PM.</div>
            <div className={classes.jsonContainer}>
              <PrettyJSON object={getFakeCharge('ch_8571bf74da37518', 999, new Date(2023, 1, 10, 12, 5))} />
            </div>
            <h4>Accounting Impact</h4>
            <div>
              <p>
                This charge with amount (<strong>$9.99</strong>) is being recognized as Deferred Revenue, even though it
                represents an over-payment of an invoice. Any credits and discounts that applied to the invoice have
                already been recognized.
              </p>
              <p>This charge is recognized as cash since it resulted in an amount that settled into your PSP wallet.</p>
            </div>
            <h4>Remediations</h4>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} width={220} height={40}>
                  Exclude from Revenue
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Reverse recognition of this charge as Deferred Revenue and exclude it from all future revenue reporting
              </div>
            </div>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} style={FormButtonStyle.NEUTRAL} width={220} height={40}>
                  Dismiss
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Dismiss this exception and continue to recognize this charge as revenue
              </div>
            </div>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} style={FormButtonStyle.NEUTRAL} width={220} height={40}>
                  Contact Support
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Contact the Numeral Support team for additional options
              </div>
            </div>
          </SectionWithHeader>
          <SectionWithHeader
            header={'Unable to Match Stripe Charge ch_194ab92c8519844 to Known Product'}
            headerUtility={'Feb 9 2023, 5:15pm'}
          >
            <h4>What Happened?</h4>
            <div>
              A charge (<strong>ID: ch_194ab92c8519844</strong>) imported from your Stripe integration{' '}
              <a href="/app/integrations">Stripe Europe</a> cannot be matched to any known products in the{' '}
              <a href="/app/price-list">Price List</a>.
            </div>
            <h4>Raw Object</h4>
            <div>This charge object was imported on February 9, 2023, at 5:15 PM.</div>
            <div className={classes.jsonContainer}>
              <PrettyJSON object={getFakeCharge('ch_194ab92c8519844', 1599, new Date(2023, 1, 9, 11, 15))} />
            </div>
            <h4>Accounting Impact</h4>
            <div>
              This charge and its amount (<strong>$15.99</strong>) has not been included in any Numeral automations, and
              is excluded from Revenue Recognition and Cash Reconciliation reports.
            </div>
            <h4>Remediations</h4>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} width={220} height={40}>
                  Select Product…
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Manually select a product from Price List for this charge
              </div>
            </div>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} width={220} height={40}>
                  Recognize One-off
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Mark this charge as ad-hoc and recognize it as Non-Deferred Revenue
              </div>
            </div>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} style={FormButtonStyle.NEUTRAL} width={220} height={40}>
                  Dismiss
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Dismiss this exception and continue to exclude this charge from Numeral
              </div>
            </div>
            <div className={classes.remediationOption}>
              <div className={classes.remediationOptionButton}>
                <FormButton onClick={() => undefined} style={FormButtonStyle.NEUTRAL} width={220} height={40}>
                  Contact Support
                </FormButton>
              </div>
              <div className={classes.remediationOptionDescription}>
                Contact the Numeral Support team for additional options
              </div>
            </div>
          </SectionWithHeader>
        </div>
      </div>
      <ActivitiesModal
        activeExceptionId={curModalItemId || ''}
        closeModal={() => setCurModalItemId(undefined)}
        currentExceptionStatus={'REVIEW_REQUIRED'}
        dropdownItemLabels={['REVIEW_REQUIRED', 'UNDER_REVIEW', 'RESOLVED']}
        incomingComment={incomingComment}
        incomingExceptionStatus={incomingExceptionStatus}
        modalIsOpen={!!curModalItemId}
        onSubmit={() => null}
        setIncomingComment={setIncomingComment}
        setIncomingExceptionStatus={setIncomingExceptionStatus}
        submitIsDisabled={false}
      />
    </div>
  );
};

export default Exceptions;
