import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import { EntryRow } from '../../generated/graphql.tsx';

type GenericRow = {
  [field: string]: string | number | boolean | null | undefined;
};

export type GenericRowWithArray = {
  [field: string]: string | number | boolean | null | undefined | GenericRow[];
};

export type CellData = {
  amount: number;
  label: string;
  timespanStart: string;
  timespanType: string;
  url: string;
};

export type DataGridRow<T = DataGridChildren> = Partial<EntryRow> & {
  total?: string | number;
  _rowCssClass?: string;
  _cellData?: CellData;
  name?: string;
  isDraggable?: boolean;
  _cssClass?: { [key: string]: any };
  _children?: T[] | null;
  reportSection?: string;
};

export type DataGridChildren = {
  dimensionValue?: string;
  name?: string;
  shouldShowSumOfTimespans?: boolean;
  sumUrl?: string | null;
  total?: string;
  _cellData?: CellData[];
};

export enum SortDir {
  ASC = 'asc',
  DESC = 'desc',
}
export type DataGridAjaxParams = {
  sort?: { field: string; dir: SortDir }[];
  filter?: { field: string; type: TabulatorTypes.FilterType; value: string | number | undefined }[];
  page?: number;
  size?: number;
};

export enum DataGridFormatter {
  PILL_FORMATTER = 'pillFormatter',
  LINK_FORMATTER = 'linkFormatter',
  DATE_FORMATTER = 'dateFormatter',
  DATETIME_FORMATTER = 'dateTimeFormatter',
  CUSTOMCSS_FORMATTER = 'customCssFormatter',
}
