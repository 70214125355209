import React, { useState } from 'react';
import Modal from 'react-modal';
import { Spacer } from '../../../../components/Spacer.tsx';
import { Spinner } from '../../../../components/Spinner.tsx';
import { FormButtonV2 } from '../../../../components/common/FormButtonV2/FormButtonV2.tsx';
import { useStyles } from './styles.ts';
import { UpsertPivotSettingModalProps } from './types.ts';

export const UpsertPivotSettingModal = ({ onSave, onClose, isLoading }: UpsertPivotSettingModalProps) => {
  const classes = useStyles();
  const [pivotName, setPivotName] = useState('');
  const [isDefault, setIsDefault] = useState(false);

  const onSaveHandler = () => onSave(pivotName, isDefault);

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={{
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#EEF3F8',
          width: 500,
          height: 200,
          paddingTop: 32,
          borderRadius: 5,
          boxSizing: 'border-box',
        },
      }}
    >
      <div>
        <input
          type="type"
          name="pivotSetting"
          className={classes.textBox}
          placeholder="Start typing here..."
          onChange={({ target }) => setPivotName(target.value)}
        />
        <Spacer height={12} />
        <div className={classes.defaultPivotContainer}>
          <div className={classes.checkboxContainer}>
            <input className={classes.checkbox} type="checkbox" onChange={() => setIsDefault(!isDefault)} />
          </div>
          <div className={classes.defaultPivotLabel}>Make this my default view</div>
        </div>
        <Spacer height={28} />
        <div className={classes.formControls}>
          <FormButtonV2 expand height={40} variant={pivotName ? 'accented' : 'disabled'} onClick={onSaveHandler}>
            {isLoading ? <Spinner size={24} /> : <span>Save</span>}
          </FormButtonV2>
          <Spacer width={44} />
          <FormButtonV2 expand height={40} variant="neutral" onClick={onClose}>
            Close
          </FormButtonV2>
        </div>
      </div>
    </Modal>
  );
};
