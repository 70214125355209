import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core';

import ArrayUtil from '../../../src/utils/ArrayUtil';
import Banner from '../Banner';
import Colors from '../common/Colors';
import ErrorMessage from '../common/ErrorMessage';
import FormatUtil from '../../../src/utils/FormatUtil';
import LoaderAnimation from '../common/LoaderAnimation';
import StringUtil from '../../../src/utils/StringUtil';
import Table, { CombinedRowType } from '../common/Table';

import { PriceListItemFragment } from '../../apollo/GraphQLFragments';
import { createUseStyles } from 'react-jss';
import { useSharedStyles } from '../../utils/CssUtil';
import {
  CurrencyToRawToDisplayPowerDifference,
  CurrencyToSymbol,
  IntervalUnitToUnitDisplay,
  TCurrency,
  TIntervalUnit,
} from '../../../src/types/BaseTypes';
import FormButton, { FormButtonStyle } from '../common/FormButton';
import {
  GetPriceListForNewBundleQuery,
  MakeBundleMutation,
  MakeBundleMutationVariables,
  PriceListItemFragmentFragment,
  TransactionCellFragmentFragment,
} from '../../generated/graphql';
import {
  PriceListItemSaleType,
  PriceListItemState,
  PriceListItemTypeToDisplay,
} from '../../../src/types/PriceListTypes';
import ValidateBundle, { BundleInput } from '../../../src/validators/ValidateBundle';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
  },

  formContainer: {
    marginRight: '30px',
    width: '100%',
  },

  formRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },

  formLabel: {
    fontSize: '14px',
    width: '140px',
  },

  formInput: {
    width: '300px',
  },

  formButtonRow: {
    marginTop: '20px',

    '& button': {
      marginRight: '20px',
    },
  },

  droppableContainer: {
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    borderRadius: '5px',
    minHeight: '150px',
    minWidth: '600px',
    padding: '15px',
    transition: 'all 0.1s',

    '& table': {
      fontSize: '12px',

      '& td:first-child span': {
        backgroundColor: Colors.MEDIUM_GRAY,
        borderRadius: '2px',
        color: Colors.WHITE,
        cursor: 'pointer',
        display: 'inline-block',
        padding: '4px 8px 5px',
        transition: 'all 0.1s',

        '&:hover': {
          backgroundColor: Colors.LIGHT_GRAY,
        },
      },
    },
  },

  droppableContainerHighlight: {
    backgroundColor: Colors.LIGHT_GRAY,
    border: `1px solid ${Colors.DARK_GRAY}`,
  },

  droppableInfo: {
    fontSize: '12px',
    paddingBottom: '10px',
  },

  listContainer: {
    border: `1px solid ${Colors.FAINT_BLUE}`,
    borderRadius: '5px',
    fontSize: '12px',
    lineHeight: '16px',
    marginLeft: 'auto',
    minWidth: '220px',
    padding: '20px',
    paddingBottom: '10px',
  },

  listInfo: {
    color: Colors.FAINT_BLUE,
    lineHeight: '12px',
    paddingBottom: '10px',
  },

  listItem: {
    backgroundColor: Colors.FAINT_BLUE,
    borderRadius: '3px',
    boxShadow: '0 0 1px rgb(0, 0, 0, 0.075)',
    color: Colors.WHITE,
    cursor: 'grab',
    marginBottom: '15px',
    padding: '10px',
    transition: 'background 0.1s',
  },

  error: {
    color: Colors.WARNING_RED,
    fontSize: '14px',
  },
});

const QUERY = gql`
  ${PriceListItemFragment}
  query GetPriceListForNewBundle {
    priceList {
      items {
        ...PriceListItemFragment
      }
    }
  }
`;

const MUTATION = gql`
  mutation MakeBundle(
    $sku: String!
    $productCode: String!
    $name: String!
    $description: String!
    $amount: Float!
    $priceListItemIds: String!
  ) {
    makeBundle(
      sku: $sku
      productCode: $productCode
      name: $name
      description: $description
      amount: $amount
      priceListItemIds: $priceListItemIds
    ) {
      id
    }
  }
`;

const getRows: (
  items: PriceListItemFragmentFragment[],
  onRemoveItem: (id: string) => void,
  bundlePriceInput?: number
) => CombinedRowType[] = (items, onRemoveItem, bundlePriceInput) => {
  const countMonths = items.reduce(
    (count, item) => ((item.termIntervalLength || 0) > count ? item.termIntervalLength || 0 : count),
    1
  );

  const undiscountedTotalPrice = ArrayUtil.sum(items.map((item) => item.amount || 0));
  const bundlePrice = bundlePriceInput || undiscountedTotalPrice;
  const monthlyRecSums: { [key in string]: number } = {};

  const rows: CombinedRowType[] = items.map((item) => {
    const currencyFormat = {
      currencySymbol: CurrencyToSymbol[item.currency as TCurrency],
      forceDecimalPlaces: CurrencyToRawToDisplayPowerDifference[item.currency as TCurrency],
      rawToDisplayPowerDifference: CurrencyToRawToDisplayPowerDifference[item.currency as TCurrency],
    };

    const cells: TransactionCellFragmentFragment[] = [];

    cells.push({
      label: 'Numeral ID',
      isLeftAligned: true,
      content: item.id,
    });

    cells.push({
      label: 'SKU',
      isLeftAligned: true,
      content: item.sku,
    });

    cells.push({
      label: 'Product code',
      isLeftAligned: true,
      content: item.productCode,
    });

    cells.push({
      label: 'Product name',
      isLeftAligned: true,
      content: item.name,
    });

    cells.push({
      label: 'Product family',
      isLeftAligned: true,
      content: item.productFamily,
    });

    cells.push({
      label: 'List price',
      isLeftAligned: false,
      content: item.amount ? FormatUtil.formatCurrencyAmount(item.amount, currencyFormat) : '-',
    });

    const priceInBundle = ((item.amount || 0) / undiscountedTotalPrice) * bundlePrice;

    cells.push({
      label: 'Price in bundle',
      isLeftAligned: false,
      content: FormatUtil.formatCurrencyAmount(priceInBundle, currencyFormat),
    });

    ArrayUtil.range(countMonths).forEach((m) => {
      const label = 'Recognized in Month ' + (m + 1);
      const amount =
        (item.termIntervalLength || 1) > m
          ? item.termIntervalLength && item.termIntervalLength > 1
            ? priceInBundle / item.termIntervalLength
            : priceInBundle
          : undefined;
      if (amount) {
        monthlyRecSums[m] = (monthlyRecSums[m] || 0) + amount;
      }
      cells.push({
        label,
        isLeftAligned: false,
        content: amount !== undefined ? FormatUtil.formatCurrencyAmount(amount) : '-',
      });
    });

    return {
      label: '×',
      cells,
      labelOnClick: () => onRemoveItem(item.id),
    };
  });

  const currencyFormat = {
    currencySymbol: CurrencyToSymbol[TCurrency.USD],
    forceDecimalPlaces: CurrencyToRawToDisplayPowerDifference[TCurrency.USD],
    rawToDisplayPowerDifference: CurrencyToRawToDisplayPowerDifference[TCurrency.USD],
  };

  const cells: TransactionCellFragmentFragment[] = [];

  cells.push({
    label: 'Numeral ID',
    isLeftAligned: true,
    content: 'Total',
  });

  cells.push({
    label: 'SKU',
    isLeftAligned: true,
    content: '-',
  });

  cells.push({
    label: 'Product code',
    isLeftAligned: true,
    content: '-',
  });

  cells.push({
    label: 'Product name',
    isLeftAligned: true,
    content: '-',
  });

  cells.push({
    label: 'Product family',
    isLeftAligned: true,
    content: '-',
  });

  cells.push({
    label: 'List price',
    isLeftAligned: false,
    content: FormatUtil.formatCurrencyAmount(ArrayUtil.sum(items.map((item) => item.amount || 0)), currencyFormat),
  });

  cells.push({
    label: 'Price in bundle',
    isLeftAligned: false,
    content: FormatUtil.formatCurrencyAmount(bundlePrice, currencyFormat),
  });

  ArrayUtil.range(countMonths).map((m) => {
    cells.push({
      label: 'Recognized in Month ' + (m + 1),
      isLeftAligned: false,
      content: FormatUtil.formatCurrencyAmount(monthlyRecSums[m] || 0),
    });
  });

  rows.push({
    label: '',
    cells,
  });

  return rows;
};

type DroppableItemListProps = {
  items: PriceListItemFragmentFragment[];
  onRemoveItem: (id: string) => void;
  priceInput?: number;
};

let tableWidth: number | undefined;

const DroppableItemList: React.FunctionComponent<DroppableItemListProps> = ({ items, onRemoveItem, priceInput }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'droppable',
  });
  const classes = useStyles();
  const tableLabelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const label = tableLabelRef.current;
    if (label && !tableWidth) {
      tableWidth = label.clientWidth;
    }
  });

  return (
    <div
      className={[classes.droppableContainer, isOver ? classes.droppableContainerHighlight : ''].join(' ')}
      ref={setNodeRef}
    >
      <div className={classes.droppableInfo} ref={tableLabelRef}>
        Drop product here to build a new bundle
      </div>
      {items.length > 0 && (
        <div style={{ overflowX: 'scroll', maxWidth: tableWidth ? `${tableWidth}px` : undefined }}>
          <Table firstColumnLabel="" rows={getRows(items, onRemoveItem, priceInput)} />
        </div>
      )}
    </div>
  );
};

type DraggablePriceListItemProps = {
  item: PriceListItemFragmentFragment;
};

const DraggablePriceListItem: React.FunctionComponent<DraggablePriceListItemProps> = ({ item }) => {
  const classes = useStyles();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item.id,
  });
  const style = transform
    ? {
        backgroundColor: Colors.MAIN_BLUE,
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;
  return (
    <div className={classes.listItem} style={style} ref={setNodeRef} {...listeners} {...attributes}>
      <div>
        <strong>
          {item.name} (SKU: {item.sku})
        </strong>
      </div>
      <div>
        {FormatUtil.formatCurrencyAmount(item.amount || 0, {
          currencySymbol: CurrencyToSymbol[item.currency as TCurrency],
          forceDecimalPlaces: CurrencyToRawToDisplayPowerDifference[item.currency as TCurrency],
          rawToDisplayPowerDifference: CurrencyToRawToDisplayPowerDifference[item.currency as TCurrency],
        })}
        {item.saleType === PriceListItemSaleType.DEFERRED && item.termIntervalLength && item.termIntervalUnit
          ? ', ' +
            StringUtil.getCountWordString(
              item.termIntervalLength,
              IntervalUnitToUnitDisplay[item.termIntervalUnit as TIntervalUnit]
            )
          : null}
        , {PriceListItemTypeToDisplay[item.saleType as PriceListItemSaleType]}
      </div>
    </div>
  );
};

const PriceListNewBundle: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  let eligibleItems: PriceListItemFragmentFragment[] = [];

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [sku, setSKU] = useState('');
  const [productCode, setProductCode] = useState('');
  const [priceStr, setPriceStr] = useState('');

  const priceInput = (Number(priceStr) || 0) * 100;

  const [selectedPriceListItems, updateSelectedPriceListItems] = useState<PriceListItemFragmentFragment[]>([]);

  const handleDragEnd = (event: DragEndEvent) => {
    if (event.over && event.over.id === 'droppable') {
      const priceListItemId = event.active.id;
      if (selectedPriceListItems.some((pli) => pli.id === priceListItemId)) {
        return;
      }

      const item = eligibleItems.find((pli) => pli.id === priceListItemId);
      if (!item) {
        return;
      }
      updateSelectedPriceListItems(selectedPriceListItems.concat([item]));
    }
  };

  const { loading: isLoading, error, data } = useQuery<GetPriceListForNewBundleQuery>(QUERY);

  const [makeMutation, { loading: isMutationLoading, error: mutationErrorStr }] = useMutation<
    MakeBundleMutation,
    MakeBundleMutationVariables
  >(MUTATION);
  const mutationVariables: BundleInput = {
    name,
    sku,
    productCode,
    description,
    amount: priceInput,
    priceListItemIds: selectedPriceListItems.map((item) => item.id).join(','),
  };
  const isValid = !ValidateBundle(mutationVariables);

  let mutationError: string | undefined;
  if (mutationErrorStr) {
    const message = mutationErrorStr.message;
    const firstIndex = message.indexOf('"');
    const lastIndex = message.lastIndexOf('"');
    if (firstIndex > -1 && lastIndex > -1 && lastIndex > firstIndex) {
      mutationError = message.substring(firstIndex + 1, lastIndex);
    }
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (data) {
    eligibleItems = data.priceList.items
      .filter((item) => item.saleType !== PriceListItemSaleType.BUNDLE && item.state === PriceListItemState.ACTIVE)
      // TODO: only support terms measured in months
      .filter((item) => !item.termIntervalUnit || item.termIntervalUnit === TIntervalUnit.MONTH)
      .filter((item) => !selectedPriceListItems.some((pli) => pli.id === item.id));
  }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div className={sharedClasses.main}>
        <Banner />
        <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
          <div className={sharedClasses.content}>
            <div className={sharedClasses.contentHeaderWrapper}>
              <div className={sharedClasses.contentHeader}>
                <div className={sharedClasses.cardTabs}>
                  <div className={classnames(sharedClasses.cardTab, sharedClasses.cardTabSelected)}>
                    <span>New Bundle</span>
                  </div>
                </div>
              </div>
              <div className={sharedClasses.contentHeaderUtils}></div>
            </div>
            {isLoading ? (
              <div className={sharedClasses.contentLoaderContainer}>
                <LoaderAnimation height={80} />
              </div>
            ) : (
              <div className={classes.container}>
                <div className={classes.formContainer}>
                  <div className={classes.formRow}>
                    <div className={classes.formLabel}>SKU:</div>
                    <input
                      className={classnames(classes.formInput, sharedClasses.formInput)}
                      value={sku}
                      onChange={(e) => setSKU(e.target.value)}
                    />
                  </div>

                  <div className={classes.formRow}>
                    <div className={classes.formLabel}>Product code:</div>
                    <input
                      className={classnames(classes.formInput, sharedClasses.formInput)}
                      value={productCode}
                      onChange={(e) => setProductCode(e.target.value)}
                    />
                  </div>

                  <div className={classes.formRow}>
                    <div className={classes.formLabel}>Name:</div>
                    <input
                      className={classnames(classes.formInput, sharedClasses.formInput)}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className={classes.formRow}>
                    <div className={classes.formLabel}>Description:</div>
                    <input
                      className={classnames(classes.formInput, sharedClasses.formInput)}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className={classes.formRow}>
                    <div className={classes.formLabel}>Price ($):</div>
                    <input
                      className={classnames(classes.formInput, sharedClasses.formInput)}
                      value={priceStr}
                      onChange={(e) => setPriceStr((e.target.value || '').replace(/[^0-9\\.]/g, ''))}
                    />
                  </div>

                  <DroppableItemList
                    items={selectedPriceListItems}
                    onRemoveItem={(id) => {
                      updateSelectedPriceListItems(selectedPriceListItems.filter((item) => item.id !== id));
                    }}
                    priceInput={priceInput || undefined}
                  />

                  <div className={classes.formButtonRow}>
                    <FormButton
                      isDisabled={isMutationLoading || !isValid}
                      width={120}
                      onClick={() =>
                        makeMutation({
                          variables: mutationVariables,
                        }).then((result) => {
                          if (!result.errors) {
                            // hard refresh to prevent graphql cache
                            document.location.pathname = '/app/price-list';
                          }
                        })
                      }
                    >
                      Save
                    </FormButton>
                    <FormButton
                      isDisabled={isMutationLoading}
                      width={120}
                      onClick={() => navigate('/app/price-list')}
                      style={FormButtonStyle.NEUTRAL}
                    >
                      Cancel
                    </FormButton>
                    <span className={classes.error}>{mutationError}</span>
                  </div>
                </div>
                <div className={classes.listContainer}>
                  <div className={classes.listInfo}>Drag and drop from eligible products below into new bundle...</div>
                  {eligibleItems.map((item, idx) => (
                    <DraggablePriceListItem item={item} key={`price-list-new-bundle-item-${idx}`} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DndContext>
  );
};

export default PriceListNewBundle;
