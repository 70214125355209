import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';

export const dateFilterHeaderClick = (
  column: Tabulator.ColumnComponent,
  updateIsDateSelectorVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setDateFilterParams: React.Dispatch<
    React.SetStateAction<
      | {
          column?: Tabulator.ColumnComponent;
          start?: string;
          end?: string;
        }
      | undefined
    >
  >
) => {
  const colDef = column.getDefinition();
  const params = colDef?.accessorParams as { [field: string]: string };
  setDateFilterParams({
    column,
    start: params?.dateFilterStartField,
    end: params?.dateFilterEndField,
  });
  updateIsDateSelectorVisible(true);
};
