import { createUseStyles } from 'react-jss';

import Colors from '../../components/common/Colors.tsx';

export const useDepositsCardWithBannerStyles = createUseStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 0',
    minHeight: '96px',
  },
  headerLeft: {
    width: '400px',

    '& h2': {
      fontSize: '22px',
      fontWeight: 'bold',
      margin: '5px 0 15px',
    },
  },
  headerRight: {
    flex: 1,
  },
  currentAccount: {
    fontSize: '14px',
  },
  balanceDate: {
    color: Colors.MEDIUM_GRAY,
    marginTop: '6px',
    fontSize: '12px',
  },
  highlightContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    marginBottom: '25px',
    padding: '15px',
    overflowY: 'auto',

    '& > div': {
      flex: 1,
      marginRight: '0',
      minWidth: '205px',
    },
  },
  loaderContainer: {
    textAlign: 'center',
    marginTop: '150px',
    marginRight: '85px',
    display: 'flex',
    justifyContent: 'center',
  },
  tableHeader: {
    backgroundColor: Colors.FAINT_BLUE,
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    fontSize: '13px',
    fontWeight: 'bold',
    padding: '5px 7px',
    textAlign: 'center',
  },
  depositsTable: {
    '&.tabulator': {
      fontSize: '13px',
      overflow: 'visible',
      '& .tabulator-tableholder': {
        overflow: 'visible',
        overflowX: 'auto',
      },
    },
  },
});
