/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import Modal from 'react-modal';
import { createUseStyles } from 'react-jss';

import { gql, useMutation } from '@apollo/client';

import Colors from './common/Colors';
import { ReconciliationURLParam } from '../../src/types/ReconciliationTypes';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import getAssetPath from '../utils/AssetPathUtil';

const CSV_EXPORT_ERROR_MESSAGE = 'Something went wrong when exporting data as CSV. Please try again later';
const CSV_EXPORT_SUCCESS_MESSAGE =
  'We are preparing your CSV for export. You will be notified when it is ready for download';

const useStyles = createUseStyles({
  exportCsv: {
    alignItems: 'center',
    color: Colors.MEDIUM_GRAY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  closeModalBtn: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  modalSection: {
    marginBottom: '48px',
  },
});

const CSV_EXPORT_MUTATION = gql`
  mutation dataHubCsvExport {
    dataHubCsvExport {
      ok
    }
  }
`;

const RECON_REPORT_MUTATION = gql`
  mutation reconReportCsvExport(
    $${ReconciliationURLParam.VENDOR}: String
    $${ReconciliationURLParam.CUSTODIAL_STATUS}: String
    $${ReconciliationURLParam.CUSTODIAN}: String
    $${ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID}: String
    $${ReconciliationURLParam.PAYOUT_ID}: String
    $${ReconciliationURLParam.VENDOR_EXTERNAL_ID}: String
    $${ReconciliationURLParam.EXCEPTION_FILTER_OPTION}: String
    $${ReconciliationURLParam.START_DATE_STRING}: String
    $${ReconciliationURLParam.END_DATE_STRING}: String
  ) {
    reconReportCsvExport(
      ${ReconciliationURLParam.VENDOR}: $${ReconciliationURLParam.VENDOR}
      ${ReconciliationURLParam.CUSTODIAL_STATUS}: $${ReconciliationURLParam.CUSTODIAL_STATUS}
      ${ReconciliationURLParam.CUSTODIAN}: $${ReconciliationURLParam.CUSTODIAN}
      ${ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID}: $${ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID}
      ${ReconciliationURLParam.PAYOUT_ID}: $${ReconciliationURLParam.PAYOUT_ID}
      ${ReconciliationURLParam.VENDOR_EXTERNAL_ID}: $${ReconciliationURLParam.VENDOR_EXTERNAL_ID}
      ${ReconciliationURLParam.EXCEPTION_FILTER_OPTION}: $${ReconciliationURLParam.EXCEPTION_FILTER_OPTION}
      ${ReconciliationURLParam.START_DATE_STRING}: $${ReconciliationURLParam.START_DATE_STRING}
      ${ReconciliationURLParam.END_DATE_STRING}: $${ReconciliationURLParam.END_DATE_STRING}
    ) {
      ok
    }
  }
`;

const MISSING_DATA_REPORT_MUTATION = gql`
  mutation missingDataExceptionReportCsvExport {
    missingDataExceptionReportCsvExport {
      ok
    }
  }
`;

const DEPOSIT_REPORT_MUTATION = gql`
  mutation depositsReportCsvExport($eaid: String) {
    depositsReportCsvExport(eaid: $eaid) {
      ok
    }
  }
`;

const CASH_TRANSACTION_REPORT_MUTATION = gql`
  mutation cashTransactionReportCsvExport(
    $st: Float!
    $et: Float
    $at: String!
    $ne: Boolean
    $di: String
    $dv: String
  ) {
    cashTransactionReportCsvExport(st: $st, et: $et, at: $at, ne: $ne, di: $di, dv: $dv) {
      ok
    }
  }
`;

const REVENUE_TRANSACTION_REPORT_MUTATION = gql`
  mutation revenueTransactionReportCsvExport(
    $st: Float!
    $et: Float
    $tt: String
    $at: String
    $di: String
    $dv: String
  ) {
    revenueTransactionReportCsvExport(st: $st, et: $et, tt: $tt, at: $at, di: $di, dv: $dv) {
      ok
    }
  }
`;
type ExportButtonProps = {
  type: TCsvExportType;
  enabled?: boolean;
  searchParams?: URLSearchParams;
  url?: string;
};

const ExportButton: React.FunctionComponent<ExportButtonProps> = ({ type, enabled, url, searchParams }) => {
  const classes = useStyles();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [csvStatus, setCsvStatus] = React.useState('');
  const [csvExportMutation, { loading: isCsvExportLoading }] = useMutation(CSV_EXPORT_MUTATION);
  const [reconReportMutation, { loading: isReconReportLoading }] = useMutation(RECON_REPORT_MUTATION);
  const [missingDataReportMutation, { loading: isMissingDataReportLoading }] =
    useMutation(MISSING_DATA_REPORT_MUTATION);
  const [depositsReportMutation, { loading: isDepositReportLoading }] = useMutation(DEPOSIT_REPORT_MUTATION);
  const [cashTransactionReportMutation, { loading: isCashTransactionReportLoading }] = useMutation(
    CASH_TRANSACTION_REPORT_MUTATION
  );
  const [revenueTransactionReportMutation, { loading: isRevenueTransactionReportLoading }] = useMutation(
    REVENUE_TRANSACTION_REPORT_MUTATION
  );
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setCsvStatus('');
    setIsOpen(false);
  };

  const exportDataHubData = async () => {
    const { data } = await csvExportMutation();
    if (data?.dataHubCsvExport?.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const exportReconReportData = async () => {
    const variables: Record<string, string | null> = {};
    if (searchParams) {
      Array.from(searchParams.keys()).map((k: string) => (variables[k] = searchParams.get(k)));
    }
    const { data } = await reconReportMutation({ variables });
    if (data?.reconReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const exportMissingDataReportData = async () => {
    const { data } = await missingDataReportMutation();
    if (data?.missingDataExceptionReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const depositsReportData = async () => {
    const { data } = await depositsReportMutation({ variables: searchParams });
    if (data?.depositsReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const cashTransactionReportData = async () => {
    const variables: Record<string, string | number | null> = {};
    if (searchParams) {
      Array.from(searchParams.keys()).map((k: string) => {
        const value = searchParams.get(k);
        return value && !isNaN(Number(value)) ? (variables[k] = Number(value)) : (variables[k] = value);
      });
    }
    const { data } = await cashTransactionReportMutation({ variables });
    if (data?.cashTransactionReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const revenueTransactionReportData = async () => {
    const variables: Record<string, string | number | null> = {};
    if (searchParams) {
      Array.from(searchParams.keys()).map((k: string) => {
        const value = searchParams.get(k);
        return value && !isNaN(Number(value)) ? (variables[k] = Number(value)) : (variables[k] = value);
      });
    }
    const { data } = await revenueTransactionReportMutation({ variables });
    if (data?.revenueTransactionReportCsvExport.ok) {
      setCsvStatus(CSV_EXPORT_SUCCESS_MESSAGE);
    } else {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
  };

  const handleClick = async () => {
    if (
      !enabled ||
      isCsvExportLoading ||
      isReconReportLoading ||
      isMissingDataReportLoading ||
      isDepositReportLoading ||
      isCashTransactionReportLoading ||
      isRevenueTransactionReportLoading
    ) {
      return;
    }
    try {
      switch (type) {
        case TCsvExportType.DATA_HUB:
          exportDataHubData();
          break;
        case TCsvExportType.RECON_REPORT:
          exportReconReportData();
          break;
        case TCsvExportType.MISSING_DATA_REPORT:
          exportMissingDataReportData();
          break;
        case TCsvExportType.DEPOSITS_REPORT:
          depositsReportData();
          break;
        case TCsvExportType.CASH_TRANSACTION_REPORT:
          cashTransactionReportData();
          break;
        case TCsvExportType.REVENUE_TRANSACTION_REPORT:
          revenueTransactionReportData();
          break;
      }
    } catch (err) {
      setCsvStatus(CSV_EXPORT_ERROR_MESSAGE);
    }
    openModal();
  };

  return (
    <>
      <a
        className={classes.exportCsv}
        href={url || '#'}
        target={url ? '_blank' : undefined}
        rel={url ? 'nonreferrer' : url}
        onClick={url ? undefined : handleClick}
      >
        <img src={`${getAssetPath()}/images/icon_csv.svg`} height="16" />
        Export
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Invite User"
        style={{
          content: {
            width: '664px',
            height: '120px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
          },
        }}
      >
        <div className={classes.modalHeader}>
          <p>CSV Export</p>
          <button onClick={closeModal} className={classes.closeModalBtn}>
            {'\u00d7'}
          </button>
        </div>
        <div className={classes.modalSection}>{csvStatus}</div>
      </Modal>
    </>
  );
};

export default ExportButton;
