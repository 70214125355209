import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';

type GenericRow = {
  [field: string]: string | number | boolean | null | undefined;
};

export type GenericRowWithArray = {
  [field: string]: string | number | boolean | null | undefined | GenericRow[];
};

export type DataGridRow<T = unknown> = GenericRowWithArray & {
  total?: string | number;
  _rowCssClass?: string;
  _cellData?: { [key: string]: GenericRowWithArray };
  _cssClass?: { [key: string]: string };
  _children?: { [key: string]: string } | T;
};

export enum SortDir {
  ASC = 'asc',
  DESC = 'desc',
}
export type DataGridAjaxParams = {
  sort?: { field: string; dir: SortDir }[];
  filter?: { field: string; type: TabulatorTypes.FilterType; value: string | number | undefined }[];
  page?: number;
  size?: number;
};

export enum DataGridFormatter {
  PILL_FORMATTER = 'pillFormatter',
  LINK_FORMATTER = 'linkFormatter',
  DATE_FORMATTER = 'dateFormatter',
  DATETIME_FORMATTER = 'dateTimeFormatter',
  CUSTOMCSS_FORMATTER = 'customCssFormatter',
}
