import { TCurrency } from './BaseTypes.js';

// filter type = query params for data hub page
export enum DataHubURLParam {
  ID = 'id',
  VENDOR = 've',
  CUSTOMER_ID = 'ct',
  SORT_KEY = 'sk',
  SORT_DIRECTION = 'sd',

  IMPORT_START_TIME_MS = 'ist',
  IMPORT_END_TIME_MS = 'iet',
  INVOICE_ISSUED_START_TIME_MS = 'vst',
  INVOICE_ISSUED_END_TIME_MS = 'vet',
  INVOICE_DUE_START_TIME_MS = 'dst',
  INVOICE_DUE_END_TIME_MS = 'det',
  INVOICE_EXTERNAL_ID = 'ied',
  INVOICE_ITEM_EXTERNAL_ID = 'iied',
  PAYMENT_COMPLETED_START_TIME_MS = 'pst',
  PAYMENT_COMPLETED_END_TIME_MS = 'pet',
  CONTRACT_START_START_TIME_MS = 'cst',
  CONTRACT_START_END_TIME_MS = 'cet',
}

export enum DataHubGraphQLParam {
  ID = 'id',
  VENDOR = 'vendor',
  SORT_KEY = 'sort_key',
  SORT_DIRECTION = 'sort_direction',
  CUSTOMER_ID = 'customer_id',
  PAGE = 'page',
  PAGE_SIZE = 'page_size',

  IMPORT_START_TIME_MS = 'import_start_time_ms',
  IMPORT_END_TIME_MS = 'import_end_time_ms',
  INVOICE_ISSUED_START_TIME_MS = 'invoice_issued_start_time_ms',
  INVOICE_ISSUED_END_TIME_MS = 'invoice_issued_end_time_ms',
  INVOICE_DUE_START_TIME_MS = 'invoice_due_start_time_ms',
  INVOICE_DUE_END_TIME_MS = 'invoice_due_end_time_ms',
  INVOICE_EXTERNAL_ID = 'invoice_external_id',
  INVOICE_ITEM_EXTERNAL_ID = 'invoice_item_external_id',
  PAYMENT_COMPLETED_START_TIME_MS = 'payment_completed_start_time_ms',
  PAYMENT_COMPLETED_END_TIME_MS = 'payment_completed_end_time_ms',
  CONTRACT_START_START_TIME_MS = 'contract_start_start_time_ms',
  CONTRACT_START_END_TIME_MS = 'contract_start_end_time_ms',
}

export const DataHubNumberGraphQLParamToURLParam: { [key in DataHubGraphQLParam]?: DataHubURLParam } = {
  [DataHubGraphQLParam.IMPORT_START_TIME_MS]: DataHubURLParam.IMPORT_START_TIME_MS,
  [DataHubGraphQLParam.IMPORT_END_TIME_MS]: DataHubURLParam.IMPORT_END_TIME_MS,
  [DataHubGraphQLParam.INVOICE_ISSUED_START_TIME_MS]: DataHubURLParam.INVOICE_ISSUED_START_TIME_MS,
  [DataHubGraphQLParam.INVOICE_ISSUED_END_TIME_MS]: DataHubURLParam.INVOICE_ISSUED_END_TIME_MS,
  [DataHubGraphQLParam.INVOICE_DUE_START_TIME_MS]: DataHubURLParam.INVOICE_DUE_START_TIME_MS,
  [DataHubGraphQLParam.INVOICE_DUE_END_TIME_MS]: DataHubURLParam.INVOICE_DUE_END_TIME_MS,
  [DataHubGraphQLParam.PAYMENT_COMPLETED_START_TIME_MS]: DataHubURLParam.PAYMENT_COMPLETED_START_TIME_MS,
  [DataHubGraphQLParam.PAYMENT_COMPLETED_END_TIME_MS]: DataHubURLParam.PAYMENT_COMPLETED_END_TIME_MS,
  [DataHubGraphQLParam.CONTRACT_START_START_TIME_MS]: DataHubURLParam.CONTRACT_START_START_TIME_MS,
  [DataHubGraphQLParam.CONTRACT_START_END_TIME_MS]: DataHubURLParam.CONTRACT_START_END_TIME_MS,
};

export const DataHubStringGraphQLParamToURLParam: { [key in DataHubGraphQLParam]?: DataHubURLParam } = {
  [DataHubGraphQLParam.ID]: DataHubURLParam.ID,
  [DataHubGraphQLParam.VENDOR]: DataHubURLParam.VENDOR,
  [DataHubGraphQLParam.SORT_KEY]: DataHubURLParam.SORT_KEY,
  [DataHubGraphQLParam.SORT_DIRECTION]: DataHubURLParam.SORT_DIRECTION,
  [DataHubGraphQLParam.CUSTOMER_ID]: DataHubURLParam.CUSTOMER_ID,
  [DataHubGraphQLParam.INVOICE_EXTERNAL_ID]: DataHubURLParam.INVOICE_EXTERNAL_ID,
  [DataHubGraphQLParam.INVOICE_ITEM_EXTERNAL_ID]: DataHubURLParam.INVOICE_ITEM_EXTERNAL_ID,
};

export const DataHubGraphQLParamToURLParam: { [key in DataHubGraphQLParam]?: DataHubURLParam } = {
  ...DataHubNumberGraphQLParamToURLParam,
  ...DataHubStringGraphQLParamToURLParam,
};

export const DataHubURLParamToGraphQLParam = Object.keys(DataHubGraphQLParamToURLParam).reduce<{
  [key in DataHubURLParam]?: DataHubGraphQLParam;
}>(
  (obj, dateFilterField) => ({
    ...obj,
    [DataHubGraphQLParamToURLParam[dateFilterField as DataHubGraphQLParam] as DataHubURLParam]:
      dateFilterField as DataHubGraphQLParam,
  }),
  {}
);

export enum SupportedSortKeys {
  IMPORTED_AT = 'imported_at',
  AMOUNT = 'amount',

  INVOICE_VENDOR = 'invoice_vendor',
  INVOICE_EXTERNAL_ID = 'invoice_external_id',
  INVOICE_ISSUED_AT = 'invoice_issued_at',
  INVOICE_DUE_AT = 'invoice_due_at',
  INVOICE_AMOUNT = 'invoice_amount',

  PAYMENT_COMPLETED_AT = 'payment_completed_at',
  PAYMENT_AMOUNT = 'payment_amount',

  REFUND_COMPLETED_AT = 'refund_completed_at',
  REFUND_AMOUNT = 'refund_amount',

  PRICE_LIST_ITEM_ID = 'price_list_item_id',
  PRICE_LIST_ITEM_SKU = 'price_list_item_sku',
  PRICE_LIST_ITEM_AMOUNT = 'price_list_item_amount',

  CUSTOMER_ID = 'customer_id',
  INVOICE_ITEM_EXTERNAL_ID = 'invoice_item_external_id',
  INVOICE_ITEM_ORIGINAL_CURRENCY = 'invoice_item_original_currency',
}

export type StringToStringMap = {
  [key: string]: string;
};

export enum SortDirections {
  ASC = 'asc',
  DESC = 'des',
}

export type Sort = {
  key: SupportedSortKeys;
  direction: SortDirections;
};

export const pageSizesAllowed = [25, 50, 75, 100];
export type DateFilter = { [key in string]?: number | undefined };

export enum TDataHubTableColumnNames {
  SOURCE = 'SOURCE',
  SOURCE_ID = 'SOURCE_ID',
  IMPORTED_AT = 'IMPORTED_AT',
  ORIGINAL_CURRENCY = 'ORIGINAL_CURRENCY',
  LINE_ITEM_AMOUNT = 'LINE_ITEM_AMOUNT',
  LINE_ITEM_AMOUNT_BASE = 'LINE_ITEM_AMOUNT_BASE',
  PRICE_LIST_ITEM_ID = 'PRICE_LIST_ITEM_ID',
  LISTING_PRICE = 'LISTING_PRICE',
  SKU = 'SKU',
  CUSTOMER = 'CUSTOMER',
  CONTRACT_START = 'CONTRACT_START',
  CONTRACT_END = 'CONTRACT_END',
  INVOICED_AT = 'INVOICED_AT',
  INVOICE_DUE_AT = 'INVOICE_DUE_AT',
  SOURCE_LINE_ITEM_ID = 'SOURCE_LINE_ITEM_ID',
  PAID_AT = 'PAID_AT',
  TOTAL_INVOICE_AMOUNT = 'TOTAL_INVOICE_AMOUNT',
  TOTAL_INVOICE_AMOUNT_BASE = 'TOTAL_INVOICE_AMOUNT_BASE',
  INVOICE_AMOUNT_PAID = 'INVOICE_AMOUNT_PAID',
  INVOICE_AMOUNT_PAID_BASE = 'INVOICE_AMOUNT_PAID_BASE',
  INVOICE_AMOUNT_REFUNDED = 'INVOICE_AMOUNT_REFUNDED',
  INVOICE_AMOUNT_REFUNDED_BASE = 'INVOICE_AMOUNT_REFUNDED_BASE',
  NET_INVOICE_AMOUNT_PAID = 'NET_INVOICE_AMOUNT_PAID',
  NET_INVOICE_AMOUNT_PAID_BASE = 'NET_INVOICE_AMOUNT_PAID_BASE',
}

export const getDataHubTableColumnNamesForDisplay: (baseCurrency: TCurrency) => {
  [value in TDataHubTableColumnNames]: string;
} = (baseCurrency: TCurrency) => {
  return {
    [TDataHubTableColumnNames.SOURCE]: 'Source',
    [TDataHubTableColumnNames.SOURCE_ID]: 'Source ID',
    [TDataHubTableColumnNames.IMPORTED_AT]: 'Imported at',
    [TDataHubTableColumnNames.ORIGINAL_CURRENCY]: 'Original currency',
    [TDataHubTableColumnNames.LINE_ITEM_AMOUNT]: 'Line item amount',
    [TDataHubTableColumnNames.LINE_ITEM_AMOUNT_BASE]: `Line item amount (${baseCurrency})`,
    [TDataHubTableColumnNames.PRICE_LIST_ITEM_ID]: 'Price list item ID',
    [TDataHubTableColumnNames.LISTING_PRICE]: 'Listing price',
    [TDataHubTableColumnNames.SKU]: 'SKU',
    [TDataHubTableColumnNames.CUSTOMER]: 'Customer',
    [TDataHubTableColumnNames.CONTRACT_START]: 'Contract start',
    [TDataHubTableColumnNames.CONTRACT_END]: 'Contract end',
    [TDataHubTableColumnNames.INVOICED_AT]: 'Invoiced at',
    [TDataHubTableColumnNames.INVOICE_DUE_AT]: 'Invoice due at',
    [TDataHubTableColumnNames.SOURCE_LINE_ITEM_ID]: 'Source line item ID',
    [TDataHubTableColumnNames.PAID_AT]: 'Paid at',
    [TDataHubTableColumnNames.TOTAL_INVOICE_AMOUNT]: 'Total invoice amount',
    [TDataHubTableColumnNames.TOTAL_INVOICE_AMOUNT_BASE]: `Total invoice amount (${baseCurrency})`,
    [TDataHubTableColumnNames.INVOICE_AMOUNT_PAID]: 'Invoice amount paid',
    [TDataHubTableColumnNames.INVOICE_AMOUNT_PAID_BASE]: `Invoice amount paid (${baseCurrency})`,
    [TDataHubTableColumnNames.INVOICE_AMOUNT_REFUNDED]: 'Invoice amount refunded',
    [TDataHubTableColumnNames.INVOICE_AMOUNT_REFUNDED_BASE]: `Invoice amount refunded (${baseCurrency})`,
    [TDataHubTableColumnNames.NET_INVOICE_AMOUNT_PAID]: 'Net invoice amount paid',
    [TDataHubTableColumnNames.NET_INVOICE_AMOUNT_PAID_BASE]: `Net invoice amount paid (${baseCurrency})`,
  };
};
