import React, { useRef, useState } from 'react';

import moment from 'moment';

import { createUseStyles } from 'react-jss';

import Colors from './Colors';
import { DATE_RANGE_SELECT_DROPDOWN_WIDTH_PX } from '../../utils/TableConstants';
import { DateRangerSelector } from './DateRangeSelectorDropdown';
import FormButton from './FormButton';
import useOutsideClick from '../../hooks/useOutsideClick';
import {
  DataHubGraphQLParam,
  DataHubGraphQLParamToURLParam,
  DateFilter,
  StringToStringMap,
} from '../../../src/types/DataHubTypes';

const useStyles = createUseStyles({
  dateRangeSelectorDropdown: {
    background: Colors.WHITE,
    boxShadow: '0 0 2px rgba(0,0,0,0.25)',
    position: 'absolute',
    marginTop: '18px',
    width: DATE_RANGE_SELECT_DROPDOWN_WIDTH_PX,
    zIndex: 1,
  },
});

type TableColumnFilterDateModalProps = {
  currentDateFilter?: DateFilter;
  dateFilterStartField?: string;
  dateFilterEndField?: string;
  onColFilter?: (obj: StringToStringMap) => void;
  getDropdownPosition: () =>
    | {
        left: number;
        top: number;
      }
    | undefined;
  updateIsDateSelectorVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const TableColumnFilterDateModal: React.FunctionComponent<TableColumnFilterDateModalProps> = ({
  currentDateFilter,
  dateFilterStartField,
  dateFilterEndField,
  onColFilter,
  getDropdownPosition,
  updateIsDateSelectorVisible,
}: TableColumnFilterDateModalProps) => {
  const classes = useStyles();

  const initialStartDateMs =
    dateFilterStartField && currentDateFilter ? currentDateFilter[dateFilterStartField] : undefined;
  const initialEndDateMs = dateFilterEndField && currentDateFilter ? currentDateFilter[dateFilterEndField] : undefined;
  const [startDate, updateStartDate] = useState<Date | undefined>(
    initialStartDateMs ? new Date(initialStartDateMs) : undefined
  );
  const [endDate, updateEndDate] = useState<Date | undefined>(
    initialEndDateMs ? new Date(initialEndDateMs) : undefined
  );

  let dateFilterFn: (start?: Date, end?: Date) => void = () => null;
  if (dateFilterStartField && dateFilterEndField && onColFilter) {
    dateFilterFn = () => {
      const startDateMs = startDate ? startDate.getTime() : undefined;
      let endDateMs = endDate ? endDate.getTime() : undefined;
      if (endDateMs) {
        // date picker uses start of day by default, switch to end
        endDateMs = moment(new Date(endDateMs)).endOf('day').toDate().getTime();
      }
      updateIsDateSelectorVisible(false);
      onColFilter({
        [DataHubGraphQLParamToURLParam[dateFilterStartField as DataHubGraphQLParam] as string]: startDateMs?.toString(),
        [DataHubGraphQLParamToURLParam[dateFilterEndField as DataHubGraphQLParam] as string]: endDateMs?.toString(),
      } as StringToStringMap);
    };
  }

  const ref = useRef(null);
  useOutsideClick(ref, () => {
    updateIsDateSelectorVisible(false);
  });

  return (
    <div
      className={classes.dateRangeSelectorDropdown}
      style={{
        ...(getDropdownPosition() || {}),
      }}
      onClick={(e) => e.stopPropagation()}
      ref={ref}
    >
      <DateRangerSelector
        startDate={startDate}
        endDate={endDate}
        onChange={(start, end) => {
          updateStartDate(start);
          updateEndDate(end);
        }}
      />
      <div style={{ padding: '15px', textAlign: 'right' }}>
        <FormButton isDisabled={!startDate || !endDate} onClick={dateFilterFn} width={150}>
          Apply filter
        </FormButton>
      </div>
    </div>
  );
};

export default TableColumnFilterDateModal;
