import { createUseStyles } from 'react-jss';
import getAssetPath from '../../utils/AssetPathUtil.ts';
import Colors from '../../components/common/Colors.tsx';

const generateTreeStyles = (maxLevels) => {
  const styles = {};
  for (let i = 1; i <= maxLevels; i++) {
    styles[`&.tabulator-tree-level-${i} > .tabulator-cell`] = {
      paddingLeft: `${i * 20 + 4}px !important`,
    };
  }

  return styles;
};

export const useStyles = createUseStyles({
  defaultStyles: {
    '& div.tabulator': {
      '& .tabulator-tableholder': {
        '& .tabulator-table': {
          '& .tabulator-row': {
            ...generateTreeStyles(10),
          },
        },
      },
    },
  },
  pivotDragging: {
    '& div.tabulator': {
      '& .tabulator-tableholder': {
        '& .tabulator-table': {
          '& .tabulator-row': {
            '&.tabulator-tree-level-1': {
              paddingLeft: '24px !important',
            },
            '&:not(.non-group-header)': {
              '& .tabulator-cell': {
                '&.content-cell-disabled': {
                  backgroundColor: 'transparent',
                },
              },
            },
            '&.tabulator-group': {
              '& + .tree-level-0-header': {
                backgroundColor: 'rgba(34, 49, 74, 0.2)',
              },
            },
          },
        },
      },
    },
  },
  // Empty styles, but needed as this class name is referenced in a nested selector elsewhere
  highlightedHeaderRow: {},
  revenueTable: {
    overflow: 'auto',
    height: 625,
    marginBottom: 50,
    '&.tabulator': {
      fontSize: '13px',
      '& .tabulator-header': {
        '& .tabulator-col': {
          '&:not(:first-child):not(:last-child)': {
            '&.content-lock-icon': {
              cursor: 'pointer',
              '&::after': {
                content: "''",
                backgroundImage: `url('${getAssetPath()}/images/icon_unlock.svg')`,
                backgroundSize: '11px 11px',
                height: 11,
                width: 11,
                marginRight: 5,
              },
            },
            '&:not(.content-cell-disabled):hover': {
              backgroundColor: Colors.MEDIUM_GRAY,
              '&.content-lock-icon::after': {
                content: "''",
                backgroundImage: `url('${getAssetPath()}/images/icon_lock.svg')`,
                backgroundSize: '11px 11px',
                height: 11,
                width: 11,
                marginRight: 5,
              },
            },
            '&.content-lock-icon.content-cell-disabled::after': {
              content: "''",
              backgroundImage: `url('${getAssetPath()}/images/icon_lock.svg')`,
              backgroundSize: '11px 11px',
              height: 11,
              width: 11,
              marginRight: 5,
            },
          },
        },
      },
      '& .tabulator-tableholder': {
        '& .tabulator-table': {
          '& .highlighted-row': {
            backgroundColor: Colors.RADIANT_BLUE,
          },
          '& .tabulator-row': {
            '&.tabulator-group': {
              backgroundColor: Colors.FAINTEST_BLUE,
              borderTop: 'none',
              cursor: 'text',
              '& .tabulator-group-toggle': {
                marginTop: 2,
              },
              '&.tabulator-group-visible': {
                '& .tabulator-group-toggle': {
                  marginTop: 5,
                  display: 'none',
                },
              },
              '& ~ .tabulator-group': {
                marginTop: 14,
                '& .tabulator-cell': {
                  borderTop: `${Colors.TABLE_HEADER_GRAY} thin solid`,
                },
              },
              '& + .tree-level-0-header': {
                marginTop: 0,
                '& .tabulator-cell': {
                  borderTop: 'none',
                },
              },
            },
            '&.tree-level-0-header': {
              '& ~ .tree-level-0-header': {
                marginTop: 14,
                '& .tabulator-cell': {
                  borderTop: `${Colors.TABLE_HEADER_GRAY} thin solid`,
                },
              },
            },
            '&.tabulator-tree-level-0': {
              '& .tabulator-cell:first-child': {
                fontWeight: 'bold',
                flexFlow: 'row-reverse',
                justifyContent: 'flex-end',
                paddingLeft: 10,
                '& .tabulator-tree-icon': {
                  transform: 'scale(0.75)',
                },
              },
              '&.non-group-header': {
                marginTop: 14,
                '& .tabulator-cell': {
                  borderTop: `${Colors.TABLE_HEADER_GRAY} thin solid`,
                },
                '& .tabulator-cell:not(:first-child)': {
                  borderRight: 'none',
                },
                '& + .tabulator-tree-level-0': {
                  marginTop: 0,
                  '& .tabulator-cell': {
                    borderTop: 'none',
                  },
                },
              },
            },
            '& .tabulator-cell': {
              '& .tabulator-tree-icon': {
                cursor: 'pointer',
                paddingLeft: 5,
                paddingRight: 5,
              },
            },
          },
        },
      },
    },
  },
});
