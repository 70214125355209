import { createUseStyles } from 'react-jss';

import Colors from '../../components/common/Colors.tsx';

export const useStyles = createUseStyles({
  loginContainer: {
    display: 'block',
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    width: '100%',
  },

  contentWrapper: {
    height: 'auto',
    minHeight: 'auto',
    margin: '-50px auto 0',
    padding: '30px',
    width: '270px',
  },

  loginLogoContainer: {
    textAlign: 'center',

    '& p': {
      fontSize: '14px',
      margin: '10px 0 20px',
    },
  },

  loginRow: {
    fontSize: '14px',
    margin: '15px 0',

    '& > span': {
      display: 'block',
      marginBottom: '-10px',
      width: '80px',
    },

    '& input': {
      background: 'transparent',
      border: `1px solid ${Colors.MEDIUM_GRAY}`,
      borderRadius: '3px',
      color: Colors.DARK_GRAY,
      fontSize: '14px',
      padding: '8px',
      transition: 'border 0.1s',
      width: '100%',

      '&:focus': {
        border: `1px solid ${Colors.DARK_GRAY}`,
        boxShadow: '1px 1px 1px rgba(0,0,0,0.035)',
        outline: 'none',
      },

      '&:disabled': {
        backgroundColor: Colors.LIGHT_GRAY,
      },
    },

    '& button': {
      width: '100%',
    },
  },

  error: {
    color: Colors.WARNING_RED,
    fontSize: '12px',
    marginTop: '-8px',
  },
});
