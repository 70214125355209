import React from 'react';

import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import { CellPillFragmentFragment } from '../../generated/graphql';
import Colors from './Colors';
import FormatUtil from '../../../src/utils/FormatUtil';
import { TCurrency } from '../../../src/types/BaseTypes';
import { useSharedStyles } from '../../utils/CssUtil';

type TableCellProps = {
  value?: number;
  formattedValue?: string;
  currency?: TCurrency;
  href?: string;
  isDisabled?: boolean;
  isLeftAligned?: boolean;
  pills?: CellPillFragmentFragment[];
  tooltip?: string;
  customClass?: string;
};

const useStyles = createUseStyles({
  pillList: {},

  tooltipCell: {
    cursor: 'pointer',
    textDecoration: 'underline dashed',

    '&:hover $tooltip': {
      display: 'block',
    },
  },

  tooltip: {
    position: 'absolute',
    display: 'none',

    background: Colors.WHITE,
    boxSizing: 'border-box',
    boxShadow: '0 0 2px rgba(0,0,0,0.1)',
    color: Colors.DARK_GRAY,
    fontWeight: 'bold',
    lineHeight: '1.3',
    padding: '10px',
    textAlign: 'center',

    borderRadius: '5px',
    bottom: '-20px',
    width: '150px',
    right: '70px',

    '&:after': {
      content: '"\\A"',
      borderStyle: 'solid',
      borderWidth: '5px 0 5px 5px',
      borderColor: `transparent transparent transparent ${Colors.WHITE}`,
      position: 'absolute',
      right: '-5px',
      bottom: 'calc(50% - 5px)',
    },
  },
});

const TableCell: React.FunctionComponent<TableCellProps> = (props: TableCellProps) => {
  const sharedClasses = useSharedStyles();
  const formatOptions =
    props.currency === TCurrency.USD
      ? {
          forceDecimalPlaces: 2,
          rawToDisplayPowerDifference: 2,
        }
      : {
          forceDecimalPlaces: 2,
          rawToDisplayPowerDifference: 0,
        };
  let valueStr =
    props.formattedValue ||
    (props.value === undefined ? '' : FormatUtil.formatCurrencyAmount(props.value, formatOptions));

  // handle negative value visual formatting
  if (parseFloat(valueStr) < 0) {
    valueStr = `(${valueStr.substring(1)})`;
  }

  const classes = useStyles();

  return (
    <td
      className={classnames(
        {
          [sharedClasses.contentCellRightAlign]: !props.isLeftAligned,
          'content-cell-negative': Math.round((props.value || 0) * 100) < 0,
          'content-cell-disabled': !!props.isDisabled,
          [classes.tooltipCell]: !!props.tooltip,
        },
        props.customClass
      )}
    >
      {props.pills && props.pills.length ? (
        <span className={classes.pillList}>
          {props.pills.map((pill, idx) =>
            pill.url ? (
              <Link
                className={classnames(sharedClasses.cellPill, sharedClasses.cellPillA)}
                key={`pill-${pill.value}-${idx}`}
                data-pill-category={pill.category}
                data-pill-value={pill.value}
                to={pill.url}
              >
                {pill.label}
              </Link>
            ) : (
              <span
                className={sharedClasses.cellPill}
                key={`pill-${pill.value}-${idx}`}
                data-pill-category={pill.category}
                data-pill-value={pill.value}
              >
                {pill.label}
              </span>
            )
          )}
        </span>
      ) : (
        ''
      )}
      {props.href ? (
        <Link className={props.customClass} to={props.href}>
          {valueStr}
        </Link>
      ) : (
        valueStr
      )}
      {props.tooltip && (
        <div style={{ position: 'relative' }}>
          <div className={classes.tooltip}>{props.tooltip}</div>
        </div>
      )}
    </td>
  );
};

export default TableCell;
