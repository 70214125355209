import React, { useRef, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import Colors from './common/Colors';
import FormButton from './common/FormButton';
import SecondaryLink from './common/SecondaryLink';
import getAssetPath from '../utils/AssetPathUtil';
import { useSharedStyles } from '../utils/CssUtil';
import { SetForgotPasswordMutation, SetForgotPasswordMutationVariables } from '../generated/graphql';

const useStyles = createUseStyles({
  loginContainer: {
    display: 'block',
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    width: '100%',
  },

  contentWrapper: {
    height: 'auto',
    minHeight: 'auto',
    margin: '-50px auto 0',
    padding: '30px',
    width: '270px',
  },

  loginLogoContainer: {
    textAlign: 'center',

    '& p': {
      fontSize: '14px',
      margin: '10px 0 20px',
    },
  },

  loginRow: {
    fontSize: '14px',
    margin: '15px 0',

    '& > span': {
      display: 'block',
      marginBottom: '-10px',
      width: '80px',
    },

    '& input': {
      background: 'transparent',
      border: `1px solid ${Colors.MEDIUM_GRAY}`,
      borderRadius: '3px',
      color: Colors.DARK_GRAY,
      fontSize: '14px',
      padding: '8px',
      transition: 'border 0.1s',
      width: '100%',

      '&:focus': {
        border: `1px solid ${Colors.DARK_GRAY}`,
        boxShadow: '1px 1px 1px rgba(0,0,0,0.035)',
        outline: 'none',
      },

      '&:disabled': {
        backgroundColor: Colors.LIGHT_GRAY,
      },
    },

    '& button': {
      width: '100%',
    },
  },

  error: {
    color: Colors.WARNING_RED,
    fontSize: '12px',
    marginTop: '-8px',
  },
});

const FORGOT_PASSWORD_MUTATION = gql`
  mutation SetForgotPassword($email: String!) {
    setForgotPassword(email: $email) {
      ok
    }
  }
`;

enum ForgotPasswordState {
  Initial,
  Submitting,
  Succeeded,
  Failed,
}

const ForgotPassword: React.FunctionComponent = () => {
  const [stateValue, setStateValue] = useState<ForgotPasswordState>(ForgotPasswordState.Initial);
  const [errorValue, setErrorStateValue] = useState<string | null>(null);
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);

  const [forgotPasswordMutation, { error: forgotPasswordErrorStr }] = useMutation<
    SetForgotPasswordMutation,
    SetForgotPasswordMutationVariables
  >(FORGOT_PASSWORD_MUTATION);

  const continueFn = async () => {
    setStateValue(ForgotPasswordState.Submitting);
    const errorMessage = 'An error occurred';
    const email = emailRef.current?.value;

    if (email) {
      const forgotPassword = await forgotPasswordMutation({
        variables: {
          email,
        },
      });
      if (forgotPassword?.data?.setForgotPassword?.ok) {
        setStateValue(ForgotPasswordState.Succeeded);
        setErrorStateValue(null);
        navigate('/email-sent', { state: { email: emailRef.current?.value }, replace: true });
      } else {
        setStateValue(ForgotPasswordState.Failed);
        setErrorStateValue(forgotPasswordErrorStr?.message || errorMessage);
      }
    }
  };

  return (
    <div className={classes.loginContainer}>
      <div className={classnames(sharedClasses.banner, sharedClasses.loginBanner)}></div>
      <div className={classnames(sharedClasses.contentWrapper, classes.contentWrapper)}>
        <div className={classes.loginLogoContainer}>
          <img src={`${getAssetPath()}/images/logo_small.png`} height={48} />
          <p>Forgot Password</p>
        </div>
        <div className={classes.loginRow}>
          <span>Email:</span>
        </div>
        <div className={classes.loginRow}>
          <input
            type="email"
            name="email"
            disabled={stateValue === ForgotPasswordState.Submitting}
            placeholder="you@company.com"
            ref={emailRef}
            required
          />
        </div>
        <div className={classes.loginRow}>
          <FormButton isDisabled={stateValue === ForgotPasswordState.Submitting} onClick={continueFn}>
            Reset Password
          </FormButton>
        </div>
        <div className={classes.loginRow} style={{ textAlign: 'center' }}>
          <SecondaryLink to="/">Already have an account? Login</SecondaryLink>
        </div>
        {errorValue ? <div className={classes.error}>{errorValue}</div> : null}
      </div>
    </div>
  );
};

export default ForgotPassword;
