import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default (
  ref: React.MutableRefObject<any>,
  onClick: (t: EventTarget | null) => void,
  otherDependencies: any[] = []
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick(event.target);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, ...otherDependencies]);
};
