import React, { useEffect } from 'react';

import { generatePath, useMatch, useNavigate } from 'react-router-dom';

import RevenueCardWithBanner from './RevenueCardWithBanner';
import { useAppContext } from '../context/AppContext.tsx';

const TransformedRevenue: React.FC = () => {
  const { isSupportTransformedRevenue, companyData } = useAppContext();
  const navigate = useNavigate();
  const isTransformedRoute = !!useMatch('/app/transactions');

  useEffect(() => {
    if (!isTransformedRoute || !companyData?.currentCompany.name) {
      return;
    }

    if (!isSupportTransformedRevenue()) {
      navigate(generatePath('/app/revenue'), {
        replace: true,
      });
    }
  }, [companyData?.currentCompany.name, isSupportTransformedRevenue, isTransformedRoute, navigate]);

  if (isSupportTransformedRevenue()) {
    return <RevenueCardWithBanner />;
  }

  return null;
};

export default TransformedRevenue;
