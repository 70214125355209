import React, { useState } from 'react';
import { CollapsableMenuItemsProps, MenuItem } from './types.ts';
import { useStyles } from './styles.ts';
import { Spacer } from '../../../../components/Spacer.tsx';
import { assetPathGenerator } from '../../../../utils/AssetPathUtil.ts';
import { Draggable } from '../../renderProps/dragDrop/Draggable.tsx';

export const CollapsableMenuItem = ({ heading, items, onSetIsDragging }: MenuItem) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className={classes.wrapper}>
      <div onClick={() => setIsCollapsed(!isCollapsed)} className={classes.headingContainer}>
        <img src={assetPathGenerator('/images/icon_collapsable.svg')} height="16" />
        <Spacer width={8} />
        <div className={classes.heading}>{heading}</div>
      </div>
      <Spacer height={8} />
      <div className={`${classes.itemContainer} ${!isCollapsed ? classes.itemContainerExpanded : ''}`}>
        {items.map((item) => (
          <Draggable
            key={item.id}
            data={item}
            id={item.id}
            onDragStart={() => onSetIsDragging?.(true)}
            onDragEnd={() => onSetIsDragging?.(false)}
          >
            {({ isDragging }) => (
              <>
                {!isDragging ? (
                  <div className={classes.itemLabel}>{item?.label}</div>
                ) : (
                  <div className={[classes.dropOverlay, classes.itemLabel].join(' ')}>{item?.label}</div>
                )}
              </>
            )}
          </Draggable>
        ))}
      </div>
    </div>
  );
};

export const CollapsableMenuItems = React.memo(({ menuItems, ...props }: CollapsableMenuItemsProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {menuItems.map((item) => (
        <CollapsableMenuItem {...item} key={item.id} id={item.id} {...props} />
      ))}
    </div>
  );
});
