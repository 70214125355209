import React from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import { gql, useQuery } from '@apollo/client';

import ErrorMessage from '../common/ErrorMessage';
import LoaderAnimation from '../common/LoaderAnimation';
import PrettyJSON from '../common/PrettyJSON';
import TabbedContainers from '../common/TabbedContainers';
import { useSharedStyles } from '../../utils/CssUtil';
import {
  GetPrimaryAndSecondaryObjectsQuery,
  GetPrimaryAndSecondaryObjectsQueryVariables,
} from '../../generated/graphql';

const useStyles = createUseStyles({
  tabHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '13px',

    '& span': {
      display: 'block',
    },
  },
  cellPill: {
    letterSpacing: '0',
    marginLeft: '5px',
    padding: '4px',
  },
  rawJSONContainer: {
    overflowY: 'scroll',
    padding: '10px 5px',
  },
  loaderContainer: {
    textAlign: 'center',
    marginTop: '150px',
    marginRight: '85px',
    display: 'flex',
    justifyContent: 'center',
  },
  tabContent: {
    fontSize: '13px',
  },
});

const QUERY = gql`
  query GetPrimaryAndSecondaryObjects(
    $integrationRelationshipId: String!
    $primaryObjectId: String
    $primaryObjectTable: String
    $secondaryObjectId: String
    $secondaryObjectTable: String
  ) {
    rawPrimaryAndSecondaryObjects(
      integration_relationship_id: $integrationRelationshipId
      primary_object_id: $primaryObjectId
      primary_object_table: $primaryObjectTable
      secondary_object_id: $secondaryObjectId
      secondary_object_table: $secondaryObjectTable
    ) {
      primaryRvo {
        objectJsonStr
        vendor
      }
      secondaryRvo {
        objectJsonStr
        vendor
      }
      primaryIntegrationType
      secondaryIntegrationType
      primaryInstitution
      secondaryInstitution
    }
  }
`;

type TRawJsonProps = {
  primaryObjectId?: string;
  primaryObjectTable?: string;
  secondaryObjectId?: string;
  secondaryObjectTable?: string;
  integrationRelationshipId: string;
};

const ReconciliationExceptionRawJson: React.FunctionComponent<TRawJsonProps> = ({
  primaryObjectId,
  primaryObjectTable,
  secondaryObjectId,
  secondaryObjectTable,
  integrationRelationshipId,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const queryParams = {
    primaryObjectId,
    primaryObjectTable,
    secondaryObjectId,
    secondaryObjectTable,
    integrationRelationshipId,
  };

  const {
    loading: isLoading,
    error,
    data,
  } = useQuery<GetPrimaryAndSecondaryObjectsQuery, GetPrimaryAndSecondaryObjectsQueryVariables>(QUERY, {
    variables: queryParams,
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className={sharedClasses.content}>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <LoaderAnimation height={80} />
        </div>
      ) : (
        <TabbedContainers
          tabContentClass={classes.rawJSONContainer}
          tabContentStyle={{ height: 150 }}
          lightMode
          tabs={[
            ...(data?.rawPrimaryAndSecondaryObjects.primaryRvo
              ? [
                  {
                    header: (
                      <div className={classes.tabHeader}>
                        <span>Primary Source</span>
                        <span
                          className={classnames(sharedClasses.cellPill, classes.cellPill)}
                          data-pill-value={
                            data.rawPrimaryAndSecondaryObjects.primaryInstitution ||
                            data.rawPrimaryAndSecondaryObjects.primaryIntegrationType ||
                            data.rawPrimaryAndSecondaryObjects.primaryRvo.vendor
                          }
                        >
                          {data.rawPrimaryAndSecondaryObjects.primaryInstitution ||
                            data.rawPrimaryAndSecondaryObjects.primaryIntegrationType ||
                            data.rawPrimaryAndSecondaryObjects.primaryRvo.vendor}
                        </span>
                      </div>
                    ),

                    content: (
                      <div className={classes.tabContent}>
                        <PrettyJSON object={data.rawPrimaryAndSecondaryObjects.primaryRvo.objectJsonStr} />
                      </div>
                    ),
                  },
                ]
              : []),
            ...(data?.rawPrimaryAndSecondaryObjects.secondaryRvo
              ? [
                  {
                    header: (
                      <div className={classes.tabHeader}>
                        <span>Secondary Source</span>
                        <span
                          className={classnames(sharedClasses.cellPill, classes.cellPill)}
                          data-pill-value={
                            data.rawPrimaryAndSecondaryObjects.secondaryInstitution ||
                            data.rawPrimaryAndSecondaryObjects.secondaryRvo.vendor
                          }
                        >
                          {data.rawPrimaryAndSecondaryObjects.secondaryInstitution ||
                            data.rawPrimaryAndSecondaryObjects.secondaryRvo.vendor}
                        </span>
                      </div>
                    ),
                    content: (
                      <div className={classes.tabContent}>
                        <PrettyJSON object={data.rawPrimaryAndSecondaryObjects.secondaryRvo.objectJsonStr} />
                        {''}
                      </div>
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </div>
  );
};

export default ReconciliationExceptionRawJson;
