import React from 'react';

import Colors from './common/Colors';
import Modal from './common/Modal';
import {
  CreateStripeIntegrationMutation,
  CreateStripeIntegrationMutationVariables,
  GetSelfServeIntegrationListQuery,
  GetSelfServeIntegrationListQueryVariables,
} from '../generated/graphql';
import FormButton, { FormButtonStyle } from './common/FormButton';

import { createUseStyles } from 'react-jss';
import getAssetPath, { getVendorLogoAssetPath } from '../utils/AssetPathUtil';
import { gql, useMutation, useQuery } from '@apollo/client';

import { TIntegrationTypes } from '../../src/types/BaseTypes';

enum ModalState {
  LIST,
  CONFIG,
  SUCCESS,
}

type ConnectIntegrationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SELF_SERVE_VENDORS_QUERY = gql`
  query GetSelfServeIntegrationList {
    selfServeIntegrationList {
      integrationType
      displayName
      secretInstructions
      secretDisplayName
      secretType
      inputPlaceHolder
      isEnabled
    }
  }
`;

const STRIPE_MUTATION = gql`
  mutation CreateStripeIntegration($vendorSecret: String!) {
    createStripeIntegration(vendorSecret: $vendorSecret) {
      id
      entity {
        name
        integrationType
        state
        lastUpdatedByUserId
      }
    }
  }
`;

const useModalStyles = createUseStyles({
  backButton: {
    color: 'black',
    fontSize: 24,
    cursor: 'pointer',
  },
  searchBar: {
    background: 'transparent',
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    borderRadius: '6px',
    display: 'block',
    height: 29,
    width: '100%',
    fontSize: 13,
    padding: '16px 12px',
  },
  section: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    marginBottom: '24px',
  },
  title: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    marginBottom: '24px',
    fontSize: '15px',
    fontWeight: 700,
  },
  vendorContainer: {
    width: 73,
    cursor: 'pointer',
    '& > p': {
      fontSize: 11,
      marginTop: 6,
      marginBottom: 24,
      color: Colors.MEDIUM_GRAY,
    },
    '&:hover p': {
      color: Colors.MAIN_BLUE,
    },
    '&:hover div': {
      borderColor: Colors.MAIN_BLUE,
    },
  },
  vendorLogoContainer: {
    width: 72,
    height: 72,
    borderRadius: '6px',
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    backgroundSize: '60%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    '& > img.smallImage': {
      width: 32,
    },
  },
  leftLogo: {
    width: 56,
    backgroundSize: 32,
    backgroundPosition: '0 center',
    backgroundRepeat: 'no-repeat',
  },
  middleArrow: {
    width: '10%',
    color: `${Colors.MEDIUM_GRAY}`,
    height: 52,
    lineHeight: '46px',
  },
  rightLogo: {
    position: 'relative',
    width: 52,
    height: 52,
    left: 20,
    backgroundSize: '32px 32px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    borderRadius: '6px',
  },
  connectMessage: {
    fontSize: 12,
    lineHeight: 1.5,
    color: `${Colors.MEDIUM_GRAY}`,
    marginBottom: 24,
  },
  vendorSecretContainer: {
    display: 'flex',
    lineHeight: '28px',
    fontSize: '12px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  vendorSecretInput: {
    background: 'transparent',
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    borderRadius: '6px',
    color: Colors.DARK_GRAY,
    display: 'block',
    width: 172,
    height: 24,
    fontSize: 13,
    marginLeft: 15,
    flex: 1,
    padding: '16px 12px',
  },
  buttonContainer: {
    width: 'calc(100% - 64px)',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    paddingBottom: 30,

    '& > button': {
      width: 'calc(50% - 10px)',
      marginRight: 20,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  successMessage: {
    color: Colors.MAIN_BLUE,
  },
  errorMessage: {
    color: Colors.WARNING_RED,
  },
});

const ConnectIntegrationModal: React.FunctionComponent<ConnectIntegrationModalProps> = ({ isOpen, onClose }) => {
  const modalClasses = useModalStyles();

  const [state, setState] = React.useState<ModalState>(ModalState.LIST);
  const [selfServeVendor, setSelfServeVendor] = React.useState<any | null>(null);
  const [vendorSearchValue, setVendorSearchValue] = React.useState<string>('');
  const [vendorSecretValue, setVendorSecretValue] = React.useState<string>('');
  const [hasError, setHasError] = React.useState<boolean>(false);

  const { data } = useQuery<GetSelfServeIntegrationListQuery, GetSelfServeIntegrationListQueryVariables>(
    SELF_SERVE_VENDORS_QUERY
  );
  const selfServeVendors = data?.selfServeIntegrationList || [];

  const [setIntegrationMutation, { loading: submitting }] = useMutation<
    CreateStripeIntegrationMutation,
    CreateStripeIntegrationMutationVariables
  >(STRIPE_MUTATION);

  const resetModalState = () => {
    setSelfServeVendor(null);
    setVendorSearchValue('');
    setVendorSecretValue('');
    setHasError(false);
    setState(ModalState.LIST);
  };

  const setVendor = (vendor: any) => {
    return () => {
      if (!vendor?.isEnabled) {
        return;
      }
      setSelfServeVendor(vendor);
      setState(ModalState.CONFIG);
    };
  };

  const closeIntegrationModal = () => {
    if (!submitting) {
      resetModalState();
      onClose();
    }
  };

  const getFilteredVendors = () => {
    return selfServeVendors.filter((x) => {
      return x.displayName.toLowerCase().includes(vendorSearchValue.toLowerCase());
    });
  };

  const connectIntegration = () => {
    setIntegrationMutation({
      variables: {
        vendorSecret: vendorSecretValue,
      },
    })
      .then((result) => {
        if (result.data?.createStripeIntegration.id) {
          setHasError(false);
          setState(ModalState.SUCCESS);
        } else {
          setHasError(true);
        }
      })
      .catch((_) => {
        setHasError(true);
      });
  };

  const handleReturnToVendorList = () => {
    setSelfServeVendor(null);
    setState(ModalState.LIST);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeIntegrationModal}
      contentLabel="Connect with New Integration"
      style={{
        content: {
          color: Colors.DARK_GRAY,
          fontFamily: 'Helvetica Neue',
          width: 431,
          height: 428,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'visible',
          padding: 32,
        },
      }}
    >
      {state === ModalState.LIST && (
        <div>
          <div className={modalClasses.title}>Connect New Integration</div>
          <div className={modalClasses.section}>
            <input
              type="search"
              name="search"
              placeholder="Search by integration name"
              value={vendorSearchValue}
              onChange={(e) => setVendorSearchValue(e.target.value)}
              className={modalClasses.searchBar}
            />
          </div>
          {vendorSearchValue && getFilteredVendors().length === 0 ? (
            <div className={modalClasses.section} style={{ height: 164, width: 252 }}>
              <p style={{ fontSize: 9, color: Colors.MEDIUM_GRAY, position: 'relative', top: '50%' }}>
                No integrations found
              </p>
            </div>
          ) : (
            <div
              className={modalClasses.section}
              style={{ display: 'flex', height: 260, columnGap: 16, flexWrap: 'wrap', overflowY: 'auto' }}
            >
              {getFilteredVendors().map((vendor) => {
                return (
                  <div key={vendor.integrationType} className={modalClasses.vendorContainer}>
                    <div
                      key={vendor.integrationType}
                      className={modalClasses.vendorLogoContainer}
                      onClick={setVendor(vendor)}
                      style={{
                        backgroundImage: `url("${getVendorLogoAssetPath(
                          vendor.integrationType as TIntegrationTypes
                        )}")`,
                      }}
                    ></div>
                    <p>{vendor.displayName}</p>
                  </div>
                );
              })}
            </div>
          )}
          <div className={modalClasses.buttonContainer}>
            <FormButton style={FormButtonStyle.PLAIN} onClick={closeIntegrationModal} height={40}>
              Cancel
            </FormButton>
          </div>
        </div>
      )}

      {(state === ModalState.CONFIG || submitting) && (
        <div>
          <div className={modalClasses.backButton} onClick={handleReturnToVendorList} style={{ position: 'fixed' }}>
            {'\u2190'}
          </div>
          <div className={modalClasses.title}>Connect {selfServeVendor?.displayName}</div>
          <div className={modalClasses.section} style={{ display: 'flex', height: 52, justifyContent: 'center' }}>
            <div
              className={modalClasses.leftLogo}
              style={{ backgroundImage: `url("${getAssetPath()}/images/logo_small.png")` }}
            ></div>
            <div className={modalClasses.middleArrow}>{'\u27F5'}</div>
            <div
              className={modalClasses.rightLogo}
              style={{
                backgroundImage: `url("${getVendorLogoAssetPath(
                  selfServeVendor.integrationType as TIntegrationTypes
                )}")`,
              }}
            ></div>
          </div>
          {!hasError ? (
            <div className={modalClasses.connectMessage}>{selfServeVendor?.secretInstructions}</div>
          ) : (
            <div className={`${modalClasses.connectMessage} ${modalClasses.errorMessage}`}>
              Unable to connect to {selfServeVendor?.displayName}. Please double check your secret key, or contact
              support support@gonumeral.com for assistance.
            </div>
          )}
          <div className={modalClasses.vendorSecretContainer}>
            <label>{selfServeVendor?.secretDisplayName}: </label>
            <input
              className={modalClasses.vendorSecretInput}
              placeholder={selfServeVendor?.inputPlaceHolder}
              disabled={submitting}
              onChange={(e) => setVendorSecretValue(e.target.value)}
            ></input>
          </div>
          <div className={modalClasses.buttonContainer}>
            <FormButton
              style={FormButtonStyle.PLAIN}
              height={40}
              onClick={closeIntegrationModal}
              isDisabled={submitting}
            >
              Cancel
            </FormButton>
            <FormButton
              style={FormButtonStyle.ACCENTED}
              height={40}
              onClick={connectIntegration}
              isDisabled={submitting || !vendorSecretValue}
              extraStyle={{ marginBottom: 14 }}
            >
              {submitting ? 'Connecting...' : 'Connect'}
            </FormButton>
          </div>
        </div>
      )}

      {state === ModalState.SUCCESS && !submitting && (
        <div>
          <div className={modalClasses.title}>Connect {selfServeVendor?.displayName}</div>
          <div className={modalClasses.section} style={{ display: 'flex', height: 52, justifyContent: 'center' }}>
            <div
              className={modalClasses.leftLogo}
              style={{ backgroundImage: `url("${getAssetPath()}/images/logo_small.png")` }}
            ></div>
            <div className={modalClasses.middleArrow}>{'\u27F5'}</div>
            <div
              className={modalClasses.rightLogo}
              style={{
                backgroundImage: `url("${getVendorLogoAssetPath(
                  selfServeVendor.integrationType as TIntegrationTypes
                )}")`,
              }}
            ></div>
          </div>
          <div className={`${modalClasses.connectMessage} ${modalClasses.successMessage}`}>
            Successfully connected to <b>{selfServeVendor?.displayName}</b>. Numeral support team will reach out to
            complete this integration.
          </div>

          <div className={modalClasses.buttonContainer}>
            <FormButton style={FormButtonStyle.PLAIN} height={40} onClick={closeIntegrationModal}>
              Done
            </FormButton>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ConnectIntegrationModal;
