import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { createUseStyles } from 'react-jss';

const ANIMATION = '$pulsate 0.45s linear infinite alternate';
const PULSATE = {
  '0%': {
    width: '15%',
  },
  '100%': {
    width: '100%',
  },
};

const useStyles = createUseStyles({
  shimmerBar: {
    '-webkit-animation': ANIMATION,
    '-moz-animation': ANIMATION,
    animation: ANIMATION,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '2px',
    display: 'inline-block',
  },

  shimmerBarLightMode: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },

  shimmerBarDisabled: {
    '-webkit-animation': 'none',
    '-moz-animation': 'none',
    animation: 'none',
    width: '15%',
  },

  '@-webkit-keyframes pulsate': PULSATE,
  '@-moz-keyframes pulsate': PULSATE,
  '@keyframes pulsate': PULSATE,
});

type ShimmerBarProps = {
  isLightMode?: boolean;
  delayMs?: number;
  height: number;
  width: number;
};

const ShimmerBar: React.FunctionComponent<ShimmerBarProps> = (props: ShimmerBarProps) => {
  const delayMs = props.delayMs || 0;
  const [startedTransitioning, updateStartedTransitioningState] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    let isMounted = true;
    if (!startedTransitioning) {
      window.setTimeout(() => {
        if (isMounted) {
          updateStartedTransitioningState(true);
        }
      }, delayMs);
    }
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <span
      style={{
        display: 'inline-block',
        height: `${props.height}px`,
        textAlign: 'left',
        width: `${props.width}px`,
      }}
    >
      <span
        className={classNames(classes.shimmerBar, {
          [classes.shimmerBarDisabled]: !startedTransitioning,
          [classes.shimmerBarLightMode]: !!props.isLightMode,
        })}
        style={{
          height: `${props.height}px`,
        }}
      />
    </span>
  );
};

export default ShimmerBar;
