import React from 'react';
import { DraggableProps, WithId } from './types.ts';

export const Draggable = <T extends WithId>({
  id,
  data,
  children,
  onDragStart,
  onDragLeave,
  onDragEnd,
}: DraggableProps<T>) => {
  const [isDragging, setIsDragging] = React.useState(false);
  const [isOverlayActive, setIsOverlayActive] = React.useState(false);

  const onDragStartHandler = (event: React.DragEvent) => {
    setIsOverlayActive(true);
    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    // Set the drag effect, if necessary (e.g., 'move')
    event.dataTransfer.effectAllowed = 'move';
    onDragStart?.(event, data);
    setIsDragging(true);
  };

  const onDragEndHandler = (event: React.DragEvent) => {
    setIsDragging(false);
    onDragEnd?.(event);
  };

  return (
    <div draggable onDragStart={onDragStartHandler} onDragLeave={onDragLeave} onDragEnd={onDragEndHandler} id={id}>
      {children({ isDragging, isOverlayActive })}
    </div>
  );
};
