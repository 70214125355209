import { AnimatedWrapperProps } from './types.ts';
import React from 'react';
import { useStyles } from './styles.ts';

export const AnimatedWrapper = ({
  children,
  isCollapsed,
  hasAnimation,
  width,
  toggleWidth,
  relative,
}: AnimatedWrapperProps) => {
  const classes = useStyles({ sidebarWidth: width, toggleWidth, relative });
  return (
    <div
      className={`
  ${classes.sidebar}
  ${isCollapsed ? classes.sidebarCollapsed : ''}
  ${hasAnimation ? classes.sidebarWithAnimation : ''}
`}
    >
      {children}
    </div>
  );
};
