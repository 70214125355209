import React from 'react';
import { assetPathGenerator } from '../../../../utils/AssetPathUtil.ts';

export const CurrencyToggle = () => {
  return (
    <div className="currency-wrapper">
      <img src={assetPathGenerator('/images/icon_currency.svg')} height="16" />
      <span>Currency:</span>
      <select className="currency-select" value="USD">
        <option value="USD">USD</option>
        <option value="EUR">EUR</option>
        <option value="GBP">GBP</option>
        <option value="JPY">JPY</option>
      </select>
    </div>
  );
};
