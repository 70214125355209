import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import { gql, useMutation, useQuery } from '@apollo/client';

import Colors from './common/Colors';
import FormButton from './common/FormButton';
import { TPasswordConfig } from '../../src/validators/ValidatePassword';
import Toggle from './common/Toggle';
import ValidatedPasswordInput from './common/ValidatedPasswordInput';
import { useSharedStyles } from '../utils/CssUtil';
import {
  GetCurrentUserSettingsQuery,
  SetChangePasswordMutation,
  SetChangePasswordMutationVariables,
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables,
} from '../generated/graphql';

const USER_SETTINGS_QUERY = gql`
  query GetCurrentUserSettings {
    currentUser {
      id
      settings {
        isTwoFactorEnabled
      }
      company {
        isRealCustomerCompany
      }
    }
  }
`;

const USER_SETTINGS_MUTATION = gql`
  mutation UpdateUserSettings($is_two_factor_enabled: Boolean!) {
    setTwoFactorSettings(isTwoFactorEnabled: $is_two_factor_enabled) {
      id
      settings {
        isTwoFactorEnabled
      }
    }
  }
`;

const useStyles = createUseStyles({
  settingsWrapper: {
    marginTop: '-235px',
  },
  settingsSubheader: {
    color: Colors.MEDIUM_GRAY,
    fontSize: '14px',
    fontWeight: 500,
  },
  settingsTwoFactorSubheader: {
    color: Colors.MEDIUM_GRAY,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1px',
    paddingBottom: '10px',
  },
  settingsRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    alignItems: 'center',
    marginBottom: 15,
    marginLeft: 10,
    width: 450,
  },
  settingsLabel: {
    alignSelf: 'flex-start',
    marginTop: 4,
    fontSize: '14px',
    marginRight: 10,
    width: 150,
  },
  settingsInput: {
    width: '160px',
    borderRadius: '5px',
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    fontSize: '14px',
    padding: '5px 8px',
    outline: 'none',
  },
  settingsButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: '14px',
    marginLeft: 160,
  },
  settingsError: {
    color: Colors.WARNING_RED,
    fontSize: '14px',
    marginTop: '-8px',
  },
  settingsSuccess: {
    color: Colors.DARK_GRAY,
    fontSize: '14px',
    marginTop: '-8px',
  },
});

const CHANGE_PASSWORD_MUTATION = gql`
  mutation SetChangePassword($currentPassword: String!, $newPassword: String!, $confirmPassword: String!) {
    setChangePassword(currentPassword: $currentPassword, newPassword: $newPassword, confirmPassword: $confirmPassword) {
      ok
      message
    }
  }
`;

enum ChangePasswordState {
  Initial,
  Submitting,
  Succeeded,
  Failed,
}

const SecuritySettings: React.FunctionComponent = () => {
  const { data } = useQuery<GetCurrentUserSettingsQuery>(USER_SETTINGS_QUERY);
  const [changeStateMutation] = useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    USER_SETTINGS_MUTATION
  );
  const [stateValue, setStateValue] = useState<ChangePasswordState>(ChangePasswordState.Submitting);
  const [errorValue, setErrorStateValue] = useState<string | null>(null);
  const [successValue, setSuccesstateValue] = useState<string | null>(null);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [canChangePassword, setCanChangePassword] = useState(false);
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const [changePasswordMutation, { error: changePasswordErrorStr }] = useMutation<
    SetChangePasswordMutation,
    SetChangePasswordMutationVariables
  >(CHANGE_PASSWORD_MUTATION);

  const toggleHandler = (state: boolean) => () => {
    changeStateMutation({
      variables: {
        is_two_factor_enabled: state === true ? false : true,
      },
    });
  };

  const updatePassword = async () => {
    let errorMessage = 'An error occurred';
    let isValidSoFar = true;
    const currentPassword = currentPasswordRef.current?.value;
    const newPassword = newPasswordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;

    if (!currentPassword) {
      errorMessage = 'Please enter current password.';
      isValidSoFar = false;
    }
    if (!newPassword && isValidSoFar) {
      errorMessage = 'Please enter a new password.';
      isValidSoFar = false;
    }
    if (!confirmPassword && isValidSoFar) {
      errorMessage = 'Please enter confirm password.';
      isValidSoFar = false;
    }
    if (newPassword && confirmPassword && newPassword !== confirmPassword && isValidSoFar) {
      errorMessage = 'New password and confirm password do not match.';
      isValidSoFar = false;
    }
    if (!isValidPassword && isValidSoFar) {
      errorMessage = 'Please confirm new password adheres to requirements.';
      isValidSoFar = false;
    }
    if (!isValidSoFar) {
      setStateValue(ChangePasswordState.Failed);
      setErrorStateValue(changePasswordErrorStr?.message || errorMessage);
      return;
    }
    setStateValue(ChangePasswordState.Submitting);

    if (currentPassword && newPassword && confirmPassword) {
      const changeUserPassword = await changePasswordMutation({
        variables: {
          currentPassword,
          newPassword,
          confirmPassword,
        },
      });
      if (changeUserPassword?.data?.setChangePassword?.ok) {
        setStateValue(ChangePasswordState.Succeeded);
        setErrorStateValue(null);
        setSuccesstateValue('Password updated successfully.');
      } else {
        setStateValue(ChangePasswordState.Failed);
        setErrorStateValue(
          changePasswordErrorStr?.message || changeUserPassword?.data?.setChangePassword?.message || errorMessage
        );
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const resp = await fetch('/me');
      const data = await resp.json();
      if (data.canChangePassword) {
        setCanChangePassword(true);
      } else {
        // user signed on with google SSO, can't change password
        setSuccesstateValue("Can't change password for users authenticated with Google SSO.");
      }
      setStateValue(ChangePasswordState.Initial);
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className={classnames(sharedClasses.contentWrapper, classes.settingsWrapper)}>
        <div className={classes.settingsSubheader}>Change Password</div>
        <div className={classes.settingsRow}>
          <span className={classes.settingsLabel}>Current Password:</span>
          <input
            className={classes.settingsInput}
            type="password"
            ref={currentPasswordRef}
            disabled={!canChangePassword}
          />
        </div>
        <div className={classes.settingsRow}>
          <span className={classes.settingsLabel}>New Password:</span>
          <ValidatedPasswordInput
            className={classes.settingsInput}
            validationRequirements={TPasswordConfig}
            setIsValid={setIsValidPassword}
            disabled={!canChangePassword}
            innerRef={newPasswordRef}
          />
        </div>
        <div className={classes.settingsRow}>
          <span className={classes.settingsLabel}>Confirm Password:</span>
          <input
            className={classes.settingsInput}
            type="password"
            ref={confirmPasswordRef}
            disabled={!canChangePassword}
          />
        </div>
        <div className={classes.settingsRow}>
          <div className={classes.settingsButtonContainer}>
            <FormButton
              isDisabled={stateValue === ChangePasswordState.Submitting || !canChangePassword}
              onClick={updatePassword}
              width={160}
            >
              Update Password
            </FormButton>
          </div>
        </div>
        {errorValue ? <div className={classes.settingsError}>{errorValue}</div> : null}
        {successValue ? <div className={classes.settingsSuccess}>{successValue}</div> : null}
        <div />
        {data === undefined || data?.currentUser.company?.isRealCustomerCompany ? null : (
          <div>
            <div className={classes.settingsTwoFactorSubheader}>Enable Two Factor Authentication</div>
            <Toggle
              isOn={data?.currentUser.settings?.isTwoFactorEnabled || false}
              onClick={toggleHandler(data?.currentUser.settings?.isTwoFactorEnabled || false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SecuritySettings;
