import Colors from '../../../../components/common/Colors';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  logoContainer: {
    margin: '20px 0',
    textAlign: 'center',
  },
  logoImage: {
    height: '64px',
    width: '64px',
  },
  idContainer: {
    marginBottom: '10px',
    textAlign: 'center',
  },
  nameContainer: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  entityContainer: {
    fontSize: '14px',
    textAlign: 'center',
    padding: '5px',
  },
  companyContainer: {
    color: Colors.MEDIUM_GRAY,
    fontSize: '16px',
    fontWeight: 400,
    margin: '12px 25px',
    textAlign: 'left',
  },
});
