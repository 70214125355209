import React, { useRef } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import Colors from './common/Colors';
import { pageSizesAllowed } from '../../src/types/DataHubTypes';

const useStyles = createUseStyles({
  tablePageLink: {
    color: Colors.MEDIUM_GRAY,
    display: 'inline-block',
    padding: '0 5px',
  },
  tablePageLinkDisabled: {
    cursor: 'default',
  },
  tablePageLabel: {
    margin: '0 3px',
  },
  tablePageInputLabel: {
    marginLeft: '20px',
  },
  tablePageInput: {
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: '3px',
    color: Colors.MEDIUM_GRAY,
    fontSize: '12px',
    lineHeight: '16px',
    outline: 'none',
    width: '60px',
    '&:focus': {
      border: `1px solid ${Colors.DARK_GRAY}`,
      color: Colors.DARK_GRAY,
    },
  },
  valueSelected: {
    fontWeight: 'bold',
  },
});

type PagingControlsProps = {
  canPageBackward: boolean;
  canPageForward: boolean;
  currentPage: number;
  pageCount: number;
  pageSize: number;
  refetchPage: (p: number, ps: number) => void;
  shouldDisableChangingPageSize?: boolean;
};

const PagingControls: React.FunctionComponent<PagingControlsProps> = ({
  canPageBackward,
  canPageForward,
  currentPage,
  pageCount,
  pageSize,
  refetchPage,
  shouldDisableChangingPageSize = false,
}: PagingControlsProps) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <a
        className={classnames(classes.tablePageLink, canPageBackward ? '' : classes.tablePageLinkDisabled)}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if (!canPageBackward) {
            return;
          }
          refetchPage(currentPage - 1, pageSize);
        }}
      >
        «
      </a>
      <span className={classes.tablePageLabel}>
        Page {currentPage.toLocaleString()} of {pageCount.toLocaleString()}
      </span>
      <a
        className={classnames(classes.tablePageLink, canPageForward ? '' : classes.tablePageLinkDisabled)}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if (!canPageForward) {
            return;
          }
          refetchPage(currentPage + 1, pageSize);
        }}
      >
        »
      </a>
      {shouldDisableChangingPageSize ? null : (
        <>
          <span className={classes.tablePageLabel}>Page Size:</span>
          {pageSizesAllowed.map((val) => (
            <a
              key={val}
              className={classnames({
                [classes.tablePageLink]: true,
                [classes.valueSelected]: val === pageSize,
                [classes.tablePageLinkDisabled]: val === pageSize,
              })}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                refetchPage(1, val);
              }}
            >
              {val}
            </a>
          ))}
        </>
      )}
      <span className={classes.tablePageInputLabel}>Go to page: </span>
      <input
        className={classes.tablePageInput}
        ref={inputRef}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            const val = Number(inputRef.current?.value);
            if (!!val && val !== currentPage && val >= 1 && val <= pageCount) {
              refetchPage(val, pageSize);
            }
          }
        }}
      />
    </>
  );
};

export default PagingControls;
