import Colors from '../Colors.tsx';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  buttonBase: {
    borderRadius: '3px',
    color: Colors.WHITE,
    cursor: 'pointer',
    fontSize: '14px',
    letterSpacing: '1px',
    transition: 'background-color 0.1s',
    alignItems: 'center',
  },
  small: {
    fontSize: '12px',
    padding: '8px 16px',
  },
  medium: {
    fontSize: '16px',
    padding: '12px 20px',
  },
  large: {
    fontSize: '20px',
    padding: '16px 24px',
  },
  expand: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  buttonAccented: {
    background: Colors.LIGHT_BLUE,
    border: `1px solid ${Colors.MAIN_BLUE}`,
    '&:hover': { background: Colors.MAIN_BLUE },
  },
  buttonNeutral: {
    background: Colors.MEDIUM_GRAY,
    border: `1px solid ${Colors.DARK_GRAY}`,
    '&:hover': { background: Colors.DARK_GRAY },
  },
  buttonPlain: {
    color: Colors.DARK_GRAY,
    background: Colors.WHITE,
    border: `1px solid ${Colors.DARK_GRAY}`,
    '&:hover': { background: Colors.LIGHT_GRAY },
  },
  buttonStale: {
    background: Colors.MEDIUM_GRAY,
    border: `1px solid ${Colors.STALE_DARK_GRAY}`,
    '&:hover': { background: Colors.DARK_GRAY },
  },
  buttonDark: {
    color: Colors.WHITE,
    background: Colors.DARK_GRAY,
    border: `1px solid ${Colors.STALE_DARK_GRAY}`,
    '&:hover': { background: Colors.DARK_GRAY },
  },
  buttonDisabled: {
    background: Colors.DISABLED,
    border: `1px solid ${Colors.DISABLED_BORDER}`,
    color: Colors.DISABLED_TEXT,
    cursor: 'default',
    '&:hover': { background: Colors.DISABLED },
  },
});
