import React, { useRef, useState } from 'react';

import { createUseStyles } from 'react-jss';

import Colors from './Colors';
import useOutsideClick from '../../hooks/useOutsideClick';
import {
  DataHubGraphQLParam,
  DataHubGraphQLParamToURLParam,
  DataHubURLParam,
  StringToStringMap,
} from '../../../src/types/DataHubTypes';
import FormButton, { FormButtonStyle } from './FormButton';
import { STRING_COL_FILTER_MODAL_WIDTH_PX, StringColFilterDelimiter } from '../../utils/TableConstants';

const useStyles = createUseStyles({
  stringColFilterModal: {
    cursor: 'hand',
    background: Colors.WHITE,
    boxShadow: '0 0 2px rgba(0,0,0,0.25)',
    position: 'absolute',
    marginTop: '18px',
    width: STRING_COL_FILTER_MODAL_WIDTH_PX,
    borderRadius: '4px',
    zIndex: 1,
  },
  stringColFilterModalTitle: {
    display: 'block',
    margin: '26px 14px 6px',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '15px',
  },
  textboxRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    margin: '12px 14px',
  },
  textboxInput: {
    width: 106,
    height: 24,
    marginRight: '15px',
  },
  textboxControlButtons: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    margin: '14px',
  },
});

type TableColumnFilterStrModalProps = {
  label: string;
  colKey?: string;
  onColFilter?: (obj: StringToStringMap) => void;
  getDropdownPosition: () =>
    | {
        left: number;
        top: number;
      }
    | undefined;
  updateIsStringColFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  searchParams?: URLSearchParams;
};

const TableColumnFilterStrModal: React.FunctionComponent<TableColumnFilterStrModalProps> = ({
  label,
  colKey,
  onColFilter,
  getDropdownPosition,
  updateIsStringColFilterVisible,
  searchParams,
}: TableColumnFilterStrModalProps) => {
  const classes = useStyles();

  const filterKey = DataHubGraphQLParamToURLParam[colKey as DataHubGraphQLParam] as DataHubURLParam;

  const defaultFilterValues: string[] = searchParams?.get(filterKey)?.split(StringColFilterDelimiter) || [''];

  const [stringColFilterValues, updateStringColFilterValues] = useState<string[]>(defaultFilterValues);

  const ref = useRef(null);
  useOutsideClick(ref, () => {
    updateIsStringColFilterVisible(false);
  });

  return (
    <div
      className={classes.stringColFilterModal}
      style={{
        ...(getDropdownPosition() || {}),
      }}
      onClick={(e) => e.stopPropagation()}
      ref={ref}
    >
      <span className={classes.stringColFilterModalTitle}>Filter by {label}</span>
      {stringColFilterValues?.map((item, idx) => (
        <div key={`string-col-filter-${idx}`} className={classes.textboxRow}>
          <input
            className={classes.textboxInput}
            type="text"
            placeholder={label}
            value={item}
            onChange={(e) => {
              updateStringColFilterValues((prev) => {
                const newValues = [...prev];
                newValues[idx] = e.target.value;
                return newValues;
              });
            }}
          />
          <FormButton
            style={FormButtonStyle.PLAIN}
            width={25}
            height={24}
            onClick={() => {
              updateStringColFilterValues((prev) => {
                const newValues = [...prev];
                newValues.splice(idx, 1);
                if (newValues.length > 0) {
                  return newValues;
                }
                return [''];
              });
            }}
          >
            ×
          </FormButton>
        </div>
      ))}

      <div className={classes.textboxControlButtons}>
        <FormButton
          style={FormButtonStyle.PLAIN}
          width={68}
          height={32}
          onClick={() => {
            updateStringColFilterValues((prev) => {
              const newValues = [...prev, ''];
              return newValues;
            });
          }}
        >
          Add ID
        </FormButton>
        <FormButton
          style={FormButtonStyle.ACCENTED}
          width={68}
          height={32}
          onClick={() => {
            if (stringColFilterValues.length > 0 && onColFilter) {
              onColFilter({ [filterKey]: stringColFilterValues.join(StringColFilterDelimiter) });
              updateIsStringColFilterVisible(false);
            }
          }}
        >
          Filter
        </FormButton>
      </div>
    </div>
  );
};

export default TableColumnFilterStrModal;
