import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';
import { DataGridRow } from '../DataGridTypes.ts';
import { useNavigate } from 'react-router-dom';

export const useLinkFormatter = () => {
  const navigate = useNavigate();

  const linkFormatter = (cell: Tabulator.CellComponent) => {
    const data = cell.getData() as DataGridRow;
    const label = cell.getValue();
    const colDef = cell?.getColumn()?.getDefinition();
    const cellElem = cell.getElement();

    // apply cell class if exists
    const cellCssClass = data?._cssClass?.[cell.getField()];
    if (cellCssClass) {
      cellElem.classList.add(cellCssClass);
    }

    // create hyperlink if URL exists
    const value = data?._cellData?.[cell.getField()]?.url;
    if (value) {
      const el = document.createElement('a');
      el.setAttribute('href', value ? String(value) : '#');
      el.setAttribute('target', '_blank');
      el.innerText = label;

      const cssClass = (colDef?.formatterParams as Tabulator.JSONRecord)?.cssClass;
      if (cssClass) {
        el.classList.add(cssClass.toString());
      }
      return el;
    }
    return label;
  };

  return linkFormatter;
};
