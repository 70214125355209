import React, { useState } from 'react';

import { createUseStyles } from 'react-jss';
import pluralize from 'pluralize';

import Colors from './Colors';
import isValidPassword, {
  TPasswordValidationRules,
  getMaximumRecurringSubSequenceLength,
} from '../../../src/validators/ValidatePassword';

const useStyles = createUseStyles({
  requirementItemWrapper: {
    display: 'flex',
  },
  validationRequirementsList: {
    marginTop: 6,
    color: Colors.DARK_GRAY,
    fontSize: 12,
  },
  satisfiedRequirement: {
    color: Colors.DARK_GREEN,
    marginLeft: 2,
  },
  unsatisfiedRequirement: {
    marginLeft: 14,
  },
});

type TValidatedPasswordInputProps = {
  validationRequirements: TPasswordValidationRules;
  setIsValid: (isValid: boolean) => void;

  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  onChange?: (args?: any) => any;
  onKeyPress?: (args?: any) => any;
  innerRef?: React.LegacyRef<HTMLInputElement>;
};

const ValidatedPasswordInput: React.FunctionComponent<TValidatedPasswordInputProps> = ({
  validationRequirements: {
    disallowedSymbols,
    maxLength,
    minLength,
    isMixedCaseRequired,
    requiredNumbersCount,
    requiredSymbolsCount,
    maximumRepeatingChars,
  },
  setIsValid,
  className,
  onChange,
  onKeyPress,
  style,
  disabled,
  innerRef,
}: TValidatedPasswordInputProps) => {
  const classes = useStyles();
  const [, setCurrentPasswordValue] = useState<string | undefined>();

  const buildRulesDisplay = (currentStr: string) => {
    const includedSymbols = Array.from(new Set(currentStr.match(/[^0-9A-z]/g) || []));
    const currentLength = currentStr.length;
    const currentNumbersCount = currentStr.match(/[0-9]/g)?.length || 0;
    const currentSymbolsCount = includedSymbols.length;
    const includesMixedCase = !!(currentStr.match(/[A-Z]/g)?.length && currentStr.match(/[a-z]/g)?.length);
    const maximumSequenceLength = getMaximumRecurringSubSequenceLength(currentStr);
    const outputArr: { isValid: boolean; message: string }[] = [];
    if (minLength) {
      outputArr.push({
        isValid: currentLength >= minLength,
        message: `At least ${minLength} ${pluralize('character', minLength)}`,
      });
    }
    if (maxLength) {
      outputArr.push({
        isValid: currentLength <= maxLength && currentLength !== 0,
        message: `Maximum of ${maxLength} ${pluralize('character', maxLength)}`,
      });
    }
    if (requiredSymbolsCount) {
      outputArr.push({
        isValid: currentSymbolsCount >= requiredSymbolsCount,
        message: `At least ${requiredSymbolsCount} special ${pluralize('character', requiredSymbolsCount)}`,
      });
    }
    if (requiredNumbersCount) {
      outputArr.push({
        isValid: currentNumbersCount >= requiredNumbersCount,
        message: `At least ${requiredNumbersCount} ${pluralize('number', requiredNumbersCount)}`,
      });
    }
    if (maximumRepeatingChars) {
      outputArr.push({
        isValid: maximumSequenceLength <= maximumRepeatingChars && currentLength !== 0,
        message:
          maximumRepeatingChars === 1
            ? 'No repeating characters'
            : `No more than ${maximumRepeatingChars} repeating characters in a row`,
      });
    }
    if (isMixedCaseRequired) {
      outputArr.push({
        isValid: includesMixedCase,
        message: 'A mixture of upper and lower case characters',
      });
    }
    if (disallowedSymbols) {
      outputArr.push({
        isValid: !includedSymbols.some((char) => disallowedSymbols.includes(char)),
        message: `Does not include: ${disallowedSymbols}`,
      });
    }
    return outputArr.map(({ isValid, message }, idx) => (
      <div key={idx} className={classes.requirementItemWrapper}>
        {isValid ? <img src={'/images/icon_checkmark_green.svg'} width="12px" height="12px" /> : undefined}
        <div className={isValid ? classes.satisfiedRequirement : classes.unsatisfiedRequirement}>{message}</div>
      </div>
    ));
  };

  const [remainingValidationRequirements, setRemainingValidationRequirements] = useState<JSX.Element[]>(
    buildRulesDisplay('')
  );

  return (
    <div style={style}>
      <input
        className={className}
        type="password"
        name="password"
        disabled={disabled}
        onChange={(e) => {
          const currentStr = e.target.value;
          setCurrentPasswordValue(currentStr);
          setRemainingValidationRequirements(buildRulesDisplay(currentStr));
          try {
            isValidPassword(currentStr);
            setIsValid(true);
          } catch (err) {
            setIsValid(false);
          }
          if (onChange) onChange(e);
        }}
        onKeyPress={onKeyPress}
        ref={innerRef}
      />
      <div className={classes.validationRequirementsList}>{remainingValidationRequirements}</div>
    </div>
  );
};

export default ValidatedPasswordInput;
