import React from 'react';

import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

import Colors from '../common/Colors.tsx';
import { DataHubURLParam } from '../../../src/types/DataHubTypes';
import { DateFormatYearMonth, DateFormatYearMonthDate12HourTime } from '../../../src/utils/FormatUtil';
import KeyValueList from '../common/KeyValueList';
import SectionNavHeader from '../SectionNavHeader';
import SectionWithHeader from '../common/SectionWithHeader';
import { TPaymentRecognitionScheduleSections } from '../../../src/types/BaseTypes';
import { useSharedStyles } from '../../utils/CssUtil';
import {
  TransactionRecognitionTableContractLevelFragmentFragment,
  TransactionRecognitionTableInvoiceFragmentFragment,
} from '../../generated/graphql';

const useStyles = createUseStyles({
  overviewSection: {
    '& *': {
      fontSize: '13px',
    },
    '& a, & a:hover, & a:visited': {
      color: Colors.DARK_GRAY,
      textDecoration: 'underline dashed',
    },
  },
});

type PaymentRecognitionScheduleCardInvoiceOverviewProps = {
  contractData?: TransactionRecognitionTableContractLevelFragmentFragment;
  invoice?: TransactionRecognitionTableInvoiceFragmentFragment;
  reportSettingsExclude?: string[] | null;
  timezoneLocation?: string;
};
const PaymentRecognitionScheduleCardInvoiceOverview: React.FunctionComponent<
  PaymentRecognitionScheduleCardInvoiceOverviewProps
> = ({
  contractData,
  invoice,
  reportSettingsExclude,
  timezoneLocation,
}: PaymentRecognitionScheduleCardInvoiceOverviewProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  if (
    invoice &&
    contractData &&
    !(reportSettingsExclude || []).includes(TPaymentRecognitionScheduleSections.INVOICE_OVERVIEW)
  ) {
    const customerId = contractData?.contract?.customer?.id;
    const invoiceVendorExternalId = invoice.entity.vendorExternalId;
    const invoiceIssuedAtMoment = moment(invoice.entity.issuedAt);
    const customerActiveSinceMoment = invoice.entity.customerActiveSince
      ? moment(invoice.entity.customerActiveSince)
      : null;
    if (timezoneLocation) {
      invoiceIssuedAtMoment.tz(timezoneLocation);
      if (customerActiveSinceMoment) {
        customerActiveSinceMoment.tz(timezoneLocation);
      }
    }
    const kvPairs = [
      {
        key: 'Invoice ID:',
        value: invoice.id,
      },
      {
        key: 'Invoice Issued At:',
        value: invoiceIssuedAtMoment.format(DateFormatYearMonthDate12HourTime),
      },
      {
        key: 'Invoice Source ID:',
        value: invoiceVendorExternalId ? (
          <Link to={`/app/data-hub?${DataHubURLParam.INVOICE_EXTERNAL_ID}=${invoiceVendorExternalId}`}>
            {invoiceVendorExternalId}
          </Link>
        ) : (
          'N/A'
        ),
      },
      {
        key: 'Contract ID:',
        value: contractData.contract?.id || 'N/A',
      },
      {
        key: 'Contract Source ID:',
        value: contractData.contract?.entity.vendorExternalId || 'N/A',
      },
      {
        key: 'Contract Duration',
        value: contractData.contract?.entity.termDescription || 'N/A',
      },
      {
        key: 'Contract Billing Cycle',
        value: contractData.contract?.entity.termLengthReadableInMonths || 'N/A',
      },
      {
        key: `Total Contract Value ${
          contractData.contract?.entity.baseCurrency ? `(${contractData.contract?.entity.baseCurrency})` : ''
        }`,
        value: contractData.contract?.entity.totalValueBaseCurrencyFormatted || 'N/A',
      },
      {
        key: 'Customer ID',
        value: customerId ? (
          <Link to={`/app/data-hub?${DataHubURLParam.CUSTOMER_ID}=${customerId}`}>{customerId}</Link>
        ) : (
          'N/A'
        ),
      },
      {
        key: 'Customer Source ID',
        value: invoice.entity.vendorExternalCustomerId || 'N/A',
      },
      {
        key: 'Customer Active Since',
        value: customerActiveSinceMoment ? customerActiveSinceMoment.format(DateFormatYearMonth) : 'N/A',
      },
    ];
    if (contractData.contract?.entity?.cancelledAt) {
      kvPairs.push(
        {
          key: 'Contract State',
          value: contractData.contract?.entity.state || 'N/A',
        },
        {
          key: 'Contract Billing Cycle',
          value: contractData.contract?.entity.cancelledAt || 'N/A',
        }
      );
    }

    return (
      <div style={{ marginTop: '10px ' }}>
        <div className={sharedClasses.contentHeaderWrapper}>
          <SectionNavHeader
            sections={[
              {
                label: 'Invoice',
              },
            ]}
          />
          <div className={sharedClasses.contentHeaderUtils} />
        </div>
        <div className={classes.overviewSection}>
          <SectionWithHeader header={`Invoice and Contract Details`}>
            <KeyValueList keyValuePairs={kvPairs} />
          </SectionWithHeader>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PaymentRecognitionScheduleCardInvoiceOverview;
