export enum PriceListItemSaleType {
  BUNDLE = 'BUNDLE',
  RECOGNIZE_IMMEDIATELY = 'RECOGNIZE_IMMEDIATELY',
  DEFERRED = 'DEFERRED',
  TAX = 'TAX',
  NON_REVENUE = 'NON_REVENUE',
}

export enum PriceListItemState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const PriceListItemTypeToDisplay = {
  [PriceListItemSaleType.BUNDLE]: 'Bundle',
  [PriceListItemSaleType.RECOGNIZE_IMMEDIATELY]: 'Recognized Immediately',
  [PriceListItemSaleType.DEFERRED]: 'Deferred',
  [PriceListItemSaleType.TAX]: 'Tax',
  [PriceListItemSaleType.NON_REVENUE]: 'Not Recognized Revenue',
};

export enum PriceListURLParam {
  ID = 'id',
}

export enum PriceListSortFields {
  ID = 'id',
  SKU = 'sku',
  PRODUCT_CODE = 'productCode',
  NAME = 'name',
  DESCRIPTION = 'description',
  PRODUCT_FAMILY = 'productFamily',
  SALES_TYPE = 'saleType',
  CURRENCY = 'currency',
  AMOUNT = 'amount',
  TERM_INTERVAL_UNIT = 'termIntervalUnit',
  TERM_INTERVAL_LENGTH = 'termIntervalLength',
  STATE = 'state',
  EFFECTIVE_DATE = 'effectiveDate',
  VENDOR = 'vendor',
  UPDATED_AT = 'updatedAt',
}
