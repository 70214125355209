import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    transition: 'min-width 0.4s ease, width 0.4s ease',
    whiteSpace: 'nowrap',
    minWidth: ({ width }) => `${width}px`,
    width: ({ width }) => `${width}px`,
  },
});
