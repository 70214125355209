import React from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import { CSS as dndCSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import Colors from './common/Colors';
import { TNavItem } from '../../src/types/BaseTypes';
import getAssetPath from '../utils/AssetPathUtil';
import NavItemLink, { navItemClasses } from './NavItemLink';

const useStyles = createUseStyles({
  ...navItemClasses,
  navItemOuter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  navItemCollapsible: {
    cursor: 'pointer',
    '&:hover': {
      '& $navItemCollapseIcon': {
        transform: 'scaleY(-1)',
      },
    },
  },
  navItemCollapseIcon: {
    padding: '4px 10px',
    fontSize: '10px',
  },
  navItemInnerCollapsed: {
    display: 'none',
  },
  navItemInner: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'left',
    alignItems: 'center',
    paddingTop: 8,
  },
  navItemInnerItem: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'left',
    alignItems: 'center',
    '&:before': {
      color: Colors.MAIN_BLUE,
      content: "' '",
      display: 'block',
      marginLeft: '8px',
      marginRight: '20px',
    },

    '&:hover:before': {
      color: Colors.WHITE,
    },

    '&$selected:before': {
      color: Colors.WHITE,
    },
  },
  navIconDragHandle: {
    position: 'absolute',
    right: 5,
    top: 12,
    cursor: 'grab',
  },
});

export type NavItemBase = {
  type: TNavItem;
  iconType: string;
  label: string;
  appPath?: string;
  isReorderable?: boolean;
  iconHeight?: number;
  iconLeftMargin?: number;
  isHover?: boolean;
  selectedNav?: string;
  isSelected?: boolean;
  onClick?: () => void;
  to?: string;
  isCollapsed?: boolean;
  setCollapsed?: () => void;
  isDisabledByDefault?: boolean;
};
export type NavItemProps = NavItemBase & {
  secondaryItems?: NavItemBase[];
  isSecondary?: boolean;
};

const NavItem: React.FunctionComponent<NavItemProps> = (props: NavItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.type });
  const dragStyle = {
    transform: dndCSS.Transform.toString(transform),
    transition,
  };

  const classes = useStyles();
  const imgStyle: { [key: string]: string } = {};
  if (props.iconHeight) {
    imgStyle.height = `${props.iconHeight}px`;
  }
  if (props.iconLeftMargin) {
    imgStyle.marginLeft = `${props.iconLeftMargin}px`;
  }

  if ((!props.to && !props.onClick) || props.isReorderable) {
    return (
      <div
        key={`navitem-reorder-${props.type}`}
        className={`
          ${classes.navItem}
        `}
        {...(props.isReorderable
          ? {
              ref: setNodeRef,
              style: dragStyle,
            }
          : {})}
      >
        <div
          className={classnames(classes.navItemOuter, { [classes.navItemCollapsible]: !props.isReorderable })}
          onClick={() => !props.isReorderable && props?.setCollapsed?.()}
        >
          <span className={classes.navIcon}>
            <img
              className={[classes.navIconImage, classes.navIconUnselected].join(' ')}
              style={imgStyle}
              src={`${getAssetPath()}/images/icon_${props.iconType}.svg`}
            />
          </span>
          <span>{props.label}</span>

          {props.isReorderable ? (
            <img
              className={classes.navIconDragHandle}
              src={`${getAssetPath()}/images/icon_draghandle.svg`}
              {...attributes}
              {...listeners}
            />
          ) : (
            <div className={classes.navItemCollapseIcon}>{props.isCollapsed ? '▲' : '▼'}</div>
          )}
        </div>
        {!props.isReorderable && props?.secondaryItems && props?.secondaryItems?.length > 0 ? (
          <div className={props.isCollapsed ? classes.navItemInnerCollapsed : classes.navItemInner}>
            {props.secondaryItems.map((item) => {
              const isSelected = item.appPath ? props.selectedNav === item.appPath : undefined;
              return (
                <div
                  key={item.type}
                  className={`
                    ${classes.navItem}
                    ${classes.navItemInnerItem}
                    ${classes.navItemHoverable}
                    ${isSelected ? classes.selected : ''}
                  `}
                >
                  <NavItemLink
                    type={item.type}
                    iconType={item.iconType}
                    label={item.label}
                    isSecondary
                    isDisabledByDefault={props.isDisabledByDefault}
                    isReorderable={item.isReorderable}
                    iconHeight={item.iconHeight}
                    iconLeftMargin={item.iconLeftMargin}
                    onClick={props.onClick}
                    isSelected={isSelected}
                    to={item.appPath ? `/app/${item.appPath}` : undefined}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <NavItemLink
      key={props.type}
      type={props.type}
      iconType={props.iconType}
      label={props.label}
      isDisabledByDefault={props.isDisabledByDefault}
      isReorderable={props.isReorderable}
      secondaryItems={props.secondaryItems}
      isSelected={props.isSelected}
      iconHeight={props.iconHeight}
      iconLeftMargin={props.iconLeftMargin}
      onClick={props.onClick}
      to={props.to}
    />
  );
};

export default React.memo(NavItem);
