import React from 'react';
import { createUseStyles } from 'react-jss';
import { getDateRangeStr } from '../common/DateRangeFilter';

import Colors from '../common/Colors';
import { Maybe } from 'graphql/jsutils/Maybe.js';
import { DataHubURLParam, StringToStringMap } from '../../../src/types/DataHubTypes';

const useStyles = createUseStyles({
  contentFilter: {
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: '5px',
    fontSize: '10px',
    flexShrink: 0,
    lineHeight: '14px',
    marginRight: '8px',
    padding: '3px 0 3px 10px',
  },
  contentFilterRemove: {
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '12px',
    marginLeft: '2px',
    padding: '0 8px',
  },
  contentFilterValue: {
    fontWeight: 'bold',
    marginLeft: '5px',
  },
  contentFilterLabel: {
    marginRight: '5px',
    whiteSpace: 'nowrap',
  },
  contentFilters: {
    overflowX: 'auto',
  },
});

export const FilterTypeToLabel: { [key in DataHubURLParam]?: string } = {
  [DataHubURLParam.ID]: 'ID',
  [DataHubURLParam.VENDOR]: 'Source',
  [DataHubURLParam.CUSTOMER_ID]: 'Customer',
};

type ContentFilterProps = {
  label: string;
  onRemove: () => void;
  value: string;
};

const ContentFilter: React.FunctionComponent<ContentFilterProps> = (props: ContentFilterProps) => {
  const classes = useStyles();
  return (
    <div className={classes.contentFilter}>
      {props.label}:<span className={classes.contentFilterValue}>{props.value}</span>
      <span className={classes.contentFilterRemove} onClick={props.onRemove}>
        ×
      </span>
    </div>
  );
};

type ContentFiltersProps = {
  customerIdFiltered?: Maybe<string>;
  searchParams: URLSearchParams;
  replaceSearchParams: (obj: StringToStringMap) => void;
  supportedFilters: {
    label: string;
    isDateRange: boolean;
    filters: DataHubURLParam[];
  }[];
};

const ContentFilters: React.FunctionComponent<ContentFiltersProps> = (props: ContentFiltersProps) => {
  const classes = useStyles();
  const activeFilters = props.supportedFilters.filter((filterObj) =>
    filterObj.filters.every((f) => !!props.searchParams.get(f))
  );

  return (
    <div className={classes.contentFilters}>
      {activeFilters.length > 0 ? (
        <>
          <div className={classes.contentFilterLabel}>Current filters:</div>
          {activeFilters.map((f, idx) => (
            <ContentFilter
              key={`filter-${f}-${idx}`}
              label={f.label}
              value={
                f.isDateRange
                  ? getDateRangeStr(
                      ...[0, 1].map((idx) => {
                        const val = props.searchParams.get(f.filters[idx]) || '';
                        return val ? new Date(Number(val)) : undefined;
                      })
                    )
                  : props.searchParams.get(f.filters[0]) || ''
              }
              onRemove={() => {
                const newSearchParams: StringToStringMap = {};
                props.searchParams.forEach((value, key) => {
                  if (!f.filters.includes(key as DataHubURLParam)) {
                    newSearchParams[key] = value;
                  }
                });
                props.replaceSearchParams(newSearchParams);
              }}
            />
          ))}
        </>
      ) : null}
    </div>
  );
};

export default ContentFilters;
