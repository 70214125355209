import Colors from './Colors';
import React from 'react';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  toggle: {
    backgroundColor: Colors.OFFWHITE,
    borderRadius: '3px',
    boxShadow: `0 0 2px ${Colors.SHADOW}`,
    cursor: 'pointer',
    height: '24px',
    width: '60px',
    transition: 'all 0.2s ease',
  },
  toggleSlider: {
    borderRadius: '3px',
    backgroundColor: Colors.MAIN_BLUE,
    boxShadow: `1px 0 1px ${Colors.SHADOW}`,
    height: '24px',
    width: '24px',
    transition: 'all 0.35s ease',
    '$toggleOn > &': {
      boxShadow: `-1px 0 1px ${Colors.SHADOW}`,
      transform: 'translateX(35px)',
    },
  },
  toggleOn: {
    backgroundColor: Colors.ACTIVE_TOGGLE_BLUE,
  },
});

interface ToggleProps {
  isOn: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  isDisabled?: boolean;
  id?: string;
}
const Toggle: React.FunctionComponent<ToggleProps> = ({ isOn, onClick, isDisabled, id }) => {
  const classes = useStyles();
  const handleClick = (e: React.SyntheticEvent) => {
    if (!isDisabled && onClick) {
      onClick(e);
    }
  };
  return (
    <div
      onClick={handleClick}
      className={classnames(classes.toggle, { [classes.toggleOn]: isOn })}
      style={isDisabled ? { cursor: 'default' } : {}}
      data-id={id}
    >
      <div className={classes.toggleSlider}></div>
    </div>
  );
};

export default Toggle;
