import React, { useState } from 'react';

import classnames from 'classnames';
import getAssetPath from '../../utils/AssetPathUtil';

import Colors from '../common/Colors';
import FormButton from '../common/FormButton';
import KeyValueList from '../common/KeyValueList';
import NewRuleFlow from './NewRuleFlow';
import SectionWithHeader from '../common/SectionWithHeader';
import { createUseStyles } from 'react-jss';
import { useSharedStyles } from '../../utils/CssUtil';

const useStyles = createUseStyles({
  rulesCardHeader: {
    display: 'flex',
    '& h3': {
      fontSize: '18px',
      fontWeight: 'bold',
      margin: 0,
      lineHeight: '38px',
      '& span': {
        color: Colors.MEDIUM_GRAY,
      },
    },
    '& button': {
      marginLeft: 'auto',
    },
  },
  rulesCardRulesList: {
    marginTop: 20,
    '& a': {
      color: Colors.MEDIUM_GRAY,
      display: 'inline-block',
      marginLeft: 10,
      textDecoration: 'underline',
    },
  },
  rulesCardRuleStatus: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
  rulesCardRuleStatusActive: {
    color: '#1e9d3c',
  },
  ruleDetailList: {
    margin: '10px 0 0',
    paddingLeft: 15,
    listStyle: 'disc',
    '&:first-child': {
      margin: 0,
    },
    '& li': {
      marginBottom: 5,
    },
  },
  rulesCardRulePill: {
    display: 'inline-block',
    padding: '4px 8px',
    backgroundColor: Colors.LIGHT_GRAY,
    minWidth: 20,
    textAlign: 'center',
    margin: 3,
    borderRadius: 3,
  },
  rulesCardEditedBy: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& a': {
      color: Colors.DARK_GRAY,
      fontWeight: 'bold',
      marginLeft: 5,
      marginRight: 5,
      textDecoration: 'underline dotted',
    },
    '& img': {
      width: 36,
      height: 36,
      borderRadius: 18,
      marginRight: 2,
    },
    '& span': {
      display: 'inline-bBlock',
      marginLeft: 5,
    },
  },
});

const RulesCard: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [isCreatingNewRule, updateIsCreatingNewRule] = useState<boolean>(false);
  return (
    <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
      <div>
        {isCreatingNewRule ? (
          <NewRuleFlow />
        ) : (
          <div>
            <div className={classes.rulesCardHeader}>
              <h3>
                Rules: <span>2 created, 2 active</span>
              </h3>
              <FormButton onClick={() => updateIsCreatingNewRule(true)} width={170}>
                Create New Rule
              </FormButton>
            </div>
            <div className={classes.rulesCardRulesList}>
              <SectionWithHeader
                header="ID: ru-8ae94f2"
                headerUtility={
                  <>
                    <a href="#">Edit</a>
                    <a href="#">Delete</a>
                  </>
                }
              >
                <KeyValueList
                  keyValuePairs={[
                    {
                      key: 'Status:',
                      value: (
                        <>
                          <div className={classnames(classes.rulesCardRuleStatus, classes.rulesCardRuleStatusActive)}>
                            Active
                          </div>
                          <a href="#">Pause</a>
                        </>
                      ),
                    },
                    {
                      key: 'Last run:',
                      value: 'Wed Oct 12 2022 01:30:00 GMT-0700 (Pacific Daylight Time)',
                    },
                    {
                      key: 'Total payments affected:',
                      value: '2213',
                    },
                    {
                      key: 'Last edited:',
                      value: (
                        <div className={classes.rulesCardEditedBy}>
                          <img src={`${getAssetPath()}/images/profiles/yl.jpeg`} />
                          <a href="#">Yufei Liu</a>
                          at
                          <span>Mon Oct 10 2022 18:35:42 GMT-0700 (Pacific Daylight Time)</span>
                        </div>
                      ),
                    },
                    {
                      key: 'Matching criteria:',
                      value: (
                        <>
                          <div>Payments that match all of following:</div>
                          <ul className={classes.ruleDetailList}>
                            <li>
                              <span className={classes.rulesCardRulePill}>payment.vendor IS stripe</span>
                            </li>
                            <li>
                              <span className={classes.rulesCardRulePill}>subscription.interval_length = 12</span>
                            </li>
                          </ul>
                        </>
                      ),
                    },
                    {
                      key: 'Triggering time:',
                      value: 'Immediately upon import',
                    },
                    {
                      key: 'Journal entries:',
                      value: (
                        <ul className={classes.ruleDetailList}>
                          <li>
                            <strong>Deferred Revenue Accrued:</strong> 1 entry with amount{' '}
                            <span className={classes.rulesCardRulePill}>payment.amount</span>, on day of payment
                          </li>
                          <li>
                            <strong>Deferred Revenue Recognized:</strong> 12 monthly entres with amount{' '}
                            <span className={classes.rulesCardRulePill}>payment.amount / 12</span>, at end of month of
                            payment
                          </li>
                        </ul>
                      ),
                    },
                  ]}
                />
              </SectionWithHeader>

              <SectionWithHeader
                header="ID: ru-ac392bf"
                headerUtility={
                  <>
                    <a href="#">Edit</a>
                    <a href="#">Delete</a>
                  </>
                }
              >
                <KeyValueList
                  keyValuePairs={[
                    {
                      key: 'Status:',
                      value: (
                        <>
                          <div className={classnames(classes.rulesCardRuleStatus, classes.rulesCardRuleStatusActive)}>
                            Active
                          </div>
                          <a href="#">Pause</a>
                        </>
                      ),
                    },
                    {
                      key: 'Last run:',
                      value: 'Wed Oct 12 2022 01:00:00 GMT-0700 (Pacific Daylight Time)',
                    },
                    {
                      key: 'Total payments affected:',
                      value: '301',
                    },
                    {
                      key: 'Last edited:',
                      value: (
                        <div className={classes.rulesCardEditedBy}>
                          <img src={`${getAssetPath()}/images/profiles/ck.jpeg`} />
                          <a href="#">Chris Kim</a>
                          at
                          <span>Mon Oct 10 2022 22:02:03 GMT-0700 (Pacific Daylight Time)</span>
                        </div>
                      ),
                    },
                    {
                      key: 'Matching criteria:',
                      value: (
                        <>
                          <div>Payments that match all of following:</div>
                          <ul className={classes.ruleDetailList}>
                            <li>
                              <span className={classes.rulesCardRulePill}>payment.vendor IS stripe</span>
                            </li>
                            <li>
                              <span className={classes.rulesCardRulePill}>subscription.interval_length ≠ 12</span>
                            </li>
                          </ul>
                        </>
                      ),
                    },
                    {
                      key: 'Triggering time:',
                      value: 'Immediately upon import',
                    },
                    {
                      key: 'Journal entries:',
                      value: (
                        <ul className={classes.ruleDetailList}>
                          <li>
                            <strong>Deferred Revenue Accrued:</strong> 1 entry with amount{' '}
                            <span className={classes.rulesCardRulePill}>payment.amount</span>, on day of payment
                          </li>
                          <li>
                            <strong>Non-deferred Revenue Recognized:</strong> 1 entry with amount{' '}
                            <span className={classes.rulesCardRulePill}>payment.amount</span>, on day of payment
                          </li>
                        </ul>
                      ),
                    },
                  ]}
                />
              </SectionWithHeader>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RulesCard;
