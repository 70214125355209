import React from 'react';

import Colors from '../common/Colors';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSharedStyles } from '../../utils/CssUtil';

import DataGrid from '../data_grid/DataGrid';
import ExportButton from '../CSVExportButton';
import SectionNavHeader from '../SectionNavHeader';
import { TCsvExportType } from '../../../src/types/CsvExportTypes';
import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import { TransactionRecognitionTableContractLevelFragmentFragment } from '../../generated/graphql';
import { flattenData, getColumns } from '../data_grid/utils/ColumnUtils';

const useStyles = createUseStyles({
  contractTable: {
    '&.tabulator': {
      fontSize: '12px',
      overflow: 'visible',
      '& .tabulator-header .tabulator-headers .tabulator-col[role="columnheader"]': {
        textAlign: 'center',
      },
      '& .tabulator-tableholder': {
        overflow: 'visible',
        overflowX: 'auto',
        '& .tabulator-table': {
          '& .tabulator-row': {
            '& .content-cell-disabled': {
              color: Colors.MEDIUM_GRAY,
            },
            '&.content-row-highlight': {
              '& .tabulator-cell': {
                textDecoration: 'none',
                '& a': {
                  color: Colors.WHITE,
                  textDecoration: 'none',
                },
              },
              '& .content-cell-disabled': {
                color: Colors.WHITE,
                backgroundColor: Colors.MAIN_BLUE_HIGHLIGHT,
              },
            },
          },
        },
      },
    },
  },

  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '-5px',
  },

  legend: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8px',
  },

  legendColor: {
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: '2px',
    marginRight: '5px',
    marginTop: '1px',

    height: '12px',
    width: '12px',

    '&.content-cell-upgraded-or-downgraded': {
      backgroundColor: '#cfecff',
    },

    '&.content-cell-cancelled': {
      backgroundColor: '#ffdada',
    },
  },
});

type PaymentRecognitionScheduleCardContractTableProps = {
  isRealCustomerCompany?: boolean;
  contractData?: TransactionRecognitionTableContractLevelFragmentFragment;
};
const PaymentRecognitionScheduleCardContractTable: React.FunctionComponent<
  PaymentRecognitionScheduleCardContractTableProps
> = ({ contractData, isRealCustomerCompany }: PaymentRecognitionScheduleCardContractTableProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const contractColumns = contractData?.rows?.[0]
    ? [
        {
          title: 'Product',
          field: 'id',
          headerSort: false,
          frozen: true,
        } as TabulatorTypes.ColumnDefinition,
        ...getColumns(contractData.rows[0], false, sharedClasses),
      ]
    : [];
  contractColumns?.forEach((col) => {
    col.headerSort = false;
    col.resizable = col.title !== 'Source';
    col.minWidth = 80;
    col.maxWidth = col.frozen ? 250 : 80;
    col.widthGrow = col.frozen ? 1.3 : undefined;
  });

  const flattenedContractData = contractData?.rows ? flattenData(contractData.rows) : [];

  const contractOptions: TabulatorTypes.Options = {
    layout: 'fitData',
    data: flattenedContractData,
    sortMode: 'remote',
    columnDefaults: {
      title: '',
      headerSortTristate: true,
    },
    persistence: undefined,
    rowFormatter: (row: TabulatorTypes.RowComponent) => {
      const data = row.getData();
      if (data?._rowCssClass) {
        row?.getElement()?.classList?.add(data._rowCssClass);
      }
    },
  };

  return contractData && contractData.contract?.hasBeenAmended ? (
    <div style={{ marginTop: '25px' }}>
      <div className={sharedClasses.contentHeaderWrapper}>
        <SectionNavHeader
          sections={[
            {
              label: `Contract recognition schedule: ${contractData.contract?.id}`,
            },
          ]}
          isLastItemClickable={false}
        />
        <div className={sharedClasses.contentHeaderUtils}>
          {isRealCustomerCompany ? undefined : <ExportButton type={TCsvExportType.FAKE} />}
        </div>
      </div>
      <div style={{ overflowX: 'scroll' }}>
        <DataGrid
          className={classes.contractTable}
          columns={contractColumns}
          options={contractOptions}
          shouldEnablePager={false}
          shouldEnableUrlFiltering
        />
      </div>
      {(contractData.legends || []).length ? (
        <div className={sharedClasses.afterTable}>
          <div className={sharedClasses.afterTableLeft} />
          <div className={sharedClasses.afterTableRight}>
            <div className={classes.legendContainer}>
              {(contractData.legends || []).map((legend, idx) => (
                <div className={classes.legend} key={`legend-${idx}`}>
                  <div className={classnames(legend.cssClass, classes.legendColor)}></div>
                  <div>{legend.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default PaymentRecognitionScheduleCardContractTable;
