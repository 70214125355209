import { NavItemProps } from '../../../components/NavItem.tsx';
import { TNavItem } from '../../../../src/types/BaseTypes.ts';

export const defaultNavItems: NavItemProps[] = [
  // {
  //   type: TNavItem.HOME,
  //   iconType: 'home',
  //   label: 'Home',
  //   appPath: 'home',
  // },
  {
    type: TNavItem.DATA_HUB,
    iconType: 'data-hub',
    label: 'Data Hub',
    appPath: 'data-hub',
    iconLeftMargin: 5,
  },
  {
    type: TNavItem.REPORTS,
    iconType: 'analytics',
    label: 'Reports',
    appPath: 'reports',
    iconHeight: 15,
  },
  {
    type: TNavItem.FINANCIALS,
    iconType: 'ledger',
    label: 'Financials',
    secondaryItems: [
      {
        type: TNavItem.REVENUE,
        iconType: 'revenue',
        label: 'Overview',
        appPath: 'revenue',
      },
      {
        type: TNavItem.TRANSACTION,
        iconType: 'transaction',
        label: 'Transaction',
        appPath: 'transactions',
        iconHeight: 25,
        iconLeftMargin: -1,
      },
      {
        type: TNavItem.DEPOSITS,
        iconType: 'deposits',
        label: 'Deposits',
        appPath: 'deposits',
        iconHeight: 25,
        iconLeftMargin: -1,
      },
      {
        type: TNavItem.RECONCILIATION,
        iconType: 'reconciliation',
        label: 'Reconciliation',
        appPath: 'reconciliation',
        iconHeight: 24,
        iconLeftMargin: -1,
      },
    ],
  },
  // {
  //   type: TNavItem.EXPENSES,
  //   iconType: "expenses",
  //   label: "Expenses",
  //   appPath: "expenses",
  // },
  // {
  //   type: TNavItem.JOURNAL,
  //   iconType: "journal",
  //   label: "Journal Entries",
  //   appPath: "journal",
  // },
  // {
  //   type: TNavItem.ANALYTICS,
  //   iconType: "analytics",
  //   label: "Analytics",
  //   appPath: "analytics",
  //   iconHeight: 15,
  // },
  {
    type: TNavItem.MONITORING,
    iconType: 'monitoring',
    label: 'Monitoring',
    iconHeight: 13,
    secondaryItems: [
      {
        type: TNavItem.AUDIT_TRAIL,
        iconType: 'audit-trail',
        label: 'Audit Trail',
        appPath: 'audit-trail',
      },
      {
        type: TNavItem.EXCEPTIONS,
        iconType: 'exceptions',
        label: 'Exceptions',
        appPath: 'exceptions',
      },
    ],
  },
  {
    type: TNavItem.SETTINGS,
    iconType: 'settings',
    label: 'Settings',
    iconLeftMargin: 5,
    secondaryItems: [
      {
        type: TNavItem.RULES,
        iconType: 'rules',
        label: 'Rules',
        appPath: 'rules',
      },
      {
        type: TNavItem.PRICE_LIST,
        iconType: 'price-list',
        label: 'Price List',
        appPath: 'price-list',
      },
      {
        type: TNavItem.INTEGRATIONS,
        iconType: 'integrations',
        label: 'Integrations',
        appPath: 'integrations',
      },
      {
        type: TNavItem.TEAM,
        iconType: 'team',
        label: 'Team',
        appPath: 'team',
        iconHeight: 24,
      },
      {
        type: TNavItem.COMPANY_SETTINGS,
        iconType: 'company',
        label: 'Company',
        appPath: 'company-settings',
        iconHeight: 18,
      },
    ],
  },
];
