import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import Banner from '../Banner';
import Colors from './Colors';
import SectionWithHeader from './SectionWithHeader';
import { useSharedStyles } from '../../utils/CssUtil';
import SectionNavHeader, { TSectionNavHeaderSection } from '../SectionNavHeader';
import TabbedContainers, { Tab, borderStyle } from './TabbedContainers';

const useStyles = createUseStyles({
  column: {
    borderRight: borderStyle,
    flex: '1',
    padding: '15px',

    '&:last-child': {
      borderRight: '0',
    },

    '& ul': {
      margin: '0',
      padding: '0 0 0 15px',
      listStyleType: 'circle',

      '& li': {
        margin: '0',
        padding: '0 0 5px 0',
      },
    },

    '&::before': {
      color: Colors.MEDIUM_GRAY,
      content: 'attr(data-header)',
      display: 'block',
      fontSize: '12px',
      fontWeight: 'bold',
      marginBottom: '15px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },

  columnGreyedOut: {
    backgroundColor: Colors.FAINT_GRAY,
    minWidth: '50%',
    maxWidth: '100%',
  },

  tabHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',

    '& span': {
      display: 'block',
    },
  },
  cellPill: {
    letterSpacing: '0',
    marginLeft: '5px',
    padding: '4px',
  },

  rawJSONContainer: {
    overflowY: 'scroll',
    padding: '10px 5px',
  },

  breadcrumb: {
    '& a': {
      fontSize: 13,
      fontWeight: 'bold',
      color: Colors.MEDIUM_GRAY,
    },
  },
});

type RawJsonDisplayCardWithBannerProps = {
  header?: string;
  initialOpenSection?: number;
  sectionData?: { header: string; headerPillLabel?: string; tabs: Tab[] }[];
  breadcrumSections?: TSectionNavHeaderSection[];
  breadcrumbUrl?: string;
};

const RawJsonDisplayCardWithBanner: React.FunctionComponent<RawJsonDisplayCardWithBannerProps> = ({
  initialOpenSection = 0,
  sectionData,
  header,
  breadcrumSections,
}: RawJsonDisplayCardWithBannerProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [isInitialSectionOpen, updateIsInitialSectionOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;
    window.setTimeout(() => {
      if (isMounted && !isInitialSectionOpen) {
        updateIsInitialSectionOpen(true);
      }
    }, 500);
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div
        className={sharedClasses.contentWrapper}
        style={{
          marginTop: '-250px',
        }}
      >
        {breadcrumSections ? (
          <SectionNavHeader
            sections={breadcrumSections}
            isLastItemClickable={!!breadcrumSections[breadcrumSections.length - 1]?.url}
          />
        ) : undefined}
        <div className={sharedClasses.contentHeaderWrapper}>
          <div className={sharedClasses.contentHeader}>
            <div className={sharedClasses.cardTabs}>
              <div className={classnames(sharedClasses.cardTab, sharedClasses.cardTabSelected)}>
                {header ? <span>{header}</span> : undefined}
              </div>
            </div>
          </div>
          <div className={sharedClasses.contentHeaderUtils}></div>
        </div>
        <div>
          {(sectionData || []).map((s, idx: number) => (
            <SectionWithHeader
              key={`${s.header}-${idx}`}
              header={
                <div>
                  {s.header}
                  {s.headerPillLabel ? (
                    <span
                      className={classnames(sharedClasses.cellPill, classes.cellPill)}
                      data-pill-value={s.headerPillLabel}
                    >
                      {s.headerPillLabel}
                    </span>
                  ) : undefined}
                </div>
              }
              isCollapsible
              isCollapsedInitially={!(isInitialSectionOpen && initialOpenSection === idx)}
              bodyPaddingPx={0}
            >
              <div className={classnames(classes.column, classes.columnGreyedOut)} data-header="Raw Data">
                <TabbedContainers
                  tabContentClass={classes.rawJSONContainer}
                  tabContentStyle={{
                    height: 300,
                  }}
                  tabs={s.tabs}
                />
              </div>
            </SectionWithHeader>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RawJsonDisplayCardWithBanner;
