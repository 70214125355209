// css required by react-date-range
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import React, { useRef, useState } from 'react';

import { DateRangePicker } from 'react-date-range';
import { createUseStyles } from 'react-jss';

import Colors from './Colors';
import FormButton from '../common/FormButton';
import useOutsideClick from '../../hooks/useOutsideClick';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
  },

  clickable: {
    cursor: 'pointer',
  },

  dropdown: {
    position: 'absolute',

    boxShadow: '0 0 3px rgba(0, 0, 0, 0.075)',
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    top: '100%',
    right: 0,
    marginTop: '15px',
    zIndex: 1,
  },

  datePickerFooterButton: {
    padding: 10,
  },
});

type DateRangerSelectorProps = {
  startDate?: Date;
  endDate?: Date;
  onChange: (start?: Date, end?: Date) => void;
  key?: string;

  maxDate?: Date;
  minDate?: Date;

  onApplyButtonClick?: () => void;
};

export const DateRangerSelector: React.FunctionComponent<DateRangerSelectorProps> = ({
  startDate,
  endDate,
  onChange,
  key = 'DateRangerSelector',

  maxDate,
  minDate,
  onApplyButtonClick,
}) => {
  const classes = useStyles();
  const selectionRange = {
    startDate,
    endDate,
    key,
  };

  return (
    <DateRangePicker
      color={Colors.MAIN_BLUE}
      rangeColors={[Colors.LIGHT_BLUE]}
      ranges={[selectionRange]}
      onChange={(r) => onChange(r[key]?.startDate, r[key]?.endDate)}
      maxDate={maxDate}
      minDate={minDate}
      footerContent={
        onApplyButtonClick ? (
          <div className={classes.datePickerFooterButton}>
            <FormButton onClick={onApplyButtonClick}>Apply</FormButton>
          </div>
        ) : undefined
      }
    />
  );
};

type DateRangerSelectorDropdownProps = DateRangerSelectorProps & {
  updateIsDateSelectorVisible: (isVisible: boolean) => void;
};

// DateRangerSelectorDropdown's onChange is only triggered when the dropdown is collapsed
const DateRangerSelectorDropdown: React.FunctionComponent<DateRangerSelectorDropdownProps> = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  onChange,
  maxDate,
  minDate,
  updateIsDateSelectorVisible,
}) => {
  const classes = useStyles();

  const [startDate, updateStartDate] = useState<Date | undefined>(initialStartDate);
  const [endDate, updateEndDate] = useState<Date | undefined>(initialEndDate);

  const ref = useRef(null);

  const onApply = () => {
    onChange(startDate, endDate);
    updateIsDateSelectorVisible(false);
  };

  useOutsideClick(ref, onApply, [startDate, endDate]);

  return (
    <div className={classes.container} ref={ref} onClick={(e) => e.stopPropagation()}>
      <div className={classes.dropdown}>
        <DateRangerSelector
          startDate={startDate}
          endDate={endDate}
          onChange={(start, end) => {
            updateStartDate(start);
            updateEndDate(end);
          }}
          maxDate={maxDate}
          minDate={minDate}
          onApplyButtonClick={onApply}
        />
      </div>
    </div>
  );
};

export default DateRangerSelectorDropdown;
