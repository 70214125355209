import { TLedgerAccountTypes } from './LedgerTypes.js';

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;
type XOR3<S, T, U> = XOR<S, XOR<T, U>>;

export type TRuleTriggerUponImport = {
  type: 'upon_import';
};
export type TRuleTriggerDelayAfterImport = {
  type: 'delay_after_import';
  delayMs: number;
};
export type TRuleTriggerWebhook = {
  type: 'webhook';
  webhookId: string;
  signatureId?: string;
};

export const CONTENT_CELL_WARNING_CLASS = 'content-cell-warning';
export const CONTENT_CELL_BOLDED_CLASS = 'content-cell-bolded';

export type TRuleTrigger = XOR3<TRuleTriggerUponImport, TRuleTriggerDelayAfterImport, TRuleTriggerWebhook>;

export enum TRuleSupportedEntityTypes {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  INVOICE = 'INVOICE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  ITEM_PRICE = 'ITEM_PRICE',
}

export enum TRuleSupportedStringFields {
  ID = 'id',
  VENDOR = 'vendor',
  VENDOR_EXTERNAL_ID = 'vendor_external_id',
  DESCRIPTION = 'description',
  INTERVAL_TYPE = 'interval_type',
}

export enum TRuleSupportedNumberFields {
  AMOUNT = 'amount',
  INTERVAL_LENGTH = 'interval_length',
}

export enum TRuleSupportedDateFields {
  COMPLETED_AT = 'completed_at',
  ISSUED_AT = 'issued_at',
  DUE_AT = 'due_at',
  STARTS_AT = 'starts_at',
  ENDS_AT = 'ends_at',
}

export type TRuleSupportedFields = TRuleSupportedStringFields | TRuleSupportedNumberFields | TRuleSupportedDateFields;

export const EntityTypeToSupportedFields: {
  [key in TRuleSupportedEntityTypes]: TRuleSupportedFields[];
} = {
  [TRuleSupportedEntityTypes.PAYMENT]: [
    TRuleSupportedStringFields.ID,
    TRuleSupportedStringFields.VENDOR,
    TRuleSupportedStringFields.VENDOR_EXTERNAL_ID,
    TRuleSupportedNumberFields.AMOUNT,
    TRuleSupportedDateFields.COMPLETED_AT,
  ],
  [TRuleSupportedEntityTypes.REFUND]: [
    TRuleSupportedStringFields.ID,
    TRuleSupportedStringFields.VENDOR,
    TRuleSupportedStringFields.VENDOR_EXTERNAL_ID,
    TRuleSupportedNumberFields.AMOUNT,
    TRuleSupportedDateFields.COMPLETED_AT,
  ],
  [TRuleSupportedEntityTypes.INVOICE]: [
    TRuleSupportedStringFields.ID,
    TRuleSupportedStringFields.VENDOR,
    TRuleSupportedStringFields.VENDOR_EXTERNAL_ID,
    TRuleSupportedNumberFields.AMOUNT,
    TRuleSupportedDateFields.ISSUED_AT,
    TRuleSupportedDateFields.DUE_AT,
  ],
  [TRuleSupportedEntityTypes.SUBSCRIPTION]: [
    TRuleSupportedStringFields.ID,
    TRuleSupportedStringFields.VENDOR,
    TRuleSupportedStringFields.VENDOR_EXTERNAL_ID,
    TRuleSupportedStringFields.DESCRIPTION,
    TRuleSupportedDateFields.STARTS_AT,
    TRuleSupportedDateFields.ENDS_AT,
    TRuleSupportedStringFields.INTERVAL_TYPE,
    TRuleSupportedNumberFields.INTERVAL_LENGTH,
  ],
  [TRuleSupportedEntityTypes.ITEM_PRICE]: [
    TRuleSupportedStringFields.ID,
    TRuleSupportedNumberFields.AMOUNT,
    TRuleSupportedStringFields.DESCRIPTION,
  ],
};

export const EntityTypeToSupportedVariableFields: {
  [key in TRuleSupportedEntityTypes]: TRuleSupportedFields[];
} = {
  [TRuleSupportedEntityTypes.PAYMENT]: [TRuleSupportedNumberFields.AMOUNT],
  [TRuleSupportedEntityTypes.REFUND]: [TRuleSupportedNumberFields.AMOUNT],
  [TRuleSupportedEntityTypes.INVOICE]: [TRuleSupportedNumberFields.AMOUNT],
  [TRuleSupportedEntityTypes.SUBSCRIPTION]: [TRuleSupportedNumberFields.INTERVAL_LENGTH],
  [TRuleSupportedEntityTypes.ITEM_PRICE]: [TRuleSupportedNumberFields.AMOUNT],
};

type TRuleEntityTypeConfig = {
  allowAsInitialEntity: boolean;
  allowAsLinkedEntity: boolean;
};

export const RuleEntityConfigs: {
  [key in TRuleSupportedEntityTypes]: TRuleEntityTypeConfig;
} = {
  [TRuleSupportedEntityTypes.PAYMENT]: {
    allowAsInitialEntity: true,
    allowAsLinkedEntity: true,
  },
  [TRuleSupportedEntityTypes.REFUND]: {
    allowAsInitialEntity: true,
    allowAsLinkedEntity: true,
  },
  [TRuleSupportedEntityTypes.INVOICE]: {
    allowAsInitialEntity: true,
    allowAsLinkedEntity: true,
  },
  [TRuleSupportedEntityTypes.SUBSCRIPTION]: {
    allowAsInitialEntity: false,
    allowAsLinkedEntity: true,
  },
  [TRuleSupportedEntityTypes.ITEM_PRICE]: {
    allowAsInitialEntity: false,
    allowAsLinkedEntity: true,
  },
};

export enum TRuleIfConditionStringOperator {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  IS_CASE_INSENSITIVE = 'IS_CASE_INSENSITIVE',
  IS_NOT_CASE_INSENSITIVE = 'IS_NOT_CASE_INSENSITIVE',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
}

export enum TRuleIfConditionNumberOperator {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  GT = 'GT',
  LT = 'LT',
  GTE = 'GTE',
  LTE = 'LTE',
}

export type TRuleIfConditionOperator = TRuleIfConditionStringOperator | TRuleIfConditionNumberOperator;

export enum TRuleLogicalOperator {
  'OR' = 'OR',
  'AND' = 'AND',
}

type TRuleInputFieldBuiltIn = {
  fieldType: 'built_in';
  fieldName: string; // TODO
};

type TRuleInputFieldCustom = {
  fieldType: 'custom';
  customDimensionName: string;
};

type TRuleLinkedEntitySelf = {
  linkType: 'self';
};

type TRuleInputField = XOR<TRuleInputFieldBuiltIn, TRuleInputFieldCustom>;

// TODO: capture the fact that linked entity must be unique (maybe via whitelist of allowed fields)
type TRuleLinkedEntityByField = {
  linkType: 'by_field';
  entityType: TRuleSupportedEntityTypes;
  fieldNameOrigin: TRuleInputField;
  fieldNameDestination: TRuleInputField;
  fromTemplate?: string; // TODO
};

export type TRuleLinkedEntity = TRuleLinkedEntitySelf | TRuleLinkedEntityByField;

export type TRuleIfConditionElementaryCondition = {
  type: 'elementary';
  entity: TRuleLinkedEntity[]; // TODO: supports nesting, up to 3 times
  fieldName: TRuleSupportedFields;
  operator: TRuleIfConditionOperator;
  operand: string | number;
};

export type TRuleIfConditionCompoundCondition = {
  type: 'compound';
  firstCondition: TRuleIfConditionCompoundCondition | TRuleIfConditionElementaryCondition;
  secondCondition: TRuleIfConditionElementaryCondition;
  logicalOperator: TRuleLogicalOperator;
};

type TRuleIfConditionUserDefined = {
  type: 'user_defined';
  condition: TRuleIfConditionCompoundCondition;
};

type TRuleIfConditionNone = {
  type: 'none';
};

type TRuleIfConditionCustom = {
  type: 'custom';
  moduleName: string;
};

type TRuleIfCondition = XOR3<TRuleIfConditionUserDefined, TRuleIfConditionNone, TRuleIfConditionCustom>;

export enum TRuleJournalEntryTiming {
  SAME_DAY = 'SAME_DAY',
  DELAY = 'DELAY',
  START_OF_CURRENT_MONTH = 'START_OF_CURRENT_MONTH',
  END_OF_CURRENT_MONTH = 'END_OF_CURRENT_MONTH',
  START_OF_NEXT_MONTH = 'START_OF_NEXT_MONTH',
  END_OF_NEXT_MONTH = 'END_OF_NEXT_MONTH',
}

export enum TRuleJournalEntryFrequency {
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_TWO_MONTHS = 'EVERY_TWO_MONTHS',
  EVERY_QUARTER = 'EVERY_QUARTER',
}

type TRuleThenActionJournalWrite = {
  jounrnalID: TLedgerAccountTypes; // TODO replace with ID
  entryCount: number;
  initialEntryTiming: TRuleJournalEntryTiming;
  initialEntryTimingDelayMs?: number;
  entryFrequency?: TRuleJournalEntryFrequency;
};

type TRuleThenActionWrites = {
  type: 'writes';
  writes: TRuleThenActionJournalWrite[];
};

type TRuleThenActionCustom = {
  type: 'custom';
  moduleName: string;
};

type TRuleThenAction = TRuleThenActionWrites | TRuleThenActionCustom;

export type TRule = {
  ifCondition: {
    entityType: TRuleSupportedEntityTypes;
    condition: TRuleIfCondition;
  };
  trigger: TRuleTrigger;
  thenAction: TRuleThenAction;
  version: number;
  createdByUserId: string;
  createdAt: number;
};
