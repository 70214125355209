import React, { useEffect, useState } from 'react';

import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import ArrayUtil from '../../src/utils/ArrayUtil';
import Colors from './common/Colors';
import DataGrid from './data_grid/DataGrid';
import { DateFormatYearMonthDate } from '../../src/utils/FormatUtil';
import DateRangeFilter from './common/DateRangeFilter';
import ExportButton from './CSVExportButton';
import LoaderAnimation from './common/LoaderAnimation';
import ShimmerBar from './common/ShimmerBar';
import { StringToStringMap } from '../../src/types/DataHubTypes';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import { useSharedStyles } from '../utils/CssUtil';
import Banner, { Highlight } from './Banner';
import {
  CustodianAndVendorData,
  Maybe,
  QueryReconciliationReportArgs,
  ReconciliationTable,
  TransactionCellRow,
} from '../generated/graphql';
import MenuDropdown, { MenuDropdownHorizontalAlignment } from './common/MenuDropdown';
import {
  ReconciliationURLParam,
  TCustodialStatus,
  TReconciliationExceptionFilterOption,
} from '../../src/types/ReconciliationTypes';
import { TableHighlightFragment, TransactionCellRowFragment } from '../apollo/GraphQLFragments';
import { flattenData, getColumns } from './data_grid/utils/ColumnUtils';

const QUERY = gql`
  ${TransactionCellRowFragment}
  ${TableHighlightFragment}
  query GetReconciliationReport(
    $${ReconciliationURLParam.VENDOR}: String
    $${ReconciliationURLParam.CUSTODIAL_STATUS}: String
    $${ReconciliationURLParam.CUSTODIAN}: String
    $${ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID}: String
    $${ReconciliationURLParam.PAYOUT_ID}: String
    $${ReconciliationURLParam.VENDOR_EXTERNAL_ID}: String
    $${ReconciliationURLParam.EXCEPTION_FILTER_OPTION}: String
    $${ReconciliationURLParam.START_DATE_STRING}: String
    $${ReconciliationURLParam.END_DATE_STRING}: String
    $${ReconciliationURLParam.PAGE}: Float
    $${ReconciliationURLParam.PAGE_SIZE}: Float
  ) {
    reconciliationReport(
      ${ReconciliationURLParam.VENDOR}: $${ReconciliationURLParam.VENDOR}
      ${ReconciliationURLParam.CUSTODIAL_STATUS}: $${ReconciliationURLParam.CUSTODIAL_STATUS}
      ${ReconciliationURLParam.CUSTODIAN}: $${ReconciliationURLParam.CUSTODIAN}
      ${ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID}: $${ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID}
      ${ReconciliationURLParam.PAYOUT_ID}: $${ReconciliationURLParam.PAYOUT_ID}
      ${ReconciliationURLParam.VENDOR_EXTERNAL_ID}: $${ReconciliationURLParam.VENDOR_EXTERNAL_ID}
      ${ReconciliationURLParam.EXCEPTION_FILTER_OPTION}: $${ReconciliationURLParam.EXCEPTION_FILTER_OPTION}
      ${ReconciliationURLParam.START_DATE_STRING}: $${ReconciliationURLParam.START_DATE_STRING}
      ${ReconciliationURLParam.END_DATE_STRING}: $${ReconciliationURLParam.END_DATE_STRING}
      ${ReconciliationURLParam.PAGE}: $${ReconciliationURLParam.PAGE}
      ${ReconciliationURLParam.PAGE_SIZE}: $${ReconciliationURLParam.PAGE_SIZE}
    ) {
      pageCount
      currentPage
      rows {
        ...TransactionCellRowFragment
      }
      highlights {
        ...TableHighlightFragment
      }
      externalAccounts {
        id
        bankName
        accountDescription
      }
      pageSize
      timezoneLocation
    }

    custodiansAndVendors {
      vendors
      custodians
    }
  }
`;

const useReconciliationStyles = createUseStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 0',
    minHeight: 96,
  },
  headerLeft: {
    width: 400,
  },
  headerRight: {
    flex: 1,
  },
  highlightContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    marginBottom: 25,
    padding: 15,
    overflowY: 'auto',

    '& > div': {
      flex: 1,
      marginRight: 0,
      minWidth: 205,
    },
  },
  highlightsLoadingContainer: {
    margin: '12px 0 7px',
  },
  loaderContainer: {
    textAlign: 'center',
    marginTop: 150,
    marginRight: 85,
    display: 'flex',
    justifyContent: 'center',
  },
  tableHeader: {
    backgroundColor: Colors.FAINT_BLUE,
    border: '3px 3px 0 0',
    fontSize: 13,
    fontWeight: 'bold',
    padding: '5px 7px',
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    marginBottom: 10,
    alignItems: 'center',
    '& > div': {
      flex: 1,
    },
  },
  rowLabel: {
    fontSize: 14,
    textAlign: 'right',
    paddingRight: 12,
  },
  dropdownContainer: {
    minWidth: 240,
  },
  horizontallyScrollableContent: {
    minHeight: 480,
    overflowX: 'scroll',
    paddingBottom: 20, // to show the scroll bar
  },
  reconciliationTable: {
    '&.tabulator': {
      fontSize: 13,
    },
  },
});

const ReconciliationCardWithBanner: React.FunctionComponent = () => {
  const classes = useReconciliationStyles();
  const sharedClasses = useSharedStyles({});
  const [params, setParams] = useSearchParams();
  const allWording = 'All';
  const menuItemStyle = {
    fontSize: 14,
    padding: '12px 15px',
  };
  const [dateRange, setDateRange] = useState<{ startDate?: Date; endDate?: Date }>({});
  const getQueryVars = (params: URLSearchParams, startDateStr?: string, endDateStr?: string) => ({
    [ReconciliationURLParam.VENDOR]: params.get(ReconciliationURLParam.VENDOR),
    [ReconciliationURLParam.CUSTODIAL_STATUS]: params.get(ReconciliationURLParam.CUSTODIAL_STATUS),
    [ReconciliationURLParam.CUSTODIAN]: params.get(ReconciliationURLParam.CUSTODIAN),
    [ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID]: params.get(ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID),
    [ReconciliationURLParam.PAYOUT_ID]: params.get(ReconciliationURLParam.PAYOUT_ID),
    [ReconciliationURLParam.VENDOR_EXTERNAL_ID]: params.get(ReconciliationURLParam.VENDOR_EXTERNAL_ID),
    [ReconciliationURLParam.EXCEPTION_FILTER_OPTION]: params.get(ReconciliationURLParam.EXCEPTION_FILTER_OPTION),
    [ReconciliationURLParam.START_DATE_STRING]: startDateStr || params.get(ReconciliationURLParam.START_DATE_STRING),
    [ReconciliationURLParam.END_DATE_STRING]: endDateStr || params.get(ReconciliationURLParam.START_DATE_STRING),
    [ReconciliationURLParam.PAGE]: params.get(ReconciliationURLParam.PAGE)
      ? Number(params.get(ReconciliationURLParam.PAGE))
      : 1,
    [ReconciliationURLParam.PAGE_SIZE]: params.get(ReconciliationURLParam.PAGE_SIZE)
      ? Number(params.get(ReconciliationURLParam.PAGE_SIZE))
      : 50,
  });

  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery<
    {
      reconciliationReport: ReconciliationTable;
      custodiansAndVendors: CustodianAndVendorData;
    },
    QueryReconciliationReportArgs
  >(QUERY, {
    variables: getQueryVars(params),
  });

  const replaceSearchParams = (obj: StringToStringMap) => {
    const newParams = new URLSearchParams();
    Object.keys(obj).forEach((k) => {
      newParams.set(k, obj[k]);
    });
    setParams(newParams);
  };
  const updateSearchParams = (newParams: StringToStringMap) => {
    // create new params from existing
    const newSearchParams: StringToStringMap = {};
    params.forEach((value, key) => {
      if (!newSearchParams[key]) {
        newSearchParams[key] = value;
      }
    });
    // add new parameters or overwrite
    replaceSearchParams({ ...newSearchParams, ...newParams });
  };

  const [dataRows, setDataRows] = useState<Maybe<TransactionCellRow[]> | undefined>();
  useEffect(() => setDataRows(data?.reconciliationReport?.rows), [data]);

  const dateFilterOnChange = (start?: Date, end?: Date) => {
    // updating any other params clears date range params
    const [startDateStr, endDateStr] = [
      moment(start).format(DateFormatYearMonthDate),
      moment(end).format(DateFormatYearMonthDate),
    ];
    updateSearchParams({
      [ReconciliationURLParam.START_DATE_STRING]: startDateStr,
      [ReconciliationURLParam.END_DATE_STRING]: endDateStr,
    });
    setDataRows(undefined);
    refetch(getQueryVars(params, startDateStr, endDateStr));
  };

  useEffect(() => {
    const [startDate, endDate] = [
      params.get(ReconciliationURLParam.START_DATE_STRING)
        ? moment(params.get(ReconciliationURLParam.START_DATE_STRING), DateFormatYearMonthDate).toDate()
        : undefined,
      params.get(ReconciliationURLParam.END_DATE_STRING)
        ? moment(params.get(ReconciliationURLParam.END_DATE_STRING), DateFormatYearMonthDate).toDate()
        : undefined,
    ];
    if (
      startDate?.getTime() !== dateRange?.startDate?.getTime() ||
      endDate?.getTime() !== dateRange?.endDate?.getTime()
    ) {
      setDateRange({ startDate, endDate });
    }
  }, [params.get(ReconciliationURLParam.START_DATE_STRING), params.get(ReconciliationURLParam.START_DATE_STRING)]);

  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
        <div className={classes.header}>
          {data && dataRows && !isLoading ? (
            <>
              <div className={classes.headerLeft}>
                <div className={sharedClasses.cardTabs}>
                  <div className={sharedClasses.cardTab}>Reconciliations</div>
                  <div className={sharedClasses.cardTab}>
                    <a href="/app/missing-data">Missing Data</a>
                  </div>
                </div>
              </div>
              <div className={classes.headerRight}>
                <div className={classes.row}>
                  <div className={classes.rowLabel}>Select Custodian: </div>
                  <div className={classes.dropdownContainer}>
                    <MenuDropdown
                      horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
                      items={[...data.custodiansAndVendors.custodians, allWording].map((str) => ({
                        label: str,
                        onClick: () => updateSearchParams({ [ReconciliationURLParam.CUSTODIAN]: str }),
                      }))}
                      menuItemStyle={menuItemStyle}
                    >
                      {params.get(ReconciliationURLParam.CUSTODIAN) || allWording}
                    </MenuDropdown>
                  </div>
                </div>

                <div className={classes.row}>
                  <div className={classes.rowLabel}>Select Vendor: </div>
                  <div className={classes.dropdownContainer}>
                    <MenuDropdown
                      horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
                      items={[...data.custodiansAndVendors.vendors, allWording].map((str) => ({
                        label: str,
                        onClick: () => updateSearchParams({ [ReconciliationURLParam.VENDOR]: str }),
                      }))}
                      menuItemStyle={menuItemStyle}
                    >
                      {params.get(ReconciliationURLParam.VENDOR) || allWording}
                    </MenuDropdown>
                  </div>
                </div>

                <div className={classes.row}>
                  <div className={classes.rowLabel}>Select Status: </div>
                  <div className={classes.dropdownContainer}>
                    <MenuDropdown
                      horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
                      items={[...Object.values(TCustodialStatus), allWording].sort().map((custodialStatus) => ({
                        label: custodialStatus,
                        onClick: () =>
                          updateSearchParams({ [ReconciliationURLParam.CUSTODIAL_STATUS]: custodialStatus }),
                      }))}
                      menuItemStyle={menuItemStyle}
                    >
                      {params.get(ReconciliationURLParam.CUSTODIAL_STATUS) || allWording}
                    </MenuDropdown>
                  </div>
                </div>

                <div className={classes.row}>
                  <div className={classes.rowLabel}>Exception Filtering </div>
                  <div className={classes.dropdownContainer}>
                    <MenuDropdown
                      horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
                      items={Object.values(TReconciliationExceptionFilterOption)
                        .sort()
                        .map((exceptionFilterOptions) => ({
                          label: exceptionFilterOptions,
                          onClick: () =>
                            updateSearchParams({
                              [ReconciliationURLParam.EXCEPTION_FILTER_OPTION]: exceptionFilterOptions,
                            }),
                        }))}
                      menuItemStyle={menuItemStyle}
                    >
                      {params.get(ReconciliationURLParam.EXCEPTION_FILTER_OPTION) ||
                        TReconciliationExceptionFilterOption.NONE}
                    </MenuDropdown>
                  </div>
                </div>

                <div className={classes.row}>
                  <div className={classes.rowLabel}>Select Account: </div>
                  <div className={classes.dropdownContainer}>
                    <MenuDropdown
                      horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
                      items={[
                        ...data.reconciliationReport.externalAccounts,
                        { id: allWording, bankName: allWording, accountDescription: allWording },
                      ].map((bankInformation) => ({
                        label: bankInformation.accountDescription,
                        onClick: () =>
                          updateSearchParams({ [ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID]: bankInformation.id }),
                      }))}
                      menuItemStyle={menuItemStyle}
                    >
                      {/* bank description */}
                      {data?.reconciliationReport.externalAccounts.find(
                        (ea) => ea.id === params.get(ReconciliationURLParam.EXTERNAL_BANK_ACCOUNT_ID)
                      )?.accountDescription || allWording}
                    </MenuDropdown>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className={classes.highlightsLoadingContainer}>
                <ShimmerBar delayMs={0} height={30} width={300} isLightMode />
              </div>
              <div>
                <ShimmerBar delayMs={200} height={20} width={300} isLightMode />
              </div>
            </div>
          )}
        </div>
        <div className={classes.highlightContainer}>
          {isLoading
            ? ArrayUtil.range(3).map((idx) => <Highlight isLoading isLightMode key={`highlight-${idx}`} />)
            : (data?.reconciliationReport?.highlights || []).map((ea, idx) => (
                <Highlight isLoading={false} isLightMode value={ea.value} header={ea.label} key={`highlight-${idx}`} />
              ))}
        </div>
        <div className={sharedClasses.content}>
          {!dataRows || isLoading ? (
            <div className={classes.loaderContainer}>
              <LoaderAnimation height={80} />
            </div>
          ) : (
            <>
              <div className={sharedClasses.contentHeaderWrapper}>
                <div className={sharedClasses.contentHeaderUtils}>
                  {
                    <DateRangeFilter
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                      onChange={dateFilterOnChange}
                    />
                  }
                  <ExportButton type={TCsvExportType.RECON_REPORT} searchParams={params} enabled />
                </div>
              </div>
              <div className={classes.tableHeader}>Transactions</div>
              <DataGrid
                className={classes.reconciliationTable}
                columns={
                  dataRows[0]
                    ? [
                        {
                          title: 'Date',
                          field: 'date',
                          minWidth: 90,
                        } as TabulatorTypes.ColumnDefinition,
                        ...getColumns(dataRows[0], false, sharedClasses, data?.reconciliationReport?.timezoneLocation),
                      ].map((col) => {
                        col.headerSort = false;
                        if (col.title === 'Numeral ID') {
                          col.minWidth = 170;
                        }
                        return col;
                      })
                    : []
                }
                options={{
                  data: flattenData(dataRows, 'date'),
                  sortMode: 'remote',
                  persistence: undefined,
                }}
                shouldEnablePager={true}
                pagerVariables={{
                  currentPage: data?.reconciliationReport?.currentPage || 1,
                  pageCount: data?.reconciliationReport?.pageCount || 1,
                  pageSize: 50,
                  refetchPage: (page: number, pageSize: number) => {
                    updateSearchParams({
                      [ReconciliationURLParam.PAGE]: String(page),
                      [ReconciliationURLParam.PAGE_SIZE]: String(pageSize),
                    });
                    refetch(getQueryVars(params));
                  },
                }}
              />
              <div className={classes.horizontallyScrollableContent}></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReconciliationCardWithBanner;
