import React from 'react';

import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

import Colors from './common/Colors';
import FormButton from './common/FormButton';
import classnames from 'classnames';
import getAssetPath from '../utils/AssetPathUtil';
import { useSharedStyles } from '../utils/CssUtil';

const useStyles = createUseStyles({
  loginContainer: {
    display: 'block',
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    width: '100%',
  },

  contentWrapper: {
    height: 'auto',
    minHeight: 'auto',
    margin: '-50px auto 0',
    padding: '30px',
    width: '270px',
  },

  loginLogoContainer: {
    textAlign: 'center',

    '& p': {
      fontSize: '14px',
      margin: '10px 0 20px',
    },
  },

  loginRow: {
    fontSize: '14px',
    margin: '15px 0',

    '& > span': {
      display: 'block',
      marginBottom: '-10px',
      width: '80px',
    },

    '& button': {
      width: '100%',
    },
  },

  error: {
    color: Colors.WARNING_RED,
    fontSize: '12px',
    marginTop: '-8px',
  },
});

const PasswordResetSuccess: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const navigate = useNavigate();

  const continueFn = () => navigate('/');

  return (
    <div className={classes.loginContainer}>
      <div className={classnames(sharedClasses.banner, sharedClasses.loginBanner)}></div>
      <div className={classnames(sharedClasses.contentWrapper, classes.contentWrapper)}>
        <div className={classes.loginLogoContainer}>
          <img src={`${getAssetPath()}/images/logo_small.png`} height={48} />
          <p>Password Reset</p>
        </div>
        <div className={classes.loginRow}>
          <p>The password has been changed successfully.</p>
        </div>
        <div className={classes.loginRow}>
          <FormButton onClick={continueFn}>Login</FormButton>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
