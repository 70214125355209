import { createUseStyles } from 'react-jss';

import Colors from '../../components/common/Colors.tsx';

export const useUserNavItemStyles = createUseStyles({
  spinner: {
    width: '24px',
    height: '24px',
  },
  userNavItem: {
    position: 'relative',
    '& >a': {
      marginLeft: '15px',
      opacity: '0.85',
      '&:hover': {
        opacity: 1,
      },
      '& img': {
        height: '30px',
      },
    },
  },
  userNavDropdown: {
    background: Colors.WHITE,
    borderRadius: '3px',
    boxShadow: '1px 1px 3px rgba(0,0,0,0.08)',
    color: Colors.DARK_GRAY,
    fontSize: '14px',
    padding: '10px 0',
    position: 'absolute',
    width: '170px',
    top: '45px',
    right: '-10px',
    zIndex: 100,
    '&:before': {
      content: '"\\A"',
      borderStyle: 'solid',
      borderWidth: '0 6px 8px 6px',
      borderColor: `transparent transparent ${Colors.WHITE} transparent`,
      position: 'absolute',
      right: '18px',
      top: '-7px',
    },
  },
  userNavDropdownItemA: {
    display: 'flex',
    alignItems: 'center',
    color: Colors.DARK_GRAY,
    opacity: 1,
    marginLeft: 0,
    padding: '10px 15px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.MAIN_BLUE,
      color: Colors.WHITE,
    },
  },
  userNavDropdownItemDiv: {
    color: Colors.DARK_GRAY,
    display: 'block',
    opacity: 1,
    marginLeft: 0,
    padding: '10px 15px',
  },
  notifCount: {
    backgroundColor: '#e77f08',
    borderRadius: '2px',
    color: Colors.WHITE,
    display: 'inline-block',
    fontSize: '11px',
    fontWeight: 700,
    lineHeight: '14px',
    marginLeft: '-15px',
    textAlign: 'center',
    width: '15px',
  },
});
