import React from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSearchParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import ArrayUtil from '../../src/utils/ArrayUtil';
import Colors from './common/Colors';
import ExportButton from './CSVExportButton';
import LoaderAnimation from './common/LoaderAnimation';
import ReconciliationExceptionActivitiesTimeline from './reconciliations/ReconciliationExceptionActivitiesTimeline';
import ReconciliationExceptionAssociatedTransactionsTable from './reconciliations/ReconciliationExceptionAssociatedTransactionsTable';
import ReconciliationExceptionDrilldownTable from './reconciliations/ReconciliationExceptionDrilldownTable';
import ReconciliationExceptionRawJson from './reconciliations/ReconciliationExceptionRawJson';
import ReconciliationExceptionRelationshipHighlights from './reconciliations/ReconciliationExceptionRelationshipHighlights';
import ReconciliationRulesTable from './reconciliations/ReconciliationRulesTable';
import { TCsvExportType } from '../../src/types/CsvExportTypes';
import { TransactionCellRowFragment } from '../apollo/GraphQLFragments';
import { useSharedStyles } from '../utils/CssUtil';
import Banner, { Highlight } from './Banner';
import {
  GetRelationshipHighlightsAndDrilldownTableQuery,
  GetRelationshipHighlightsAndDrilldownTableQueryVariables,
} from '../generated/graphql';

const useStyles = createUseStyles({
  tableHeader: {
    backgroundColor: Colors.FAINT_BLUE,
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    fontSize: '13px',
    fontWeight: 'bold',
    padding: '5px 7px',
    textAlign: 'center',
  },
  priorSection: {
    paddingBottom: '20px',
  },
  loaderContainer: {
    textAlign: 'center',
    marginTop: '150px',
    marginRight: '85px',
    display: 'flex',
    justifyContent: 'center',
  },
});

const QUERY = gql`
  ${TransactionCellRowFragment}
  query GetRelationshipHighlightsAndDrilldownTable(
    $integrationRelationshipId: String!
    $primaryObjectId: String
    $primaryObjectTable: String
    $secondaryObjectId: String
    $secondaryObjectTable: String
  ) {
    relationshipHighlights(
      integration_relationship_id: $integrationRelationshipId
      primary_object_id: $primaryObjectId
      primary_object_table: $primaryObjectTable
      secondary_object_id: $secondaryObjectId
      secondary_object_table: $secondaryObjectTable
    ) {
      label
      type
      value
      pill {
        label
      }
    }

    reconciliationExceptionDrilldownTable(
      integration_relationship_id: $integrationRelationshipId
      primary_object_id: $primaryObjectId
      primary_object_table: $primaryObjectTable
      secondary_object_id: $secondaryObjectId
      secondary_object_table: $secondaryObjectTable
    ) {
      rows {
        ...TransactionCellRowFragment
      }
    }

    getReconciliationRulesForIntegrationRelationship(
      integration_relationship_id: $integrationRelationshipId
      primary_object_id: $primaryObjectId
      primary_object_table: $primaryObjectTable
      secondary_object_id: $secondaryObjectId
      secondary_object_table: $secondaryObjectTable
    ) {
      rows {
        ...TransactionCellRowFragment
      }
    }
  }
`;

const RAW_VENDOR_OBJECTS_TABLE_NAME = 'raw_vendor_objects';

const ReconciliationExceptionDrilldownCardWithBanner: React.FunctionComponent = () => {
  const [params] = useSearchParams();
  const [
    primaryObjectId,
    primaryObjectTable,
    integrationRelationshipId,
    secondaryObjectId,
    secondaryObjectTable,
    reconciliationExceptionId,
  ] = [
    params.get('pid') || undefined,
    params.get('pt') || undefined,
    params.get('ir') || '',
    params.get('sid') || undefined,
    params.get('st') || undefined,
    params.get('eid') || undefined,
  ];
  //

  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const isNonBankingException =
    primaryObjectTable === RAW_VENDOR_OBJECTS_TABLE_NAME || secondaryObjectTable === RAW_VENDOR_OBJECTS_TABLE_NAME;

  const {
    loading: isLoading,
    error,
    data,
    refetch,
  } = useQuery<
    GetRelationshipHighlightsAndDrilldownTableQuery,
    GetRelationshipHighlightsAndDrilldownTableQueryVariables
  >(QUERY, {
    variables: {
      integrationRelationshipId,
      primaryObjectId,
      primaryObjectTable,
      secondaryObjectId,
      secondaryObjectTable,
    },
  });

  return (
    <div className={sharedClasses.main}>
      <Banner />
      <div className={classnames(sharedClasses.contentWrapper, sharedClasses.contentWrapperWithoutHighlights)}>
        {isLoading ? (
          ArrayUtil.range(4).map((idx) => <Highlight isLoading key={`highlight-${idx}`} />)
        ) : error ? (
          <div className={sharedClasses.main}>Error: {(error as Error).message}</div>
        ) : (
          <ReconciliationExceptionRelationshipHighlights
            primaryObjectId={primaryObjectId || secondaryObjectId || 'N/A'}
            data={data}
          />
        )}
        <div className={sharedClasses.content}>
          {isNonBankingException ? (
            <>
              <div className={classes.tableHeader}>Reconciliation Rules</div>
              <div className={classes.priorSection}>
                <ReconciliationRulesTable data={data} isLoading={isLoading} />
              </div>
            </>
          ) : (
            <></>
          )}
          {(data?.reconciliationExceptionDrilldownTable.rows || []).length > 0 || isLoading ? (
            <>
              <div className={classes.tableHeader}>Reconciliation Exceptions</div>
              <div className={classes.priorSection}>
                {isLoading ? (
                  <div className={classes.loaderContainer}>
                    <LoaderAnimation height={80} />
                  </div>
                ) : error ? (
                  <div className={sharedClasses.main}>Error: {(error as Error).message}</div>
                ) : (
                  <ReconciliationExceptionDrilldownTable data={data} />
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {isNonBankingException || !primaryObjectId || !primaryObjectTable ? (
            <></>
          ) : (
            <>
              <div className={sharedClasses.contentHeaderWrapper}>
                <div className={sharedClasses.contentHeaderUtils}>
                  <ExportButton
                    type={TCsvExportType.BANKING_TRANSACTION_REPORT}
                    url={`/download/csv-bank-transactions?pid=${primaryObjectId}&pt=${primaryObjectTable}&ir=${integrationRelationshipId}${
                      reconciliationExceptionId ? '&eid=' + reconciliationExceptionId : ''
                    }`}
                    enabled
                  />
                </div>
              </div>
              <div className={classes.tableHeader}>Associated Transactions</div>
              <div className={classes.priorSection}>
                <ReconciliationExceptionAssociatedTransactionsTable
                  primaryObjectId={primaryObjectId}
                  primaryObjectTable={primaryObjectTable}
                  integrationRelationshipId={integrationRelationshipId}
                  reconciliationExceptionId={reconciliationExceptionId}
                />
              </div>
            </>
          )}
          {data || isLoading ? (
            <>
              <ReconciliationExceptionActivitiesTimeline
                primaryObjectId={primaryObjectId}
                primaryObjectTable={primaryObjectTable}
                secondaryObjectId={secondaryObjectId}
                secondaryObjectTable={secondaryObjectTable}
                onAddActivitySubmit={refetch}
              />
            </>
          ) : (
            <></>
          )}
          <div className={classes.tableHeader}>Raw JSON Data</div>
          <ReconciliationExceptionRawJson
            primaryObjectId={primaryObjectId}
            primaryObjectTable={primaryObjectTable}
            secondaryObjectId={secondaryObjectId}
            secondaryObjectTable={secondaryObjectTable}
            integrationRelationshipId={integrationRelationshipId}
          />
        </div>
      </div>
    </div>
  );
};

export default ReconciliationExceptionDrilldownCardWithBanner;
