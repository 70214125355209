export type TPasswordValidationRules = {
  minLength?: number;
  maxLength?: number;
  requiredSymbolsCount?: number;
  requiredNumbersCount?: number;
  isMixedCaseRequired?: boolean;
  maximumRepeatingChars?: number;
  disallowedSymbols?: string;
};

// NB> This has to be done manually by calling process.env, otherwise it won't compile
// in the frontend components due to the relative import of SiteConstants
export const ProdPasswordConfig = {
  minLength: 10,
  maxLength: 64,
  requiredSymbolsCount: 1,
  requiredNumbersCount: 1,
  isMixedCaseRequired: true,
  maximumRepeatingChars: 2,
};
export const TPasswordConfig: TPasswordValidationRules =
  process.env.NODE_ENV === 'test' || process.env.APP_ENV === 'test' ? {} : ProdPasswordConfig;
export const getMaximumRecurringSubSequenceLength = (str: string) => {
  let [activeChar, activeCharCount, maxSequenceLength] = ['', 0, 0];
  for (const char of str.split('')) {
    if (activeChar !== char) {
      activeChar = char;
      activeCharCount = 0;
    }
    activeCharCount++;
    if (activeCharCount > maxSequenceLength) {
      maxSequenceLength = activeCharCount;
    }
  }
  return maxSequenceLength;
};

export default function isValidPassword(passwordStr: string): void | never {
  if (
    (TPasswordConfig.minLength && passwordStr.length < TPasswordConfig.minLength) ||
    (TPasswordConfig.maxLength && passwordStr.length > TPasswordConfig.maxLength)
  ) {
    throw new Error(
      `Password length must be ${TPasswordConfig.minLength} characters or more and ${TPasswordConfig.maxLength} or less`
    );
  }
  if (
    TPasswordConfig.requiredSymbolsCount &&
    (passwordStr.match(/[^A-z0-9]/g) || []).length < TPasswordConfig.requiredSymbolsCount
  ) {
    throw new Error(`Password must contain ${TPasswordConfig.requiredSymbolsCount} special characters`);
  }
  if (
    TPasswordConfig.requiredNumbersCount &&
    (passwordStr.match(/[0-9]/g) || []).length < TPasswordConfig.requiredNumbersCount
  ) {
    throw new Error(`Password must contain ${TPasswordConfig.requiredNumbersCount} numbers`);
  }
  if (
    TPasswordConfig.isMixedCaseRequired &&
    ((passwordStr.match(/[A-Z]/g) || []).length === 0 || (passwordStr.match(/[a-z]/g) || []).length === 0)
  ) {
    throw new Error('Password must contain both uppercase and lowercase characters');
  }
  if (
    TPasswordConfig.maximumRepeatingChars &&
    getMaximumRecurringSubSequenceLength(passwordStr) > TPasswordConfig.maximumRepeatingChars
  ) {
    throw new Error(
      `Password must not contain more than ${TPasswordConfig.maximumRepeatingChars} repeating characters`
    );
  }
  if (TPasswordConfig.disallowedSymbols) {
    const includedSymbolsArr = Array.from(new Set(passwordStr.match(/[^0-9A-z]/g) || []));
    if (includedSymbolsArr.some((char) => (TPasswordConfig.disallowedSymbols || '').includes(char))) {
      throw new Error(
        `Password must not contain any of the following character(s): ${TPasswordConfig.disallowedSymbols}`
      );
    }
  }
}
