import React, { ReactElement } from 'react';

import { createUseStyles } from 'react-jss';

import { useSharedStyles } from '../utils/CssUtil';
import Colors from './common/Colors';
import ShimmerBar from './common/ShimmerBar';
import SearchBar, { TSearchBarLazyQueryFn } from './SearchBar';
import UserNav from './UserNav';

const useStyles = createUseStyles({
  highlightContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    padding: '15px 50px 25px',
  },
  highlightItem: {
    marginRight: '30px',
    minWidth: '220px',
    textAlign: 'center',
  },
  highlightItemValue: {
    fontSize: '28px',
    fontWeight: 500,
  },
  secondaryText: {
    fontSize: '12px',
    paddingTop: '5px',
    color: Colors.MEDIUM_GRAY,
    fontWeight: 'bold',
  },
  highlightItemSubheader: {
    fontSize: '14px',
    marginTop: '5px',
    whiteSpace: 'nowrap',
    textWrap: 'wrap',
  },
});

let delayCycle = 0;
const getRandomDelayMs: () => number = () => {
  return (delayCycle++ % 3) * 200;
};

type HighlightProps = {
  value?: ReactElement | string | number;
  header?: string;
  isLoading: boolean;
  isLightMode?: boolean;
  secondaryHeader?: string;
};

export const Highlight: React.FunctionComponent<HighlightProps> = ({
  isLoading,
  value,
  header,
  isLightMode,
  secondaryHeader,
}: HighlightProps) => {
  const classes = useStyles();

  return (
    <div className={classes.highlightItem}>
      <div className={classes.highlightItemValue}>
        {isLoading ? (
          <ShimmerBar delayMs={getRandomDelayMs()} height={28} width={150} isLightMode={isLightMode} />
        ) : (
          value
        )}
      </div>
      <div className={classes.highlightItemSubheader}>
        {isLoading ? (
          <ShimmerBar delayMs={getRandomDelayMs()} height={16} width={150} isLightMode={isLightMode} />
        ) : (
          <>
            {header}
            {secondaryHeader ? <div className={classes.secondaryText}>{secondaryHeader}</div> : <></>}
          </>
        )}
      </div>
    </div>
  );
};

type BannerProps = {
  highlights?: Array<{
    value: string;
    header: string;
    isLoading?: boolean;
  }>;
  showSearchBar?: boolean;
  searchBarQueryFn?: TSearchBarLazyQueryFn;
  hideUserNav?: boolean;
};

const Banner: React.FunctionComponent<BannerProps> = (props: BannerProps) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  return (
    <div className={sharedClasses.banner}>
      <div className={sharedClasses.bannerTop}>
        {props.showSearchBar && <SearchBar queryLazyFn={props.searchBarQueryFn} />}
        {!props.hideUserNav && <UserNav />}
      </div>
      {props.highlights && props.highlights.length > 0 ? (
        <div className={classes.highlightContainer}>
          {props.highlights.map((ea, idx) => (
            <Highlight isLoading={!!ea.isLoading} value={ea.value} header={ea.header} key={`highlight-${idx}`} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Banner;
