import React from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import DataGrid from '../data_grid/DataGrid';
import { DataGridFormatter } from '../../../src/types/DataGridTypes';
import { GetRelationshipHighlightsAndDrilldownTableQuery } from '../../generated/graphql';
import { Tabulator as TabulatorTypes } from 'react-tabulator/lib/types/TabulatorTypes';
import { useSharedStyles } from '../../utils/CssUtil';
import { flattenData, getColumns } from '../data_grid/utils/ColumnUtils';

const useStyles = createUseStyles({
  tableFixedWidth: {
    '& > table': {
      tableLayout: 'fixed',
    },
    '& > table td': {
      tableLayout: 'fixed',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
    },
  },
  exceptionDrilldownTable: {
    '&.tabulator': {
      fontSize: '13px',
    },
  },
});

type TExceptionDrilldownTableProps = {
  data?: GetRelationshipHighlightsAndDrilldownTableQuery;
};

const ReconciliationExceptionDrilldownTable: React.FunctionComponent<TExceptionDrilldownTableProps> = ({ data }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const columns = data?.reconciliationExceptionDrilldownTable?.rows?.[0]
    ? [
        {
          title: 'Exception ID',
          field: 'id',
          minWidth: 150,
          formatterParams: {
            customFormatter: DataGridFormatter.LINK_FORMATTER,
          },
          cssClass: classnames(sharedClasses.linkUnderlined, sharedClasses.contentCellTextWrapped),
        } as TabulatorTypes.ColumnDefinition,
        ...getColumns(data.reconciliationExceptionDrilldownTable.rows[0], false, sharedClasses),
      ]
    : [];
  columns.forEach((col) => {
    col.headerSort = false;
  });
  const flattenedData = data?.reconciliationExceptionDrilldownTable?.rows
    ? flattenData(data?.reconciliationExceptionDrilldownTable.rows)
    : [];

  const options: TabulatorTypes.Options = {
    data: flattenedData,
    sortMode: 'remote',
    persistence: undefined,
  };

  return (
    <div className={classnames(sharedClasses.content, classes.tableFixedWidth)}>
      <DataGrid className={classes.exceptionDrilldownTable} columns={columns} options={options} />
    </div>
  );
};

export default ReconciliationExceptionDrilldownTable;
