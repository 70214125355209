import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { Timeline, TimelineEvent } from 'react-event-timeline';

import { gql, useMutation, useQuery } from '@apollo/client';

import Colors from '../common/Colors';
import { DateFormatYearMonthDateAt12HourTime } from '../../../src/utils/FormatUtil';
import LoaderAnimation from '../common/LoaderAnimation';
import Modal from '../common/Modal';
import TabbedContainers from '../common/TabbedContainers';
import getAssetPath from '../../utils/AssetPathUtil';
import { useSharedStyles } from '../../utils/CssUtil';
import {
  AddActivityToReconciliationExceptionMutation,
  AddActivityToReconciliationExceptionMutationVariables,
  GetExceptionsActivitiesQuery,
  GetExceptionsActivitiesQueryVariables,
  ReconciliationExceptionModel,
} from '../../generated/graphql';
import FormButton, { FormButtonStyle } from '../common/FormButton';
import MenuDropdown, { MenuDropdownHorizontalAlignment } from '../common/MenuDropdown';

const ICON_UPDATED = 'icon_updated.svg';
const ICON_COMMENTED = 'icon_chatbox.svg';
const ICON_RESOLVED = 'icon_resolved.svg';

const useStyles = createUseStyles({
  tableHeader: {
    backgroundColor: Colors.FAINT_BLUE,
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    fontSize: '13px',
    fontWeight: 'bold',
    padding: '5px 7px',
    textAlign: 'center',
  },
  priorSection: {
    paddingBottom: '20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  closeModalBtn: {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  modalSection: {
    marginBottom: '48px',
  },
  dropdownMenu: {
    display: 'inline-flex',
    align: 'center',
    width: '250px',
    marginRight: '16px',
  },
  commentInputTextArea: {
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    height: '100px',
    width: '100%',
    fontSize: '14px',
    padding: '8px',
    marginRight: '16px',
    resize: 'none',
    textAlign: 'top',
  },
  loaderContainer: {
    textAlign: 'center',
    marginTop: '150px',
    marginRight: '85px',
    display: 'flex',
    justifyContent: 'center',
  },
  ruleStatusException: {},
  ruleStatusReconciled: {},
  exceptionStatus: {
    '& $ruleStatusException': {
      color: Colors.WARNING_RED,
      fontWeight: 'bold',
    },
    '& $ruleStatusReconciled': {
      fontWeight: 'bold',
    },
  },
  tabHeader: {
    fontSize: '13px',
  },
  tabBelowHeader: {
    overflowY: 'scroll',
    height: '350px',
    padding: '10px',

    '& div': {
      flex: 1,

      '& section': {
        height: 'calc(100% - 30px)',

        '& > div': {
          padding: '10px 0px',

          '& div': {
            top: '-12px',
          },
        },
      },
    },
  },
  tabContent: {
    fontSize: '13px',
  },
  resolvingActivity: {
    color: Colors.DARK_GREEN,
    fontWeight: 'bold',
  },
});

const QUERY = gql`
  query GetExceptionsActivities(
    $primaryObjectId: String
    $primaryObjectTable: String
    $secondaryObjectId: String
    $secondaryObjectTable: String
  ) {
    reconciliationExceptionActivity(
      primary_object_id: $primaryObjectId
      primary_object_table: $primaryObjectTable
      secondary_object_id: $secondaryObjectId
      secondary_object_table: $secondaryObjectTable
    ) {
      id
      reconciliationRuleId
      primaryObjectId
      primaryObjectTable
      secondaryObjectId
      secondaryObjectTable
      status
      exceptionActivities {
        userId
        reconciliationId
        timestampMs
        activityType
        comment
        incomingStatus
        user {
          firstName
          lastName
          email
          role
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation AddActivityToReconciliationException(
    $reconciliationExceptionId: String!
    $comment: String
    $incomingStatus: String
  ) {
    addActivityToReconciliationException(
      reconciliation_exception_id: $reconciliationExceptionId
      comment: $comment
      incoming_status: $incomingStatus
    ) {
      id
    }
  }
`;

type TActivitiesTimelineProps = {
  activeTabIndex: number;
  data: GetExceptionsActivitiesQuery;
  onTabChange: (idx: number) => void;
  openModal: () => void;
};

export const ActivitiesTimeline: React.FunctionComponent<TActivitiesTimelineProps> = ({
  activeTabIndex,
  data,
  onTabChange,
  openModal,
}) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.exceptionStatus, classes.tabHeader)}>
      <TabbedContainers
        lightMode
        initialTabIndex={activeTabIndex}
        tabContentClass={classes.tabBelowHeader}
        onTabSelect={onTabChange}
        tabs={
          data?.reconciliationExceptionActivity.map((ex) => {
            return {
              header: ex.id,
              content: (
                <>
                  <Timeline>
                    {[
                      <TimelineEvent
                        title={
                          <FormButton onClick={() => openModal()} width={250} style={FormButtonStyle.PLAIN}>
                            Comment or Update Status
                          </FormButton>
                        }
                        icon={
                          <img
                            src={`${getAssetPath()}/images/icon_edit.svg`}
                            height="20px"
                            alt="A pencil, representing the ability to leave a comment or make a change to status"
                          />
                        }
                        iconColor={Colors.MEDIUM_GRAY}
                        key={`${ex.id}-btn`}
                        className={classnames(classes.tabContent)}
                      />,
                      ...ex.exceptionActivities
                        .slice(0)
                        .sort((a, b) => {
                          const diff = b.timestampMs - a.timestampMs;
                          if (diff === 0) {
                            if (b.incomingStatus) {
                              return 1;
                            } else if (a.incomingStatus) {
                              return -1;
                            }
                          }
                          return diff;
                        })
                        .map((activity, idx: number) => {
                          let title: string;
                          let isStatusChange = true;
                          const isResolving = activity.incomingStatus === 'RESOLVED';
                          const userName = activity.user
                            ? `${activity.user.firstName} ${activity.user.lastName}`
                            : 'System';
                          switch (activity.activityType) {
                            case 'COMMENT':
                              title = `${userName} commented:`;
                              isStatusChange = false;
                              break;
                            case 'STATUS_CHANGE':
                              title = `${userName} updated status to ${activity.incomingStatus}`;
                              break;
                            default:
                              title = `${userName} updated status to ${activity.incomingStatus} with comment:`;
                          }
                          return (
                            <TimelineEvent
                              title={title}
                              createdAt={moment(new Date(activity.timestampMs)).format(
                                DateFormatYearMonthDateAt12HourTime
                              )}
                              icon={
                                <img
                                  src={`${getAssetPath()}/images/${
                                    isResolving ? ICON_RESOLVED : isStatusChange ? ICON_UPDATED : ICON_COMMENTED
                                  }`}
                                  height="20px"
                                  alt={
                                    isResolving
                                      ? 'An image of a green check box representing a resolved exception'
                                      : isStatusChange
                                      ? 'An image of two arrows forming a circle to represent a status change'
                                      : 'An image of a chatbox to represent a comment'
                                  }
                                />
                              }
                              collapsible
                              showContent
                              iconColor={Colors.MEDIUM_GRAY}
                              key={`${ex.id}-${idx}`}
                              className={classnames(classes.tabContent, isResolving ? classes.resolvingActivity : '')}
                            >
                              {activity.comment}
                            </TimelineEvent>
                          );
                        }),
                    ]}
                  </Timeline>
                </>
              ),
            };
          }) || []
        }
      />
    </div>
  );
};

type TActivitiesModalProps = {
  activeExceptionId: string;
  closeModal: () => void;
  currentExceptionStatus?: string;
  dropdownItemLabels: string[];
  incomingComment?: string;
  incomingExceptionStatus?: string;
  modalIsOpen: boolean;
  onSubmit: (reconciliationExceptionId: string, options: { comment?: string; incomingStatus?: string }) => void;
  setIncomingComment: (comment?: string) => void;
  setIncomingExceptionStatus: (status?: string) => void;
  submitIsDisabled: boolean;
};

export const ActivitiesModal: React.FunctionComponent<TActivitiesModalProps> = ({
  activeExceptionId,
  closeModal,
  currentExceptionStatus,
  dropdownItemLabels,
  incomingComment,
  incomingExceptionStatus,
  modalIsOpen,
  onSubmit,
  setIncomingComment,
  setIncomingExceptionStatus,
  submitIsDisabled,
}) => {
  const classes = useStyles();
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel={`Update exception: ${activeExceptionId}`}
      style={{
        content: {
          width: '664px',
          height: '240px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'visible',
        },
        overlay: {
          zIndex: 1000,
        },
      }}
    >
      <div className={classes.modalHeader}>
        <p>{`Update exception: ${activeExceptionId}`}</p>
        <button onClick={closeModal} className={classes.closeModalBtn}>
          {'\u00d7'}
        </button>
      </div>
      <div className={classes.modalSection}>
        <textarea
          name="comment"
          onChange={(e) => setIncomingComment(e.target.value)}
          placeholder="Leave a comment..."
          className={classnames(classes.commentInputTextArea)}
          disabled={false}
          value={incomingComment}
        />
      </div>
      <div className={classes.dropdownMenu}>
        <MenuDropdown
          horizontalAlignment={MenuDropdownHorizontalAlignment.LEFT}
          items={dropdownItemLabels.map((label) => {
            return { label, onClick: () => setIncomingExceptionStatus(label) };
          })}
        >
          {incomingExceptionStatus || currentExceptionStatus || ''}
        </MenuDropdown>
      </div>
      <FormButton
        width={160}
        onClick={() => {
          onSubmit(activeExceptionId, {
            comment: incomingComment ? incomingComment : undefined,
            incomingStatus: incomingExceptionStatus,
          });
        }}
        isDisabled={submitIsDisabled}
      >
        Submit
      </FormButton>
    </Modal>
  );
};

enum TReconciliationExceptionStatus {
  REVIEW_REQUIRED = 'REVIEW_REQUIRED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  RESOLVED = 'RESOLVED',
}

type TExceptionActivitiesProps = {
  primaryObjectId?: string;
  primaryObjectTable?: string;
  secondaryObjectId?: string;
  secondaryObjectTable?: string;
  onAddActivitySubmit?: () => void;
};

const ReconciliationExceptionActivitiesTimeline: React.FunctionComponent<TExceptionActivitiesProps> = ({
  primaryObjectId,
  primaryObjectTable,
  secondaryObjectId,
  secondaryObjectTable,
  onAddActivitySubmit,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const queryParams = {
    primaryObjectId,
    primaryObjectTable,
    secondaryObjectId,
    secondaryObjectTable,
  };

  const {
    loading: isLoading,
    error,
    data,
    refetch,
  } = useQuery<GetExceptionsActivitiesQuery, GetExceptionsActivitiesQueryVariables>(QUERY, { variables: queryParams });

  const [addActivityToReconciliationExceptionMutation, { error: addActivityErr }] = useMutation<
    AddActivityToReconciliationExceptionMutation,
    AddActivityToReconciliationExceptionMutationVariables
  >(MUTATION);

  const [params] = useSearchParams();
  const activeExceptionId = params.get('eid') || '';

  const [modalIsOpen, setIsOpen] = useState(false);
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
  const [activeException, setActiveException] = useState<ReconciliationExceptionModel | undefined>(undefined);
  const [dropdownItemLabels, setDropdownItemLabels] = useState<string[]>([]);
  const [incomingExceptionStatus, setIncomingExceptionStatus] = useState<string | undefined>();
  const [activeTabIndex, setActiveTabIndex] = useState(-1);
  const [currentExceptionStatus, setCurrentExceptionStatus] = useState<string | undefined>();
  const [incomingComment, setIncomingComment] = useState<string | undefined>();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIncomingExceptionStatus('');
  };

  const getDropdownItemLabels = (incomingStatus?: string) => {
    const allLabels = Object.keys(TReconciliationExceptionStatus);
    if (!incomingStatus) {
      return allLabels;
    }
    return [incomingStatus || currentExceptionStatus || '', ...allLabels.filter((status) => status !== incomingStatus)];
  };

  // On submission, refetch data, update the modal status options, and close modal
  const onSubmit = (reconciliationExceptionId: string, options: { comment?: string; incomingStatus?: string }) => {
    const { comment, incomingStatus } = options;
    if (comment || incomingStatus) {
      addActivityToReconciliationExceptionMutation({
        variables: { reconciliationExceptionId, comment, incomingStatus },
      }).then(() => {
        refetch();
        if (options.incomingStatus !== currentExceptionStatus) {
          setDropdownItemLabels(getDropdownItemLabels(options.incomingStatus));
          setCurrentExceptionStatus(options.incomingStatus);
        }
        if (options.comment) {
          setIncomingComment(undefined);
        }
        if (onAddActivitySubmit) {
          onAddActivitySubmit();
        }
        closeModal();
      });
    }
  };

  // NB> this gets passed to the tabbed container to allow for both URL params and tab clicks to update the active exception
  const onTabChange = (index: number) => {
    if (index !== activeTabIndex) {
      setActiveTabIndex(index);
      const exception = data?.reconciliationExceptionActivity[index] as ReconciliationExceptionModel;
      setActiveException(exception);
      if (exception) {
        setDropdownItemLabels(getDropdownItemLabels(exception.status));
        setCurrentExceptionStatus(exception.status);
      }
    }
  };

  useEffect(() => {
    setDropdownItemLabels(getDropdownItemLabels(currentExceptionStatus));
  }, [currentExceptionStatus]);

  // determine whether submit button should be disabled
  useEffect(() => {
    if (!modalIsOpen) {
      return setSubmitIsDisabled(true);
    } else if (incomingComment) {
      return setSubmitIsDisabled(false);
    } else if (incomingExceptionStatus && incomingExceptionStatus !== currentExceptionStatus) {
      return setSubmitIsDisabled(false);
    }
    setSubmitIsDisabled(!incomingComment);
  }, [modalIsOpen, incomingExceptionStatus, incomingComment]);

  // Monitor for changes in URL params for source object
  useEffect(() => {
    if (incomingComment) {
      setIncomingComment(undefined);
    }
  }, [primaryObjectId, primaryObjectTable, secondaryObjectId, secondaryObjectTable, activeExceptionId]);

  // Monitor for changes to selected error ID
  useEffect(() => {
    const idx = data?.reconciliationExceptionActivity.findIndex((ex) => ex.id === activeExceptionId);
    if (idx !== undefined) {
      onTabChange(idx);
    }
  }, []);

  if (error || addActivityErr) {
    return (
      <div className={sharedClasses.main}>
        Error: {(error as Error).message} {addActivityErr?.message}
      </div>
    );
  }
  if (data) {
    if (activeTabIndex === -1) {
      onTabChange(0);
    }
  }

  return (
    <div className={sharedClasses.content}>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <LoaderAnimation height={80} />
        </div>
      ) : data && data.reconciliationExceptionActivity.length > 0 ? (
        <>
          <div className={classes.tableHeader}>Activity</div>
          <div className={classes.priorSection}>
            <ActivitiesTimeline
              activeTabIndex={activeTabIndex}
              data={data}
              onTabChange={onTabChange}
              openModal={openModal}
            />
            <ActivitiesModal
              activeExceptionId={activeException?.id || ''}
              closeModal={closeModal}
              currentExceptionStatus={currentExceptionStatus}
              dropdownItemLabels={dropdownItemLabels}
              incomingComment={incomingComment}
              incomingExceptionStatus={incomingExceptionStatus}
              modalIsOpen={modalIsOpen}
              onSubmit={onSubmit}
              setIncomingComment={setIncomingComment}
              setIncomingExceptionStatus={setIncomingExceptionStatus}
              submitIsDisabled={submitIsDisabled}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReconciliationExceptionActivitiesTimeline;
