import { createUseStyles } from 'react-jss';
import Colors from '../common/Colors.tsx';

export const useStyles = createUseStyles({
  dataGridWrapper: {
    position: 'relative',
  },
  tabulatorContainer: {
    '&.tabulator': {
      width: '100%',
      '& .tabulator-tableholder': {
        '& .tabulator-table .tabulator-row': {
          display: 'flex',
          '& .tabulator-cell': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'text',
            '&$tooltipCell': {
              cursor: 'pointer',
              overflow: 'visible',
              '& .tooltip-outer': {
                position: 'fixed',
                zIndex: 10,
              },
            },
          },
        },
        '& .tabulator-placeholder': {
          minHeight: '300px',
          height: '30vh',

          backgroundColor: Colors.LIGHT_GRAY,
          borderRadius: '3px',
          boxSizing: 'border-box',

          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',

          '& span': {
            color: Colors.MEDIUM_GRAY,
            fontSize: '14px',
            fontWeight: 400,
            padding: '25px',
          },
        },
      },
      '& .tabulator-footer': {
        textAlign: 'left',
      },
    },
  },
  tablePageLabel: {
    margin: '0 15px 0 3px',
  },
  tooltipCell: {
    cursor: 'pointer',
    textDecoration: 'underline dashed',
    '&:hover $tooltip': {
      display: 'block',
      zIndex: 1,
    },
  },

  tooltip: {
    position: 'absolute',
    display: 'none',

    background: Colors.WHITE,
    boxSizing: 'border-box',
    boxShadow: '0 0 2px rgba(0,0,0,0.1)',
    color: Colors.DARK_GRAY,
    fontWeight: 'bold',
    lineHeight: '1.3',
    padding: '10px',
    textAlign: 'center',

    borderRadius: '5px',
    bottom: '-20px',
    width: '150px',
    right: '70px',

    '&.topRows': {
      top: -10,
      bottom: 'unset',
      '&:after': {
        top: 5,
        bottom: 'unset',
      },
    },

    '&:after': {
      content: '"\\A"',
      borderStyle: 'solid',
      borderWidth: '5px 0 5px 5px',
      borderColor: `transparent transparent transparent ${Colors.WHITE}`,
      position: 'absolute',
      right: '-5px',
      bottom: 13,
      lineHeight: 0.125,
    },
  },

  pillsCell: {
    textDecoration: 'none',
  },
});
