import React from 'react';
import { prettyPrintJson } from 'pretty-print-json';

import Colors from './Colors';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    '& ol.json-lines': {
      listStyleType: 'none',
      paddingInlineStart: '0',
      whiteSpace: 'normal',
      margin: 0,
      '& .json-key': { transition: 'all 400ms', color: 'brown' },
      '& .json-string': { transition: 'all 400ms', color: 'olive' },
      '& .json-number': { transition: 'all 400ms', color: 'navy' },
      '& .json-boolean': { transition: 'all 400ms', color: 'teal' },
      '& .json-null': { transition: 'all 400ms', color: 'dimgray' },
      '& .json-mark': { transition: 'all 400ms', color: Colors.BLACK },
      '& a.json-link': {
        transition: 'all 400ms',
        color: 'purple',
        textDecoration: 'none',
        borderBottom: '1px solid',
        outline: 'none',
        '&:visited': { color: 'slategray' },
        '&:active': { color: 'slategray' },
        '&:hover': {
          color: 'blueviolet',
          backgroundColor: 'transparent',
          outline: 'none',
        },
      },
      '& >li': {
        transition: 'all 400ms',
        whiteSpace: 'pre',
        textIndent: '0.7em',
        lineHeight: '1.5em',
        padding: 0,
        '&::marker': { display: 'none' },
        '&:nth-child(odd)': { backgroundColor: 'transparent' },
        '&:nth-child(even)': { backgroundColor: 'transparent' },
      },
    },
  },
});

type JSONProps = {
  object: { [key: string]: any } | string;
};

const PrettyJSON: React.FunctionComponent<JSONProps> = ({ object }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      dangerouslySetInnerHTML={{
        __html: prettyPrintJson.toHtml(typeof object === 'string' ? JSON.parse(object) : object, {
          lineNumbers: true,
        }),
      }}
    />
  );
};

export default PrettyJSON;
