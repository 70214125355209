import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import Colors from './Colors';

export const borderStyle = `1px solid ${Colors.LIGHT_BLUE}`;

const useStyles = createUseStyles({
  container: {},
  tabsRow: {
    display: 'flex',
    overflowX: 'auto',
    flexDirection: 'row',
    marginBottom: '-1px',
    paddingLeft: '1px',
    position: 'relative',
    zIndex: '1',
  },
  tab: {
    backgroundColor: Colors.WHITE,
    border: borderStyle,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',

    color: Colors.MAIN_BLUE,
    cursor: 'pointer',
    fontWeight: 'bold',
    marginLeft: '-1px',
    padding: '8px 10px',

    '&[data-active]': {
      backgroundColor: Colors.FAINTEST_BLUE,
      borderBottom: `1px solid ${Colors.FAINTEST_BLUE}`,
    },

    '&[data-active-light-mode]': {
      backgroundColor: Colors.FAINT_GRAY,
      borderBottom: `1px solid ${Colors.FAINT_GRAY}`,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.FAINTEST_BLUE,
    border: borderStyle,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
    position: 'relative',
  },
  lightMode: {
    backgroundColor: Colors.FAINT_GRAY,
  },
});

export type Tab = {
  header: ReactElement | string | number;
  content: ReactElement | string | number;
};

type TabbedContainersProps = {
  tabs: Tab[];
  tabHeaderClass?: string;
  tabContentClass?: string;
  tabContentStyle?: CSSProperties;
  initialTabIndex?: number;
  lightMode?: boolean;
  onTabSelect?: (idx: number) => void;
};

const TabbedContainers: React.FunctionComponent<TabbedContainersProps> = ({
  tabs,
  tabHeaderClass,
  tabContentClass,
  tabContentStyle,
  initialTabIndex,
  lightMode,
  onTabSelect,
}) => {
  const classes = useStyles();
  const [currentTabIndex, updateCurrentTabIndex] = useState(initialTabIndex || 0);

  useEffect(() => {
    updateCurrentTabIndex(initialTabIndex || 0);
  }, [initialTabIndex]);

  return (
    <div className={classes.container}>
      <div className={classes.tabsRow}>
        {tabs.map((tab, idx) => (
          <div
            className={classnames(classes.tab, tabHeaderClass)}
            key={`tab-header-${idx}`}
            data-active={idx === currentTabIndex && !lightMode ? true : undefined}
            data-active-light-mode={idx === currentTabIndex && lightMode ? true : undefined}
            onClick={() => {
              updateCurrentTabIndex(idx);
              if (onTabSelect) {
                onTabSelect(idx);
              }
            }}
          >
            {tab.header}
          </div>
        ))}
      </div>
      <div
        className={classnames(classes.content, tabContentClass, lightMode ? classes.lightMode : '')}
        style={tabContentStyle}
      >
        {tabs[currentTabIndex]?.content}
      </div>
    </div>
  );
};

export default TabbedContainers;
