import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';

import classnames from 'classnames';

import { Toast } from './Toast.tsx';
import { useStyles } from './styles';
import { ToastItem, ToasterProps } from './types';

const Toaster: React.FC<ToasterProps> = ({ toast, autoHideDuration = 3000 }) => {
  const classes = useStyles();
  const [visibleToast, setVisibleToast] = useState<ToastItem | null>(null);

  useEffect(() => {
    if (toast) {
      setVisibleToast(toast);

      const timer = setTimeout(() => {
        setVisibleToast(null);
      }, autoHideDuration);

      // Cleanup the timer on component unmount or when toast changes
      return () => clearTimeout(timer);
    }
  }, [toast, autoHideDuration]);

  return visibleToast
    ? ReactDOM.createPortal(
        <div className={classes.toasterContainer}>
          <div className={classnames(classes.toast, 'visible')}>
            <Toast {...visibleToast} />
          </div>
        </div>,
        document.getElementById('toaster-root')!
      )
    : null;
};

export default Toaster;
