import React from 'react';

import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

import { GetRelationshipHighlightsAndDrilldownTableQuery } from '../../generated/graphql';
import { Highlight } from '../Banner';
import { useSharedStyles } from '../../utils/CssUtil';

const NUMERAL_ID_OVERRIDE = 'NUMERAL_ID_OVERRIDE';

const useStyles = createUseStyles({
  header: {
    margin: '15px 0px 5px',
    minHeight: '96px',

    width: '50%',

    '& h2': {
      fontSize: '22px',
      fontWeight: 'bold',
      margin: '5px 0 15px',
    },

    '& $selectedObject': {
      fontSize: '18px',
      fontWeight: 'normal',
    },
  },
  selectedObject: {},
  highlightContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    marginBottom: '25px',
    overflowY: 'auto',

    '& > div': {
      flex: 1,
      marginRight: '0',
      minWidth: '205px',
    },
  },
  pill: {
    width: '175px',
    padding: '12px 20px',
    fontSize: '18px',
    borderRadius: '5px',
  },
});

type TRelationshipHighlightsProps = {
  primaryObjectId: string;
  data?: GetRelationshipHighlightsAndDrilldownTableQuery;
};

const ReconciliationExceptionRelationshipHighlights: React.FunctionComponent<TRelationshipHighlightsProps> = ({
  primaryObjectId,
  data,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  return (
    <>
      <div className={classes.header}>
        <h2>Exceptions Report</h2>
        <div className={classes.selectedObject}>
          <strong>Numeral ID: </strong>
          {` ${data?.relationshipHighlights.find((hl) => hl.label === NUMERAL_ID_OVERRIDE)?.value || primaryObjectId}`}
        </div>
      </div>

      <div className={classes.highlightContainer}>
        {(data?.relationshipHighlights || []).map((ea, idx) =>
          ea.label === NUMERAL_ID_OVERRIDE ? (
            <></>
          ) : (
            <Highlight
              isLoading={false}
              value={
                ea.pill ? (
                  <span
                    className={classnames(sharedClasses.cellPill, classes.pill)}
                    data-pill-category={'vendor'}
                    data-pill-value={ea.pill.label}
                  >
                    {ea.pill.label}
                  </span>
                ) : (
                  ea.value
                )
              }
              header={ea.label}
              key={`highlight-${idx}`}
              secondaryHeader={ea.pill ? ea.value : undefined}
            />
          )
        )}
      </div>
    </>
  );
};

export default ReconciliationExceptionRelationshipHighlights;
