import React, { useState } from 'react';

import { createUseStyles } from 'react-jss';
import moment from 'moment';

import Colors from './Colors';
import { DateFormatYearMonthDate } from '../../../src/utils/FormatUtil';
import DateRangerSelectorDropdown from './DateRangeSelectorDropdown';
import getAssetPath from '../../utils/AssetPathUtil';

const useStyles = createUseStyles({
  button: {
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '5px',
    padding: '3px 6px',
    '&:after': {
      content: '"▽"',
      display: 'inline-block',
      fontSize: '10px',
      marginLeft: '3px',
    },
  },
});

export const getDateRangeStr = (startDate?: Date, endDate?: Date) => {
  if (!startDate && !endDate) {
    return 'All time';
  }
  if (!startDate) {
    return 'Before ' + moment(endDate).format(DateFormatYearMonthDate);
  }
  if (!endDate) {
    return 'After ' + moment(startDate).format(DateFormatYearMonthDate);
  }
  const startStr = moment(startDate).format(DateFormatYearMonthDate);
  const endStr = moment(endDate).format(DateFormatYearMonthDate);
  if (startStr === endStr) {
    return startStr;
  }
  return [startStr, endStr].join(' to ');
};

const nowDate = new Date();

type DateRangeFilterProps = {
  startDate?: Date;
  endDate?: Date;
  onChange: (start?: Date, end?: Date) => void;
  shouldAllowFuture?: boolean;
};

const DateRangeFilter: React.FunctionComponent<DateRangeFilterProps> = ({
  startDate,
  endDate,
  onChange,
  shouldAllowFuture = false,
}) => {
  const classes = useStyles();
  const [isVisible, updateIsVisible] = useState(false);

  return (
    <div>
      <img src={`${getAssetPath()}/images/icon_calendar.svg`} height="16" />
      <span>Viewing:</span>
      <div
        className={classes.button}
        onClick={(e) => {
          e.stopPropagation();
          updateIsVisible(true);
        }}
      >
        {getDateRangeStr(startDate, endDate)}
      </div>
      {isVisible ? (
        <DateRangerSelectorDropdown
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          maxDate={shouldAllowFuture ? undefined : nowDate}
          updateIsDateSelectorVisible={updateIsVisible}
        />
      ) : null}
    </div>
  );
};

export default DateRangeFilter;
