import { last } from 'lodash';
import { useMemo } from 'react';

import { SectionBreadcrumb } from '../../../generated/graphql.tsx';
import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';
import { generateParentChildRelation } from '../../../features/revenue/utils/generateParentChildRelation.ts';

export const useTempBreadcrumbs = (
  sectionBreadcrumbs: SectionBreadcrumb[],
  selectedCellPath: Tabulator.CellComponent | null,
  breakcrumbsLabel: string
) => {
  return useMemo(() => {
    const breadcrumbs = [...(sectionBreadcrumbs || [])];
    if (breadcrumbs.length > 1 && selectedCellPath) {
      const path = selectedCellPath ? generateParentChildRelation(selectedCellPath) : [];
      if (path.length > 1) {
        path.shift();
      }
      const transaction = { ...last(breadcrumbs) } as SectionBreadcrumb;
      const date = last(transaction.label?.split(','))?.trim();
      transaction.label = `${breakcrumbsLabel}: ${path.join(', ')}, ${date}`;
      breadcrumbs[breadcrumbs.length - 1] = transaction;
    }
    return breadcrumbs;
  }, [breakcrumbsLabel, sectionBreadcrumbs, selectedCellPath]);
};
