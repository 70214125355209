import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import Banner from './Banner';
import ErrorMessage from './common/ErrorMessage';
import PaymentRecognitionScheduleCard from './PaymentRecognitionScheduleCard';
import { TSearchBarLazyQueryFn } from './SearchBar';
import { useAppContext } from '../context/AppContext.tsx';
import { useSharedStyles } from '../utils/CssUtil';
import {
  GetRecognitionScheduleQuery,
  GetRecognitionScheduleQueryVariables,
  TableHighlightFragmentFragment,
  useGetAvailableRecognitionAccountsQuery,
} from '../generated/graphql';
import { TableHighlightFragment, TransactionCellRowFragment } from '../apollo/GraphQLFragments';

const QUERY = gql`
  ${TransactionCellRowFragment}
  ${TableHighlightFragment}

  fragment TransactionRecognitionTableFragment on TransactionRecognitionTable {
    timespanStart
    timespanEnd
    companyId
    modelId
    rows {
      ...TransactionCellRowFragment
    }
    sectionBreadcrumbs {
      key
      shouldLinkLastBreadcrumb
      breadcrumbs {
        label
        url
      }
    }
    highlights {
      ...TableHighlightFragment
    }
  }

  fragment TransactionRecognitionTableContractLevelFragment on TransactionRecognitionTable {
    timespanStart
    timespanEnd
    contract {
      id
      hasBeenAmended
      entity {
        baseCurrency
        termDescription
        termLengthReadableInMonths
        totalValueBaseCurrencyFormatted
        vendorExternalId
        state
        cancelledAt
      }
      customer {
        id
      }
    }
    legends {
      cssClass
      label
    }
    rows {
      ...TransactionCellRowFragment
    }
  }

  fragment TransactionRecognitionTableInvoiceFragment on Invoice {
    id
    entity {
      vendor
      issuedAt
      vendorExternalId
      vendorExternalCustomerId
      customerActiveSince
    }
    importedAt
    paymentApplications {
      paymentId
      paymentVendorExternalId
      entity {
        currency
        baseCurrency
        formattedAmount
        formattedAmountBaseCurrency
        formattedRefundedAmount
        formattedRefundedAmountBaseCurrency
        formattedAmountForTaxPayableToDate
        formattedAmountForFXConversionCostToDate
        formattedAmountForTransactionFeeToDate
        formattedAmountForCashToDate
        formattedGrossAmount
        hasSettled
      }
      paymentEntity {
        vendor
        completedAt
      }
    }
    invoiceItems {
      id
      entity {
        description
      }
      priceListItem {
        id
        ruleId
        ruleUrl
        entity {
          sku
        }
      }
    }
  }

  query GetRecognitionSchedule(
    $transaction_id: String!
    $granularity: String
    $breadcrumb_key: String
    $model_table_name: String
    $accounts_str: String
    $should_show_invoiced_amount: Boolean
  ) {
    recognitionScheduleV2(
      transaction_id: $transaction_id
      granularity: $granularity
      breadcrumb_key: $breadcrumb_key
      model_table_name: $model_table_name
      accounts_str: $accounts_str
      should_show_invoiced_amount: $should_show_invoiced_amount
    ) {
      ...TransactionRecognitionTableFragment
    }
    paymentRecognitionScheduleContractLevel(
      transaction_id: $transaction_id
      granularity: $granularity
      model_table_name: $model_table_name
    ) {
      ...TransactionRecognitionTableContractLevelFragment
    }
    invoiceDetails(id: $transaction_id) {
      ...TransactionRecognitionTableInvoiceFragment
    }
    getJournalEntriesForInvoice(invoice_id: $transaction_id) {
      label
      entries {
        dateStr
        label
        direction
        amountStr
        isSpread
        description
        glAccount
        metadata
      }
    }
  }
`;

const PaymentRecognitionScheduleCardWithBanner: React.FunctionComponent = () => {
  const sharedClasses = useSharedStyles();
  const [params] = useSearchParams();
  const transactionId = params.get('id') || '';
  const modelTableName = params.get('mt') || '';
  const { isRealCustomerCompany } = useAppContext();
  const {
    loading: isLoading,
    error,
    data,
  } = useQuery<GetRecognitionScheduleQuery, GetRecognitionScheduleQueryVariables>(QUERY, {
    variables: {
      transaction_id: transactionId,
      granularity: params.get('tt') || '',
      breadcrumb_key: params.get('bk'),
      should_show_invoiced_amount: params.get('si') === 'true',
      model_table_name: modelTableName,
      accounts_str: params.get('at'),
    },
    fetchPolicy: 'no-cache',
  });
  const version = params.get('v');

  const { data: availableRecognitionAccountsData } = useGetAvailableRecognitionAccountsQuery({
    variables: {
      transactionId,
      modelTableName,
    },
  });

  let highlights: TableHighlightFragmentFragment[] = [
    { label: '', type: '', value: '' },
    { label: '', type: '', value: '' },
  ];

  if (data?.recognitionScheduleV2?.highlights && data?.recognitionScheduleV2.highlights.length) {
    highlights = data?.recognitionScheduleV2.highlights;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const shouldShowSearchBar = version === '2';

  return (
    <div className={sharedClasses.main}>
      <Banner
        showSearchBar={shouldShowSearchBar}
        searchBarQueryFn={TSearchBarLazyQueryFn.SEARCH_RECOGNITION_SCHEDULE}
        highlights={highlights.map((highlight) => ({
          header: highlight.label || '',
          isLoading: isLoading,
          value: highlight.value || '',
        }))}
      />
      <PaymentRecognitionScheduleCard
        isLoading={isLoading}
        isRealCustomerCompany={isRealCustomerCompany}
        availableRecognitionAccounts={availableRecognitionAccountsData?.availableRecognitionAccounts || []}
        data={data as GetRecognitionScheduleQuery}
        contractData={data?.paymentRecognitionScheduleContractLevel || undefined}
        invoice={data?.invoiceDetails || undefined}
      />
    </div>
  );
};

export default PaymentRecognitionScheduleCardWithBanner;
