import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';
import { DataGridRow } from '../DataGridTypes.ts';

export const customCssFormatter = (cell: Tabulator.CellComponent) => {
  const data = cell.getData() as DataGridRow;
  const customClass = data?._cellData?.[cell.getField()]?.customClass?.toString();
  if (customClass) {
    const cellElem = cell.getElement();
    cellElem.classList.add(customClass);
  }
  return cell.getValue();
};
