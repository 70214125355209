import { createUseStyles } from 'react-jss';

import Colors from '../components/common/Colors';
import getAssetPath from './AssetPathUtil';

export const sharedStyles = {
  main: {
    flex: 1,
    fontFamily:
      'MessinaSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    /* 264px is the sidebar width, scrollbar default width 12-17px depending on browser */
    width: 'calc(100vw - 281px)',
  },
  banner: {
    backgroundImage: `linear-gradient(to bottom, rgb(116, 162, 249), ${Colors.MAIN_BLUE})`,
    color: Colors.WHITE,
    height: 340,
  },
  loginBanner: {
    height: '35vh',
    maxHeight: 400,
    width: '100%',
  },
  bannerTop: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 30,
    minHeight: 45,
    '& div:only-child': {
      marginLeft: 'auto',
    },
  },
  contentWrapper: {
    backgroundColor: Colors.WHITE,
    borderRadius: 5,
    boxShadow: '1px 1px 3px rgba(0,0,0,0.08)',
    minHeight: 600,
    margin: '-130px 30px 30px',
    padding: '20px 25px',
    overflowX: 'hidden',
  },
  contentWrapperWithoutHighlights: {
    marginTop: -235,
  },
  contentLoaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 230,
    textAlign: 'center',
  },
  contentHeaderWrapper: {
    alignItems: 'center',
    color: Colors.MEDIUM_GRAY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20,
  },
  contentHeader: {
    display: 'inline-block',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '1px',
    padding: '5px 5px',
  },
  cardTabs: {
    display: 'flex',
  },
  // cardTab should become a new component
  cardTab: {
    borderBottom: `1px dotted ${Colors.MEDIUM_GRAY}`,
    marginRight: 15,
    padding: '2px 0',
    '& a': {
      color: Colors.MEDIUM_GRAY,
      textDecoration: 'none',
      '&:visited': {
        color: Colors.MEDIUM_GRAY,
        textDecoration: 'none',
      },
    },
  },
  cardTabSelected: {
    borderBottom: 'none',
    color: Colors.DARK_GRAY,
    textDecoration: 'none',
  },
  contentHeaderUtils: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '13px',
    justifyContent: 'stretch',
    marginLeft: 'auto',
    '& .year-label': {
      minWidth: '40px',
    },
    '& > div,> a': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'stretch',
      marginLeft: 20,
    },
    '& select': {
      border: `1px solid ${Colors.MEDIUM_GRAY}`,
      borderRadius: 3,
      color: Colors.MEDIUM_GRAY,
      marginLeft: 4,
    },
    '& img': {
      marginRight: 5,
    },
  },
  content: {
    '& table': {
      backgroundColor: Colors.TABLE_HEADER_GRAY,
      borderSpacing: 1,
      fontSize: '13px',
      width: '100%',
      '& thead': {
        fontWeight: 'bold',
        '& td': {
          backgroundColor: Colors.TABLE_HEADER_GRAY,
        },
      },
      '& tr': {
        '&.content-row-highlight': {
          '& td': {
            backgroundColor: Colors.MAIN_BLUE_HIGHLIGHT,
            color: Colors.WHITE,
            fontWeight: 'bold',
          },
        },
      },
      '& td': {
        backgroundColor: Colors.WHITE,
        border: 0,
        padding: '5px 7px',
        height: 14,
        '&.frozen-column': {
          position: 'sticky',
          left: 1,
        },
        '&.content-cell-negative': {
          color: Colors.WARNING_RED,
          '& a': {
            color: Colors.WARNING_RED,
            borderBottom: `1px dashed ${Colors.WARNING_RED}`,
          },
        },
        '&.shaded-content-cell-negative': {
          backgroundColor: Colors.FAINT_GRAY,
          fontWeight: 'bold',
          color: Colors.WARNING_RED,
          '& a': {
            color: Colors.WARNING_RED,
            borderBottom: `1px dashed ${Colors.WARNING_RED}`,
          },
        },
        '&.shaded-content-cell': {
          backgroundColor: Colors.FAINT_GRAY,
        },
        '&.content-cell-warning': {
          color: Colors.WARNING_RED,
        },
        '&.content-cell-bolded': {
          fontWeight: 'bold',
        },
        '&.indent-single': {
          paddingLeft: 20,
        },
        '&.content-cell-disabled': {
          backgroundColor: Colors.OFFWHITE,
          color: Colors.MEDIUM_GRAY,
        },
        '&.content-cell-highlight': {
          backgroundColor: Colors.LIGHT_BLUE_HIGHLIGHT,
          color: Colors.WHITE,
        },
        '&.content-cell-cancelled': {
          backgroundColor: '#ffdada',
          color: Colors.MEDIUM_GRAY,
        },
        '&.content-cell-upgraded-or-downgraded': {
          backgroundColor: '#cfecff',
          color: Colors.MEDIUM_GRAY,
        },
        '&:first-child': {
          maxWidth: 'fit-content',
          whiteSpace: 'nowrap',
        },
        '&:last-child': {
          paddingLeft: 10,
        },
        '& a': {
          color: Colors.DARK_GRAY,
          display: 'inline-block',
          textDecoration: 'underline dashed',
          textUnderlineOffset: '2px',
          '&.content-cell-warning': {
            color: Colors.WARNING_RED,
            fontWeight: 'bold',
          },
        },
      },
    },
    '& .tabulator .tabulator-header .tabulator-headers .tabulator-col': {
      backgroundColor: Colors.TABLE_HEADER_GRAY,
      border: 0,
      '& .tabulator-col-content .tabulator-col-title': {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'normal',
      },
      '&$dataGridHeaderFilter': {
        color: Colors.DARK_GRAY,
      },
    },
    '& .tabulator-tableholder .tabulator-table .tabulator-row': {
      borderBottomColor: Colors.TABLE_HEADER_GRAY,
      '&.content-row-highlight': {
        '& .tabulator-cell': {
          backgroundColor: Colors.MAIN_BLUE_HIGHLIGHT,
          color: Colors.WHITE,
          fontWeight: 'bold',
        },
      },
      '& .tabulator-cell': {
        borderRightColor: Colors.TABLE_HEADER_GRAY,
        '&.content-cell-highlight': {
          backgroundColor: Colors.LIGHT_BLUE_HIGHLIGHT,
          color: Colors.WHITE,
        },
        '&$contentCellTextWrapped': {
          whiteSpace: 'pre-wrap',
          overflowWrap: 'anywhere',
        },
        '&$contentCellRightAlign': {
          textAlign: 'right',
          justifyContent: 'flex-end',
        },
        '&$linkUnderlined': {
          color: Colors.DARK_GRAY,
          textDecoration: 'underline dashed',
          textUnderlineOffset: '2px',
        },
        '&.content-cell-negative': {
          color: Colors.WARNING_RED,
          '& a': {
            color: Colors.WARNING_RED,
            borderBottom: `1px dashed ${Colors.WARNING_RED}`,
          },
        },
        '&.content-cell-upgraded-or-downgraded': {
          backgroundColor: '#cfecff',
          color: Colors.MEDIUM_GRAY,
        },
        '&.shaded-content-cell-negative': {
          backgroundColor: Colors.FAINT_GRAY,
          fontWeight: 'bold',
          color: Colors.WARNING_RED,
          '& a': {
            color: Colors.WARNING_RED,
            borderBottom: `1px dashed ${Colors.WARNING_RED}`,
          },
        },
        '&.shaded-content-cell': {
          backgroundColor: Colors.FAINT_GRAY,
        },
        '&.content-cell-warning': {
          color: Colors.WARNING_RED,
        },
        '&.content-cell-bolded': {
          fontWeight: 'bold',
        },
      },
      '&:not(.non-group-header)': {
        '& .tabulator-cell': {
          '&.content-cell-disabled': {
            backgroundColor: Colors.OFFWHITE,
          },
        },
      },

      '&.highlighted-row': {
        '&:not(.non-group-header)': {
          '& .tabulator-cell': {
            '&.content-cell-disabled': {
              backgroundColor: Colors.RADIANT_BLUE,
            },
          },
        },
      },
    },
  },
  tabulator: {
    '& .tabulator-header .tabulator-headers .tabulator-col': {
      backgroundColor: Colors.TABLE_HEADER_GRAY,
      border: 0,
      textDecoration: 'unset',
      textUnderlineOffset: 'unset',
      display: 'inline-flex',
      flexFlow: 'row',
      alignItems: 'center',
      '&$contentCellTextWrapped': {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
      },
      '&$contentCellRightAlign': {
        textAlign: 'right',
        justifyContent: 'flex-end',
      },
      '& .tabulator-col-content .tabulator-col-title': {
        whiteSpace: 'normal',
      },
    },
    '& .tabulator-tableholder .tabulator-table .tabulator-row': {
      borderBottomColor: Colors.TABLE_HEADER_GRAY,
      '&.content-row-highlight': {
        '& .tabulator-cell': {
          backgroundColor: Colors.MAIN_BLUE_HIGHLIGHT,
          color: Colors.WHITE,
          fontWeight: 'bold',
        },
      },
      '& .tabulator-cell': {
        borderRightColor: Colors.TABLE_HEADER_GRAY,
        '&.content-cell-highlight': {
          backgroundColor: Colors.LIGHT_BLUE_HIGHLIGHT,
          color: Colors.WHITE,
        },
        '&$contentCellTextWrapped': {
          whiteSpace: 'pre-wrap',
          overflowWrap: 'anywhere',
        },
        '&$contentCellRightAlign': {
          textAlign: 'right',
          justifyContent: 'flex-end',
        },
        '&$linkUnderlined': {
          '& a': {
            color: Colors.DARK_GRAY,
            textDecoration: 'underline dashed',
            textUnderlineOffset: '2px',
          },
        },
        '&.content-cell-upgraded-or-downgraded': {
          backgroundColor: '#cfecff',
          color: Colors.MEDIUM_GRAY,
        },
      },
    },
  },
  afterTable: {
    color: Colors.MEDIUM_GRAY,
    display: 'flex',
    marginTop: 20,
  },
  afterTableLeft: {
    fontSize: '12px',
    marginRight: 'auto',
  },
  afterTableRight: {
    fontSize: '10px',
  },
  ruleCreationIfFlow: {
    fontSize: '14px',
    marginRight: 20,
    marginTop: 18,
  },
  ruleCreationTriggerFlow: {
    fontSize: '14px',
    marginRight: 20,
    marginTop: 18,
    maxWidth: 700,
  },
  ruleCreationThenFlow: {
    fontSize: '14px',
    marginRight: 20,
    marginTop: 18,
    maxWidth: 700,
  },
  ruleCreationSubheader: {
    marginBottom: 10,
  },
  ruleCreationFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formInput: {
    border: `1px solid ${Colors.MEDIUM_GRAY}`,
    borderRadius: 3,
    boxSizing: 'border-box',
    fontSize: '14px',
    height: 38,
    outline: 'none',
    padding: 10,
    '&:focus': {
      border: `2px solid ${Colors.MAIN_BLUE}`,
      padding: 9,
    },
    '&:disabled': {
      backgroundColor: Colors.LIGHT_GRAY,
    },
  },
  formBlock: {
    fontSize: '14px',
    lineHeight: '22px',
    padding: 20,
    border: `1px dotted ${Colors.MEDIUM_GRAY}`,
    borderRadius: 5,
    marginBottom: 20,
    '&:hover': {
      border: `1px solid ${Colors.DARK_GRAY}`,
    },
  },
  formBlockClickable: {
    cursor: 'pointer',
  },
  formBlockInactive: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1.0,
    },
  },
  formBlockActive: {
    border: `1px solid ${Colors.DARK_GRAY}`,
  },
  contentCellTextWrapped: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
  },
  contentCellRightAlign: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  linkUnderlined: {
    color: Colors.DARK_GRAY,
    textDecoration: 'underline dashed',
    textUnderlineOffset: '2px',
  },
  dataGridHeaderFilter: {
    '& .tabulator-col-content': {
      width: '100%',
      '& .tabulator-col-title': {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        '&:hover': {
          '&::after': {
            cursor: 'pointer',
            content: "''",
            backgroundImage: `url('${getAssetPath()}/images/icon_filter.svg')`,
            backgroundSize: '16px 16px',
            height: 16,
            width: 16,
            opacity: 0.85,
          },
        },
      },
    },
  },
  pillList: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  cellPill: {
    backgroundColor: Colors.PILL_DEFAULT,
    borderBottom: 'none',
    color: `${Colors.WHITE} !important`,
    borderRadius: 3,
    display: 'inline-block',
    fontSize: '9px',
    letterSpacing: '0.5px',
    minWidth: 50,
    padding: '4px 6px',
    textAlign: 'center',
    textTransform: 'uppercase',
    textShadow: Colors.MEDIUM_GRAY,
    marginBottom: 5,
    marginRight: 5,
    '&[data-pill-value="AMAZON_SELLER_CENTRAL"]': {
      backgroundColor: Colors.PILL_AMAZON_SELLER_CENTRAL,
    },
    '&[data-pill-value="AUTHORIZE_NET"]': {
      backgroundColor: Colors.PILL_AUTHORIZE_NET,
    },
    '&[data-pill-value="BRAINTREE"]': {
      backgroundColor: Colors.PILL_BRAINTREE,
    },
    '&[data-pill-value="CHARGEBEE"]': {
      backgroundColor: Colors.PILL_CHARGEBEE,
    },
    '&[data-pill-value="CHASE"]': {
      backgroundColor: Colors.PILL_CHASE,
    },
    '&[data-pill-value="HOMEGROWN"]': {
      backgroundColor: Colors.PILL_HOMEGROWN,
    },
    '&[data-pill-value="MODERN_TREASURY"]': {
      backgroundColor: Colors.PILL_MODERN_TREASURY,
    },
    '&[data-pill-value="OANDA"]': {
      backgroundColor: Colors.PILL_OANDA,
    },
    '&[data-pill-value="PAYMENTECH"]': {
      backgroundColor: Colors.PILL_PAYMENTECH,
    },
    '&[data-pill-value="PAYPAL"]': {
      backgroundColor: Colors.PILL_PAYPAL,
    },
    '&[data-pill-value="SALESFORCE"]': {
      backgroundColor: Colors.PILL_SALESFORCE,
    },
    '&[data-pill-value="SHOPIFY"]': {
      backgroundColor: Colors.PILL_SHOPIFY,
    },
    '&[data-pill-value="STRIPE"]': {
      backgroundColor: Colors.PILL_STRIPE,
    },
    '&[data-pill-value="TELISPIRE"]': {
      backgroundColor: Colors.PILL_TELISPIRE,
    },
    '&[data-pill-category="reconciliationStatus"]': {
      fontSize: '8px',
      display: 'block',
      whiteSpace: 'nowrap',
      minWidth: 120,
      '&[data-pill-value="RECONCILED_WITHOUT_EXCEPTIONS"]': {
        backgroundColor: Colors.PILL_RECONCILED_WITHOUT_EXCEPTIONS,
      },
      '&[data-pill-value="RECONCILED_WITH_EXCEPTIONS_RESOLVED"]': {
        backgroundColor: Colors.PILL_RECONCILED_WITH_EXCEPTIONS_RESOLVED,
      },
      '&[data-pill-value="RECONCILED_WITH_EXCEPTIONS"]': {
        backgroundColor: Colors.PILL_RECONCILED_WITH_EXCEPTIONS,
      },
      '&:last-child': {
        marginBottom: 5,
        marginRight: 5,
      },
    },
  },
  cellPillA: {
    textDecoration: 'none !important',
  },
  frozenHeader: {
    background: Colors.WHITE,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  chartContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  chartTitle: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 0,
    width: '100%',
    position: 'absolute',
    top: 11,
    // left: 'calc(50% - 50px)',
    color: Colors.DARK_GRAY,
  },
  horizontallyScrollableContent: {
    minHeight: '480px',
    overflowX: 'scroll',
    paddingBottom: '20px', // to show the scroll bar
  },
  loaderContainer: {
    textAlign: 'center',
    marginTop: '150px',
    marginRight: '85px',
    display: 'flex',
    justifyContent: 'center',
  },
};

export const useSharedStyles = createUseStyles(sharedStyles);
