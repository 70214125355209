import { first, last, omit } from 'lodash';
import { RevenueRowGroupOuput, RevenueRowOuput, RevenueSectionOuput } from '../../../generated/graphql.tsx';

export type DraggedItems = [...string[], Dimensions];
export type Dimensions = { value: string; label?: string; dimensionKeys: Record<string, string[]>; rowLabel: string };

export const removeTypename = (data: RevenueRowGroupOuput[] | RevenueRowGroupOuput | RevenueSectionOuput[]) => {
  if (Array.isArray(data)) {
    return data.map((item) => removeTypename(item));
  } else if (data !== null && typeof data === 'object') {
    const cleanedData = omit(data, '__typename');
    for (const key in cleanedData) {
      cleanedData[key] = removeTypename(cleanedData[key]);
    }
    return cleanedData;
  } else {
    return data;
  }
};

const findAndInsertSelectedDimensions = (
  row: RevenueRowOuput,
  dimension: Dimensions,
  defaultPivot: RevenueRowOuput,
  depth: number
): RevenueRowOuput => {
  const processNestedRows = (row: RevenueRowOuput, currentDepth: number) => {
    if (currentDepth === 1) {
      row.rows = [
        generateSelectedDimension(dimension, defaultPivot, {
          ...row.dimensionKeys,
        }),
      ];
      return;
    }

    // If there are nested rows, recursively process them
    if (row.rows && row.rows.length > 0) {
      processNestedRows(first(row?.rows) as RevenueRowOuput, currentDepth - 1);
    }
  };

  processNestedRows(row, depth);

  return row;
};

const generateSelectedDimension = (
  dimension: Dimensions,
  defaultPivot: RevenueRowOuput,
  additionalDimensionsKeys: Record<string, string[]>
): RevenueRowOuput => {
  return {
    label: dimension.rowLabel ?? '',
    dimensionKeys: dimension.dimensionKeys ?? {
      ...additionalDimensionsKeys,
      [dimension.value]: defaultPivot.dimensionKeys[dimension.value],
    },
  };
};

export const transformedDraggedDimension = (
  defaultPivots: RevenueRowGroupOuput[],
  draggedHierarchy: DraggedItems[]
): RevenueRowGroupOuput[] => {
  let newAppliedPivots: RevenueRowGroupOuput[] = [];
  draggedHierarchy.forEach((hierarchy) => {
    const minimumDepthRequired = 3;
    const [rowGroupLabel, rowLabel, ...rest] = hierarchy;
    newAppliedPivots = defaultPivots.map((rowGroup) => {
      if (rowGroup.label === rowGroupLabel) {
        return {
          ...rowGroup,
          rows: rowGroup?.rows?.map((row) => {
            if (row.label === rowLabel) {
              // In this case 3 item in hierarchy will always be dimension
              return {
                ...row,
                rows: [
                  hierarchy.length === minimumDepthRequired
                    ? generateSelectedDimension(first(rest) as Dimensions, row, row.dimensionKeys)
                    : findAndInsertSelectedDimensions(
                        first(row.rows) as RevenueRowOuput,
                        last(rest) as Dimensions,
                        row,
                        hierarchy.length - minimumDepthRequired
                      ),
                ],
              };
            }
            return row;
          }),
        };
      }
      return rowGroup;
    });
  });

  return removeTypename(newAppliedPivots);
};
