import { FetchResult, NextLink, Observable, Operation } from '@apollo/client/core';

export class CancelableRequest {
  controller: AbortController;
  operation: Operation;
  forward: NextLink;

  constructor(operation: Operation, forward: NextLink) {
    this.controller = new AbortController();
    this.operation = operation;
    this.forward = forward;
  }

  execute(): Observable<FetchResult> {
    return new Observable<FetchResult>((observer) => {
      const modifiedOperation = this.operation;
      modifiedOperation.setContext(({ fetchOptions = {} }) => ({
        fetchOptions: {
          ...fetchOptions,
          signal: this.controller.signal,
        },
      }));

      const subscription = this.forward(modifiedOperation).subscribe({
        next: (result) => observer.next(result),
        error: (error) => observer.error(error),
        complete: () => observer.complete(),
      });

      return () => {
        this.controller.abort();
        subscription.unsubscribe();
      };
    });
  }
}
